<template>
    <div class="inner-menu-t2-wrap">
        <div class="inner-menu-t2 inner-menu-t2_tours desktop-menu-template">
            <div class="menu-link-t2__item" @mouseover="showSubMenu" @mouseleave="hideSubMenu">
                <div class="menu-link-t2__label" :class="{ 'dropdown-icon': this.totalItems > 1 }">
                    <div class="menu-link-t2__label__title">
                        <span class="aside-menu-localisation-element-padding">{{ currentOptionText }}</span>
                    </div>
                </div>
                <div class="sub-menu" v-show="isSubMenuVisible" v-if="this.totalItems > 1">
                    <template v-for="(tour, tourIndex) in tourshotspots">
                        <template v-for="(room, roomIndex) in tour.rooms">
                            <div v-if="room.hotspots.length" class="sub-menu-item">
                                <div
                                    class="sub-menu-item__title"
                                    @click="
                                        onUpdateTourRoomHotspots({
                                            tourIndex: tourIndex,
                                            roomIndex: roomIndex,
                                            hotspotIndex: 0
                                        })
                                    "
                                    :class="{ active: activeEntity.id === room.id }"
                                >
                                    <span>{{ room.title }}</span>
                                </div>
                            </div>
                        </template>
                    </template>
                    <template v-if="mediaItems && mediaItems.length">
                        <div v-for="mediaItem in mediaItems" :key="mediaItem.id" class="sub-menu-item">
                            <div
                                class="sub-menu-item__title"
                                @click="onSetActiveEntity(mediaItem)"
                                :class="{ active: activeEntity.id === mediaItem.id }"
                            >
                                <span v-html="mediaItem.title" />
                            </div>
                        </div>
                    </template>
                    <template v-if="maps && maps.length">
                        <div v-for="mapItem in maps" :key="mapItem.id" class="sub-menu-item">
                            <div
                                class="sub-menu-item__title"
                                @click="onSetActiveEntity(mapItem)"
                                :class="{ active: activeEntity.id === mapItem.id }"
                            >
                                <span>{{ mapItem.title }}</span>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="inner-menu-t2" v-if="currentOptionItem.tour">
            <div class="menu-link-t2__item">
                <div class="menu-link-t2__label ico">
                    <div class="menu-link-t2__label__title">
                        <span class="aside-menu-localisation-element-padding" @click="enterVR">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                                <g
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    color="currentColor"
                                >
                                    <path
                                        d="M17 9H7c-1.87 0-2.804 0-3.5.442c-.456.29-.835.706-1.098 1.208C2 11.416 2 12.444 2 14.5s0 3.084.402 3.85c.263.502.642.918 1.098 1.208C4.196 20 5.13 20 7 20c.613 0 .92 0 1.21-.047a3.85 3.85 0 0 0 2.083-1.073c.219-.215.415-.474.808-.993l.334-.44c.198-.263.298-.394.42-.446a.46.46 0 0 1 .315-.015c.125.039.235.16.454.4l.962 1.058c.238.263.357.394.482.51c.638.596 1.43.957 2.264 1.031c.162.015.33.015.668.015c1.87 0 2.804 0 3.5-.442c.456-.29.835-.706 1.098-1.208c.402-.766.402-1.794.402-3.85s0-3.084-.402-3.85A3.17 3.17 0 0 0 20.5 9.442C19.804 9 18.87 9 17 9m-1 4h2"
                                    />
                                    <path
                                        d="M22 15v-2.548c0-.98 0-1.47-.077-1.943a6 6 0 0 0-.157-.69c-.133-.455-.339-.875-.751-1.715c-.827-1.685-1.24-2.527-1.843-3.093a3.9 3.9 0 0 0-.97-.67C17.495 4 16.692 4 15.084 4H8.916c-1.607 0-2.41 0-3.117.34a3.9 3.9 0 0 0-.97.671c-.602.566-1.016 1.408-1.843 3.093c-.412.84-.618 1.26-.751 1.715a6 6 0 0 0-.157.69C2 10.982 2 11.472 2 12.452V15"
                                    />
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import locationMixin from '@front/mixins/locationMixin';
import homeMenuMixin from '@front/mixins/homeMenuMixin';

export default {
    mixins: [locationMixin, homeMenuMixin],
    data() {
        return {
            subMenuVisible: false,
            currentOptionText: '',
            currentOptionItem: {},
            totalItems: 0
        };
    },
    watch: {
        activeEntity: {
            handler(newVal) {
                this.currentOptionItem = newVal;
                if (newVal && newVal.id) {
                    let activeItem = this.findActiveItem(newVal.id);
                    this.currentOptionText = activeItem ? activeItem.title : '';
                } else {
                    this.currentOptionText = '';
                }
            }
        }
    },
    computed: {},
    methods: {
        enterVR() {
            var krpano = document.getElementById('locationPano');
            krpano?.call('webvr.enterVR()');
        },
        onUpdateTourRoomHotspots(data) {
            this.updateTourRoomHotspots(data);
            const room = this.tourshotspots[data.tourIndex].rooms[data.roomIndex];
            this.setActiveEntity(room);
        },
        onSetActiveEntity(data) {
            this.setActiveEntity(data);
        },
        showSubMenu() {
            this.subMenuVisible = true;
        },
        hideSubMenu() {
            this.subMenuVisible = false;
        },
        isSubMenuVisible() {
            return this.subMenuVisible;
        },
        findActiveItem(id) {
            const allItems = [...this.tourshotspots.flatMap((tour) => tour.rooms), ...this.mediaItems, ...this.maps];
            this.totalItems = allItems.length;
            return allItems.find((item) => item.id === id) || null;
        }
    },
    mounted() {
        setTimeout(() => {
            if (this.activeEntity && this.activeEntity.id) {
                this.currentOptionText = this.findActiveItem(this.activeEntity.id).title || '';
                this.currentOptionItem = this.findActiveItem(this.activeEntity.id);
            }
        }, 50);
    }
};
</script>

<style scoped>
.inner-menu-t2-wrap {
    display: flex;
    gap: 15px;
}

.inner-menu-t2 {
    position: relative;
}

.menu-link-t2__item {
    position: relative;
}

.sub-menu {
    top: 100%;
    left: 0;
    display: none;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.5) !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #fff;
    border-radius: 0 0 4px 4px;
    padding-bottom: 10px;
}

.menu-link-t2__item:hover .sub-menu {
    display: block;
}

.sub-menu-item {
    padding: 8px 20px;
    font-size: 12px;
    letter-spacing: 0.1em;
}

.sub-menu-item__title {
    cursor: pointer;
}

.sub-menu-item__title.active {
    font-weight: bold;
}

.menu-link-t2__label {
    color: #ffffff;
    font-family: 'Open Sans';
    font-size: 18px;
    border-radius: 4px;
    letter-spacing: 0.1em;
    text-decoration: none;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    line-height: 1;
    box-sizing: border-box;
    height: 100%;
    padding: 13px 24px;
}

.menu-link-t2__label.dropdown-icon {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20"><path fill="%23FFFFFF" d="M10.103 12.778L16.81 6.08a.69.69 0 0 1 .99.012a.726.726 0 0 1-.012 1.012l-7.203 7.193a.69.69 0 0 1-.985-.006L2.205 6.72a.727.727 0 0 1 0-1.01a.69.69 0 0 1 .99 0z"/></svg>')
        no-repeat right 15px center;
    background-color: rgba(0, 0, 0, 0.5);
    padding-right: 40px;
}

.menu-link-t2__label.ico {
    padding: 7px 15px;
    cursor: pointer;
}

.menu-link-t2__item:hover .menu-link-t2__label {
    border-radius: 4px 4px 0 0;
}
</style>
