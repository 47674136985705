<template>
    <button
        :class="['cl-button']"
        :style="{
            border: `1px solid ${textColor}`,
            ...styleObject,
        }"
        @click="clickHandle"
        v-html="label"
    />
</template>

<script>
import {hexToHsl} from '@common/helpers/hexToHsl';

export default {
    name: 'CustomButton',
    props: {
        label: {
            type: String,
            default: () => '',
        },
        textColor: {
            type: String,
            default: () => null,
        },
        backgroundColor: {
            type: String,
            default: () => null,
        },
    },
    emits: ['click'],
    computed: {
        styleObject() {
            const {h, s, l} = hexToHsl(this.backgroundColor);

            return {
                '--accent-color': this.textColor || null,
                '--background-color': this.backgroundColor || null,
                '--hue': h,
                '--saturation': `${s}%`,
                '--light': `${l}%`,
            };
        },
    },
    methods: {
        clickHandle(e) {
            this.$emit('click', e);
        },
    },
};
</script>

<style lang="scss">
.cl-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;
    width: 100%;
    height: 60px;
    border: 1px solid var(--accent-color);
    border-radius: 30px;
    color: var(--accent-color);
    background-color: var(--background-color);
    cursor: pointer;
    transition: border 0.2s ease-in;

    * {
        font-size: 20px;
        letter-spacing: 0.8px;
        padding: 0;
        margin: 0;
    }

    @media (max-width: 768px) {
        * {
            font-size: 20px !important;
        }
    }

    &:hover {
        border: 3px solid var(--accent-color) !important;
        background-color: hsl(var(--hue), var(--saturation), calc(var(--light) - 10%)) !important;
        color: var(--secondary-color);
    }
}
</style>
