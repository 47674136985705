<template>
    <transition name="lot-list">
        <div class="scheme-lot-list--wrapper">
            <div id="my-choices-btn" class="v360-menu-btns v360-menu-btns_toggle scheme-lot-list__v360 mes-choix-btn"
                :class="isComparatorVisible ? 'v360-btn-active' : ''" @click="toggleComparator">
                <img :src="$getImagePath('icons/picto-dispo-wt.svg')" :alt="altImgText" class="v360-menu-btns__img" />
                <p class="v360-menu-btns__text">Mes Choix</p>
                <div v-if="favorites.length" class="add-to-favorite">
                    {{ favorites.length }}
                </div>
            </div>
            <ModalComparator v-show="isComparatorVisible" @close="toggleComparator" />
            <div class="scheme-lot-list__close scheme-lot-list__close_recherche">
                <button type="button" class="btn-close" @click="onCloseHandler">
                    <img v-if="!isDarkTheme" :src="$getImagePath('icons/icon-close-black.svg')" :alt="altImgText" />
                    <img v-else :src="$getImagePath('icons/icon-close.svg')" :alt="altImgText" />
                </button>
            </div>
            <div class="scheme-lot-list__left-menu scheme-lot-list__left-menu_disable">
                <div class="back-btn back-btn--table" @click="onCloseHandler" />
                <p class="scheme-lot-list--title">RECHERCHE</p>
                <RoomsFilterScheme3d class="scheme-lot-list__filters" />
            </div>
            <!-- <div class='scheme-lot-list__line'></div> -->
            <div class="scheme-lot-list__table-wr">
                <div class="scheme-lot-list__table__title">résultats</div>
                <div class="scheme-lot-list__table-results">
                    <ul v-for="col in colCount" :key="`col_${col}`" class="scheme-lot-list__table--column">
                        <li v-for="(lot, index) in lotDataForCol(col)" :key="lot.color + index">
                            <LotListElement :lot="lot" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import clone from 'lodash.clonedeep';
import RoomsFilterScheme3d from '@front/components/Scheme3dLotInfo/RoomsFilterScheme3d.vue';
import LotListElement from '@front/components/Scheme3dLotInfo/LotList/LotListElement.vue';
import ModalComparator from '@front/components/Comparator/ModalComparator.vue';

export default {
    name: 'LotList',
    components: {
        LotListElement,
        RoomsFilterScheme3d,
        ModalComparator,
    },
    data() {
        return {
            isComparatorVisible: false,
            colSize: 10,
        };
    },
    computed: {
        ...mapGetters({
            lotsData: 'scheme/lotsData',
            isDarkTheme: 'theme/isDarkTheme',
            favorites: 'scheme/favorites',
            altImgText: 'config/altImgText',
        }),
        tableData() {
            return this.filterLots(this.lotsData);
        },
        colCount() {
            return Math.ceil(this.tableData.length / this.colSize);
        },
    },
    methods: {
        ...mapMutations({
            toggleActivityAdditionalWindow: 'navigation/toggleActivityAdditionalWindow',
            changeIsMenuMustBeStatic: 'navigation/changeIsMenuMustBeStatic',
            changeIsMenuMustBeHidden: 'navigation/changeIsMenuMustBeHidden',
            setIsCloseBurgerButtonHide: 'navigation/setIsCloseBurgerButtonHide',
        }),
        filterLots(lots) {
            return this.array(lots).filter((lot) => {
                return ![this.$constants.statuses.LIVRAISON].includes(lot.status) && lot.published;
            });
        },
        toggleComparator() {
            this.isComparatorVisible = !this.isComparatorVisible;
        },
        toggleInfo() {
            this.isInfoVisible = !this.isInfoVisible;
        },
        onCloseHandler() {
            if (this.$route?.query?.results) {
                this.$router.replace({ query: null }).catch((error) => error);
            }
            this.toggleActivityAdditionalWindow();
            this.changeIsMenuMustBeStatic(false);
            this.changeIsMenuMustBeHidden(false);
            this.setIsCloseBurgerButtonHide(false);
        },
        sortData(data) {
            return data
                .filter((item) => item.status === this.$constants.statuses.DISPO)
                .sort((a, b) => {
                    const first = clone(a);
                    const second = clone(b);

                    if (first.rooms === 'studio') {
                        first.rooms = 0;
                    }
                    if (second.rooms === 'studio') {
                        second.rooms = 0;
                    }

                    return +second.rooms - +first.rooms;
                });
        },
        lotDataForCol(column) {
            return this.sortData(this.tableData).slice((column - 1) * this.colSize, column * this.colSize);
        },
        array(input) {
            return Array.isArray(input) ? input : []
        }
    },
};
</script>

<style>
.toggles {
    color: white;
    cursor: pointer;
}
</style>
