<template>
    <div
        v-if="isLoaderVisible"
        :class="!isLoaderActive ? 'warning-modal' : 'warning-modal--rel'"
    >
        <button
            v-if="$route.path !== '/scheme3d'"
            class="warning-modal__close"
            style="display: none"
            @click="close"
        >
            <img
                :src="$getImagePath('icons/icon-close-black.svg')"
                alt=""
            />
        </button>
        <div class="warning-modal__content">
            <div class="warning-modal__content__icon">
                <svg
                    width="228"
                    height="226"
                    viewBox="0 0 228 226"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M82.9437 4.15674C86.4437 4.35674 89.7437 5.35674 92.4437 7.75674C93.1437 8.35674 93.8437 9.05674 94.4437 9.65674C135.344 50.5567 176.344 91.5568 217.244 132.457C221.944 137.157 223.944 142.657 221.644 149.057C220.844 151.257 219.444 153.457 217.744 155.157C197.344 175.757 176.844 196.157 156.344 216.657C149.644 223.357 140.644 223.357 133.944 216.657C92.6437 175.357 51.3437 134.057 10.0437 92.8568C3.44375 86.2568 3.44375 77.2567 10.0437 70.6567C30.5437 50.1567 51.0437 29.6567 71.5437 9.15674C74.7437 5.95674 78.4437 4.35674 82.9437 4.15674ZM214.744 144.357C214.444 141.457 212.844 139.657 211.144 137.957C170.344 97.2568 129.544 56.4567 88.7437 15.6567C84.4437 11.3567 81.0437 11.3567 76.7437 15.6567C56.7437 35.5567 36.8438 55.5568 16.8438 75.4568C12.3438 79.9568 12.2437 83.3568 16.7437 87.8568C33.5437 104.657 50.4437 121.557 67.2437 138.357C91.2437 162.357 115.244 186.357 139.344 210.457C143.044 214.157 146.844 214.357 150.244 210.957C170.844 190.457 191.344 169.857 211.844 149.257C213.244 147.957 213.844 146.057 214.744 144.357Z"
                        fill="black"
                    />
                    <path
                        d="M161.244 14.4566C183.944 23.0566 201.544 37.4566 213.944 57.9566C221.444 70.4566 225.944 83.9566 227.744 98.4566C228.044 101.057 226.644 103.157 224.344 103.657C222.144 104.057 220.144 102.557 219.544 99.8566C217.944 92.7566 216.944 85.4566 214.544 78.7566C204.944 52.2566 186.744 33.7566 160.644 23.1566C160.244 22.9566 159.844 22.8566 159.444 22.7566C159.344 22.7566 159.144 22.7566 158.744 22.6566C159.044 23.4566 159.344 24.0566 159.644 24.6566C161.544 28.3566 163.544 31.9566 165.444 35.6566C166.544 37.8566 165.844 40.0566 163.944 41.1566C162.044 42.2566 159.544 41.8566 158.444 39.7566C154.544 32.7566 150.844 25.6566 147.144 18.4566C145.844 15.8566 146.844 12.8566 149.444 11.3566C156.244 7.55657 163.144 3.95656 170.044 0.456561C172.144 -0.643439 174.444 0.356564 175.444 2.25656C176.444 4.25656 175.844 6.45656 173.644 7.65656C170.444 9.45656 167.044 11.1566 163.844 12.9566C162.944 13.4566 162.244 13.9566 161.244 14.4566Z"
                        fill="black"
                    />
                    <path
                        d="M69.4441 203.157C67.1441 198.757 65.0441 194.857 62.9441 190.857C61.4441 187.957 61.9441 185.757 64.1441 184.457C66.4441 183.257 68.6441 184.057 70.1441 186.857C73.5441 193.157 76.8441 199.357 80.1441 205.657C82.3441 209.757 81.3441 212.757 77.2441 214.957C70.9441 218.257 64.6441 221.557 58.4441 224.957C56.3441 226.057 54.4441 225.857 52.9441 223.957C51.6441 222.357 51.6441 220.657 52.8441 219.057C53.4441 218.257 54.3441 217.757 55.2441 217.257C58.8441 215.257 62.5441 213.357 66.3441 211.357C62.2441 209.457 58.1441 207.857 54.4441 205.857C24.9441 189.957 7.04414 165.557 0.844142 132.557C0.544142 131.157 0.444137 129.757 0.144137 128.257C-0.455863 124.757 0.844139 122.357 3.54414 122.057C6.04414 121.757 7.94414 123.657 8.34414 127.157C9.24414 135.357 11.3441 143.257 14.5441 150.857C24.7441 175.157 42.1441 192.257 66.4441 202.357C66.9441 202.557 67.5441 202.757 68.1441 202.957C68.5441 203.057 68.7441 203.057 69.4441 203.157Z"
                        fill="black"
                    />
                    <path
                        d="M157.145 167.457C157.145 161.057 162.045 156.057 168.545 156.057C174.845 156.057 180.045 161.157 180.045 167.457C180.045 173.657 174.845 178.957 168.545 178.957C162.245 178.957 157.145 173.757 157.145 167.457ZM171.645 167.557C171.745 165.857 170.245 164.257 168.545 164.257C166.845 164.257 165.245 165.757 165.345 167.457C165.445 169.357 166.545 170.357 168.345 170.557C170.145 170.757 171.645 169.357 171.645 167.557Z"
                        fill="black"
                    />
                    <path
                        d="M66.6438 45.0566C65.7438 46.5566 65.2438 47.7566 64.4438 48.6566C59.5438 53.6566 54.6438 58.5566 49.7438 63.3566C47.6438 65.4566 45.2438 65.5566 43.4438 63.6566C41.7438 61.8566 41.8438 59.5566 43.8438 57.5566C48.7438 52.5566 53.7438 47.6566 58.7438 42.6566C60.1438 41.2566 61.7438 40.4566 63.5438 41.5566C64.7438 42.4566 65.5438 43.8566 66.6438 45.0566Z"
                        fill="black"
                    />
                </svg>
            </div>
            <div class="warning-modal__content__text">
                {{ title ? title : `Merci de pivoter votre mobile pour pouvoir utiliser la maquette interactive.` }}
            </div>
        </div>
        <div class="warning-modal__separator" />
        <div
            v-if="gallery === 'scheme3d'"
            class="warning-modal__content"
        >
            <div class="warning-modal__content__icon">
                <svg
                    width="235"
                    height="219"
                    viewBox="0 0 235 219"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M234.809 171.8C234.709 171.8 234.609 171.9 234.609 171.9C232.309 181.2 224.909 186.1 214.609 186C192.209 185.7 169.809 185.9 147.509 185.9H144.209C145.709 191.7 147.609 197.4 149.809 203C152.209 208 157.309 211.1 162.809 210.9C171.009 211 179.109 210.9 187.309 211C188.509 211 189.709 211.3 190.709 212C192.109 213.1 192.409 215.2 191.209 216.6C190.809 217.1 190.309 217.5 189.609 217.7C188.709 218 187.709 218.1 186.709 218.1C140.509 218.1 94.3087 218.1 48.0087 218.1C47.7087 218.1 47.4087 218.1 47.1087 218.1C44.4087 218 42.7087 216.5 42.8087 214.4C42.9087 212.4 44.5086 211 47.2086 211C55.1086 211 63.1087 210.8 71.0087 211C78.4087 211.1 84.9087 206.1 86.8087 198.9C87.7087 194.6 89.2087 190.4 90.5087 185.9H87.5087C64.5087 185.9 41.5087 185.9 18.5087 185.9C11.0087 186.4 4.10868 182 1.30868 175C0.508683 172.8 0.108671 170.5 0.108671 168.1C0.00867119 118 0.0086956 67.9 0.0086956 17.7C-0.291304 8.20001 7.20865 0.2 16.7086 0C16.9086 0 17.1087 0 17.3087 0C18.1087 0 19.0087 0 19.8087 0H213.009C225.809 0 229.709 2.50001 234.609 14.1V171.8H234.809ZM7.0087 137.3H227.909C227.909 136.7 228.009 136.4 228.009 136C228.009 96.6 228.009 57.2 228.009 17.7C228.309 12.1 224.009 7.3 218.309 7C217.909 7 217.509 7 217.109 7C150.709 7 84.3087 7 18.0087 7C16.9087 7 15.7087 7.1 14.6087 7.3C9.80867 8.7 6.60867 13.3 7.10867 18.3C7.10867 57.2 7.10867 96.1 7.10867 135V137.3H7.0087ZM7.0087 144.8V147.2C7.0087 153.8 7.20866 160.3 6.90866 166.9C6.30866 173 10.7087 178.4 16.8087 179C17.5087 179.1 18.2087 179.1 18.9087 179C36.2087 178.7 53.6087 178.9 70.9087 178.9C119.309 178.9 167.709 178.8 216.209 179C222.209 179.5 227.509 174.9 228.009 168.9C228.009 168.3 228.009 167.8 228.009 167.2C227.809 160.6 227.909 153.9 227.909 147.3V144.9L7.0087 144.8ZM88.3087 210.7H146.609C140.309 203.4 140.309 194.1 136.909 186H98.0087C94.6087 194.1 94.6087 203.5 88.3087 210.7Z"
                        fill="#2F2F30"
                    />
                    <path
                        d="M214.008 21.0002H20.7081V63.9002C20.7081 64.4002 20.7081 65.0002 20.7081 65.5002C20.7081 67.4002 19.2081 69.0002 17.2081 69.0002C15.3081 69.0002 13.8081 67.4002 13.8081 65.5002V65.4002C13.8081 60.1002 13.8081 54.7002 13.8081 49.3002C13.8081 38.9002 13.8081 28.5002 13.8081 18.1002C13.8081 15.0002 15.0081 13.8002 18.0081 13.7002C18.4081 13.7002 18.8081 13.7002 19.1081 13.7002H215.608C216.108 13.7002 216.508 13.7002 217.008 13.7002C219.808 13.8002 221.008 15.0002 221.008 17.8002C221.008 21.4002 221.008 25.0002 221.008 28.6002C221.008 61.0002 221.008 93.4002 221.008 125.8C221.008 129.7 219.908 130.8 216.008 130.8C150.308 130.8 84.6081 130.8 18.9081 130.8C14.8081 130.8 13.8081 129.8 13.8081 125.6C13.8081 110.6 13.8081 95.7002 13.8081 80.7002C13.8081 80.2002 13.8081 79.8002 13.8081 79.3002C13.6081 77.4002 15.0081 75.7002 16.9081 75.6002C18.8081 75.5002 20.5081 76.8002 20.6081 78.7002C20.6081 78.9002 20.6081 79.1002 20.6081 79.3002C20.6081 88.7002 20.6081 98.1002 20.6081 107.5C20.6081 112.8 20.6081 118 20.6081 123.4H213.908L214.008 21.0002Z"
                        fill="#2F2F30"
                    />
                    <path
                        d="M127.909 161.6C127.909 167.4 123.309 172 117.609 172.1C111.809 172.1 107.209 167.5 107.109 161.8C107.109 156 111.709 151.4 117.409 151.3C117.409 151.3 117.409 151.3 117.509 151.3C123.109 151.4 127.709 155.9 127.909 161.6ZM117.509 165C119.309 165 120.809 163.5 120.809 161.7C120.809 159.9 119.309 158.4 117.509 158.4C115.709 158.4 114.209 159.9 114.209 161.7C114.109 163.4 115.509 164.9 117.209 165C117.309 165 117.409 165 117.509 165Z"
                        fill="#2F2F30"
                    />
                </svg>
            </div>
            <div class="warning-modal__content__text">
                {{
                    description
                        ? description
                        : `Votre appareil n'est pas adapté pour la visualisation de la maquette 3D.
                        Pour une meilleure expérience, veuillez utiliser un écran plus grand.`
                }}
            </div>
        </div>
        <button
            v-if="$route.path !== '/scheme3d'"
            class="warning-modal__btn"
            style="display: none"
            @click="close"
        >
            <span>J'AI COMPRIS !</span>
        </button>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {toggleFullScreen} from '@front/utils/toggleFullScreen';

export default {
    name: 'WarningModal',
    props: {
        gallery: {
            type: String,
            default: () => '',
        },
        title: {
            type: String,
            default: () => '',
        },
        description: {
            type: String,
            default: () => '',
        },
    },
    data() {
        return {
            isVisible: true,
            isPortrait: false,
        };
    },
    computed: {
        ...mapGetters({
            isLoaderActive: 'loader/isLoaderActive',
        }),
        modalVisibility() {
            return this.isVisible && window.innerWidth < 1368 && window.screen.availHeight > window.screen.availWidth;
        },
        isLoaderVisible() {
            return !this.isLoaderActive && this.isPortrait && this.modalVisibility;
        },
    },
    mounted() {
        this.handleOrientationChange();
        window.addEventListener('orientationchange', this.handleOrientationChange);
    },
    methods: {
        toggleFullScreen,
        ...mapActions({
            loaderHide: 'loader/hide',
        }),
        close() {
            this.isVisible = false;
            this.loaderHide();
        },
        openFullScreen() {
            this.isVisible = false;
            this.loaderHide();
            this.toggleFullScreen('app-wrapper');
        },
        handleOrientationChange() {
            switch (window.orientation) {
                case 0:
                    this.isPortrait = true;
                    this.isVisible = true;
                    break;

                case 180:
                    this.isPortrait = true;
                    this.isVisible = true;
                    break;

                case -90:
                    this.isPortrait = false;
                    break;

                case 90:
                    this.isPortrait = false;
                    break;
            }
        },
    },
};
</script>
