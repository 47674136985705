<template>
    <div class="contact-from-error">
        <div
            v-click-outside="() => $emit('close')"
            class="content-box"
        >
            <button
                class="contact-from-error__close"
                type="button"
                @click="$emit('close')"
            >
                <div class="horizontal" />
                <div class="vertical" />
            </button>
            <h4 v-html="title" />
            <p v-html="desription" />
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactFormError',
    props: {
        title: {
            type: String,
            default: () => '',
        },
        desription: {
            type: String,
            default: () => '',
        },
    },
    emits: ['close'],
};
</script>

<style lang="scss" scoped>
$black: #000000;
$white: #fff;
$primary: #0c3431;
$secondary: #814639;
$chart: #f9f9f9;

.contact-from-error {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    right: 0;

    .content-box {
        background-color: $chart;
        width: 538px;
        height: auto;
        max-width: 100%;
        position: relative;
        margin: auto;
        text-align: center;
        padding: 100px 25px 100px 25px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
        .contact-from-error__close {
            height: 38px;
            width: 38px;
            position: absolute;
            right: 10px;
            top: 10px;
            color: $primary;
            font-size: 22px;
            text-align: center;
            padding-top: 3px;
            cursor: pointer;
            border: 2px solid $primary;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: rotate(45deg);
            padding: 0 !important;
            .horizontal {
                width: 70%;
                height: 2px;
                background-color: $primary;
                border-radius: 2px;
                @media (max-width: 576px) {
                    height: 4px;
                }
            }
            .vertical {
                position: absolute;
                background-color: $primary;
                width: 70%;
                height: 2px;
                transform: rotate(90deg);
                border-radius: 2px;
                @media (max-width: 576px) {
                    height: 4px;
                }
            }
        }

        h4 {
            color: $primary;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        p,
        a {
            font-size: 16px;
            margin-bottom: 20px;
            color: $primary;
        }

        a {
            font-size: 18px;
        }

        .visite-link {
            display: inline-block;
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;
            color: $primary;
            border: 2px solid $secondary;
            padding: 10px 15px;
            background-color: $white;
            margin-top: 15px;
            transition: 0.1s;
            &:hover {
                border: 4px solid $secondary;
            }
        }
    }
}
</style>
