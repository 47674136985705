<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38.37"
        height="38.373"
        viewBox="0 0 38.37 38.373"
    >
        <path
            id="Path_565"
            data-name="Path 565"
            d="M30.519,38.274A27.517,27.517,0,0,1,19.6,35.881,33.251,33.251,0,0,1,9.427,28.867L9.4,28.842l-.04-.043-.022-.02-.034-.033A33.18,33.18,0,0,1,2.17,18.286,27.2,27.2,0,0,1-.094,7.095l.009-.367L.142,6.44a20.621,20.621,0,0,1,1.6-1.819A20.166,20.166,0,0,1,8.758.054,2.462,2.462,0,0,1,9.652-.1c.963,0,2.565.482,2.943,1.516l0,.006c.924,2.564,2.092,5.918,2.938,9.12a3.759,3.759,0,0,1-.054,1.608,2.457,2.457,0,0,1-.937,1.611c-.622.4-1.254.8-1.866,1.186l-1.151.73-.012.007a.547.547,0,0,0-.2.354,2.073,2.073,0,0,0,.449,1.536,43.286,43.286,0,0,0,4.073,4.774A42.836,42.836,0,0,0,20.6,26.4a2.285,2.285,0,0,0,1.3.474.7.7,0,0,0,.592-.216l.008-.013q.4-.632.8-1.266c.364-.575.74-1.17,1.113-1.755.668-1.037,2.628-1.049,2.647-1.049a2.4,2.4,0,0,1,.532.053,42.172,42.172,0,0,1,9.241,2.981,2.753,2.753,0,0,1,1.221,1.66,3.351,3.351,0,0,1,.061,2.147,20.216,20.216,0,0,1-4.558,7.01l-.006.006a20.968,20.968,0,0,1-1.82,1.6l-.287.225-.364.009C30.895,38.271,30.706,38.274,30.519,38.274ZM10.994,27.323c5.567,5.561,12.682,8.751,19.525,8.751h.143c.473-.384.922-.787,1.34-1.2a18.028,18.028,0,0,0,4.061-6.247,2.122,2.122,0,0,0-.276-1.073,40.3,40.3,0,0,0-8.664-2.773c-.009,0-.03,0-.063,0a2.776,2.776,0,0,0-.878.165c-.342.536-.688,1.084-1.024,1.614q-.4.633-.8,1.265a2.8,2.8,0,0,1-2.46,1.255,4.456,4.456,0,0,1-2.6-.9,45.04,45.04,0,0,1-5.008-4.27L14.28,23.9A45.5,45.5,0,0,1,9.992,18.87a4.226,4.226,0,0,1-.831-3.256,2.68,2.68,0,0,1,1.191-1.8l1.149-.729c.57-.361,1.158-.733,1.736-1.1a2.634,2.634,0,0,0,.165-.9c-.8-3.037-1.912-6.235-2.806-8.724A2.258,2.258,0,0,0,9.652,2.1a.541.541,0,0,0-.106.008A17.766,17.766,0,0,0,2.1,7.514c-.042,6.787,3.2,14.1,8.731,19.646a1.321,1.321,0,0,1,.118.114l.02.023Z"
            transform="translate(0.1 0.1)"
        />
    </svg>
</template>

<script>
export default {
    name: 'SvgPhone',
};
</script>
