<template>
    <div class="loader">
        <div class="loader__wrapper">
            <img
                :src="loaderImagePath"
                :alt="altImgText"
                class="loader__img"
            />
            <div class="loader__chargement">
                <span>CHARGEMENT</span>
                <div class="ball-loader">
                    <div class="ball-loader-ball ball1" />
                    <div class="ball-loader-ball ball2" />
                    <div class="ball-loader-ball ball3" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'AppLoader',
    computed: {
        ...mapGetters({
            altImgText: 'config/altImgText',
            loaderImagePath: 'config/loaderImagePath',
        }),
    },
};
</script>
