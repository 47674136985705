<template>
    <div class="wrapper">
        <transition name="fade">
            <div
                v-show="isVisible"
                id="contact-modal"
                class="mutual-contact-modal"
                :style="`background-color: ${hexToRgba(backgroundColors)}`"
            >
                <div class="modal-content">
                    <div class="form-wrapper">
                        <h2 class="heading">
                            REMPLISSEZ LE FORMULAIRE
                            <br />
                            POUR PRENDRE RENDEZ-VOUS OU EN SAVOIR PLUS
                        </h2>
                        <form
                            class="contact-form"
                            @submit.prevent="sendForm"
                        >
                            <div class="input-columns">
                                <div class="col-first">
                                    <input
                                        v-model="contactModel.name"
                                        class="form-input"
                                        type="text"
                                        placeholder="NOM*"
                                        required
                                    />
                                    <input
                                        v-model="contactModel.surname"
                                        class="form-input"
                                        type="text"
                                        placeholder="PRÉNOM*"
                                        required
                                    />
                                    <input
                                        v-model="contactModel.mail"
                                        class="form-input"
                                        type="text"
                                        placeholder="EMAIL*"
                                        required
                                    />
                                    <input
                                        v-model="contactModel.postalCode"
                                        class="form-input"
                                        type="text"
                                        placeholder="CODE POSTAL*"
                                        required
                                    />
                                </div>
                                <div class="col-second">
                                    <input
                                        v-model="contactModel.phone"
                                        class="form-input"
                                        type="text"
                                        placeholder="TÉLÉPHONE"
                                    />
                                    <textarea
                                        v-model="contactModel.message"
                                        name="Message"
                                        placeholder="MESSAGE*"
                                        required
                                    >
                                        Message
                                    </textarea>
                                </div>
                            </div>
                            <div class="select-row">
                                <div id="radio-row">
                                    <div>
                                        <input
                                            id="habiter"
                                            v-model="contactModel.investType"
                                            type="radio"
                                            name="habiter"
                                            value="HABITER"
                                        />
                                        <label
                                            for="habiter"
                                            class="radio-label"
                                        >
                                            HABITER
                                        </label>
                                        <label
                                            class="span"
                                            for="habiter"
                                            @click="contactModel.investType = 'HABITER'"
                                        >
                                            <span class="checked" />
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            id="invester"
                                            v-model="contactModel.investType"
                                            type="radio"
                                            name="invester"
                                            value="INVESTIR"
                                        />
                                        <label
                                            for="invester"
                                            class="radio-label"
                                        >
                                            INVESTIR
                                        </label>
                                        <label
                                            class="span"
                                            for="investers"
                                            @click="contactModel.investType = 'INVESTIR'"
                                        >
                                            <span class="checked" />
                                        </label>
                                    </div>
                                    <div class="line" />
                                </div>
                                <div
                                    class="landing-select"
                                    @mouseleave="onSelectFocus = false"
                                >
                                    <div
                                        :class="[
                                            'select-selected',
                                            onSelectFocus ? 'select-arrow-active text-primary' : 'text-white',
                                        ]"
                                        @click="onSelectFocus = !onSelectFocus"
                                    >
                                        {{ contactModel.lotRooms.label || 'TYPOLOGIE RECHERCHÉE' }}
                                    </div>
                                    <div :class="['select-items', !onSelectFocus ? 'select-hide' : '']">
                                        <div
                                            v-for="piece in piecesOptions"
                                            :key="piece.label"
                                            class="custom-select__list-item"
                                            @click="updatePieceModel(piece)"
                                        >
                                            {{ piece.label }}
                                        </div>
                                    </div>
                                    <input
                                        id="lotRooms"
                                        :value="contactModel.lotRooms.value"
                                        name="lotRooms"
                                        required
                                        readonly
                                        hidden
                                    />
                                    <input
                                        id="selectedLot"
                                        :value="contactModel.selectedLot"
                                        name="selectedLot"
                                        readonly
                                        hidden
                                    />
                                </div>
                            </div>
                            <div
                                class="select-row"
                                style="padding-top: 16px"
                            >
                                <div class="col-first captcha">
                                    <img
                                        :src="captcha"
                                        class="captcha-image"
                                    />
                                    <input
                                        id="captcha"
                                        v-model="contactModel.captcha"
                                        type="text"
                                        name="captcha"
                                        class="captcha-input"
                                        placeholder="Retapez ce code *"
                                    />
                                    <button
                                        type="button"
                                        class="captcha__refresh"
                                        @click="getCaptcha()"
                                    />
                                </div>
                            </div>
                            <div class="checkbox">
                                <label class="custom-checkbox">
                                    En cochant cette case, je confirme avoir lu et accepté les conditions et mentions
                                    légales et la politique de confidentialité.
                                    <input
                                        v-model="agreement"
                                        type="checkbox"
                                        name="agree_from"
                                        required
                                    />
                                    <span class="checkmark" />
                                </label>
                            </div>
                            <div class="champ-obligations">*Champs obligatoires.</div>
                            <div class="btn-container">
                                <button
                                    class="submit-btn"
                                    type="submit"
                                    :disabled="isFormSubmitting"
                                >
                                    <div class="icon">
                                        <div class="horizontal" />
                                        <div class="vertical" />
                                    </div>
                                    <p class="btn-text">JE PRENDS RENDEZ-VOUS</p>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div
                    id="close-contact-modal"
                    class="close-modal"
                    @click="onCloseHandler"
                >
                    <div class="horizontal" />
                    <div class="vertical" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapMutations, mapGetters} from 'vuex';
import clone from 'lodash.clonedeep';
import hexToRgba from '@front/helpers/hexToRgba';

const PIECES = [
    {value: 1, label: 'STUDIO'},
    {value: 2, label: '2 PIÈCES'},
    {value: 3, label: '3 PIÈCES'},
    {value: 4, label: '4 PIÈCES'},
    {value: 5, label: '5 PIÈCES'},
];

const initialModel = {
    name: '',
    surname: '',
    mail: '',
    postalCode: '',
    phone: '',
    message: '',
    investType: '',
    captcha: null,
    lotRooms: PIECES[0],
    selectedLot: '',
};

export default {
    name: 'MutualContactForm',
    props: {
        isVisible: {
            type: Boolean,
            default: () => false,
        },
        backgroundColors: {
            type: String,
            default: () => '',
        },
    },
    data() {
        return {
            contactModel: clone(initialModel),
            agreement: false,
            onSelectFocus: false,
            isFormFilled: false,
            isFormSubmitting: false,
        };
    },
    computed: {
        ...mapGetters({
            captcha: 'captcha/captcha',
            analyticsEvents: 'config/analyticsEvents',
            selectedLot: 'scheme/selectedLot',
        }),
        piecesOptions() {
            return PIECES;
        },
    },
    watch: {
        isVisible(isVisible) {
            if (isVisible) {
                document.body.style.overflow = 'hidden';
            } else {
                setTimeout(() => {
                    document.body.style.overflow = 'auto';
                }, 500);
            }
        },
        contactModel: {
            deep: true,
            handler() {
                this.formFillHandler();
            },
        },
        selectedLot() {
            this.contactModel.selectedLot = this.selectedLot?.lot ?? '';
        },
    },
    mounted() {
        this.getCaptcha();
    },
    methods: {
        hexToRgba,
        ...mapActions({
            getCaptcha: 'captcha/getCaptcha',
        }),
        ...mapMutations({
            updateContactFormModalProps: 'projectExtend/updateContactFormModalProps',
            updateSelectedLot: 'scheme/updateSelectedLot',
        }),
        updatePieceModel(piece) {
            this.contactModel.lotRooms = piece;
            this.onSelectFocus = false;
        },
        onCloseHandler() {
            this.updateSelectedLot(null);
            this.$emit('close');
        },
        formFillHandler() {
            if (this.isFormFilled) {
                return;
            }

            gtag('event', 'submit', {
                event_category: 'start_fill_form',
                event_label: 'start_fill_form',
            });
            gtag('event', 'conversion', {send_to: this.analyticsEvents?.googleStartFillFormId});

            this.isFormFilled = true;
        },
        async sendForm() {
            if (!this.agreement) {
                return;
            }

            if (!navigator.onLine) {
                this.$notify({
                    type: 'error',
                    title: 'Envoi non possible, connexion internet hors service',
                    text: 'Veuillez vérifier votre connexion.',
                });

                return;
            }
            this.isFormSubmitting = true;

            try {
                const payload = clone(this.contactModel);
                payload.lotRooms = this.contactModel.lotRooms.value;
                await axios.post('/api/callback/landing-form', payload);
                this.$emit('close');
                this.updateContactFormModalProps({modal: 'success', prop: 'isActive', value: true});
                this.contactModel = clone(initialModel);
                this.agreement = false;
                this.isFormSubmitting = false;
                this.updateSelectedLot(null);
                gtag('event', 'submit', {
                    event_category: 'formulaire_contact',
                    event_label: 'formulaire_contact',
                });

                fbq('track', 'Lead');
                gtag('event', 'conversion', {send_to: this.analyticsEvents?.googleContactFormId});
                obApi('track', 'Validation');
                this.$emit('send');
            } catch (error) {
                this.isFormSubmitting = false;
                console.error(error);
                if (error.name === 'ReferenceError') {
                    return;
                }

                if (error.response?.data?.detail.includes('Le code saisi est incorrect')) {
                    this.$notify({
                        type: 'error',
                        title: 'Envoi non possible',
                        text: error.response?.data?.detail ?? '',
                    });
                } else {
                    this.updateContactFormModalProps({modal: 'error', prop: 'isActive', value: true});
                }
            }
        },
    },
};
</script>
