export default {
    name: '',
    surname: '',
    address: '',
    phone: '',
    type: 'rendez-vous',
    includeDetails: false,
    includePlan: false,
    lot: ''
};
