<template>
    <LocationMenuDesktop />
</template>

<script>
import LocationMenuDesktop from './LocationMenuDesktop.vue';

export default {
    name: 'LocationMenu',
    components: {
        LocationMenuDesktop
    }
};
</script>
