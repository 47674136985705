export default {
    tours: [],
    selectedRoomIndex: 0,
    selectedTourIndex: 0,
    assetsPath: '',
    hotspotsPath: '',
    roomsPath: '',
    toursPath: '',
    uploadsPath: '',
    tourIndexFile: 'index.xml',
    isToursLoading: false,
};
