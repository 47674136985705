<template>
    <div
        v-click-outside="() => (isOptionsShown = false)"
        :class="['ui-select', {hovered: hovered, open: isOptionsShown}]"
    >
        <div
            class="ui-select-container"
            @click="isOptionsShown = !isOptionsShown"
        >
            <slot name="icon">
                <div class="ui-select__icon" />
            </slot>
            <div class="ui-select-content">
                <input
                    :id="inputName"
                    v-model.trim="inputValue"
                    :name="inputName"
                    :readonly="!searchable"
                    :placeholder="placeholder"
                    type="text"
                    class="ui-select__input"
                    @change="$emit('change', $event)"
                    @input="$emit('input', $event)"
                    @focus="$emit('focus', $event)"
                    @blur="$emit('blur', $event)"
                />
            </div>
        </div>
        <ul class="ui-select-options">
            <template v-if="!hasOptionsSlot">
                <li
                    v-for="(option, index) in formattedOptions"
                    :key="option.label + index"
                    class="ui-select-options__item"
                    @click="onSelectHandler(option)"
                >
                    {{ option.label }}
                </li>
            </template>
            <slot
                name="options"
                :options="options"
            />
        </ul>
    </div>
</template>

<script>
import clone from 'lodash.clonedeep';

export default {
    name: 'UISelect',
    props: {
        inputName: {
            type: String,
            required: true,
        },
        searchable: {
            type: Boolean,
            default: () => false,
        },
        options: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: () => '',
        },
        hovered: {
            type: Boolean,
            default: () => false,
        },
        selectedOption: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            inputValue: '',
            isOptionsShown: false,
        };
    },
    computed: {
        hasOptionsSlot() {
            return !!this.$slots?.options ?? false;
        },
        formattedOptions() {
            return clone(this.options)
                .filter((option) => option.value !== this.selectedOption?.value)
                .sort((a, b) => a.value - b.value);
        },
    },
    watch: {
        selectedOption(newValue) {
            this.inputValue = newValue.label;
        },
    },
    mounted() {
        this.inputValue = this.selectedOption.label;
    },
    methods: {
        onSelectHandler(option) {
            this.inputValue = option.label;
            this.isOptionsShown = false;
            this.$emit('select', option);
        },
    },
};
</script>

<style lang="scss">
@import '@vue/styles/vars.sass';

%border-radius {
    border-radius: 20px 20px 0 0;
}

%show {
    opacity: 1;
    z-index: 0;
    visibility: visible;
}

.ui-select {
    $self: &;
    position: relative;
    display: flex;
    align-items: center;
    max-height: 40px;
    border-radius: 20px;
    background-color: #2c2c2c;
    cursor: pointer;
    transition: border-radius 0.2s ease;
    @media (max-width: 900px) and (orientation: landscape) {
        max-width: 170px;
    }
    * {
        color: #ffffff;
        text-transform: uppercase;
        font-family: $main-font;
        font-size: 12px;
        font-weight: bold;
        text-align: left;
        letter-spacing: 1.2px;
    }
    &.hovered {
        &:hover {
            @extend %border-radius;
            #{$self}-options {
                @extend %show;
            }
            #{$self}__icon {
                &::before {
                    transform: rotate(270deg);
                }
                &::after {
                    opacity: 0;
                }
            }
        }
    }
    &.open {
        @extend %border-radius;
        #{$self}-options {
            @extend %show;
        }
        #{$self}__icon {
            &::before {
                transform: rotate(270deg);
            }
            &::after {
                opacity: 0;
            }
        }
    }
    &-container {
        position: relative;
        z-index: 2;
        padding: 12px 30px 12px 46px;
    }
    &__icon {
        position: absolute;
        top: 50%;
        left: 24px;
        width: 1px;
        height: 1px;
        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            background: {
                image: url('/assets/images/icons/icon-down.svg');
                position: center;
                repeat: no-repeat;
                size: 8px;
            }
            transition: transform 0.3s ease;
        }
        &::before {
            top: -9px;
            left: -3px;
            transform: rotate(180deg);
            transform-origin: 100%;
        }
        &::after {
            top: 3px;
            left: 6px;
        }
    }
    &__input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
    }
    &-options {
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 20px;
        background-color: #2c2c2c;
        padding: 52px 30px 10px 52px;
        transition: opacity 0.3s ease;
        &__item {
            position: relative;
            padding-bottom: 12px;
            &::before {
                content: '';
                position: absolute;
                top: 2px;
                left: -22px;
                width: 8px;
                height: 8px;
                background: {
                    image: url('/assets/images/icons/icon-down.svg');
                    position: center;
                    repeat: no-repeat;
                    size: 8px;
                }
                opacity: 0;
                transform: rotate(-90deg);
                transition: opacity 0.5s ease;
            }
            &:hover {
                &::before {
                    opacity: 1;
                }
            }
        }
    }
}
</style>
