import { mapGetters, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            isShowMobileMenu: 'gallery/isShowMobileMenu'
        })
    },
    methods: {
        ...mapMutations({
            setIsShowMobileMenu: 'gallery/setIsShowMobileMenu',
            setActiveComponent: 'gallery/setActiveComponent'
        })
    }
};
