export default {
    setExternalLotsData(state, payload) {
        state.externalLotsData = payload;
    },
    setApiToken(state, payload) {
        state.apiToken = payload;
    },
    setCities(state, payload) {
        state.cities = payload;
    },
    setContacts(state, payload) {
        state.contacts = payload;
    },
};
