import {menu} from '@front/models/gallery-menu';

export default {
    async fetchGalleryConfig({commit, getters}) {
        const config = await (await fetch('/data/project/gallery_module/config.json')).json();

        commit('setAssetsPath', config.assetsPath.startsWith('.') ? config.assetsPath.slice(1) : config.assetsPath);
        commit('setBrochure', config.brochure);
        commit('setEnableAxonometryGallery', config.enableAxonometryGallery);
        commit('setEnableProgressGallery', config.enableProgressGallery);
        commit('setGalleries', config.galleries);
        commit('setIsBrochureVisible', config.isBrochureVisible);
        commit('setIsPerspectivesVisible', config.isPerspectivesVisible);
        commit('setIsVideosVisible', config.isVideosVisible);
        commit('setQualityOptions', config.qualityOptions);
        commit('setSlidesPath', config.slidesPath);
        commit('setUploadsPath', config.uploadsPath);
        commit('setVideos', config.videos);
        commit('setVideosPath', config.videosPath);

        if (!getters.isConfigLoad) {
            const [firstActiveMenuItem] = menu.filter(({activityFlag}) => getters[activityFlag] === true);
            if (firstActiveMenuItem) {
                commit('setActiveComponent', {
                    name: firstActiveMenuItem.name,
                    componentName: firstActiveMenuItem.componentName,
                });
            }
        }

        commit('setIsConfigLoad', true);
    },
};
