<template>
    <div
        class="v360-menu-btns v360-menu-btns_toggle"
        @click="$emit('click')"
    >
        <div class="v360-menu-btns-image flex">
            <img
                v-if="imageSrc"
                :src="imageSrc"
                :alt="imageAlt"
                class="v360-menu-btns__img"
            />
            <slot
                v-if="!imageSrc"
                name="image"
            />
        </div>
        <p :class="['v360-menu-btns__text', titleClasses]">
            {{ title }}
        </p>
        <slot name="additional" />
    </div>
</template>

<script>
export default {
    name: 'UIButton',
    props: {
        title: {
            type: String,
            default: () => '',
        },
        titleClasses: {
            type: String,
            default: () => '',
        },
        imageSrc: {
            type: String,
            default: () => '',
        },
        imageAlt: {
            type: String,
            default: () => '',
        },
    },
};
</script>
