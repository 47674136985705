export default {
    isConfigLoaded: false,
    tours: [],
    selectedRoomIndex: 0,
    selectedTourIndex: 0,
    assetsPath: '',
    hotspotsPath: '',
    roomsPath: '',
    toursPath: '',
    uploadsPath: '',
    mediaItemsPath: '',
    googleMapsPath: '',
    mediaItems: [],
    maps: [],
    activeEntity: {},
    qualityOptions: null,
    currentImageQuality: 'original',
};
