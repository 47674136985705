export default {
    setIsConfigLoad(state, bool) {
        state.isConfigLoad = bool;
    },
    setAssetsPath(state, payload) {
        state.assetsPath = payload;
    },
    setBrochure(state, payload) {
        state.brochure = payload;
    },
    setEnableAxonometryGallery(state, payload) {
        state.enableAxonometryGallery = payload;
    },
    setEnableProgressGallery(state, bool) {
        state.enableProgressGallery = bool;
    },
    setGalleries(state, payload) {
        state.galleries = payload;
    },
    setIsBrochureVisible(state, bool) {
        state.isBrochureVisible = bool;
    },
    setIsPerspectivesVisible(state, bool) {
        state.isPerspectivesVisible = bool;
    },
    setIsVideosVisible(state, bool) {
        state.isVideosVisible = bool;
    },
    setQualityOptions(state, payload) {
        state.qualityOptions = payload;
    },
    setSlidesPath(state, payload) {
        state.slidesPath = payload;
    },
    setUploadsPath(state, payload) {
        state.uploadsPath = payload;
    },
    setVideos(state, payload) {
        state.videos = payload;
    },
    setVideosPath(state, payload) {
        state.videosPath = payload;
    },
    setCurrentImageQuality(state, payload) {
        state.currentImageQuality = payload;
    },
    setActiveComponent(state, payload) {
        state.activeComponent = payload;
    },
};
