<template>
    <div class="inner-menu-t2 inner-menu-t2_kauf">
        <div class="inner-menu-t2_wrapper">
            <!-- <router-link class="menu-link-t2 menu-link-t2_nomargin menu-back-btn-wrap" to="/">
                <div class="menu-link-t2__label menu-link-t2__label_padding menu-back-btn">
                    <div class="back-btn">
                        <fa-icon icon="chevron-left" class="back-icon" />
                    </div>
                    <div>Homepage</div>
                </div>
            </router-link> -->
            <div v-if="tours.length > 1" class="select-wrapper">
                <CustomSelect :value="selectedTourIndex" :options="tours" @input="setSelectedTourIndex" />
            </div>
            <ul v-if="tours[selectedTourIndex]" class="tour-list">
                <li
                    v-for="(room, roomIndex) in tours[selectedTourIndex].rooms"
                    :key="`room_${roomIndex}`"
                    class="tour-sublist__item"
                >
                    <span
                        class="tour-sublist__item__link"
                        :class="{ active: roomIndex === selectedRoomIndex }"
                        @click="setSelectedRoomIndex(roomIndex)"
                        v-html="room.title"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import CustomSelect from '@front/components/Filters/LotsFilter/CustomSelect';

export default {
    name: 'ToursMenu',
    components: {
        CustomSelect
    },
    computed: {
        ...mapGetters('tours', ['tours', 'selectedTourIndex', 'selectedRoomIndex'])
    },
    created() {
        this.toggleActivityAdditionalWindow();
        this.getSchemeData();
        // this.selectTourByParam();
    },
    methods: {
        ...mapActions({
            getSchemeData: 'scheme/getSchemeData'
        }),
        ...mapMutations({
            setSelectedRoomIndex: 'tours/setSelectedRoomIndex',
            setSelectedTourIndex: 'tours/setSelectedTourIndex',
            toggleActivityAdditionalWindow: 'navigation/toggleActivityAdditionalWindow'
        }),
        selectTourByParam() {
            const tourIndex = this.tours.findIndex((tour) => {
                return tour.id === this.$route.params.vrId;
            });

            this.setSelectedTourIndex(tourIndex !== -1 ? tourIndex : 0);
        }
    }
};
</script>
