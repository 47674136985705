<template>
    <HomeMenuDesktop v-if="!$isMobile()" />
    <HomeMenuMobile v-else />
</template>

<script>
import HomeMenuDesktop from './HomeMenuDesktop.vue';
import HomeMenuMobile from './HomeMenuMobile.vue';

export default {
    name: 'HomeMenu',
    components: {
        HomeMenuDesktop,
        HomeMenuMobile: HomeMenuDesktop
    }
};
</script>
