// Filter
export const ORIENTATIONS_TYPE = 'orientation';
export const FLOORS_TYPE = 'floor';
export const STATUSES_TYPE = 'status';
export const APARTMENTS_TYPE = 'type';
export const ROOMS_TYPE = 'rooms';
export const SALE_TYPE = 'typeOfSale';
export const POINT_OF_INTEREST_TYPE = 'pointOfInterestType';
export const ADDITIONS_TYPE = 'additions';
export const PRICE_TYPE = 'price';
export const AREA_TYPE = 'area';
export const TRANCHE = 'tranche';

// Misc
export const DISPLAY_TYPE = 'displayTypes';
export const LOT_TYPE = 'lot';
export const LOT_AS_LINK_TYPE = 'link';
export const LOT_AS_TEMPLATE_TYPE = 'template';
export const DEFAULT_DISPLAY_TYPE = LOT_TYPE;
