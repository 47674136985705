/**
 * @param {number} price
 * @param {string} currencyValue
 * @return {string}
 */

const SPACE = '\u00A0';
const currency = '€';

export function priceFormat(price, currencyValue = currency) {
    const formattedPrice = Intl.NumberFormat('fr-FR').format(price);

    return `${formattedPrice}${SPACE}${currencyValue}`;
}
