var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider__wrapper",on:{"click":_vm.clickHandler}},[_c('hooper',{ref:"slider",staticClass:"illusio-slider illusio-slider--noarrow",staticStyle:{"height":"100vh","width":"100%"},attrs:{"settings":_vm.hooperSettings}},[_vm._l((_vm.sliderImages),function(slide,index){return _c('slide',{key:index},[_c('div',{staticClass:"illusio-slider__slide"},[_c('img',{staticClass:"illusio-slider__slide__image",style:({
                        height: _vm.slider.containerWidth > _vm.slider.containerHeight ? 'auto' : '100vh',
                        width: _vm.slider.containerWidth > _vm.slider.containerHeight ? '100%' : 'auto',
                        transform: `translate${slide.direction}(-${
                            index === _vm.currentSlide ? _vm.transformOffset : 0
                        }px)`,
                    }),attrs:{"src":slide.path,"alt":_vm.altImgText}}),_vm._v(" "),_c('div',{staticClass:"illusio-slider__slide__content"},[_c('router-link',{staticClass:"illusio-slider__slide__label",attrs:{"to":{name: _vm.$constants.SСHEME_PAGE}}},[_vm._v("\n                        "+_vm._s(slide.label)+"\n                    ")])],1)])])}),_vm._v(" "),_c('hooper-pagination',{attrs:{"slot":"hooper-addons"},slot:"hooper-addons"})],2)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }