<template>
    <div class="aside-menu-t2__links aside-menu-t2__links_landing desktop-menu-template">
        <HomeMenuRoutes
            :menu-routes="menuRoutes"
            :is-dark-theme="isDarkTheme"
            :enable-lot-availability-page="enableLotAvailabilityPage"
            :enable-contact-form="enableContactForm"
            :form-anchor="formAnchor"
            class="d-flex flex-row align-items-start"
            @click="onContactFormHandler"
        />
    </div>
</template>

<script>
import homeMenuMixin from '@front/mixins/homeMenuMixin';

export default {
    mixins: [homeMenuMixin]
};
</script>
