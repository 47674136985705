export default {
    editLotsData: 'Modifier les données des lots',
    schemeTableTitle: 'Régimes',
    manageFloors: 'Gérer les étages',
    schemeConfigFields: {
        programId: 'Programme id',
        lotsInfoApi: 'API d`informations sur les lots externes',
        extranetApiKey: 'Clé API extranet (MAQUETTE_API_KEY)',
        enableCreatingLotInfoForm: 'Activer la création d`un formulaire de réservation souple',
        showPriceConnected: 'Autoriser l`utilisateur connecté sur crm à voir le prix',
        showPricePublic: 'Autoriser le visiteur à voir le prix',
        showLotAvailabilityConnected: 'Autoriser l`utilisateur connecté dans crm à voir la disponibilité des lots',
        showLotAvailabilityPublic: 'Autoriser le visiteur à voir la disponibilité des lots',
        enableSync: 'Activer la synchronisation des lots',
        enableLotsComparison: 'Activer la comparaison des lots dans le comparateur',
        enableLotsVisibility: 'Activer les lots et les filtres sur schema-3d',
        enableAxonometricLot: 'Activer les axos des lots',
        maxCanvasScale: 'Définir l`échelle maximale du canevas',
        syncPeriod: 'Période de synchronisation des lots (heures)',
    },
    menuConfigFields: {
        livingAreaFilterName: 'Nom du filtre de zone de vie',
        surfacesFilterName: 'Nom du filtre de surfaces',
        genreFilterName: 'Nom du filtre de genre',
        typologieFilterName: 'Nom du filtre typologique',
        orientationsFilterName: 'Nom du filtre Orientations',
        floorsFilterName: 'Nom du filtre d`étages',
        typeOfSaleFilterName: 'Type de nom du filtre de vente',
    },
    filtersTableDataTitle: 'Filtres',
    saveShemeConfig: 'Enregistrer la config du schéma',
    toggleSelectLots: 'Basculer la sélection de plusieurs lots',
    editMultipleLots: 'Modifier plusieurs lots',
    deleteMultipleLots: 'Supprimer plusieurs lots',
    manageImages: 'Gérer les images/masques',
    manageProcessingConfig: 'Gérer la config de traitement',
    imagesProcessingConfig: 'Configuration du traitement des images',
    processScheme: 'Schéma de processus',
    processForScheme: 'Processus de chargement des images pour le régime',
    processSuccess: 'Toutes les images ont été téléchargées et traitées',

    // lots
    selectLot: 'Sélectionnez le lot',
    lot: 'Parcelle',
    displayType: 'Type d`affichage',
    price: 'Prix',
    area: 'Zone',
    taxIncluded: 'Taxe inclu',
    caves: 'Grottes',
    published: 'Publié',
    axonometry: 'Axonométrie',
    tranche: 'Tranche',
    orientation: 'Orientation',
    terrasse: 'Terrasse',
    cour: 'Cour',
    balcon: 'Balcon',
    loggia: 'Loggia',
    jardin: 'Jardin',
    parking: 'Parking',
    status: 'Statut',
    plan: 'Planifier',
    rooms: 'Chambres',
};
