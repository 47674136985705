export default {
    editLotsData: 'Edit lots data',
    schemeTableTitle: 'Schemes',
    manageFloors: 'Manage floors',
    schemeConfigFields: {
        programId: 'Program id',
        lotsInfoApi: 'External lots info api',
        extranetApiKey: 'Extranet Api key (MAQUETTE_API_KEY)',
        enableCreatingLotInfoForm: 'Enable creating soft booking form',
        showPriceConnected: 'Allow connected user on crm to see price',
        showPricePublic: 'Allow visitor to see price',
        showLotAvailabilityConnected: 'Allow the connected user in crm to see the availability of lots',
        showLotAvailabilityPublic: 'Allow the visitor to see the availability of lots',
        enableSync: 'Enable lots sync',
        enableLotsComparison: 'Enable lots comparison in the comparator',
        enableLotsVisibility: 'Enable lots and filters on scheme-3d',
        enableAxonometricLot: 'Enable axonometric lot',
        maxCanvasScale: 'Set maximum canvas scale',
        syncPeriod: 'Lots sync period (hours)',
    },
    menuConfigFields: {
        livingAreaFilterName: 'Living area filter name',
        surfacesFilterName: 'Surfaces filter name',
        genreFilterName: 'Genre filter name',
        typologieFilterName: 'Typologie filter name',
        orientationsFilterName: 'Orientations filter name',
        floorsFilterName: 'Floors filter name',
        typeOfSaleFilterName: 'Type of sale filter name',
    },
    filtersTableDataTitle: 'Filters',
    saveShemeConfig: 'Save scheme config',
    toggleSelectLots: 'Toggle select multiple lots',
    editMultipleLots: 'Edit multiple lots',
    deleteMultipleLots: 'Delete multiple lots',
    manageImages: 'Manage images/masks',
    manageProcessingConfig: 'Manage processing config',
    imagesProcessingConfig: 'Images processing config',
    processScheme: 'process scheme',
    processForScheme: 'Process of loading images for the scheme',
    processSuccess: 'All images have been uploaded and processed',

    // lots
    selectLot: 'Select lot',
    lot: 'Lot',
    displayType: 'Display Type',
    price: 'Price',
    area: 'Area',
    taxIncluded: 'Tax Included',
    caves: 'Caves',
    published: 'Published',
    axonometry: 'Axonometry',
    tranche: 'Tranche',
    orientation: 'Orientation',
    terrasse: 'Terrasse',
    cour: 'Cour',
    balcon: 'Balcon',
    loggia: 'Loggia',
    jardin: 'Jardin',
    parking: 'Parking',
    status: 'Status',
    plan: 'Plan',
    rooms: 'Rooms',
};
