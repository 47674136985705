<template>
    <div>
        <transition-group
            tag="div"
            name="fade"
            mode="out-in"
        >
            <Loader
                v-show="isLoaderActive"
                key="loader"
            />
            <div key="content">
                <template v-if="$isMobileOrTablet()">
                    <div
                        v-if="enableMenu"
                        class="desktop-menu"
                    >
                        <AsideMenuBurger @mousedown.native.passive="toggleMenuVisibility" />
                        <transition name="fade">
                            <AsideMenu
                                v-show="isMenuVisible"
                                :is-menu-active="isMenuVisible"
                            >
                                <template #menu>
                                    <transition
                                        name="fade"
                                        mode="out-in"
                                    >
                                        <component :is="menuTemplate" />
                                    </transition>
                                </template>
                            </AsideMenu>
                        </transition>
                    </div>
                </template>
                <template v-else>
                    <AsideMenuDesktop v-if="enableMenu" />
                </template>
                <transition
                    name="router"
                    mode="out-in"
                >
                    <!-- !Removed auto overflow. If there are problems, return it. -->
                    <div>
                        <router-view :is-menu-active="isMenuVisible" />
                        <div class="layout-controls-wrap">
                            <div class="layout-controls-inner">
                                <div class="layout-controls">
                                    <!-- <transition
                                        name="fade"
                                        mode="out-in"
                                    >
                                        <UISelect
                                            v-if="isToggleViewer && (!enableScheme3dDisplay || isToggleViewerAvailable)"
                                            placeholder="Sol"
                                            input-name="floor-select"
                                            hovered
                                            :options="floorSelectOptions"
                                            :selected-option="selectedOption"
                                            @select="onSelectFloorHandler"
                                        />
                                    </transition>
                                    <UIButton
                                        v-if="isToggleViewerAvailable"
                                        :title="isToggleViewer ? 'MAQUETTE 3D' : 'VUE EN PLAN'"
                                        :image-src="$getImagePath('icons/eye.svg')"
                                        :image-alt="altImgText"
                                        class="toggle-viewer"
                                        :style="{
                                            cursor: isLoaderActive || isDefaultLoaderActive ? 'not-allowed' : 'pointer',
                                        }"
                                        @click="onToggleViewerHandler"
                                    /> -->
                                    <!-- <UIButton
                                        v-if="isReturnAvailable"
                                        title="RETOUR À LA MAQUETTE 3D"
                                        class="toggle-viewer toggle-viewer__back"
                                        :image-src="$getImagePath('icons/arrow-back.svg')"
                                        @click="
                                            $router.push({path: '/scheme3d', query: {'init-with-last-image': true}})
                                        "
                                    /> -->
                                    <UIButton
                                        v-if="isComparisonAvailable"
                                        id="my-choices-btn"
                                        title="Mes Choix"
                                        :class="['mes-choix-btn', isComparatorVisible ? 'v360-btn-active' : '']"
                                        @click="toggleComparator"
                                    >
                                        <template #image>
                                            <div class="favorite-icon">
                                                <img
                                                    :src="$getImagePath('icons/heart-icon-bt-fill.svg')"
                                                    :alt="altImgText"
                                                    class="favorite-icon__img"
                                                />
                                                {{ favorites.length > 0 ? favorites.length : '' }}
                                            </div>
                                        </template>
                                    </UIButton>
                                </div>
                            </div>
                        </div>
                        <transition
                            name="fade"
                            mode="out-in"
                        >
                            <ModalComparator
                                v-if="isComparatorVisible"
                                @close="toggleComparator"
                            />
                        </transition>
                    </div>
                </transition>
                <a
                    v-if="enableIllusioButton && $route.name !== 'Home'"
                    href="https://www.illusio360.com/fr/offers"
                    target="_blank"
                    class="i-360"
                >
                    &#169; Illusio
                </a>
                <component
                    :is="activeAdditionalWindow"
                    v-if="activeAdditionalWindow"
                    key="activeAdditionalWindow"
                />
            </div>
        </transition-group>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import Loader from '@front/components/Loader/Loader';

import Scheme3dMenu from '@front/components/T02/Templates/Scheme3dMenu/Scheme3dMenu';
import AsideMenu from '@front/components/T02/AsideMenu/AsideMenu';
import AsideMenuDesktop from '@front/components/T02/AsideMenu/AsideMenuDesktop';
import AsideMenuBurger from '@front/components/T02/AsideMenu/AsideMenuBurger';
import HomeMenu from '@front/components/T02/Templates/HomeMenu/HomeMenu';
import LocationMenu from '@front/components/T02/Templates/LocationMenu/LocationMenu';
import ToursMenu from '@front/components/T02/Templates/ToursMenu';
import GalleryMenu from '@front/components/T02/Templates/GalleryMenu';

import ModalComparator from '@front/components/Comparator/ModalComparator';
import UIButton from '@front/components/UI/UIButton';
import UISelect from '@front/components/UI/UISelect';
import LotInfo from '@front/components/Scheme3dLotInfo/SchemeLotInfo';
import LotList from '@front/components/Scheme3dLotInfo/LotList/LotList';

import locationMixin from '@front/mixins/locationMixin';
import floorSelectMixin from '@front/mixins/floorSelectMixin';

export default {
    components: {
        ModalComparator,
        AsideMenu,
        AsideMenuDesktop,
        AsideMenuBurger,
        Loader,
        HomeMenu,
        Scheme3dMenu,
        ToursMenu,
        LocationMenu,
        GalleryMenu,
        UIButton,
        UISelect,
        LotList,
        LotInfo,
    },
    mixins: [locationMixin, floorSelectMixin],
    data() {
        return {
            isComparatorVisible: false,
        };
    },
    computed: {
        ...mapGetters({
            isLoaderActive: 'loader/isLoaderActive',
            isDefaultLoaderActive: 'loader/isDefaultLoaderActive',
            altImgText: 'config/altImgText',
            mainPageTemplate: 'config/mainPageTemplate',
            isMenuVisible: 'navigation/isMenuVisible',
            isSchemePageVisible: 'config/isSchemePageVisible',
            activeAdditionalWindow: 'navigation/activeAdditionalWindow',
            enableLotsVisibility: 'scheme/enableLotsVisibility',
            enableFloorPlanDisplay: 'scheme/enableFloorPlanDisplay',
            enableLotsComparison: 'scheme/enableLotsComparison',
            favorites: 'scheme/favorites',
            isToggleViewer: 'scheme/isToggleViewer',
            enableScheme3dDisplay: 'scheme/enableScheme3dDisplay',
            enableIllusioButton: 'config/enableIllusioButton',
            enableMenu: 'config/enableMenu',
        }),
        menuTemplate() {
            return this.$route.meta.menuTemplate;
        },
        isReturnAvailable() {
            return this.menuTemplate === 'ToursMenu' && this.isSchemePageVisible;
        },
        isComparisonAvailable() {
            if (!this.enableLotsVisibility) {
                return false;
            }

            // this.menuTemplate !== 'ToursMenu' &&
            if (this.menuTemplate !== 'Scheme3dMenu') {
                return false;
                // return (
                //     this.$constants.mainPageTemplates.SLIDER_VIEW === this.mainPageTemplate
                // && this.enableLotsComparison
                // );
            }

            return this.enableLotsComparison;
        },
        isToggleViewerAvailable() {
            return this.menuTemplate === 'Scheme3dMenu' && this.enableFloorPlanDisplay;
        },
    },
    watch: {
        $route(newRoute) {
            if (newRoute?.meta?.menuAutoclose) {
                this.setIsMenuVisible(false);
            }
        },
    },
    methods: {
        ...mapMutations({
            setIsMenuVisible: 'navigation/setIsMenuVisible',
            toggleActivityAdditionalWindow: 'navigation/toggleActivityAdditionalWindow',
            setIsToggleViewer: 'scheme/setIsToggleViewer',
        }),
        toggleComparator() {
            this.isComparatorVisible = !this.isComparatorVisible;
        },
        toggleMenuVisibility() {
            this.setIsMenuVisible(!this.isMenuVisible);
            document.documentElement.style.setProperty('--compasLeft', this.isMenuVisible ? '475px' : '170px');
        },
        onToggleViewerHandler() {
            if (this.isLoaderActive || this.isDefaultLoaderActive) {
                return;
            }

            this.setIsToggleViewer(!this.isToggleViewer);
        },
    },
};
</script>
