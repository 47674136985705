import { mapMutations, mapGetters } from 'vuex';

export default {
    data() {
        return {
            filterModel: [],
            isAllVisible: true
        };
    },
    computed: {
        ...mapGetters({
            activeEntity: 'location/activeEntity',
            tours: 'location/tours',
            tourshotspots: 'location/tours',
            mediaItems: 'location/mediaItems',
            maps: 'location/maps',
            selectedRoomIndex: 'location/selectedRoomIndex',
            selectedTourIndex: 'location/selectedTourIndex',
            // isDarkTheme: 'theme/isDarkTheme',
            altImgText: 'config/altImgText'
        }),
        isCycloramaRoomsExists() {
            return this.tours && (!!this.tours?.[this.selectedTourIndex]?.rooms?.length ?? false);
        },
        isRoomsHotspotsExists() {
            return this.isCycloramaRoomsExists
                ? this.tours?.[this.selectedTourIndex].rooms.some((room) => room.hotspots.length)
                : false;
        }
    },
    methods: {
        ...mapMutations({
            updateTourRoomHotspots: 'location/updateTourRoomHotspots',
            setSelectedRoomIndex: 'location/setSelectedRoomIndex',
            hideAllRoomHotspots: 'location/hideAllRoomHotspots',
            showAllRoomHotspots: 'location/showAllRoomHotspots',
            setActiveEntity: 'location/setActiveEntity'
        }),
        toggleVisibleAllTours(args) {
            this.isAllVisible = !this.isAllVisible;
            if (!this.isAllVisible) {
                this.hideAllRoomHotspots(args);
            } else {
                this.showAllRoomHotspots(args);
            }
        }
    }
};
