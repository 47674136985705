import { mapFields } from 'vuex-map-fields';

export default {
    computed: {
        ...mapFields('navigation', { isMenuVisible: 'isMenuVisible', menuOffTimeout: 'menuOffTimeout' })
    },
    methods: {
        enterHandler() {
            clearTimeout(this.menuOffTimeout);
        },
        leaveHandler() {
            if (process.env.NODE_ENV === 'development') {
                return;
            }

            this.menuOffTimeout = setTimeout(this.hideMenuHandler, 10000);
        },
        hideMenuHandler() {
            if (!this.isMenuVisible) {
                return;
            }

            this.isMenuVisible = false;
            document.documentElement.style.setProperty('--compasLeft', this.isMenuVisible ? '475px' : '170px');
        }
    }
};
