export default {
    setIsTourPageVisible(state, bool) {
        state.isTourPageVisible = bool;
    },
    setIsSchemePageVisible(state, bool) {
        state.isSchemePageVisible = bool;
    },
    setIsLocationPageVisible(state, bool) {
        state.isLocationPageVisible = bool;
    },
    setIsGalleryPageVisible(state, bool) {
        state.isGalleryPageVisible = bool;
    },
    setEnableChangeColorTheme(state, bool) {
        state.enableChangeColorTheme = bool;
    },
    setEnableChangeLang(state, bool) {
        state.enableChangeLang = bool;
    },
    setGoogleAdsId(state, payload) {
        state.googleAdsId = payload;
    },
    setClientName(state, payload) {
        state.clientName = payload;
    },
    setEnableIllusioButton(state, payload) {
        state.enableIllusioButton = payload;
    },
    setProgramName(state, payload) {
        state.programName = payload;
    },
    setAltImgText(state, payload) {
        state.altImgText = payload;
    },
    setMainPageTemplate(state, payload) {
        state.mainPageTemplate = payload;
    },
    setAssetsPath(state, payload) {
        state.assetsPath = payload;
    },
    setTopLogoWhite(state, payload) {
        state.topLogoWhite = payload;
    },
    setTopLogoDark(state, payload) {
        state.topLogoDark = payload;
    },
    setEnableContactForm(state, bool) {
        state.enableContactForm = bool;
    },
    setProgramCity(state, payload) {
        state.programCity = payload;
    },
    setExternalRepo(state, payload) {
        state.externalRepo = payload;
    },
    setLoaderImage(state, payload) {
        state.loaderImage = payload;
    },
    setDefaultImages(state, payload) {
        state.defaultImages = payload;
    },
    setBackgroundWhiteMenuImageName(state, payload) {
        state.backgroundWhiteMenuImageName = payload;
    },
    setBackgroundDarkMenuImageName(state, payload) {
        state.backgroundDarkMenuImageName = payload;
    },
    setEnableLotAvailabilityPage(state, bool) {
        state.enableLotAvailabilityPage = bool;
    },
    setEnableCookieAlert(state, bool) {
        state.enableCookieAlert = bool;
    },
    setLegalMentionHtml(state, payload) {
        state.legalMentionHtml = payload;
    },
    setEnableMenu(state, bool) {
        state.enableMenu = bool;
    },
    setEnableSchemeLinkDisplayInMenu(state, payload) {
        state.enableSchemeLinkDisplayInMenu = payload;
    },
    setWhatsappNumber(state, payload) {
        state.whatsappNumber = payload;
    },
    setTelephoneNumber(state, payload) {
        state.telephoneNumber = payload;
    },
    setContactSidebarBackground(state, payload) {
        state.contactSidebarBackground = payload;
    },
    setEnableContactSidebar(state, bool) {
        state.enableContactSidebar = bool;
    },
    setEmailBackgroundColor(state, payload) {
        state.emailBackgroundColor = payload;
    },
    setCurrentImageQuality(state, payload) {
        state.currentImageQuality = payload;
    },
    setQualityOptions(state, payload) {
        state.qualityOptions = payload;
    },
    setBottomLogos(state, payload) {
        state.bottomLogos = payload;
    },
    setGalleries(state, payload) {
        state.galleries = payload;
    },
    setCurrentGallery(state, payload) {
        state.currentGallery = payload;
    },
    setAnalyticsEvents(state, payload) {
        state.analyticsEvents = payload;
    },
    setCustomLanding(state, payload) {
        state.customLanding = payload;
    },
    setIsShowMobileMenu(state, bool) {
        state.isShowMobileMenu = bool;
    },
};
