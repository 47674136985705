export default {
    tours(state) {
        return state.tours;
    },
    selectedTourIndex(state) {
        return state.selectedTourIndex;
    },
    selectedRoomIndex(state) {
        return state.selectedRoomIndex;
    },
    assetsPath(state) {
        return state.assetsPath;
    },
    toursPath(state, getters) {
        return `${getters.assetsPath}/${state.toursPath}`;
    },
    roomsPath(state, getters) {
        return `${getters.toursPath}/${getters.tours[getters.selectedTourIndex].id}/${state.roomsPath}`;
    },
    hotspotsPath(state, getters) {
        // eslint-disable-next-line max-len
        return `${getters.roomsPath}/${getters.tours[getters.selectedTourIndex].rooms[getters.selectedRoomIndex].id}/${
            state.hotspotsPath
        }`;
    },
    uploadsPath(state) {
        return state.uploadsPath;
    },
    tourIndexFile(state) {
        return state.tourIndexFile;
    },
    isToursLoading(state) {
        return state.isToursLoading;
    },
};
