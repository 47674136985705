<template>
    <div class="contact-form-success">
        <div class="contact-form-success-content">
            <button
                type="button"
                class="contact-form-success__close"
                :style="`color: ${color}; border: 2px solid ${color};`"
                @click="$emit('close')"
            >
                <span
                    class="horizontal"
                    :style="`background-color: ${color};`"
                />
                <span
                    class="vertical"
                    :style="`background-color: ${color};`"
                />
            </button>
            <h4
                :style="`color: ${color}`"
                v-html="title"
            />
            <p
                :style="`color: ${color}`"
                v-html="description"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactFormSuccess',
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.contact-form-success {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    right: 0;

    &-content {
        background-color: #f9f9f9;
        width: 538px;
        height: auto;
        max-width: 100%;
        position: relative;
        margin: auto;
        text-align: center;
        padding: 100px 25px 100px 25px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        overflow-y: auto;

        h4 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        p {
            font-size: 16px;
            margin-bottom: 20px;
        }

        @media (max-width: 700px) {
            width: 80%;
            height: auto;
            min-height: 500px;
        }
    }

    &__close {
        height: 38px;
        width: 38px;
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 22px;
        text-align: center;
        padding-top: 3px;
        cursor: pointer;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(45deg);
        padding: 0 !important;
        .horizontal {
            width: 70%;
            height: 2px;
            border-radius: 2px;
            @media (max-width: 576px) {
                height: 4px;
            }
        }
        .vertical {
            position: absolute;
            width: 70%;
            height: 2px;
            transform: rotate(90deg);
            border-radius: 2px;
            @media (max-width: 576px) {
                height: 4px;
            }
        }
    }
}
</style>
