export default {
    slots: {
        logo: 'Manage site logo, loader and menu background',
        slider: 'Manage slider',
        clientInfo: 'Manage client info',
        seo: 'Manage markup for SEO',
        analytics: 'Manage analytics',
        metaImages: 'Manage meta images',
        microData: 'Manage micro data',
        legalMentionHtml: 'Manage legal mention',
        contactFormEmail: 'Manage contact form email',
        contactPhone: 'Manage contact sidebar',
        configParams: 'Manage config params',
        homepage: 'Manage homepage',
        contactForm: 'Manage contact form',
    },
    mainImages: {
        topLogoWhite: 'Choose top logo (white)',
        topLogoDark: 'Choose top logo (dark)',
        loader: 'Choose loader image',
        menuBackground: 'Choose menu background image (white)',
        menuBackgroundDark: 'Choose menu background image (dark)',
    },
    mainSlider: {
        title: 'Main slider',
    },
    clientInfo: {
        title: 'Client info',
        thead: {
            link: 'Link',
            text: 'Text',
            logos: 'Logos',
            actions: 'Actions',
        },
        logoDark: 'Client logo dark',
        logoWhite: 'Client logo white',
    },
    analytics: {
        googleAdsId: 'Google Ads id',
        gtmId: 'GTM id',
        fbqId: 'fbq id',
        fdvCode: 'Facebook domain verification code',
        outbrainId: 'Outbrain pixel id',
        googleStartFillFormId: 'Google Ads "start_fill_form" id',
        googleContactFormId: 'Google Ads "formulaire_contact" id',
        customScriptHead: 'Custom script in head',
    },
    metaImages: {
        ogImage: 'Choose og image',
        twitterImage: 'Choose twitter image',
        googleImage: 'Choose google+ image',
        favicon: 'Choose favicon image',
    },
    phoneFields: {
        enableContactSidebar: 'Enable contact sidebar',
        whatsappNumber: 'Whatsapp number',
        telephoneNumber: 'Telephone number',
        backgroundPhoneColor: 'Background phone color',
    },
    configMenuButtons: {
        enableMenu: 'Enable to show the menu',
        enableSchemeLinkDisplayInMenu: 'Enable scheme link display in menu',
        enableIllusioButton: 'Enable illusio button',
        enableContactForm: 'Enable display of the contact form on the main page',
        enableLotAvailabilityPage: 'Enable lots availability page',
        enableChangeColorTheme: 'Enable color theme change',
        darkThemeByDefault: 'Set dark theme as default',
        enableCookieAlert: 'Enable the display of the cookie alert',
        enableChangeLang: 'Enable language change',
    },
    configMainFields: {
        client: 'Client name',
        programCity: 'Program city',
        altImgText: 'Alt images text',
        externalRepo: 'External repo name',
        programPostalCode: 'Program postal code',
    },
    indexDataFields: {
        siteTitle: 'Site title',
        siteDescription: 'Site description',
    },
    mainPageTemplate: {
        mainPage: 'Main page template',
        slider: {
            title: 'Slider view',
        },
        customLanding: {
            title: 'Custom landing view',
            globalSettings: 'Global settings',
            mainColor: 'Main color',
            secondaryColor: 'Secondary color',
            saveGlobalParams: 'Save global settings',
            pointText: 'Point text',
            pointsTextColor: 'Points text color',
            pointsIconColor: 'Points icon color',
            pointIcon: 'Point icon',
            pointActive: 'Point is active',
            pointIconType: 'Point icon type',
            types: {
                introduction: 'Introduction',
                progress: 'Progress',
                maquette: 'Maquette',
                information: 'Information',
                price: 'Price',
                title: 'Title',
                apartment: 'Apartment',
                form: 'Form',
                logo: 'Logo',
            },
        },
        tailoredLanding: {
            title: 'Tailored landing view (premium)',
        },
    },
    mainModuleButtons: {
        getDb: 'Dump SQLite',
        backupLeads: 'Backup leads',
    },
};
