<template>
    <div
        ref="map"
        :id="id"
        class="google-map"
    />
</template>

<script>
import {googleMapBuilder, setMapMarkers} from '@front/helpers/googleMapsBuilder';

export default {
    name: 'GoogleMap',
    props: {
        id: {
            type: String,
            required: true,
        },
        apiKey: {
            type: String,
            required: true,
        },
        centerLatitude: {
            type: [String, Number],
            default: () => 0,
        },
        centerLongitude: {
            type: [String, Number],
            default: () => 0,
        },
        latitude: {
            type: [String, Number],
            default: () => 0,
        },
        longitude: {
            type: [String, Number],
            default: () => 0,
        },
        styles: {
            type: String,
            default: () => null,
        },
        markerImagePath: {
            type: [String, null],
            default: () => null,
        },
        tooltip: {
            type: String,
            default: () => '',
        },
        tooltipLinkLabel: {
            type: String,
            default: () => '',
        },
        address: {
            type: String,
            default: () => '',
        },
        zoom: {
            type: String,
            default: () => '',
        },
    },
    data() {
        return {
            map: null,
        };
    },
    computed: {
        mapOptions() {
            let styles = null;

            try {
                styles = JSON.parse(this.styles);
            } catch (error) {
                styles = null;
            }

            return {
                center: {lat: parseFloat(this.centerLatitude), lng: parseFloat(this.centerLongitude)},
                zoomControl: true,
                zoom: parseInt(this.zoom) || 15,
                styles,
                streetViewControl: false,
                disableDefaultUI: this.$isMobile(),
            };
        },
    },
    mounted() {
        this.initMap();
    },
    methods: {
        async initMap() {
            await this.$nextTick();

            await googleMapBuilder({
                mapId: this.id,
                apiKey: this.apiKey,
                mapContainer: this.$refs.map,
                mapOptions: this.mapOptions,
            });
            setMapMarkers({
                mapId: this.id,
                coordinates: {lat: parseFloat(this.latitude), lng: parseFloat(this.longitude)},
                styles: JSON.parse(this.styles),
                markerImagePath: this.markerImagePath,
                tooltip: this.tooltip,
                tooltipLink: this.address,
                tooltipLinkLabel: this.tooltipLinkLabel,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.google-map {
    width: 100vw;
    height: 100vh;
}
</style>
