<template>
    <div class="aside-desktop-page-label">
        <RouterLink to="/" class="aside-desktop-page-label__back">
            <img class="aside-desktop-page-label__back-icon" :src="$getImagePath('svg/new-menu-icons/chevron.svg')" />
        </RouterLink>
        <div class="aside-desktop-page-label__title" v-if="isShowLabel">
            {{ $route.meta.title || '' }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageLabel',
    computed: {
        isShowLabel() {
            return (
                ['GalleryMenu', 'LocationMenu', 'ToursMenu', 'Scheme3dMenu'].indexOf(this.$route.meta.menuTemplate) ===
                -1
            );
        }
    }
};
</script>

<style lang="scss" scoped>
.aside-desktop-page-label {
    display: flex;
    align-items: center;
    gap: 16px;

    &__back {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 45px;
    }

    &__title {
        display: flex;
        height: 100%;
        padding: 13px 24px 10px;
        border-radius: 4px;
        color: #ffffff;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 18px;
        line-height: 1;
        letter-spacing: 0.1em;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        background-color: rgba(0, 0, 0, 0.5);
    }
}
</style>
