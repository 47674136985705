import Vue from 'vue';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    fas,
    faTimes,
    faHourglass,
    faMapMarker,
    faClock,
    faMinus,
    faPlus,
    faCalendar,
    faTrashAlt,
    faHeart,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faHandPaper,
    faInfo,
    faMap,
    faPause,
    faPlay,
    faSearchMinus,
    faSearchPlus,
    faPlusCircle,
    faSync,
    faVrCardboard,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import Notifications from 'vue-notification';
import Meta from 'vue-meta';
import VueSmoothScroll from 'vue2-smooth-scroll';
import vClickOutside from 'v-click-outside';
import VueRouter from 'vue-router';
import 'bootstrap/dist/css/bootstrap.css';
import VueCookies from 'vue-cookies';

import App from '@front/App.vue';
import store from '@front/store';
import * as constants from '@front/constants';
import {isMobile, isMobileOrTablet} from '@front/utils/mobileDetector';
import {getImagePath} from '@front/utils/getImagePath';
import routerController from '@front/controllers/RouterController';
import {wait} from '@front/utils/wait';
import lotConstants from '@common/constants/lot-constants';
import pageNames from '@common/constants/page-names';
import * as lotTypes from '@common/constants/lot-types';
import scrollTo from '@front/directives/scrollTo';
import VModal from 'vue-js-modal';

import i18n from './i18n.js';

library.add(
    fas,
    faHourglass,
    faClock,
    faTimes,
    faMapMarker,
    faMinus,
    faPlus,
    faCalendar,
    faTrashAlt,
    faHeart,
    faPlay,
    faPause,
    faSearchPlus,
    faPlusCircle,
    faSearchMinus,
    faHandPaper,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faSync,
    faInfo,
    faMap,
    faVrCardboard,
);

const telInputOptions = {
    mode: 'international',
    autoFormat: true,
    dropdownOptions: {
        showDialCodeInSelection: true,
        showSearchBox: true,
        showDialCodeInList: true,
        showFlags: true,
    },
    inputOptions: {
        placeholder: '',
        showDialCode: true,
        required: true,
    },
    validCharactersOnly: true,
};

Vue.component('FaIcon', FontAwesomeIcon);
Vue.component('DatePicker', DatePicker);
Vue.use(VueTelInput, telInputOptions);
Vue.component('VueSlider', VueSlider);
Vue.use(Notifications);
Vue.use(Meta);
Vue.use(VueSmoothScroll, {
    updateHistory: false,
    duration: 100,
});
Vue.use(vClickOutside);
Vue.use(VueRouter);
Vue.use(VueCookies);
Vue.use(VModal, {
    dynamicDefaults: {
        draggable: true,
        resizable: true,
        height: 'auto',
    },
});
Vue.directive('scroll-to', scrollTo);

Vue.prototype.$constants = {...constants, ...lotConstants, ...pageNames};
Vue.prototype.$types = {...lotTypes};
Vue.prototype.$isMobile = isMobile;
Vue.prototype.$isMobileOrTablet = isMobileOrTablet;
Vue.prototype.$getImagePath = getImagePath;
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$wait = wait;

const initApp = () => {
    const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes: routerController.getFullRoutes(),
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            }

            if (from.path === to.path) {
                return {};
            }

            if (to.hash) {
                return {selector: to.hash};
            }

            return {x: 0, y: 0};
        },
    });

    router.beforeEach((to, _, next) => {
        if (to.path.includes('mentions-legales')) {
            next();

            return;
        }

        to.meta?.isActive() ? next() : next('/');
    });

    return new Vue({
        i18n,
        store,
        router,
        render: (h) => h(App),
    }).$mount('#app');
};

(async () => {
    try {
        await store.dispatch('config/getModulesAvailabilityConfig');
        await store.dispatch('config/getConfigData');
        initApp();
    } catch (error) {
        console.error(error);
    }
})();
