<script>
import {mapGetters} from 'vuex';
import * as Blocks from '@front/components/CustomLandingBlocks';
import LegalInformation from '@front/components/CustomLandingBlocks/LegalInformation.vue';

export default {
    name: 'CustomLandingView',
    computed: {
        ...mapGetters('config', ['customLanding']),
    },
    created() {
        this.customLanding?.mainColor &&
            document.documentElement.style.setProperty('--accent-color', this.customLanding.mainColor);
        this.customLanding?.secondaryColor &&
            document.documentElement.style.setProperty('--secondary-color', this.customLanding.secondaryColor);
        this.customLanding?.backgroundColor &&
            document.documentElement.style.setProperty('--background-color', this.customLanding.backgroundColor);
    },
    render(h) {
        if (!Array.isArray(this.customLanding?.blocks)) {
            return h('h1', {class: 'custom-landing-view--empty'}, 'You have not selected blocks to display');
        }

        const blockError = (block) => `The block "${block}" is not valid, fill in the data correctly`;

        return h('div', {class: 'custom-landing-view'}, [
            ...this.customLanding.blocks
                .filter(({enabled}) => enabled)
                .map((block) => {
                    if (Blocks?.default?.[block?.type]?.default) {
                        return h(Blocks.default[block.type].default, {props: {blockData: block}});
                    } else {
                        return h('h3', {class: 'custom-landing-view--error'}, blockError(block?.type));
                    }
                }),
            h(LegalInformation),
        ]);
    },
};
</script>

<style lang="scss">
@import '@vue/styles/custom-view.scss';
</style>
