<template>
    <div
        v-show="hotspot || isMouseOver"
        ref="hotspotRef"
        class="hotspot-360--point"
        :style="{
            top: `${hotspotCoords.y - ($isMobile() ? 40 : 50)}px`,
            left: `${hotspotCoords.x - 20}px`,
        }"
        @mouseover="isMouseOver = true"
        @mouseleave="mouseOut"
    >
        <div
            v-if="isTypeLink"
            :class="{
                'hotspot-360__link': isHoverIcon,
                'hotspot-360__link-special': !isHoverIcon,
            }"
            @click="onChangeTypeHandler"
        />
        <div
            v-if="additionalLotInfo && isTypeLot"
            class="hotspot-360__lot"
            :style="{background: hotspotColor}"
        >
            <p class="hotspot-360__lot__number">
                {{ hotspotData.lot }}
            </p>
            <template v-if="isPriceFilterAvailable">
                <template v-if="additionalLotInfo.priceData.length">
                    <template v-for="(priceData, index) in additionalLotInfo.priceData">
                        <p
                            v-if="isPrice(priceData.value)"
                            :key="priceData.tvaCode + index"
                            class="hotspot-360__lot__price grid grid-price"
                        >
                            <span>{{ priceData.labelShort }}{{ priceData.tvaLabel }}</span>
                            <span>: {{ priceFormat(priceData.value, priceData.unit) }}</span>
                        </p>
                    </template>
                </template>
                <p
                    v-if="!additionalLotInfo.priceData.length && isPrice(additionalLotInfo.price)"
                    class="hotspot-360__lot__price"
                >
                    {{ priceFormat(getPrice(additionalLotInfo)) }}
                </p>
            </template>
            <span
                v-if="hotspotData.showStatus && isLotAvailabilityVisible"
                class="status-dscr"
            >
                {{ additionalLotInfo.status }}
            </span>
        </div>
        <div
            v-if="isTypeTemplate && hotspotData.pointOfInterestLabel"
            class="hotspot-360__lot hotspot-360__lot--template"
            :style="{background: hotspotColor}"
        >
            <div
                class="hotspot-360__template"
                v-html="hotspotData.pointOfInterestLabel"
            />
        </div>
        <div
            v-if="additionalLotInfo && !isTypeLink"
            class="hotspot-block"
            @mouseover="isMouseOver = true"
            @mouseleave="mouseOut"
        >
            <template v-if="isLotInfoVisible">
                <div
                    v-if="hotspotData.showInfo && isTypeLot"
                    class="hotspot-360__action hotspot-360__action-info info hotspot-360__action_hover"
                    :style="{background: hotspotColor}"
                    @click="lotInfoClick"
                >
                    <fa-icon icon="info" />
                    <p class="hotspot-360__btn-info">Voir la fiche du {{ typeLot }}</p>
                </div>
                <router-link
                    v-if="isTypeTemplate && hotspotData.pointOfInterestAnchor"
                    class="hotspot-360__action hotspot-360__action-template info hotspot-360__action_hover"
                    :style="{background: hotspotColor}"
                    :to="hotspotData.pointOfInterestAnchor"
                    exact
                >
                    <fa-icon icon="info" />
                    <p class="hotspot-360__btn-info">Voir la fiche</p>
                </router-link>
            </template>
            <div v-if="!isVisibleLotStatus && isTypeLot">
                <div
                    v-if="isStatusExamination"
                    class="hotspot-360__times info"
                >
                    <fa-icon
                        icon="times"
                        style="cursor: pointer"
                    />
                </div>
                <div
                    v-if="hotspotData.showComparison && !isStatusExamination"
                    class="hotspot-360__action info hotspot-360__action_hover"
                    :style="{
                        backgroundColor: '#2E2E2E',
                    }"
                    @click="updateFavorites(hotspotData)"
                >
                    <svg
                        v-if="favorites.includes(hotspotData)"
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                    >
                        <path
                            d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
                        />
                    </svg>
                    <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                    >
                        <path
                            d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"
                        />
                    </svg>
                    <p class="hotspot-360__btn-info">
                        AJOUTER
                        <br />
                        À MES FAVORIS
                    </p>
                </div>
            </div>
            <div
                v-if="allowSwitchingToFloorView"
                class="hotspot-360__action hotspot-360__action_hover"
                :style="{background: hotspotColor}"
                @click="onLotSelectHandler(hotspotData)"
            >
                <fa-icon icon="map" />
                <p class="hotspot-360__btn-info">PASSER EN VUE PAR ÉTAGE</p>
            </div>
            <div
                v-if="isVisibleLotStatus && !isStatusExamination && isTypeLot"
                class="hotspot-360__action"
                style="background: red"
                @click="lotInfoClick"
            >
                <fa-icon icon="hourglass" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {priceFormat} from '@front/utils/priceFormat';
import priceLotsMixin from '@front/mixins/priceLotsMixin';

export default {
    name: 'SchemeHotspot',
    mixins: [priceLotsMixin],
    props: {
        offsets: {
            type: Object,
            required: true,
        },
        ratio: {
            type: Number,
            required: true,
        },
        canvasImageHeightOffset: {
            type: Number,
            required: true,
        },
        movementOffsetY: {
            type: Number,
            required: true,
        },
        zoomVerticalOffset: {
            type: Number,
            required: true,
        },
        canvasImageWidthOffset: {
            type: Number,
            required: true,
        },
        movementOffsetX: {
            type: Number,
            required: true,
        },
        zoomHorizontalOffset: {
            type: Number,
            required: true,
        },
        transformE: {
            type: Number,
            required: true,
        },
        transformF: {
            type: Number,
            required: true,
        },
        transformA: {
            type: Number,
            required: true,
        },
        hotspotCoords: {
            type: Object,
            required: true,
        },
        hotspot: {
            type: [String, null],
            default: () => null,
        },
    },
    data() {
        return {
            isMouseOver: false,
            yMargin: 55,
            xMargin: 126,
        };
    },
    computed: {
        ...mapGetters({
            lotInfo: 'scheme/lotInfo',
            lotsData: 'scheme/lotsData',
            favorites: 'scheme/favorites',
            isToggleViewer: 'scheme/isToggleViewer',
            enableFloorPlanDisplay: 'scheme/enableFloorPlanDisplay',
            availableFloors: 'scheme/availableFloors',
            isPriceFilterAvailable: 'scheme/isPriceFilterAvailable',
            tours: 'tours/tours',
            activeImage: 'scheme/activeImage',
            isLotAvailabilityVisible: 'scheme/isLotAvailabilityVisible',
        }),
        hotspotData() {
            return this.lotsData.find((lot) => lot.color === this.hotspot) || {};
        },
        isHoverIcon() {
            return this.hotspotData.hoverIcon;
        },
        additionalLotInfo() {
            return this.lotsData.find((x) => x.lot === this.hotspotData.lot);
        },
        isVisibleLotStatus() {
            return this.additionalLotInfo.status === this.$constants.statuses.OPTION;
        },
        isStatusExamination() {
            const {DESISTEMENT, LIVRAISON, RESERVATION, ALLOTEMENT, PRE_RESERVATION, SIGNATURE} =
                this.$constants.statuses;
            const disallowStatuses = [DESISTEMENT, LIVRAISON, RESERVATION, ALLOTEMENT, PRE_RESERVATION, SIGNATURE];

            return this.additionalLotInfo ? disallowStatuses.includes(this.additionalLotInfo?.status) : false;
        },
        hotspotColor() {
            if (!this.hotspotData) {
                return this.$constants.defaultLotColor;
            }

            if (this.hotspotData.filterColor) {
                return this.hotspotData.filterColor.split('|')[0];
            }

            if (this.hotspotData.palette) {
                return this.hotspotData.palette.split('|')[0];
            }

            return `#${this.hotspotData.color}`;
        },
        myOffsetX() {
            return (
                (this.canvasImageWidthOffset * this.ratio) / this.transformA +
                this.movementOffsetX * this.transformA * this.ratio +
                this.zoomHorizontalOffset * this.transformA * this.ratio -
                this.transformE / this.ratio
            );
        },
        myOffsetY() {
            return (
                (this.canvasImageHeightOffset * this.ratio) / this.transformA +
                this.movementOffsetY * this.transformA * this.ratio +
                this.zoomVerticalOffset * this.transformA * this.ratio -
                this.transformF / this.ratio
            );
        },
        isLotHasFloorView() {
            if (!this.enableFloorPlanDisplay) {
                return;
            }

            if (!this.hotspotData.floor) {
                return;
            }

            if (this.isToggleViewer) {
                return false;
            }

            const isFloorExist = this.availableFloors.includes(this.additionalLotInfo?.floor);
            const isLotHasFloorView = this.additionalLotInfo?.floor && this.additionalLotInfo?.color === this.hotspot;

            return isFloorExist && isLotHasFloorView;
        },
        isTypeLot() {
            return this.hotspotData.displayType === this.$types.LOT_TYPE;
        },
        isTypeTemplate() {
            return this.hotspotData.displayType === this.$types.LOT_AS_TEMPLATE_TYPE;
        },
        isTypeLink() {
            return this.hotspotData.displayType === this.$types.LOT_AS_LINK_TYPE;
        },
        isLotInfoVisible() {
            if (this.isTypeTemplate) {
                return true;
            }

            if (!this.hotspotData?.document && !this.hotspotData?.image) {
                return false;
            }

            return !this.isVisibleLotStatus && !this.isStatusExamination;
        },
        typeLot() {
            return this.hotspotData.type;
        },
        allowSwitchingToFloorView() {
            return this.isLotHasFloorView && !this.isVisibleLotStatus && !this.isStatusExamination && this.isTypeLot;
        },
    },
    created() {
        !this.tours.length && this.getTours();
    },
    methods: {
        priceFormat,
        ...mapMutations({
            setLotInfo: 'scheme/setLotInfo',
            updateSelectedLot: 'scheme/updateSelectedLot',
            setIsToggleViewer: 'scheme/setIsToggleViewer',
            setSelectedFloor: 'scheme/setSelectedFloor',
            toggleActivityAdditionalWindow: 'navigation/toggleActivityAdditionalWindow',
            setIsMenuVisible: 'navigation/setIsMenuVisible',
            changeIsMenuMustBeStatic: 'navigation/changeIsMenuMustBeStatic',
            changeIsMenuMustBeHidden: 'navigation/changeIsMenuMustBeHidden',
            setIsCloseBurgerButtonHide: 'navigation/setIsCloseBurgerButtonHide',
            setSelectedTourIndex: 'tours/setSelectedTourIndex',
        }),
        ...mapActions({
            updateFavorites: 'scheme/updateFavorites',
            getTours: 'tours/getTours',
        }),
        showLotInfo(hotspotData) {
            this.setLotInfo(hotspotData);
            this.toggleActivityAdditionalWindow(this.$constants.additionalWindows.LOT_INFO);
        },
        mouseOut() {
            this.isMouseOver = false;
            this.$emit('userleft');
        },
        lotInfoClick() {
            this.toggleActivityAdditionalWindow();
            this.setIsMenuVisible(true);
            this.changeIsMenuMustBeStatic(true);
            this.showLotInfo(this.hotspotData);
            this.changeIsMenuMustBeHidden(true);
            this.setIsCloseBurgerButtonHide(true);
        },
        onLotSelectHandler(lot) {
            this.updateSelectedLot(lot);
            this.setIsToggleViewer(true);
            this.setSelectedFloor(String(lot.floor));
        },
        onChangeTypeHandler() {
            const currentTourIndex = this.tours.findIndex((tour) => tour.title === this.hotspotData.url);
            this.setSelectedTourIndex(currentTourIndex !== -1 ? currentTourIndex : 0);
            this.activeImage && window.sessionStorage.setItem('image-number', this.activeImage);
            this.$router.push({name: 'Tours'});
        },
    },
};
</script>

<style>
.status-dscr {
    color: white;
    font-size: 12px;
    margin-top: 5px;
}
.colorGrey {
    background: gray;
}
</style>
