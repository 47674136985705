export default {
    methods: {
        isPrice(price) {
            return price && price !== 0;
        },
        getPrice(lot) {
            const price = parseFloat(lot?.price);

            return Number.isNaN(price) ? 0 : price;
        }
    }
};
