<template>
    <div>
        <div id="krpano" style="width: 100%; height: 100vh" />
        <FullscreenToggle />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FullscreenToggle from '@front/components/Navigation/FullscreenToggle';

function updateUrl(tour, room) {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('tour', tour);
    queryParams.set('room-index', room);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState(null, '', newUrl);
}

export default {
    name: 'ToursPage',
    components: {
        FullscreenToggle
    },
    data() {
        return {
            krpano: null,
            roomQuery: null
        };
    },
    computed: {
        ...mapGetters({
            tours: 'tours/tours',
            selectedTourIndex: 'tours/selectedTourIndex',
            selectedRoomIndex: 'tours/selectedRoomIndex',
            isTourPageVisible: 'config/isTourPageVisible',
            tourIndexFile: 'tours/tourIndexFile',
            toursPath: 'tours/toursPath'
        })
    },
    watch: {
        selectedTourIndex() {
            this.reloadPano();
        },
        selectedRoomIndex() {
            this.changeRoom();
        }
    },
    async created() {
        !this.tours?.length && (await this.getTours());
        setTimeout(this.init, 300);
    },
    beforeDestroy() {
        window.changeRoom = null;
    },
    methods: {
        ...mapMutations({
            setSelectedRoomIndex: 'tours/setSelectedRoomIndex',
            setSelectedTourIndex: 'tours/setSelectedTourIndex'
        }),
        ...mapActions({
            loaderHide: 'loader/hide',
            getTours: 'tours/getTours'
        }),
        async init() {
            try {
                if (!this.isTourPageVisible) {
                    this.$router.push({ name: 'Home' });
                }

                window.changeRoom = (id) => this.listenChangeRoom(id);
                let roomIndex = parseInt(this.$route.query['room-index'] || this.$route.params['room-index'] || 0);

                // Tours all case
                let tourIndex = 0;
                if (this.$route.query.vrId !== undefined) {
                    tourIndex = this.tours.findIndex((tour) => tour.id === this.$route.query.vrId);
                    this.setSelectedTourIndex(tourIndex);
                }

                if (this.$route.params.vrId !== undefined) {
                    tourIndex = this.tours.findIndex((tour) => tour.id === this.$route.params.vrId);
                    this.setSelectedTourIndex(tourIndex);
                }

                let tourIndexParam = parseInt(this.$route.query.tour || this.$route.params.tour || 0);
                if (!tourIndex) {
                    tourIndex = tourIndexParam || this.selectedTourIndex;
                    this.setSelectedTourIndex(tourIndex);
                }

                // Rooms all case
                if (this.$route.query.room !== undefined) {
                    this.roomQuery = this.tours[this.selectedTourIndex].rooms.findIndex((room) => {
                        return room.id === this.$route.query.room;
                    });
                    this.setSelectedTourIndex(tourIndex);
                }

                if (!this.roomQuery) {
                    this.roomQuery = roomIndex || this.selectedRoomIndex;
                    this.setSelectedRoomIndex(this.roomQuery);
                }

                updateUrl(this.selectedTourIndex, this.selectedRoomIndex);
                this.initKr();
            } catch (error) {
                this.$notify({
                    type: 'error',
                    title: 'Erreur',
                    text: 'Quelque chose s`est mal passé. Actualiser la page.'
                });
            }
        },
        initKr() {
            embedpano({
                xml: `${this.toursPath}/${this.tours[this.selectedTourIndex].id}/${this.tourIndexFile}`,
                target: 'krpano',
                id: 'tourPano',
                onready: this.krpanoReady
            });

            this.loaderHide();
        },
        krpanoReady(krpano) {
            this.krpano = krpano;
        },
        reloadPano() {
            try {
                removepano('tourPano');
                this.initKr();
            } catch (e) {}
        },
        changeRoom() {
            const room = this.tours[this.selectedTourIndex].rooms[this.selectedRoomIndex];
            this.krpano.call(`changePiece(${room.id}, ${room.angle}, get(globalTon))`);
        },
        listenChangeRoom(id) {
            if (this.roomQuery !== null) {
                this.setSelectedRoomIndex(this.roomQuery);
                this.roomQuery = null;
                this.changeRoom();

                return;
            }
            const index = this.tours[this.selectedTourIndex].rooms.findIndex((room) => room.id === id);
            this.setSelectedRoomIndex(index);
            updateUrl(this.selectedTourIndex, this.selectedRoomIndex);
        }
    }
};
</script>
