<template>
    <div class="inner-menu-t2 desktop-menu-template">
        <div class="menu-link-t2__item">
            <div class="menu-link-t2__label" :class="{ 'dropdown-icon': tours.length > 1 }">
                <div class="menu-link-t2__label__title">
                    <span class="aside-menu-localisation-element-padding">{{ tours[selectedTourIndex]?.title }}</span>
                </div>
            </div>
            <div class="sub-menu" v-if="tours.length > 1">
                <template v-for="(tour, tourIndex) in tours">
                    <div class="sub-menu-item">
                        <div
                            class="sub-menu-item__title"
                            :class="{ active: selectedTourIndex === tour.id }"
                            @click="() => setSelectedTourIndex(tourIndex)"
                        >
                            {{ tour.title }}
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="menu-link-t2__item" v-if="tours[selectedTourIndex]">
            <div class="menu-link-t2__label" :class="{ 'dropdown-icon': tours[selectedTourIndex]?.rooms.length > 1 }">
                <div class="menu-link-t2__label__title">
                    <span class="aside-menu-localisation-element-padding">{{
                        tours[selectedTourIndex]?.rooms[selectedRoomIndex]?.title
                    }}</span>
                </div>
            </div>
            <div class="sub-menu" v-if="tours[selectedTourIndex]?.rooms.length > 1">
                <template v-for="(room, roomIndex) in tours[selectedTourIndex].rooms">
                    <div class="sub-menu-item" :key="`room_${roomIndex}`">
                        <div
                            class="sub-menu-item__title"
                            :class="{ active: roomIndex === selectedRoomIndex }"
                            @click="() => setSelectedRoomIndex(roomIndex)"
                        >
                            {{ room.title }}
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="inner-menu-t2">
            <div class="menu-link-t2__item">
                <div class="menu-link-t2__label ico">
                    <div class="menu-link-t2__label__title">
                        <span class="aside-menu-localisation-element-padding" @click="enterVR">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                                <g
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                    color="currentColor"
                                >
                                    <path
                                        d="M17 9H7c-1.87 0-2.804 0-3.5.442c-.456.29-.835.706-1.098 1.208C2 11.416 2 12.444 2 14.5s0 3.084.402 3.85c.263.502.642.918 1.098 1.208C4.196 20 5.13 20 7 20c.613 0 .92 0 1.21-.047a3.85 3.85 0 0 0 2.083-1.073c.219-.215.415-.474.808-.993l.334-.44c.198-.263.298-.394.42-.446a.46.46 0 0 1 .315-.015c.125.039.235.16.454.4l.962 1.058c.238.263.357.394.482.51c.638.596 1.43.957 2.264 1.031c.162.015.33.015.668.015c1.87 0 2.804 0 3.5-.442c.456-.29.835-.706 1.098-1.208c.402-.766.402-1.794.402-3.85s0-3.084-.402-3.85A3.17 3.17 0 0 0 20.5 9.442C19.804 9 18.87 9 17 9m-1 4h2"
                                    />
                                    <path
                                        d="M22 15v-2.548c0-.98 0-1.47-.077-1.943a6 6 0 0 0-.157-.69c-.133-.455-.339-.875-.751-1.715c-.827-1.685-1.24-2.527-1.843-3.093a3.9 3.9 0 0 0-.97-.67C17.495 4 16.692 4 15.084 4H8.916c-1.607 0-2.41 0-3.117.34a3.9 3.9 0 0 0-.97.671c-.602.566-1.016 1.408-1.843 3.093c-.412.84-.618 1.26-.751 1.715a6 6 0 0 0-.157.69C2 10.982 2 11.472 2 12.452V15"
                                    />
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';

export default {
    name: 'ToursMenuDropdown',
    computed: {
        ...mapGetters('tours', ['tours', 'selectedTourIndex', 'selectedRoomIndex'])
    },
    created() {
        this.toggleActivityAdditionalWindow();
        this.getSchemeData();
        // this.selectTourByParam();
    },
    methods: {
        ...mapActions({
            getSchemeData: 'scheme/getSchemeData'
        }),
        ...mapMutations({
            setSelectedRoomIndex: 'tours/setSelectedRoomIndex',
            setSelectedTourIndex: 'tours/setSelectedTourIndex',
            toggleActivityAdditionalWindow: 'navigation/toggleActivityAdditionalWindow'
        }),
        enterVR() {
            var krpano = document.getElementById('tourPano');
            krpano?.call('webvr.enterVR()');
        },
        selectTourByParam() {
            const tourIndex = this.tours.findIndex((tour) => {
                return tour.id === this.$route.params.vrId;
            });

            this.setSelectedTourIndex(tourIndex !== -1 ? tourIndex : 0);
        }
    }
};
</script>

<style scoped>
.inner-menu-t2 {
    position: relative;
    display: flex;
    gap: 15px;
}

.menu-link-t2__item {
    position: relative;
}

.sub-menu {
    top: 100%;
    left: 0;
    display: none;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.5) !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #fff;
    border-radius: 0 0 4px 4px;
    padding-bottom: 10px;
}

.menu-link-t2__item:hover .sub-menu {
    display: block;
}

.sub-menu-item {
    padding: 8px 20px;
    font-size: 12px;
    letter-spacing: 0.1em;
}

.sub-menu-item__title {
    cursor: pointer;
}

.sub-menu-item__title.active {
    font-weight: bold;
}

.menu-link-t2__label {
    color: #ffffff;
    font-family: 'Open Sans';
    font-size: 18px;
    border-radius: 4px;
    letter-spacing: 0.1em;
    text-decoration: none;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    line-height: 1;
    box-sizing: border-box;
    height: 100%;
    padding: 13px 24px;
}
.menu-link-t2__label.dropdown-icon {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20"><path fill="%23FFFFFF" d="M10.103 12.778L16.81 6.08a.69.69 0 0 1 .99.012a.726.726 0 0 1-.012 1.012l-7.203 7.193a.69.69 0 0 1-.985-.006L2.205 6.72a.727.727 0 0 1 0-1.01a.69.69 0 0 1 .99 0z"/></svg>')
        no-repeat right 15px center;
    background-color: rgba(0, 0, 0, 0.5);
    padding-right: 40px;
}
.menu-link-t2__label i {
    color: #ccc;
    font-weight: 100;
    margin-right: 10px;
}

.menu-link-t2__item:hover .menu-link-t2__label {
    border-radius: 4px 4px 0 0;
}
.menu-link-t2__label.ico {
    padding: 7px 15px;
    cursor: pointer;
}
</style>
