const resolutions = [
    {
        name: 'original',
        condition: (res) => res >= 1900,
    },
    {
        name: 'big',
        condition: (res) => res < 1900 && res >= 1200,
    },
    {
        name: 'md',
        condition: (res) => res < 1200 && res >= 768,
    },
    {
        name: 'sm',
        condition: (res) => res < 768,
    },
];

export default function () {
    const resolution = resolutions.find((item) => item.condition(window.innerWidth));

    return resolution.name;
}
