/**
 * @param {Window} window
 * @return {boolean}
 */
export function isInIframe(window) {
    try {
        return window.self !== window.top;
    } catch (error) {
        return true;
    }
}
