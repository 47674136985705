export default {
    externalCrmTitle: 'Système externe de CRM',
    enableCheckbox: 'activer',
    unlatchFields: {
        devUrl: 'Dev URL',
        devProgramId: 'Dev programId',
        devMediaReference: 'Dev médiaRéférence',
        url: 'Prod URL',
        programId: 'Prod programId',
        mediaReference: 'Prod médiaRéférence',
    },
    emailBackgroundColor: {
        title: 'Arrière-plan du formulaire de contact principal',
    },
    emailHeaderInfo: {
        // eslint-disable-next-line quotes
        title: `Informations de l\'en-tête de l\'email`,
        fields: {
            mailFrom: 'Courrier de',
            mailTo: 'Envoyer à (séparer par , pour ajouter plusieurs adresses)',
            testMailTo: 'Test mail to (séparer par , pour ajouter plusieurs adresses)',
            mailSubject: 'Sujet',
        },
    },
    emailTemplate: {
        title: 'Modèle d`e-maile',
    },
    adminEmailTemplate: {
        title: 'Modèle d`e-mail d`administration',
    },
    formField: {
        title: 'Champs disponibles à utiliser dans les modèles',
        text: {
            insertImageInsideEmail: `
                Si vous souhaitez insérer une image dans le modèle d'e-mail, téléchargez-la ou sélectionnez-la
                dans la liste ci-dessous et collez-la dans les champs d'édition
                ci-dessus, comme indiqué dans l'exemple ci-dessous:
            `,
            tagAsText:
                '<img src="{{ email.image("@images/ <téléchargée de la liste ci-dessous avec extension>") }}" alt="">',
            forExample: 'par exemple,',
            doNotCopyExample: 'NE PAS COPIER L`EXEMPLE! Tous les styles sont enregistrés lors de la copie.',
        },
    },
    adleadFields: {
        url: 'URL',
        programId: 'ProgramId',
    },
    immoleadFields: {
        url: 'Url',
        devUrl: 'Dev url',
    },
    uploadImages: 'Télécharger des images pour le courrier électronique',
    sendTestEmail: 'Envoyer un e-mail test',
    saveConfiguration: 'Enregistrer la configuration',
};
