<template>
    <div
        v-if="enableChangeLang"
        v-click-outside="onLangClose"
        class="aside-desktop-dropdown"
        :class="isLangOpen ? 'active' : ''"
        @click="onLangToggle"
    >
        {{ langObj[lang] ?? langObj.fr }} <Icon icon="uiw:down" />
        <div class="aside-desktop-dropdown__items">
            <template v-for="(v, k) in langObj">
                <div v-if="lang != k" :key="k" class="aside-desktop-dropdown__items-item" @click="onLangChange(k)">
                    {{ v }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Icon } from '@iconify/vue2';

export default {
    name: 'HomePageActions',
    components: {
        Icon
    },
    data() {
        return {
            isLangOpen: false,
            lang: this.$i18n.locale || 'fr',
            langObj: {
                fr: 'Français',
                en: 'English'
            }
        };
    },
    computed: {
        ...mapGetters({
            enableChangeLang: 'config/enableChangeLang'
        })
    },
    mounted() {
        this.applyLangParams();
    },
    watch: {
        $route(to, from) {
            if (to.path !== from.path) {
                this.addLanguageParam();
            }
        }
    },
    methods: {
        applyLangParams() {
            const urlParams = new URLSearchParams(window.location.search);
            let lang = urlParams.get('lang');

            // Set to default if lang is not supported
            if (!this.langObj.hasOwnProperty(lang)) {
                lang = this.$i18n.locale;
            }
            this.onLangChange(lang);
        },
        onLangClose() {
            this.isLangOpen = false;
        },
        onLangToggle() {
            this.isLangOpen = !this.isLangOpen;
        },
        onLangChange(lang) {
            this.lang = lang;
            this.$i18n.locale = this.lang;
            localStorage.setItem('lang', this.lang);
            this.addLanguageParam();
        },
        addLanguageParam() {
            const currentUrl = new URL(window.location.href);
            const lang = this.$i18n.locale; // Assuming you're using vue-i18n
            currentUrl.searchParams.set('lang', lang);
            window.history.replaceState({}, '', currentUrl.toString());
        }
    }
};
</script>

<style lang="scss" scoped>
.aside-desktop-dropdown {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px 15px;
    transition: background 0.5s;
    border-radius: 4px;
    color: #ffffff;
    font-family: 'Open Sans';
    font-size: 14px;
    cursor: pointer;

    svg {
        margin-left: 12px;
        transition: transform 0.3s;
    }

    &__items {
        position: absolute;
        z-index: -1;
        top: 100%;
        right: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-radius: 0 0 4px 4px;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s;
        padding: 10px 0;

        &-item {
            padding: 0 15px;
            color: #ffffff;
            font-family: 'Open Sans';
            font-size: 14px;
            text-align: left;
        }
    }

    &.active {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 4px 4px 0 0;

        svg {
            transform: rotate(180deg);
        }

        .aside-desktop-dropdown {
            &__items {
                z-index: 1;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
</style>
