<template>
    <div ref="brochure" />
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'GalleryBrochure',
    computed: {
        ...mapGetters({
            brochurePath: 'gallery/brochurePath',
        }),
    },
    created() {
        this.$wait(100).then(this.loaderHide);
    },
    mounted() {
        this.$nextTick().then(() => {
            const flipBook = $(this.$refs.brochure).flipBook({
                pdfUrl: this.brochurePath,
                backgroundColor: '#191919',
                menuTransparent: true,
                btnColor: '#191919',
                sideBtnSize: 55,
                sideBtnRadius: 40,
                sideBtnMargin: 40,
                sideBtnBackground: '#191919',
                sideBtnPaddingH: 5,
                sideBtnShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
            });

            flipBook.initPdf();
        });
    },
    methods: {
        ...mapActions({
            loaderHide: 'loader/hide',
        }),
    },
};
</script>

<style>
.flipbook-right-arrow::before,
.flipbook-left-arrow::before {
    font-size: 42px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
