import HttpClient from '@vue/js/service/HttpClient';
import ApiConstants from '@common/constants/api-constants';

export default {
    async getCaptcha({commit}) {
        return HttpClient.get(ApiConstants.CAPTCHA_URL)
            .then((response) => {
                commit('setCaptcha', response.data);
            })
            .catch((error) => {
                this._vm.$notify({
                    title: 'Error',
                    text: error?.response?.data?.error ?? 'Error',
                    type: 'error',
                });

                return error;
            });
    },
};
