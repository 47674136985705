import {getMainPageTemplate} from '@front/helpers';

export const getRoutes = (store, projectExtendData) => ({
    menuRoutes: [
        {
            path: '',
            name: 'Home',
            component: () => getMainPageTemplate(projectExtendData, store),
            meta: {
                menuTemplate: 'HomeMenu',
                title: 'ACCUEIL',
                isActive: (notActive) => notActive === undefined,
                icon: 'home',
                isIconBar: true,
            },
        },
        {
            path: '/location',
            name: 'Location',
            component: () => import('@front/pages/Location'),
            meta: {
                menuTemplate: 'LocationMenu',
                title: 'Localisation',
                isActive: () => store.getters['config/isLocationPageVisible'],
                icon: 'localisation',
                isIconBar: true,
            },
        },
        {
            path: '/scheme3d',
            name: 'Scheme3d',
            component: () => import('@front/pages/Scheme3d.wrap'),
            meta: {
                menuTemplate: 'Scheme3dMenu',
                title: 'MAQUETTE 3D',
                isLotsAvailable: false,
                isActive: () => store.getters['config/isSchemePageVisible'],
                icon: 'residence',
                isIconBar: true,
            },
        },
        {
            path: '/tours',
            name: 'Tours',
            component: () => import('@front/pages/Tours'),
            meta: {
                menuTemplate: 'ToursMenu',
                title: 'LA VISITE VIRTUELLE',
                isActive: () => store.getters['config/isTourPageVisible'],
                icon: '360deg',
                isIconBar: true,
            },
        },
        {
            path: '/tours/:vrId',
            name: 'ToursWithId',
            component: () => import('@front/pages/Tours'),
            props: true,
            meta: {
                menuTemplate: 'ToursMenu',
                isActive: () => false,
                icon: '360deg',
            },
        },
        {
            path: '/gallery',
            name: 'Gallery',
            component: () => import('@front/pages/Gallery'),
            meta: {
                menuTemplate: 'GalleryMenu',
                title: 'GALERIE',
                isActive: () => store.getters['config/isGalleryPageVisible'],
                icon: 'gallery',
                isIconBar: true,
            },
        },
    ],
    additionalRoutes: [
        {
            path: '/mentions-legales',
            name: 'MentionsLegales',
            component: () => import('@front/pages/MentionsLegales'),
            meta: {
                menuTemplate: 'HomeMenu',
                title: 'Mentions légales',
                isActive: () => true,
            },
        },
    ],
});
