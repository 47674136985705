export default {
    manageSlide: {
        title: 'Set slide title',
        dimensionInfo: 'Set slide image (max resolution {maxDimension} pixels)',
    },
    clientInfo: {
        ref: 'Client ref',
        bottomLogoText: 'Bottom logo text',
        logos: {
            imageWhite: 'Choose logo (white)',
            imageDark: 'Choose logo (dark)',
        },
    },
    editTour: {
        enableCompass: 'Enable compass',
    },
    editScheme: {
        enableCompass: 'Enable compass',
        compassDegree: 'Compass degree',
        enableCompassAngleLimit: 'Enable limitations of rotation angles',
        beginningImage: 'Beginning image',
        endingImage: 'Ending image',
        mustBeBetween: 'Must be between',
        and: 'and',
    },
    uploadViewsTitle: 'Upload scheme',
    filterCreation: {
        title: 'Create filter',
        filterType: 'Filter type',
        colorMapping: 'Enable color mapping',
        removeFilter: 'Remove filter',
        saveFilter: 'Save filter',
    },
    filterOptions: {
        chooseOption: 'Choose option',
        chooseTitle: 'Choose option title',
        chooseColor: 'Choose option color',
    },
    editRoom: {
        initialAngle: 'Initial compass angle',
        initialCamera: 'Initial camera {coords}',
        floor: 'Floor',
        imageType: 'Image type',
        sphere: 'sphere',
        imagesOrder: 'Images order {order} don`t change it if not sure',
        imagesRotation: 'Images rotation {rotation} don`t change it if not sure',
        stereoSphere: 'stereo sphere',
    },
    mediaItem: {
        mapArea: 'Map area',
        entityIdTitle: 'Entity id for area tag',
        textInfo: {
            switchThePlan: 'If you want to switch the plan on the',
            loction: 'location',
            pageByClicking: 'page by clicking on the image area, you need to insert',
            entityId: 'entityId',
            hrefAttributeExample: 'into the href attribute of the area, for example:',
        },
        listAvailableIds: 'List of available ids',
        entryTitle: 'Entity title',
    },
    mediaItemTable: {
        createMediaItem: 'Create media item',
    },
    mapsTable: {
        createMap: 'Create map',
        apiKey: 'Google api key',
        address: 'Cliquable address (URL)',
        latitude: 'Marker latitude',
        longitude: 'Marker longitude',
        centerLatitude: 'Map center latitude',
        centerLongitude: 'Map center longitude',
        tooltipLinkLabel: 'Tooltip link label',
        zoom: 'Default map zoom',
        tooltipContent: 'Tooltip content',
        snazzyStyle: 'Snazzy style',
        uploadMarkerImage: 'Upload marker image',
    },
    editProceccingConfig: {
        distance: 'distance',
        ignoreColorThreshold: 'ignore color threshold (background)',
        detectColorThreshold: 'detect color threshold (lot)',
        generateRotation: 'generate rotation',
        detectedColors: 'detected colors',
        ignoredColors: 'ignored colors',
        add: 'add',
    },
    editLot: {
        hotspotSettings: 'Hotspot settings',
        untitledLot: 'Untitled lot',
        specialLots: 'Special lots',
        iconSize: 'Icon {size}',
        hoverIcon: 'hover icon',
        anchor: 'Anchore',
        template: 'Template',
        typeOfSale: 'Type of sale',
        planPdf: 'Plan pdf (desktop)',
        planImage: 'Plan image (mobile)',
    },
    multiEdit: {
        showStatus: 'Show status',
        allowComparison: 'Allow comparison',
        showInfo: 'Show info',
        building: 'Building',
    },
    externalArea: 'External Area',
    addBlock: {
        title: 'Add new block',
    },
};
