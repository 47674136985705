<template>
    <div
        id="app-wrapper"
        class="app-wrapper"
    >
        <notifications position="top center" />
        <transition
            name="fade"
            mode="out-in"
        >
            <router-view />
        </transition>
        <ContactForm />
        <CommunicationPanel v-if="enableContactSidebar && $route.name === $constants.MAIN && false" />
        <CookieAlert
            :show="isCookieAlertVisible"
            @accept="onAcceptCookieHandler"
            @refuse="onRefuseCookieHandler"
        />
    </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';
import mainPageTemplates from '@front/constants/mainPageTemplates';
import {MOBILE_WIDTH} from '@front/constants';
import {parseQueryString} from '@front/utils/parseQueryString';
import ContactForm from '@/assets/js/app/components/Forms/ContactForm/ContactForm.vue';
import CookieAlert from '@front/components/CookieAlert.vue';
import CommunicationPanel from '@front/components/Extends/CommunicationPanel.vue';

export default {
    components: {
        ContactForm,
        CookieAlert,
        CommunicationPanel,
    },
    data() {
        return {
            isCookieAlertVisible: false,
        };
    },
    computed: {
        ...mapGetters({
            mainPageTemplate: 'config/mainPageTemplate',
            qualityOptions: 'config/qualityOptions',
            enableCookieAlert: 'config/enableCookieAlert',
            enableContactSidebar: 'config/enableContactSidebar',
        }),
        templateView() {
            return this.mainPageTemplate ?? mainPageTemplates.SLIDER_VIEW;
        },
    },
    watch: {
        '$route.path': {
            immediate: true,
            handler() {
                this.setIsMenuVisible(window && window.innerWidth > MOBILE_WIDTH);
            },
        },
    },
    created() {
        const apiToken = parseQueryString()?.token;

        if (apiToken) {
            this.setApiToken(apiToken);
        }
    },
    mounted() {
        this.$nextTick().then(this.init);
    },
    methods: {
        ...mapMutations({
            setApiToken: 'externalApi/setApiToken',
            setIsMenuVisible: 'navigation/setIsMenuVisible',
            setCurrentImageQuality: 'config/setCurrentImageQuality',
        }),
        init() {
            if (this.templateView === mainPageTemplates.SLIDER_VIEW) {
                document.body.style.overflow = 'hidden';
            }

            const quality = this.qualityOptions?.find(({minWidth, maxWidth}) => {
                return window.innerWidth >= minWidth && window.innerWidth <= maxWidth;
            })?.title;

            quality && this.setCurrentImageQuality(quality);

            const acceptCookies = this.$cookies.get('acceptCookies');

            if (!acceptCookies && this.enableCookieAlert) {
                this.isCookieAlertVisible = true;
            }
        },
        onAcceptCookieHandler() {
            this.$cookies.set('acceptCookies', true, '1y');
            this.isCookieAlertVisible = false;
        },
        onRefuseCookieHandler() {
            this.$cookies.remove('acceptCookies');
            this.isCookieAlertVisible = false;
        },
    },
};
</script>
