<template>
    <div class="aside-menu-t2__links aside-menu-t2__links_landing mobile-menu-template">
        <span class="mobile-menu-template__line" />
        <HomeMenuRoutes
            :menu-routes="menuRoutes"
            :is-dark-theme="isDarkTheme"
            :enable-lot-availability-page="enableLotAvailabilityPage"
            :enable-contact-form="enableContactForm"
            :form-anchor="formAnchor"
            class="d-flex flex-column align-items-start"
            @click="onContactFormHandler"
        />
    </div>
</template>

<script>
import homeMenuMixin from '@front/mixins/homeMenuMixin';

export default {
    mixins: [homeMenuMixin]
};
</script>

<style lang="scss">
.mobile-menu-template {
    position: relative;

    @media (orientation: landscape) {
        &__line {
            position: absolute;
            left: 0;
            top: 5%;
            bottom: 5%;
            width: 1px;
            background: #000;
        }

        .menu-footer {
            display: none;
        }
    }

    .aside-menu__links_landing .menu-link-t2-t2__img-container {
        @media (max-width: 900px) {
            width: 25px;
            height: 25px;
        }
    }
}
</style>
