<template>
    <div style="overflow: hidden">
        <div
            v-if="!activeEntity.image && !activeEntity.apiKey && tourshotspots && tourshotspots.length"
            id="krpano"
            style="width: 100%; height: 100vh"
        />
        <transition name="fade" v-else-if="activeEntity.image">
            <div>
                <img
                    :src="`${mediaItemsPath}/${activeEntity.id}/${currentImageQuality}/${activeEntity.image}`"
                    :usemap="`#${usemap}`"
                    :class="['location-plan', { 'location-plan--black': isDarkTheme }]"
                />
                <p :key="activeEntity.id" v-catch-map-redirect v-html="activeEntity.mapCoordinates" />
            </div>
        </transition>
        <transition name="fade" v-else-if="activeEntity.apiKey">
            <div>
                <template v-for="map in maps">
                    <GoogleMap
                        v-show="map.id === activeEntity.id"
                        :key="map.id"
                        v-bind="map"
                        :marker-image-path="getMapMarkerImagePath(map)"
                    />
                </template>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import GoogleMap from '@front/components/GoogleMap.vue';
import catchMapRedirect from '@front/directives/catchMapRedirect';

export default {
    name: 'LocationPage',
    components: {
        GoogleMap
    },
    directives: {
        'catch-map-redirect': catchMapRedirect
    },
    data() {
        return {
            krpano: null
        };
    },
    computed: {
        ...mapGetters({
            isConfigLoaded: 'location/isConfigLoaded',
            tourshotspots: 'location/tours',
            maps: 'location/maps',
            activeEntity: 'location/activeEntity',
            selectedTourIndex: 'location/selectedTourIndex',
            selectedRoomIndex: 'location/selectedRoomIndex',
            toursPath: 'location/toursPath',
            mediaItemsPath: 'location/mediaItemsPath',
            googleMapsPath: 'location/googleMapsPath',
            qualityOptions: 'location/qualityOptions',
            currentImageQuality: 'location/currentImageQuality',
            isDarkTheme: 'theme/isDarkTheme'
        }),
        usemap() {
            try {
                return this.activeEntity.mapCoordinates.match(/name="(.*?)"/)[1];
            } catch (error) {
                return null;
            }
        }
    },
    watch: {
        tourshotspots: {
            deep: true,
            handler(newValue) {
                newValue[this.selectedTourIndex]?.rooms[this.selectedRoomIndex]?.hotspots?.forEach((hotspot) =>
                    this.setHotspotState(hotspot.id, hotspot.visible)
                );
            }
        },
        activeEntity: {
            deep: true,
            handler(entity) {
                const index = this.tourshotspots.findIndex((obj) => obj.id === entity.tour);
                if (index === -1) {
                    removepano('locationPano');
                    return;
                }

                const roomIndex = this.tourshotspots[index].rooms.findIndex((obj) => obj.id === entity.id);
                this.setSelectedTourIndex(index);
                this.setSelectedRoomIndex(roomIndex);

                setTimeout(() => this.reloadPano(), 300);
            }
        },
        selectedRoomIndex: {
            handler(index) {
                this.krpano.call(
                    `changePiece(${this.tourshotspots[this.selectedRoomIndex].rooms[index].id},get(globalTon))`
                );
            }
        }
    },
    created() {
        setTimeout(this.init, 300);
    },
    beforeDestroy() {
        window.changeRoom = null;
    },
    methods: {
        ...mapMutations({
            setSelectedRoomIndex: 'location/setSelectedRoomIndex',
            setSelectedTourIndex: 'location/setSelectedTourIndex',
            setCurrentImageQuality: 'location/setCurrentImageQuality'
        }),
        ...mapActions({
            loaderHide: 'loader/hide',
            getLocations: 'location/getLocations'
        }),
        async init() {
            try {
                !this.isConfigLoaded && (await this.getLocations());
                this.setImageQuality();

                if (!this.tourshotspots.length) {
                    return;
                }

                window.changeRoom = (id) => {
                    this.listenChangeRoom(id);
                };

                this.initKr();
            } catch (error) {
                this.$notify({
                    type: 'error',
                    title: 'Erreur',
                    text: 'Quelque chose s`est mal passé. Actualiser la page.'
                });
            }
        },
        initKr() {
            embedpano({
                xml: `${this.toursPath}/${this.tourshotspots[this.selectedTourIndex].id}/index.xml`,
                target: 'krpano',
                id: 'locationPano',
                onready: this.krpanoReady,
                onerror: (e) => {
                    console.log(e);
                }
            });

            this.loaderHide();
        },
        krpanoReady(krpano) {
            this.krpano = krpano;
        },
        reloadPano() {
            removepano('locationPano');
            this.initKr();
        },
        setHotspotState(name, state) {
            this.krpano?.set(`hotspot[${name}].visible`, state);
        },
        listenChangeRoom(id) {
            const index = this.tourshotspots[this.selectedTourIndex].rooms.findIndex((room) => room.id === id);
            this.setSelectedRoomIndex(index);
        },
        getMapMarkerImagePath({ id, markerImage }) {
            if (!markerImage) {
                return null;
            }

            return `${this.googleMapsPath}/${id}/${markerImage}`;
        },
        async setImageQuality() {
            await this.$nextTick();
            const quality = this.qualityOptions?.find(({ minWidth, maxWidth }) => {
                return window.innerWidth >= minWidth && window.innerWidth <= maxWidth;
            })?.title;

            quality && this.setCurrentImageQuality(quality);
        }
    }
};
</script>

<style lang="scss" scoped>
.location-plan {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    object-fit: scale-down;

    @media screen and (max-width: 520px) {
        bottom: initial;
        height: 100%;
    }

    &--black {
        background: rgb(25, 25, 25);
    }
}
</style>
