import mainPageTemplates from '@front/constants/mainPageTemplates';

// {page_name: mutation}
const pagesVisibilityMap = {
    tours: 'setIsTourPageVisible',
    scheme: 'setIsSchemePageVisible',
    localisation: 'setIsLocationPageVisible',
    gallery: 'setIsGalleryPageVisible',
};

export default {
    async getModulesAvailabilityConfig({commit}) {
        const {modules} = await (await fetch('/data/project/main_module/modules_config.json')).json();
        modules.forEach((module) => commit(pagesVisibilityMap[module.name], module.enabled));
    },
    async getConfigData({commit, dispatch}) {
        const config = await (await fetch('/data/project/main_module/config.json')).json();

        dispatch('theme/getThemeData', config.darkThemeByDefault ?? false, {root: true});

        commit('setEnableChangeColorTheme', config.enableChangeColorTheme ?? false);
        commit('setEnableChangeLang', config.enableChangeLang ?? true);
        commit('setClientName', config.client ?? '');
        commit('setProgramName', config.program ?? '');
        commit('setAltImgText', config.altImgText ?? '');
        commit('setMainPageTemplate', config.mainPageTemplate ?? mainPageTemplates.SLIDER_VIEW);
        commit('setAssetsPath', config.assetsPath?.startsWith('.') ? config.assetsPath.slice(1) : config.assetsPath);
        commit('setTopLogoWhite', config.topLogoWhite ?? '');
        commit('setTopLogoDark', config.topLogoDark ?? '');
        commit('setEnableContactForm', config.enableContactForm ?? false);
        commit('setEnableMenu', config.enableMenu);
        commit('setEnableSchemeLinkDisplayInMenu', config.enableSchemeLinkDisplayInMenu ?? false);
        commit('setProgramCity', config.programCity ?? '');
        commit('setExternalRepo', config.externalRepo ?? '');
        commit('setLoaderImage', config.loader ?? '');
        commit('setGoogleAdsId', config.googleAdsId ?? '');
        commit('setDefaultImages', {
            loader: config.defaultLoader ?? '',
            logo: config.defaultLogo ?? '',
        });
        commit('setBackgroundWhiteMenuImageName', config.menuBackground ?? '');
        commit('setBackgroundDarkMenuImageName', config.menuBackgroundDark ?? '');
        commit('setEnableLotAvailabilityPage', config.enableLotAvailabilityPage ?? false);
        commit('setEnableCookieAlert', config.enableCookieAlert ?? false);
        commit('setEnableIllusioButton', config.enableIllusioButton ?? false);
        commit('setLegalMentionHtml', config.legalMentionHtml ?? '');
        commit('setWhatsappNumber', config.contactSidebarConfig?.whatsappNumber ?? '');
        commit('setTelephoneNumber', config.contactSidebarConfig?.telephoneNumber ?? '');
        commit('setContactSidebarBackground', config.contactSidebarConfig?.contactSidebarBackground ?? '');
        commit('setEnableContactSidebar', config.contactSidebarConfig?.enableContactSidebar ?? false);
        commit('setEmailBackgroundColor', config.contactFormConfig?.backgroundColor ?? '#AAAAAA');
        commit('setQualityOptions', config.qualityOptions);
        commit('setBottomLogos', config.bottomLogos);
        commit('setGalleries', config.galleries);
        commit('setAnalyticsEvents', config.analyticsEvents);
        commit('setCustomLanding', config.customLanding);
        commit('setIsShowMobileMenu', false);
    },
};
