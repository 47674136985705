export default {
    async getTours({commit, getters}) {
        if (getters.isToursLoading) {
            return;
        }

        commit('setIsToursLoading', true);
        const config = await (await fetch('/data/project/tours_module/config.json')).json();
        commit('setTours', config.tours || []);
        commit('setAssetsPath', config.assetsPath || '');
        commit('setHotspotsPath', config.hotspotsPath || '');
        commit('setRoomsPath', config.roomsPath || '');
        commit('setToursPath', config.toursPath || '');
        commit('setUploadsPath', config.uploadsPath || '');
        config.tourIndexFile && commit('setTourIndexFile', config.tourIndexFile);
        commit('setIsToursLoading', false);
    },
};
