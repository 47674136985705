<template>
    <div
        :class="['modal-backdrop modal-comparator', {'modal-comparator--mobile': $isMobile()}]"
        @click.self="$emit('close')"
    >
        <div class="modal">
            <section
                v-if="!isInfoVisible"
                class="modal-content-wrapper"
            >
                <header class="modal-header">
                    <slot name="header">
                        <div class="header__left">
                            <img
                                v-if="!isDarkTheme"
                                :src="$getImagePath('icons/men-icon-wt.svg')"
                                :alt="altImgText"
                            />
                            <img
                                v-if="isDarkTheme"
                                :src="$getImagePath('icons/men-icon-bt.svg')"
                                :alt="altImgText"
                            />
                            <p class="modal-header-title">MES CHOIX</p>
                        </div>
                    </slot>
                    <div class="header__right">
                        <button
                            type="button"
                            class="btn-close"
                            @click="$emit('close')"
                        >
                            <img
                                v-if="!isDarkTheme"
                                :src="$getImagePath('icons/icon-close-black.svg')"
                                :alt="altImgText"
                            />
                            <img
                                v-else
                                :src="$getImagePath('icons/icon-close.svg')"
                                :alt="altImgText"
                            />
                        </button>
                    </div>
                </header>
                <section class="modal-body">
                    <div class="modal-text modal-text_header">Comparaison des lots</div>
                    <slot name="body">
                        <ComparatorField
                            class="modal-left"
                            :lot="left"
                            :lot-info="additionalLotInfoLeft"
                            :lot-image-paths="imagePath(left)"
                            :hotspot-color="hotspotColor(left)"
                            :descriptions="descriptions"
                            :is-compare="left !== null && formattedFavorites.includes(left)"
                            @drop="onDrop($event, $constants.DROP_ZONES.LEFT)"
                            @option-lot="showLotInfo"
                            @remove-lot="toggleToComparator"
                        />
                        <ComparatorField
                            class="modal-right"
                            :lot="right"
                            :lot-info="additionalLotInfoRight"
                            :lot-image-paths="imagePath(right)"
                            :hotspot-color="hotspotColor(right)"
                            :descriptions="descriptions"
                            :is-compare="right !== null && formattedFavorites.includes(right)"
                            @drop="onDrop($event, $constants.DROP_ZONES.RIGHT)"
                            @option-lot="showLotInfo"
                            @remove-lot="toggleToComparator"
                        />
                    </slot>
                </section>
                <footer class="modal-footer">
                    <slot name="footer">
                        <div class="modal-text_footer">DÉPLACER POUR COMPARER</div>
                        <div class="flex modal-footer-wrapper">
                            <div
                                v-for="item in formattedFavorites"
                                :key="item.lot"
                                class="footer-favorites__block"
                                draggable
                                @dragstart="startDrag($event, item)"
                            >
                                <div
                                    class="footer-favorites__item"
                                    :style="`
                                        color: ${hotspotColor(item)};
                                        background-image: url('${imageBackgroundPath(item)}')
                                    `"
                                >
                                    <span class="footer-favorites__lot">
                                        <span class="footer-favorites__num">{{ item.lot }}</span>
                                    </span>
                                    <div
                                        v-if="isLotExist(item)"
                                        class="green-circle"
                                    />
                                    <ComparerBtns
                                        :is-compare="isLotExist(item)"
                                        :enable-lots-comparison="enableLotsComparison"
                                        @compare="toggleToComparator(item)"
                                        @show-info="showLotInfo(item)"
                                        @remove="updateFavorites(item)"
                                    />
                                </div>
                            </div>
                        </div>
                    </slot>
                </footer>
            </section>
            <ModalSchemeLotInfo
                v-if="isInfoVisible"
                :lot="lotInfo"
                @close="toggleInfo"
            />
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import ModalSchemeLotInfo from '@front/components/Scheme3dLotInfo/ModalSchemeLotInfo';
import roomsDescriptions from '@front/constants/rooms-descriptions';
import ComparerBtns from '@front/components/Comparator/ComparerBtns.vue';
import ComparatorField from '@front/components/Comparator/ComparatorField.vue';
import {getMediaPath} from '@front/utils/getMediaPath';

export default {
    name: 'ModalComparator',
    components: {
        ModalSchemeLotInfo,
        ComparerBtns,
        ComparatorField,
    },
    data() {
        return {
            defaultImage: 'images/lot_default.png',
            left: null,
            right: null,
            isInfoVisible: false,
            lotInfo: null,
        };
    },
    computed: {
        ...mapGetters({
            favorites: 'scheme/favorites',
            lotsData: 'scheme/lotsData',
            enableLotsComparison: 'scheme/enableLotsComparison',
            lotMediaPath: 'scheme/lotMediaPath',
            isDarkTheme: 'theme/isDarkTheme',
            altImgText: 'config/altImgText',
        }),
        descriptions() {
            return roomsDescriptions;
        },
        additionalLotInfoLeft() {
            return this.lotsData?.find((x) => x.lot === this.left?.lot);
        },
        additionalLotInfoRight() {
            return this.lotsData?.find((x) => x.lot === this.right?.lot);
        },
        formattedFavorites() {
            return this.favorites.map((item) => Object.assign(item, {list: 0}));
        },
    },
    mounted() {
        if (this.formattedFavorites.length < 3) {
            [this.left, this.right] = this.formattedFavorites;
        }
    },
    methods: {
        getMediaPath,
        ...mapActions({
            updateFavorites: 'scheme/updateFavorites',
        }),
        ...mapMutations({
            setLotInfo: 'scheme/setLotInfo',
        }),
        showLotInfo(hotspotData) {
            this.toggleInfo();
            this.setLotInfo(hotspotData);
            this.lotInfo = hotspotData;
        },
        toggleInfo() {
            this.isInfoVisible = !this.isInfoVisible;
        },
        isLotExist(item) {
            return item.lot === this.left?.lot || item.lot === this.right?.lot;
        },
        toggleToComparator(item) {
            if (this.left && item.lot === this.left.lot) {
                this.left = null;

                return;
            }

            if (this.right && item.lot === this.right.lot) {
                this.right = null;

                return;
            }

            if (this.left === null || item === this.left) {
                this.left = item;
            } else {
                this.right = item;
            }
        },
        imagePath(lot) {
            if (lot?.image) {
                return this.getMediaPath(this.lotMediaPath, lot.color, lot.image);
            }

            if (lot?.documentImages.length === 1) {
                return getMediaPath(this.lotMediaPath, lot.color, lot.documentImages[0]);
            }

            if (lot?.documentImages.length > 1) {
                return lot.documentImages.map((image) => {
                    return {
                        path: getMediaPath(this.lotMediaPath, lot.color, image),
                    };
                });
            }

            return this.defaultImage;
        },
        imageBackgroundPath(lot) {
            return typeof this.imagePath(lot) === 'string'
                ? this.imagePath(lot)
                : getMediaPath(this.lotMediaPath, lot.color, lot.documentImages[0]);
        },
        hotspotColor(lot) {
            if (!lot) {
                return this.$constants.defaultLotColor;
            }

            return lot.filterColor ? lot.filterColor.split('|')[0] : `#${lot.color}`;
        },
        startDrag(event, item) {
            event.dataTransfer.dropEffect = 'move';
            event.dataTransfer.effectAllowed = 'move';
            event.dataTransfer.setData('itemID', item.color);
        },
        onDrop(event, list) {
            const itemID = event.dataTransfer.getData('itemID');
            const item = this.formattedFavorites.find((item) => item.color === itemID);
            if (!item) {
                return;
            }
            item.list = list;

            const leftLotId = this.left ? this.left.color : null;
            const rightLotId = this.right ? this.right.color : null;

            if (itemID !== leftLotId && item.list === 1) {
                this.left = item;
            }

            if (itemID !== rightLotId && item.list === 2) {
                this.right = item;
            }

            if (leftLotId === itemID && item.list === 2) {
                this.left = null;
            }

            if (rightLotId === itemID && item.list === 1) {
                this.right = null;
            }
        },
    },
};
</script>

<style>
.vti__dropdown-item {
    color: #000;
}
</style>
