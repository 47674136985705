export default {
    async getThemeData({commit, dispatch}, isDarkTheme) {
        commit('setColorTheme', isDarkTheme || false);
        dispatch('setThemeColors', true);
    },
    async setThemeColors({commit, getters}, isInitAction = false) {
        try {
            if (!isInitAction) {
                commit('switchTheme');
            }

            Object.entries(getters.themeColors).map(([key, value]) => {
                document.documentElement.style.setProperty(`--${key}`, value);

                return 0;
            });

            return getters.isDarkTheme;
        } catch (error) {
            console.error(error);
        }
    },
};
