<template>
    <div>
        <MutualContactForm
            v-if="!formAction"
            :is-visible="isContactModalActive"
            :background-colors="emailBackgroundColor"
            @close="setIsContactModalActive(false)"
        />
        <ExtendContactForm
            v-else
            :is-visible="isContactModalActive"
            :background-colors="emailBackgroundColor"
            @close="setIsContactModalActive(false)"
        />
        <transition name="fade">
            <ContactFormError
                v-if="errorModalProps.isActive"
                v-bind="errorModalDefaultProps"
                @close="updateContactFormModalProps({modal: 'error', prop: 'isActive', value: false})"
            >
                <a :href="`tel:${telephoneNumber.replace(/\s/g, '')}`">{{ telephoneNumber }}</a>
            </ContactFormError>
        </transition>
        <transition name="fade">
            <ContactFormSuccess
                v-if="successModalProps.isActive"
                v-bind="successModalDefaultProps"
                @close="onCloseSuccessPopup"
            />
        </transition>
    </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';
import store from '@front/store';
import ContactFormError from '@front/components/Modal/ContactFormError.vue';
import ContactFormSuccess from '@front/components/Modal/ContactFormSuccess.vue';
import MutualContactForm from './MutualContactForm.vue';

export default {
    name: 'ContactForm',
    components: {
        ExtendContactForm: () => store.getters['projectExtend/formComponent'],
        MutualContactForm,
        ContactFormError,
        ContactFormSuccess,
    },
    computed: {
        ...mapGetters({
            emailBackgroundColor: 'config/emailBackgroundColor',
            telephoneNumber: 'config/telephoneNumber',
            formAction: 'projectExtend/formAction',
            errorModalProps: 'projectExtend/errorModalProps',
            successModalProps: 'projectExtend/successModalProps',
            isContactModalActive: 'projectExtend/isContactModalActive',
        }),
        errorModalDefaultProps() {
            return {
                title: this.errorModalProps.title || 'Quelque chose s&#39;est mal passé',
                description:
                    this.errorModalProps.description ||
                    'Veuillez réessayer ultérieurement ou nous contacter directement au',
                color: this.errorModalProps.color || '#4b5a53',
            };
        },
        successModalDefaultProps() {
            return {
                title: this.successModalProps.title || 'Merci pour l&#39;intérêt que vous portez à notre résidence !',
                description:
                    this.successModalProps.description ||
                    'Votre demande a bien été enregistrée. Vous allez recevoir un email de confirmation.',
                color: this.successModalProps.color || '#4b5a53',
            };
        },
    },
    methods: {
        ...mapMutations({
            setIsContactModalActive: 'projectExtend/setIsContactModalActive',
            updateContactFormModalProps: 'projectExtend/updateContactFormModalProps',
        }),
        onCloseSuccessPopup() {
            this.updateContactFormModalProps({modal: 'success', prop: 'isActive', value: false});
            this.setIsContactModalActive(false);
        },
    },
};
</script>
