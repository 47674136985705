/**
 * @generator
 * @param {object} prop
 * @param {number} prop.from
 * @param {number} prop.to
 * @param {number} prop.step
 * @yields {number}
 */
export function* getNumberRange({from = 0, to = 0, step = 1}) {
    let i = 0;
    const length = Math.floor((to - from) / step) + 1;
    while (i < length) {
        yield i++ * step + from;
    }
}
