<template>
    <div v-if="isRenderAllow">
        <transition name="fade" mode="out-in">
            <component
                :is="currentViewer"
                :class="{ 'viewer-loading': isDefaultLoaderActive }"
                v-bind="currentViewerProps"
                @upload:start="toggleDefaultLoader(true)"
                @upload:success="hideDefaultLoader"
            />
        </transition>
        <WarningModal gallery="scheme3d" />
        <UIProgressBar v-if="isDefaultLoaderActive" v-bind="loadProgressProps" class="loader-progress" />
        <transition name="slide-spa">
            <router-view />
        </transition>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Scheme3dViewer from '@front/components/Viewers/Scheme3dViewer';
import FloorViewer from '@front/components/Viewers/FloorViewer';
import WarningModal from '@front/components/Modal/WarningModal';
import UIProgressBar from '@front/components/UI/UIProgressBar';
import screenResolution from '@front/utils/screenResolution';

const DEFAULT_TAX_RATE = 1.2;
const DEFAULT_IMAGE_COUNT = 1;
const DEFAULT_DELAY = 500;

export default {
    name: 'Scheme3DPage',
    components: {
        Scheme3dViewer,
        FloorViewer,
        WarningModal,
        UIProgressBar
    },
    data() {
        return {
            isRenderAllow: false,
            scheme3dImagesCount: 0,
            scheme3dMaskDataPaths: [],
            floorMaskDataPaths: {},
            currentViewer: '',
            hasFloorViewerInitOccurred: false,
            schemeAssetsPath: '',
            masksPath: '',
            imagesPath: '',
            floorsPath: '',
            isScheme3dDataInitted: false,
            abortController: null
        };
    },
    computed: {
        ...mapGetters({
            initialLotsData: 'scheme/initialLotsData',
            lotsData: 'scheme/lotsData',
            scheme3dImages: 'scheme/scheme3dImages',
            pathsToScheme3dImages: 'scheme/pathsToScheme3dImages',
            pathsToSheme3dMasks: 'scheme/pathsToSheme3dMasks',
            scheme3dImagesLoaded: 'scheme/scheme3dImagesLoaded',
            schemeData: 'scheme/schemeData',
            scheme3dMasks: 'scheme/scheme3dMasks',
            isToggleViewer: 'scheme/isToggleViewer',
            floorImagesLoaded: 'scheme/floorImagesLoaded',
            pathsToFloorImages: 'scheme/pathsToFloorImages',
            pathsToFloorMasks: 'scheme/pathsToFloorMasks',
            floorImages: 'scheme/floorImages',
            floorMasks: 'scheme/floorMasks',
            selectedFloor: 'scheme/selectedFloor',
            currentSchemeView: 'scheme/currentSchemeView',
            currentSchemeViewDelayed: 'scheme/currentSchemeViewDelayed',
            enableScheme3dDisplay: 'scheme/enableScheme3dDisplay',
            loadedViewerMaskCount: 'scheme/loadedViewerMaskCount',
            apiToken: 'externalApi/apiToken',
            isEmployee: 'externalApi/isEmployee',
            externalLotsData: 'externalApi/externalLotsData',
            isDefaultLoaderActive: 'loader/isDefaultLoaderActive'
        }),
        loadProgressProps() {
            if (this.currentViewer === this.$constants.viewers.SCHEME_3D_VIEWER) {
                return {
                    currentProgress: this.loadedViewerMaskCount + this.scheme3dImagesLoaded,
                    maxValue: this.totalScheme3dImagesCount + this.totalScheme3dMasksCount
                };
            }

            return {
                currentProgress: this.floorImagesLoaded[this.selectedFloor] + this.loadedViewerMaskCount,
                maxValue: this.totalFloorImagesCount + this.totalFloorMasksCount
            };
        },
        sheme3dMaskPath() {
            return `${this.schemeAssetsPath}/${this.masksPath}/${screenResolution()}`;
        },
        sheme3dImagePath() {
            return `${this.schemeAssetsPath}/${this.imagesPath}/${screenResolution()}`;
        },
        totalScheme3dImagesCount() {
            return this.schemeData.schemes[this.currentSchemeView]?.imagesCount || 0;
        },
        totalScheme3dMasksCount() {
            return this.schemeData.schemes[this.currentSchemeView]?.masksCount || 0;
        },
        floorMaskPath() {
            // eslint-disable-next-line max-len
            return `${this.schemeAssetsPath}/${this.floorsPath}/${this.currentFloor.id}/${
                this.masksPath
            }/${screenResolution()}`;
        },
        floorImagePath() {
            // eslint-disable-next-line max-len
            return `${this.schemeAssetsPath}/${this.floorsPath}/${this.currentFloor.id}/${
                this.imagesPath
            }/${screenResolution()}`;
        },
        currentFloor() {
            let floor = this.schemeData.schemes[this.currentSchemeView]?.floors.find(
                (floor) => floor.title === this.selectedFloor
            );
            if (!floor) {
                this.selectedFloor = 0;
                floor = this.schemeData.schemes[this.currentSchemeView]?.floors[this.selectedFloor];
            }
            return floor;
        },
        totalFloorImagesCount() {
            return this.currentFloor.imagesCount;
        },
        totalFloorMasksCount() {
            return this.currentFloor.masksCount;
        },
        currentViewerProps() {
            if (this.currentViewer === this.$constants.viewers.SCHEME_3D_VIEWER) {
                return {
                    pathsToImages: this.pathsToScheme3dImages,
                    loadedImagesCount: this.scheme3dImagesLoaded,
                    images: this.scheme3dImages,
                    masks: this.scheme3dMasks?.length ? this.scheme3dMasks : Array(this.scheme3dImagesCount).fill(''),
                    imagesCount: this.scheme3dImagesCount,
                    totalImagesCount: this.totalScheme3dImagesCount,
                    currentViewer: this.currentViewer
                };
            }

            if (this.currentViewer === this.$constants.viewers.FLOOR_VIEWER) {
                return {
                    pathsToImages: this.pathsToFloorImages[this.selectedFloor],
                    loadedImagesCount: this.floorImagesLoaded[this.selectedFloor],
                    images: this.floorImages[this.selectedFloor],
                    masks: this.floorMasks[this.selectedFloor],
                    imagesCount: this.totalFloorImagesCount,
                    totalImagesCount: this.totalFloorImagesCount,
                    currentViewer: this.currentViewer
                };
            }

            this.$notify({
                title: this.$i18n.t('success'),
                text: 'Le visualiseur actuel n`est pas défini',
                type: 'error'
            });
            console.error('Current viewer is not defined');

            return null;
        }
    },
    watch: {
        isToggleViewer(newValue) {
            if (!this.hasFloorViewerInitOccurred) {
                this.setInitDataForFloorViewer();
            }

            this.currentViewer = newValue
                ? this.$constants.viewers.FLOOR_VIEWER
                : this.$constants.viewers.SCHEME_3D_VIEWER;
        },
        selectedFloor() {
            if (this.hasFloorViewerInitOccurred) {
                this.setInitDataForFloorViewer();
                setTimeout(() => {
                    this.$eventBus.$emit('reload-filters');
                }, DEFAULT_DELAY);
            }
        },
        currentSchemeView(newValue) {
            // Reload this component
            this.getSchemeData()
            this.refresh()
        }
    },
    created() {
        this.init().then(this.uploadSchemeImages);
    },
    beforeDestroy() {
        this.setIsToggleViewer(false);
        if (!this.isScheme3dDataInitted) {
            this.resetViewerData();
        }
    },
    methods: {
        ...mapMutations({
            updateMinMaxAreaFilter: 'scheme/updateMinMaxAreaFilter',
            updateMinMaxPriceFilter: 'scheme/updateMinMaxPriceFilter',
            setLotsData: 'scheme/setLotsData',
            setScheme3dImages: 'scheme/setScheme3dImages',
            setPathsToScheme3dImages: 'scheme/setPathsToScheme3dImages',
            setPathsToSheme3dMasks: 'scheme/setPathsToSheme3dMasks',
            setScheme3dImagesLoaded: 'scheme/setScheme3dImagesLoaded',
            setReadyToDrag: 'scheme/setReadyToDrag',
            setScheme3dMasks: 'scheme/setScheme3dMasks',
            setFloorImages: 'scheme/setFloorImages',
            setSelectedFloor: 'scheme/setSelectedFloor',
            setFloorMasks: 'scheme/setFloorMasks',
            setPathsToFloorImages: 'scheme/setPathsToFloorImages',
            setPathsToFloorMasks: 'scheme/setPathsToFloorMasks',
            setFloorImagesLoaded: 'scheme/setFloorImagesLoaded',
            setIsToggleViewer: 'scheme/setIsToggleViewer',
            updateLoadedViewerMaskCount: 'scheme/updateLoadedViewerMaskCount',
            updateSelectedLot: 'scheme/updateSelectedLot',
            toggleDefaultLoader: 'loader/toggleDefaultLoader',
            refresh: 'scheme/refresh',
        }),
        ...mapActions({
            getExternalLotsData: 'externalApi/getExternalLotsData',
            getSchemeData: 'scheme/getSchemeData',
            loaderShow: 'loader/show',
            loaderHide: 'loader/hide'
        }),
        async init() {
            this.abortController = new AbortController();
            /**
             * Get the necessary initial data, if they are not there
             */
            (!this.schemeData || !this.initialLotsData?.length) && (await this.getSchemeData());

            await this.getSchemeDataForViewers();

            this.updateMinMaxAreaFilter(this.getMinMaxArrayValueByProp(this.initialLotsData, this.$types.AREA_TYPE));

            /**
             * Getting data from an external source for an employee
             */
            this.apiToken && (await this.getExternalLotsData());

            /**
             * Set new values for lots if the current user is an employee
             */
            this.externalLotsData.length && this.setInitDataForEmployee();

            /**
             * Update the price of lots after receiving data on the employee (mandatory after)
             */
            this.updateMinMaxPriceFilter(
                this.getMinMaxArrayValueByProp(this.mapExternalLotsByPrice(), this.$types.PRICE_TYPE)
            );

            /**
             * Setting initial data for default viewer
             */
            if (this.enableScheme3dDisplay) {
                this.currentViewer = this.$constants.viewers.SCHEME_3D_VIEWER;
                await this.setInitDataForScheme3dViewer();
            } else {
                this.currentViewer = this.$constants.viewers.FLOOR_VIEWER;
                await this.setInitDataForFloorViewer();
            }

            this.loaderHide();
        },
        setInitDataForEmployee() {
            const updatedLots = this.initialLotsData.reduce((acc, lot) => {
                const externalLot = this.externalLotsData?.find((externalLot) => externalLot?.lot === lot?.lot);
                if (externalLot) {
                    const updatedLotData = {
                        ...externalLot,
                        price: externalLot.contractPrice || externalLot.price * DEFAULT_TAX_RATE
                    };

                    acc.push({ ...lot, ...updatedLotData });
                } else {
                    acc.push(lot);
                }

                return acc;
            }, []);

            const internalLots = this.initialLotsData.map((lot) => lot.lot);

            this.externalLotsData.forEach((externalLot) => {
                if (!internalLots.includes(externalLot.lot)) {
                    console.warn(`Lot: ${externalLot.lot} not found in lots-data.json`);
                }
            });

            this.setLotsData(updatedLots);
        },
        async setInitDataForScheme3dViewer() {
            const imageDataPaths = [];
            this.isScheme3dDataInitted =
                this.totalScheme3dImagesCount === this.scheme3dImages.length &&
                this.totalScheme3dImagesCount === this.scheme3dMasks.length;
            if (this.isScheme3dDataInitted) {
                this.isRenderAllow = true;

                return;
            }

            this.setScheme3dImagesLoaded(0);

            for (let imageIndex = 1; imageIndex <= this.totalScheme3dImagesCount; imageIndex++) {
                imageDataPaths.push(`${this.sheme3dImagePath}/${imageIndex}.webp`);
            }

            for (let maskIndex = 1; maskIndex <= this.totalScheme3dMasksCount; maskIndex++) {
                this.scheme3dMaskDataPaths.push(`${this.sheme3dMaskPath}/${maskIndex}.svg`);
            }

            this.setPathsToScheme3dImages(imageDataPaths);
            this.setPathsToSheme3dMasks(this.scheme3dMaskDataPaths.slice(0, DEFAULT_IMAGE_COUNT));

            this.scheme3dImagesCount = DEFAULT_IMAGE_COUNT;
            this.setScheme3dImages(
                await this.preloadImages(
                    this.pathsToScheme3dImages.slice(0, DEFAULT_IMAGE_COUNT),
                    this.preloadImageCallback
                )
            );
            this.setScheme3dMasks(await this.preloadMasks(this.pathsToSheme3dMasks.slice(0, DEFAULT_IMAGE_COUNT)));
            this.isRenderAllow = true;
        },
        async setInitDataForFloorViewer() {
            if (!this.enableScheme3dDisplay) {
                this.setIsToggleViewer(true);
                this.isRenderAllow = true;
            }

            const floor = this.selectedFloor;
            if (this.pathsToFloorMasks[floor]?.length && this.pathsToFloorImages[floor]?.length) {
                return;
            }

            this.toggleDefaultLoader(true);

            const imageDataPaths = { [floor]: [] };
            this.floorMaskDataPaths[floor] = [];

            for (let imageIndex = 1; imageIndex <= this.totalFloorImagesCount; imageIndex++) {
                imageDataPaths[floor].push(`${this.floorImagePath}/${imageIndex}.webp`);
            }

            for (let maskIndex = 1; maskIndex <= this.totalFloorMasksCount; maskIndex++) {
                this.floorMaskDataPaths[floor].push(`${this.floorMaskPath}/${maskIndex}.svg`);
            }

            this.setPathsToFloorImages({
                floor,
                payload: imageDataPaths[floor]
            });
            this.setPathsToFloorMasks({
                floor,
                payload: this.floorMaskDataPaths[floor]
            });

            const uploadedFloorImages = await this.preloadImages(
                this.pathsToFloorImages[floor],
                this.preloadImageCallback
            );
            const floorMasks = await this.preloadMasks(this.pathsToFloorMasks[floor]);

            this.setFloorImages({
                floor,
                payload: uploadedFloorImages
            });
            this.setFloorMasks({
                floor,
                payload: floorMasks
            });

            this.hasFloorViewerInitOccurred = true;
            this.isRenderAllow = true;
        },
        resetViewerData() {
            this.abortController.abort();
            this.setScheme3dImages([]);
            this.setPathsToScheme3dImages([]);
            this.setScheme3dMasks([]);
            this.setPathsToSheme3dMasks([]);
            this.setScheme3dImagesLoaded(0);
        },
        getSchemeDataForViewers() {
            return new Promise((resolve) => {
                // eslint-disable-next-line max-len
                this.schemeAssetsPath = `${this.schemeData.schemeAssetsPath}/${
                    this.schemeData.schemes[this.currentSchemeView]?.id
                }`;
                this.masksPath = this.schemeData.schemes[this.currentSchemeView]?.masksPath;
                this.imagesPath = this.schemeData.schemes[this.currentSchemeView]?.imagesPath;
                this.floorsPath = this.schemeData.schemes[this.currentSchemeView]?.floorsPath;
                resolve();
            });
        },
        preloadImageCallback() {
            if (this.isToggleViewer) {
                this.setFloorImagesLoaded({
                    floor: this.selectedFloor,
                    payload: (this.floorImagesLoaded[this.selectedFloor] ?? 0) + 1
                });

                return;
            }

            this.setScheme3dImagesLoaded(this.scheme3dImagesLoaded + 1);
        },
        /**
         * @param {Object[]} array
         * @param {String} prop
         * @return {{min: number, max: number}}
         */
        getMinMaxArrayValueByProp(array, prop) {
            const value = array.map((arrayItem) => arrayItem[prop]);

            if (!value.length) {
                return {
                    min: 0,
                    max: 0
                };
            }

            return {
                min: Math.floor(Math.min(...value)),
                max: Math.ceil(Math.max(...value))
            };
        },
        /**
         * @return {{price: number}}
         * @description Converts data from an external source to filter by price based on status and tax rates.
         */
        mapExternalLotsByPrice() {
            return (this.isEmployee ? this.externalLotsData : this.initialLotsData).map((lot) => {
                return {
                    ...lot,
                    price: lot.contractPrice ? lot.contractPrice : lot.price * DEFAULT_TAX_RATE
                };
            });
        },
        /**
         * @param {String[]} images
         * @param {Function} fn
         * @return {HTMLImageElement[]}
         * @description Function to convert an array of image paths to images
         */
        async preloadImages(images = [], fn = () => {}) {
            if (!images.length) {
                console.warn('No Images Found');

                return;
            }

            const loadedImages = [];

            try {
                for (const image of images) {
                    loadedImages.push(await this.addImage(image, fn));
                }
            } catch (error) {
                console.error(`Something went wrong while loading images: ${error.message}`);
            }

            return loadedImages;
        },
        /**
         * @param {String} resultSrc
         * @param {Function} fn
         * @return {Promise<HTMLImageElement>}
         * @description Function for dynamic loading of pictures
         */
        addImage(resultSrc, fn) {
            return new Promise((resolve) => {
                const image = new Image();
                image.src = resultSrc;
                const dataId = resultSrc.match(/\d{1,3}/g);
                dataId[dataId.length - 1] && image.setAttribute('data-id', dataId[dataId.length - 1]);
                image.onload = () => {
                    fn();
                    resolve(image);
                };
                image.onerror = (error) => {
                    // console.error(error);
                    // this.$notify({
                    //     title: 'Erreur',
                    //     text: `
                    //         Impossible de charger l'image à partir de src
                    //         ${error?.path[0]?.src.split('/').slice(6).join('/')}
                    //     `,
                    //     type: 'error',
                    // });
                };
            });
        },
        /**
         * @param {string[]} paths
         * @return {string[]}
         * @description Function to convert an array of masks paths to masks blobs
         */
        async preloadMasks(paths = []) {
            if (!paths.length) {
                console.warn('Paths not found');

                return [];
            }

            const loadedMask = [];

            try {
                for (const path of paths) {
                    loadedMask.push(await this.addMaskByPath(path));
                }
            } catch (error) {
                console.error(`Something went wrong while loading mask: ${error.message}`);

                return [];
            }

            return loadedMask;
        },
        /**
         * @param {String} path
         * @return {String}
         * @description Function for dynamic loading of masks
         */
        async addMaskByPath(path) {
            const blob = await (await fetch(path, { signal: this.abortController?.signal })).blob();
            this.updateLoadedViewerMaskCount(1);

            return URL.createObjectURL(blob);
        },
        async uploadSchemeImages() {
            if (!this.enableScheme3dDisplay) {
                return;
            }

            if (this.totalScheme3dImagesCount === this.scheme3dImages.length) {
                this.scheme3dImagesCount = this.scheme3dImages.length;

                return;
            }

            this.toggleDefaultLoader(true);
            const uploadedImages = await this.preloadImages(
                this.pathsToScheme3dImages.slice(DEFAULT_IMAGE_COUNT),
                this.preloadImageCallback
            );

            /**
             * Sorting the resulting array of images for correct display on the canvas
             */
            const sortedImages = this.getSortedImages([...this.scheme3dImages, ...uploadedImages]);
            this.setScheme3dImages(sortedImages);
            this.scheme3dImagesCount = this.scheme3dImages.length;

            /**
             * Loading image masks
             */
            const pathsToScheme3dMasks = [
                ...this.pathsToSheme3dMasks,
                ...this.scheme3dMaskDataPaths.slice(DEFAULT_IMAGE_COUNT)
            ];
            this.setPathsToSheme3dMasks(pathsToScheme3dMasks);
            const uploadedMask = await this.preloadMasks(this.pathsToSheme3dMasks.slice(DEFAULT_IMAGE_COUNT));
            this.setScheme3dMasks([...this.scheme3dMasks, ...uploadedMask]);

            this.hideDefaultLoader();
        },
        hideDefaultLoader() {
            this.setReadyToDrag(true);

            setTimeout(() => {
                this.toggleDefaultLoader(false);
                this.updateLoadedViewerMaskCount();
                this.$route.params?.lotToShow && this.updateSelectedLot(this.$route.params?.lotToShow);
                this.isScheme3dDataInitted &&
                    this.$notify({
                        title: this.$i18n.t('success'),
                        text: 'Modèle 3d chargé',
                        type: 'success'
                    });
                this.$eventBus.$emit('reload-filters', !this.hasFloorViewerInitOccurred);
            }, DEFAULT_DELAY);
        },
        /**
         * @param {string[]} images
         * @return {string[]}
         * @private
         */
        getSortedImages(images = []) {
            return [...images].sort((imageA, imageB) => {
                return parseInt(imageA?.dataset?.id) - parseInt(imageB?.dataset?.id);
            });
        }
    }
};
</script>

<style scoped>
.viewer-loading {
    pointer-events: none;
    opacity: 0.7;
}

.loader-progress {
    position: fixed;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 10px;
}
</style>
