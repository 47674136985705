export const PIECES = [
    { value: 1, label: 'STUDIO' },
    { value: 2, label: '2 PIÈCES' },
    { value: 3, label: '3 PIÈCES' },
    { value: 4, label: '4 PIÈCES' },
    { value: 5, label: '5 PIÈCES' }
];

export const INITIAL_MODEL = {
    name: '',
    surname: '',
    mail: '',
    postalCode: '',
    phone: '',
    message: '',
    investType: 'HABITER',
    captcha: null,
    lotRooms: PIECES[0]
};
