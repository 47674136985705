export default {
    isDarkTheme(state) {
        return state.isDarkTheme;
    },
    themeColors(state, getters) {
        if (getters.isDarkTheme) {
            return state.themeColors.dark;
        }

        return state.themeColors.bright;
    },
};
