import {updateField} from 'vuex-map-fields';
import clone from 'lodash.clonedeep';
import {ADDITIONS_TYPE, AREA_TYPE, PRICE_TYPE} from '@common/constants/lot-types';

export default {
    updateField,

    // Lots
    refresh(state) {
        state.show = false
        setTimeout(() => {
            state.show = true
        }, 50)
    },
    setLotsData(state, lots) {
        state.lotsData = Object.freeze(lots);
    },
    setLotInfo(state, info = null) {
        state.lotInfo = info;
    },
    setFavorites(state, favorites) {
        state.favorites = favorites;
    },
    setActiveImage(state, payload) {
        state.activeImage = payload;
    },
    updateSelectedLot(state, payload) {
        state.selectedLot = clone(payload);
    },
    setProgramId(state, payload) {
        state.programId = payload;
    },
    setLotsInfoApi(state, payload) {
        state.lotsInfoApi = payload;
    },
    setEnableLotsComparison(state, bool) {
        state.enableLotsComparison = bool;
    },
    setEnableLotsVisibility(state, bool) {
        state.enableLotsVisibility = bool;
    },
    setEnableAxonometricLot(state, bool) {
        state.enableAxonometricLot = bool;
    },
    setEnableCreatingLotInfoForm(state, bool) {
        state.enableCreatingLotInfoForm = bool;
    },
    setShowPricePublic(state, bool) {
        state.showPricePublic = bool;
    },
    setShowPriceConnected(state, bool) {
        state.showPriceConnected = bool;
    },
    setShowLotAvailabilityConnected(state, bool) {
        state.showLotAvailabilityConnected = bool;
    },
    setShowLotAvailabilityPublic(state, bool) {
        state.showLotAvailabilityPublic = bool;
    },

    // Scheme
    setSchemeData(state, payload) {
        state.schemeData = Object.freeze(payload);
    },
    setFloorLoaded(state, payload) {
        state.isFloorLoaded = payload;
    },
    setScheme3dImages(state, payload) {
        state.scheme3dImages = payload;
    },
    setScheme3dMasks(state, payload) {
        state.scheme3dMasks = payload;
    },
    setPathsToScheme3dImages(state, payload) {
        state.pathsToScheme3dImages = payload;
    },
    setPathsToSheme3dMasks(state, payload) {
        state.pathsToSheme3dMasks = payload;
    },
    setScheme3dImagesLoaded(state, payload) {
        state.scheme3dImagesLoaded = payload;
    },
    setReadyToDrag(state, payload) {
        state.readyToDrag = payload;
    },
    setIsToggleViewer(state, payload) {
        state.isToggleViewer = payload;
    },
    setSelectedFloor(state, payload) {
        state.selectedFloor = payload;
    },
    setFloorImages(state, {floor, payload}) {
        state.floorImages = {...state.floorImages, [floor]: payload};
    },
    setFloorMasks(state, {floor, payload}) {
        state.floorMasks = {...state.floorMasks, [floor]: payload};
    },
    setPathsToFloorImages(state, {floor, payload}) {
        state.pathsToFloorImages = {...state.pathsToFloorImages, [floor]: payload};
    },
    setPathsToFloorMasks(state, {floor, payload}) {
        state.pathsToFloorMasks = {...state.pathsToFloorMasks, [floor]: payload};
    },
    setFloorImagesLoaded(state, {floor, payload}) {
        state.floorImagesLoaded = {...state.floorImagesLoaded, [floor]: payload};
    },
    setCurrentSchemeView(state, payload) {
        state.currentSchemeView = payload;
    },
    setAssetsPath(state, payload) {
        state.assetsPath = payload;
    },
    setLotsPath(state, payload) {
        state.lotsPath = payload;
    },
    setEnableCompassFloors(state, payload) {
        state.enableCompassFloors = payload;
    },
    updateCurrentSchemeAngle(state, angle) {
        state.currentSchemeAngle = angle;
    },
    setChartRotationAngles(state, payload) {
        state.chartRotationAngles = payload;
    },
    setMaxCanvasScale(state, payload) {
        state.maxCanvasScale = payload;
    },
    updateLoadedViewerMaskCount(state, payload) {
        if (payload) {
            state.loadedViewerMaskCount += payload;
        } else {
            state.loadedViewerMaskCount = 0;
        }
    },

    // Filters
    setFilters(state, payload) {
        state.filters = payload.reduce((acc, filter) => {
            const existedOptions = filter.options.reduce((innerAcc, option) => {
                const pushCondition = state.lotsData.some((lot) => {
                    if ([ADDITIONS_TYPE, AREA_TYPE, PRICE_TYPE].includes(filter.field)) {
                        return lot[option.option];
                    }

                    return lot[filter.field] === option.option;
                });

                if (pushCondition) {
                    innerAcc.push({
                        ...option,
                        range: [0, 0],
                        type: filter.field,
                        checked: false,
                    });
                }

                return innerAcc;
            }, []);

            if (existedOptions.length) {
                acc.push({
                    title: filter.title,
                    type: filter.field,
                    isRange: [AREA_TYPE, PRICE_TYPE].includes(filter.field),
                    hasColorMapping: filter.hasColorMapping,
                    options: existedOptions,
                });
            }

            return acc;
        }, []);
    },
    updateFilters(state, payload) {
        state.filters = state.filters.map((filter) => {
            const updatedFilter = payload.find(({type}) => filter.type === type);

            if (updatedFilter) {
                return updatedFilter;
            }

            return filter;
        });
    },
    updateFilter(state, payload) {
        state.filters = state.filters.map((filter) => {
            if (filter.type !== payload.type) {
                return filter;
            }

            const updatedFilterOptions = filter.options.map((option) => {
                return option.option !== payload.option ? option : payload;
            });

            return {
                ...filter,
                options: updatedFilterOptions,
            };
        });
    },
    resetActiveFilters(state) {
        state.filters = state.filters.map((filter) => {
            return {
                ...filter,
                options: filter.options.map((option) => {
                    if (![AREA_TYPE, PRICE_TYPE].includes(filter.type)) {
                        return {
                            ...option,
                            checked: false,
                        };
                    }

                    return {
                        ...option,
                        range: _getMinMaxValueByType(state, option.option),
                    };
                }),
            };
        });
    },
    setIsFiltersResetted(state, bool) {
        state.isFiltersResetted = bool;
    },
    updateMinMaxAreaFilter(state, {min = 0, max = 0}) {
        state.minMaxArea = {
            min,
            max,
        };
    },
    updateMinMaxPriceFilter(state, {min = 0, max = 0}) {
        state.minMaxPrice = {
            min,
            max,
        };
    },
};

/**
 * @param {object} state
 * @param {string} type
 * @return {[number, number]}
 * @private
 */
function _getMinMaxValueByType(state, type) {
    const formattedType = type[0].toUpperCase() + type.slice(1);

    return [state[`minMax${formattedType}`].min, state[`minMax${formattedType}`].max];
}
