export default {
    formComponent(state) {
        return state.formComponent;
    },
    formAction(state) {
        return state.formAction;
    },
    formAnchor(state) {
        return state.formAnchor;
    },
    accessionButton(state) {
        return state.accessionButton;
    },
    isContactModalActive(state) {
        return state.isContactModalActive;
    },
    additionalData(state) {
        return state.additionalData;
    },
    errorModalProps(state) {
        return state.errorModalProps;
    },
    successModalProps(state) {
        return state.successModalProps;
    },
};
