import clone from 'lodash.clonedeep';
import groupBy from 'lodash.groupby';
import uniqBy from 'lodash.uniqby';
import store from '@front/store';
import {getRoutes} from '@front/router/routes';
import {getProjectExtendData, setContactFormProps, setExtraOptions} from '@front/helpers';

class RouterController {
    constructor(store) {
        this.store = store;
    }

    get projectExtendData() {
        return getProjectExtendData(this.store);
    }

    get routes() {
        const {extendedMenuRoutes, extendedAdditionalRoutes} = groupBy(
            this.projectExtendData?.routes,
            'meta.routeGroup',
        );

        const {menuRoutes, additionalRoutes} = getRoutes(this.store, this.projectExtendData);

        return {
            menuRoutes: extendedMenuRoutes
                ? this._sortRoutesByOrderPosition(uniqBy([...extendedMenuRoutes, ...menuRoutes], 'path'))
                : menuRoutes,
            additionalRoutes: extendedAdditionalRoutes
                ? union(additionalRoutes, extendedAdditionalRoutes)
                : additionalRoutes,
        };
    }

    getMenuRoutes() {
        const clonedRoutes = clone(this.routes.menuRoutes);

        try {
            if (this.projectExtendData?.routes) {
                for (const extendRoute of this.projectExtendData.routes) {
                    if (extendRoute.meta?.childOf) {
                        const parentRouteIndex = clonedRoutes.findIndex((route) => {
                            return route.name === extendRoute.meta?.childOf;
                        });
                        if (!clonedRoutes[parentRouteIndex].children) {
                            clonedRoutes[parentRouteIndex].children = [];
                        }
                        clonedRoutes[parentRouteIndex].children.push(extendRoute);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }

        return clonedRoutes;
    }

    getFullRoutes() {
        const menuRoutes = this.getMenuRoutes();
        setContactFormProps(this.projectExtendData, this.store);
        setExtraOptions(this.projectExtendData, this.store);

        const filtredAdditionalRoutes = this._getFiltredRoutes(this.routes.additionalRoutes, menuRoutes);

        return [
            {
                path: '/',
                component: () => import('@front/layouts/MainLayout'),
                children: [...menuRoutes, ...filtredAdditionalRoutes],
            },
            {
                path: '*',
                redirect: '/',
            },
        ];
    }

    /**
     * @param {Array<object>} filtrableRoutes
     * @param {Array<object>} filterRoutes
     * @return {Array<object>}
     * @private
     */
    _getFiltredRoutes(filtrableRoutes = [], filterRoutes) {
        return filtrableRoutes.filter((route) => {
            return this._filterRoutesByPath(filterRoutes, route?.path);
        });
    }

    /**
     * @param {Array<object>} routes
     * @param {string} path
     * @return {boolean}
     * @private
     */
    _filterRoutesByPath(routes = [], path) {
        return routes.every((route) => route.path !== path);
    }

    /**
     * @param {Array<object>} routes
     * @return {Array<object>}
     * @private
     */
    _sortRoutesByOrderPosition(routes) {
        const result = [];

        for (const route of routes) {
            if (typeof route?.meta?.orderPosition !== 'undefined') {
                result.splice(route.meta.orderPosition, 0, route);
            } else {
                result.push(route);
            }
        }

        return result;
    }
}

export default new RouterController(store);
