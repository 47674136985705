<template>
    <section class="cl-introduction">
        <header class="cl-introduction-header">
            <div class="cl-introduction-header-row">
                <h1
                    class="cl-introduction__title"
                    :style="{color: elements.title.color, backgroundColor: elements.title.backgroundColor}"
                    v-html="elements.title.text"
                />
                <p
                    class="cl-introduction__subtitle"
                    :style="{color: elements.subtitle.color, backgroundColor: elements.subtitle.backgroundColor}"
                    v-html="elements.subtitle.text"
                />
            </div>
            <img
                v-if="elements.title.image"
                :src="elementImagePath({id: elements.title.id, image: elements.title.image})"
                :alt="altImgText"
                class="cl-introduction__image"
            />
        </header>
        <div class="cl-introduction-main">
            <img
                :src="`${imagePath}/${blockData.backgroundImage}`"
                :alt="altImgText"
                class="cl-introduction-main__image"
            />
            <div class="cl-introduction-main-row">
                <div
                    class="cl-introduction__description"
                    :style="{color: elements.description.color, backgroundColor: elements.description.backgroundColor}"
                    v-html="elements.description.text"
                />
                <CLButton
                    :label="elements.button.text"
                    :text-color="elements.button.color"
                    :background-color="elements.button.backgroundColor"
                    @click="onFormButtonHandler"
                />
            </div>
        </div>
    </section>
</template>

<script>
import customLandingMixin from '@front/mixins/customLandingMixin';

export default {
    name: 'IntroductionBlock',
    mixins: [customLandingMixin],
};
</script>

<style lang="scss" scoped>
.cl-introduction {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;

    ::v-deep {
        strong,
        b {
            font-weight: bold !important;
        }

        em,
        i {
            font-style: italic !important;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        padding: 76px 0 31px 0;

        @media (max-width: 1700px) {
            padding: 76px 50px 31px 50px;
        }

        @media (max-width: 1200px) {
            flex-direction: column;
            align-items: center;
            padding: 50px 30px;
        }

        &-row {
            display: flex;
            align-items: center;

            @media (max-width: 768px) {
                flex-direction: column;
            }
        }
    }

    &__title {
        padding-right: 45px;

        * {
            font-size: 66px;
            letter-spacing: 3.3px;
            line-height: 77px;
        }

        @media (max-width: 768px) {
            padding-bottom: 25px;
            padding-right: 0;
        }

        @media (max-width: 576px) {
            * {
                font-size: 36px !important;
                letter-spacing: 1.8px;
                line-height: 42px;
                text-align: center;
            }
        }
    }

    &__subtitle {
        display: inline-block;
        padding: 6px 17px 7px 20px;
        font-size: 16px;
        letter-spacing: 1.6px;
        line-height: 21px;
    }

    &__image {
        max-width: 435px;
        max-height: 120px;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: 1200px) {
            padding-top: 25px;
        }
    }

    &__description {
        font-size: 30px;
        letter-spacing: 1.8px;
        line-height: 36px;
        padding-right: 26px;
        text-align: right;

        @media (max-width: 768px) {
            text-align: center;
            font-size: 24px;
            letter-spacing: 1.44px;
            line-height: 30px;
            padding-right: 0;
            padding-bottom: 20px;

            ::v-deep br {
                display: none;
            }
        }
    }

    &-main {
        position: relative;

        &-row {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 50px;
            display: flex;
            align-items: center;
            max-width: 610px;
            width: 100%;

            @media (max-width: 768px) {
                position: static;
                transform: unset;
                background-color: var(--accent-color);
                max-width: unset;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                padding: 40px 30px 0 30px;
            }
        }

        &__image {
            display: block;
            height: 779px;
            width: 100%;
            object-fit: cover;

            @media (max-width: 992px) {
                height: 650px;
            }

            @media (max-width: 768px) {
                height: 550px;
            }

            @media (max-width: 576px) {
                height: 450px;
            }
        }
    }
}
</style>
