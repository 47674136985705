import { getField } from 'vuex-map-fields';
import { ADDITIONS_TYPE, AREA_TYPE, PRICE_TYPE } from '@common/constants/lot-types';

export default {
    getField,
    show(state) {
        return state.show;
    },
    // Lots
    lotsData(state, getters) {
        let result = state.lotsData;

        if (getters.checkedFilters.length) {
            result = _filterLotsByTypeFilters(getters.checkedFilters, result);
        }

        return result;
    },
    initialLotsData(state) {
        return state.lotsData ?? [];
    },
    lotInfo(state) {
        return state.lotInfo;
    },
    favorites(state) {
        return state.favorites;
    },
    selectedLot(state) {
        return state.selectedLot;
    },
    programId(state) {
        return state.programId;
    },
    lotsInfoApi(state) {
        return state.lotsInfoApi;
    },
    enableLotsComparison(state, _, __, rootGetters) {
        return state.enableLotsComparison && rootGetters['config/isSchemePageVisible'];
    },
    enableLotsVisibility(state) {
        return state.enableLotsVisibility;
    },
    enableAxonometricLot(state) {
        return state.enableAxonometricLot;
    },
    enableCreatingLotInfoForm(state) {
        return state.enableCreatingLotInfoForm;
    },
    showPricePublic(state) {
        return state.showPricePublic;
    },
    showPriceConnected(state) {
        return state.showPriceConnected;
    },
    isPriceFilterAvailable(_, getters, __, rootGetters) {
        const { showPricePublic, showPriceConnected } = getters;
        const isEmployee = rootGetters['externalApi/isEmployee'];

        return _getAvailability({
            publicUser: showPricePublic,
            crmUser: showPriceConnected,
            employee: isEmployee
        });
    },
    showLotAvailabilityConnected(state) {
        return state.showLotAvailabilityConnected;
    },
    showLotAvailabilityPublic(state) {
        return state.showLotAvailabilityPublic;
    },
    isLotAvailabilityVisible(_, getters, __, rootGetters) {
        const { showLotAvailabilityConnected, showLotAvailabilityPublic } = getters;
        const isEmployee = rootGetters['externalApi/isEmployee'];

        return _getAvailability({
            publicUser: showLotAvailabilityPublic,
            crmUser: showLotAvailabilityConnected,
            employee: isEmployee
        });
    },

    // Scheme
    schemeData(state) {
        return state.schemeData;
    },
    scheme3dImagesLoaded(state) {
        return state.scheme3dImagesLoaded;
    },
    scheme3dImages(state) {
        return state.scheme3dImages;
    },
    scheme3dMasks(state) {
        return state.scheme3dMasks;
    },
    pathsToScheme3dImages(state) {
        return state.pathsToScheme3dImages;
    },
    pathsToSheme3dMasks(state) {
        return state.pathsToSheme3dMasks;
    },
    readyToDrag(state) {
        return state.readyToDrag;
    },
    isToggleViewer(state) {
        return state.isToggleViewer;
    },
    selectedFloor(state) {
        return state.selectedFloor;
    },
    floorImages(state) {
        return state.floorImages;
    },
    floorMasks(state) {
        return state.floorMasks;
    },
    pathsToFloorImages(state) {
        return state.pathsToFloorImages;
    },
    pathsToFloorMasks(state) {
        return state.pathsToFloorMasks;
    },
    floorImagesLoaded(state) {
        return state.floorImagesLoaded;
    },
    currentSchemeView(state) {
        return state.currentSchemeView;
    },
    isFloorPlanAvaliable(_, getters) {
        return !!getters.schemeData?.schemes?.[getters.currentSchemeView]?.floors?.length;
    },
    enableFloorPlanDisplay(_, getters) {
        return getters.isFloorPlanAvaliable && getters.enableScheme3dDisplay;
    },
    enableScheme3dDisplay(_, getters) {
        return !!getters.schemeData?.schemes?.[getters.currentSchemeView]?.imagesCount;
    },
    enableCompassFloors(state) {
        return state.enableCompassFloors;
    },
    compassDegree(_, getters) {
        if (getters.isToggleViewer) {
            return (
                getters.schemeData?.schemes?.[getters.currentSchemeView]?.floors.find(
                    (floor) => floor.title === getters.selectedFloor
                ).compassDegree ?? 0
            );
        }

        return getters.schemeData?.schemes?.[getters.currentSchemeView]?.compassDegree ?? 0;
    },
    lotMediaPath(state) {
        return `${state.assetsPath}/${state.lotsPath}`;
    },
    enableSchemeCompas(_, getters) {
        return !!getters.schemeData?.schemes?.[getters.currentSchemeView]?.enableCompass;
    },
    enableFloorsCompas(_, getters) {
        return !!getters.schemeData?.schemes?.[getters.currentSchemeView]?.floors.find(
            (floor) => floor.title === getters.selectedFloor
        ).enableCompass;
    },
    isFloorLoaded(state, getters) {
        return !getters.isToggleViewer || state.isFloorLoaded;
    },
    availableFloors(_, getters) {
        return getters.schemeData.schemes[getters.currentSchemeView].floors.map((floor) => floor.title);
    },
    currentSchemeAngle(state) {
        return state.currentSchemeAngle;
    },
    chartRotationAngles(state) {
        return state.chartRotationAngles;
    },
    maxCanvasScale(state) {
        return state.maxCanvasScale;
    },
    loadedViewerMaskCount(state) {
        return state.loadedViewerMaskCount;
    },
    enableSchemeCompassAngleLimit(_, getters) {
        return !!getters.schemeData?.schemes?.[getters.currentSchemeView]?.enableCompassAngleLimit;
    },
    beginningSchemeImage(_, getters) {
        return getters.schemeData.schemes[getters.currentSchemeView].beginningImage;
    },
    activeImage(state) {
        return state.activeImage;
    },
    endingSchemeImage(_, getters) {
        return getters.schemeData.schemes[getters.currentSchemeView].endingImage;
    },

    // Filters
    filters(state) {
        return state.filters;
    },
    standardFilters(state) {
        return state.filters.filter((filter) => !filter.isRange) ?? [];
    },
    rangeFilters(state) {
        return state.filters.filter((filter) => filter.isRange) ?? [];
    },
    filterByType: (state) => (type) => {
        return state.filters.find((filter) => filter.type === type) ?? [];
    },
    checkedFilters(state, getters) {
        return state.filters.reduce((acc, filter) => {
            const options = filter.options.filter((option) => {
                if (![AREA_TYPE, PRICE_TYPE].includes(option.type)) {
                    return option.checked;
                }

                if (option.option === AREA_TYPE) {
                    return _getRangeComparison(getters.minMaxArea, option.range);
                }

                if (option.option === PRICE_TYPE) {
                    return _getRangeComparison(getters.minMaxPrice, option.range);
                }

                return option.range.reduce((acc, rangeValue) => (acc += rangeValue), 0);
            });

            if (options.length) {
                acc.push({
                    options,
                    type: filter.type
                });
            }

            return acc;
        }, []);
    },
    isFiltersResetted(state) {
        return state.isFiltersResetted;
    },
    minMaxArea(state) {
        return state.minMaxArea;
    },
    minMaxPrice(state) {
        return state.minMaxPrice;
    }
};

/**
 * @param {object[]} filtersArray
 * @param {object[]} targetArray
 * @return {object[] | []}
 * @private
 */
function _filterLotsByTypeFilters(filtersArray, targetArray) {
    const filtredArray = filtersArray.reduce((acc, { type, options }) => {
        if (type === filtersArray[0].type) {
            acc.push(..._filterLotsByFilterOptions(targetArray, options));

            return acc;
        }

        return _filterLotsByFilterOptions(acc, options);
    }, []);

    return targetArray.map((lot) => {
        const filtred = filtredArray.find(({ color }) => color === lot.color);

        return filtred || lot;
    });
}

/**
 * @param {object[]} targetArray
 * @param {object[]} filterOptions
 * @return {object[] | []}
 * @private
 */
function _filterLotsByFilterOptions(targetArray, filterOptions) {
    return targetArray.reduce((acc, lot) => {
        const filtredLots = filterOptions.reduce((innerAcc, { option, type, color, range }) => {
            let pushCondition = option === lot[type];

            if (type === ADDITIONS_TYPE) {
                pushCondition = lot[option];
            }

            if ([AREA_TYPE, PRICE_TYPE].includes(type)) {
                pushCondition = lot[option] >= range[0] && lot[option] <= range[1];
            }

            if (pushCondition) {
                innerAcc.push({
                    ...lot,
                    filterColor: `${lot.filterColor ? `${lot.filterColor}|` : ''}${color}`
                });
            }

            return innerAcc;
        }, []);

        if (filtredLots.length) {
            acc.push(...filtredLots);
        }

        return acc;
    }, []);
}

/**
 * @param {{min: number, max: number}} minMaxValue
 * @param {[number, number]} range
 * @return {boolean}
 * @private
 */
function _getRangeComparison({ min, max }, range) {
    if (range[0] > min && range[1] < max) {
        return true;
    }

    if (range[0] === min && range[1] < max) {
        return true;
    }

    if (range[0] > min && range[1] === max) {
        return true;
    }

    return false;
}

/**
 * @param {object} props
 * @param {boolean} props.publicUser
 * @param {boolean} props.crmUser
 * @param {boolean} props.employee
 * @return {boolean}
 */
function _getAvailability({ publicUser, crmUser, employee }) {
    // p - for unregistered users,
    // c - for users with crm access,
    // e - salesman

    // p - c + e = -
    if (publicUser && !crmUser && employee) {
        return false;
    }

    // p - c - e = +
    if (publicUser && !crmUser && !employee) {
        return true;
    }

    // p + c + e = +
    if (publicUser && crmUser && employee) {
        return true;
    }

    // p + c - e = +
    if (publicUser && crmUser && !employee) {
        return true;
    }

    // -p + c + e = +
    if (!publicUser && crmUser && employee) {
        return true;
    }

    // -p - c + e = -
    if (!publicUser && !crmUser && employee) {
        return false;
    }

    // -p + c - e = -
    if (!publicUser && crmUser && !employee) {
        return false;
    }
}
