<template>
    <div
        :class="['typology-item', {active: options.checked, 'typology-item--default-color': !hasColorMapping}]"
        :style="{
            '--item-color': options.color,
        }"
    >
        <label class="typology-item__checkbox">
            <input
                type="checkbox"
                :value="options.option"
                @change="$emit('toggle', {...options, checked: !options.checked})"
            />
            <span class="typology-item__checkbox__label">
                {{ options.title }}
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'LotsFilterOption',
    props: {
        options: {
            type: Object,
            required: true,
        },
        hasColorMapping: {
            type: Boolean,
            default: () => false,
        },
    },
    emits: ['toggle'],
};
</script>
