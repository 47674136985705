<template>
    <div class="scheme3d-viewer">
        <div
            ref="viewerContainer"
            class="v360-viewer-container"
            style="overflow: hidden"
        >
            <slot name="header" />
            <div
                ref="viewport"
                style="position: relative; width: 100vw; height: 100vh"
            >
                <h4
                    v-if="!readyToDrag"
                    style="position: absolute; left: 50%"
                >
                    Chargement, veuillez patienter.
                </h4>
                <object
                    v-for="(mask, index) in masks"
                    :id="'svg-' + index"
                    :key="index"
                    :data="mask"
                    type="image/svg+xml"
                    class="v360-image-container"
                    style="position: absolute; z-index: 2"
                    :style="{
                        height: activeImage - 1 === index && readyToShowSVG ? imageSize.height + 'px' : '1px',
                        width: imageSize.width + 'px',
                        opacity: isDefaultLoaderActive ? 0 : 1,
                    }"
                    @load="onSVGLoad(index)"
                />
                <canvas
                    ref="imageContainer"
                    class="v360-image-container"
                />
            </div>
            <template v-if="enableLotsVisibility">
                <Hotspot360
                    :hotspot="currHoverHotspot"
                    :ratio="maxRatioCanvasToImage"
                    :image-size="imageSize"
                    :canvas-image-width-offset="canvasImageOffset.Horizontal"
                    :canvas-image-height-offset="canvasImageOffset.Vertical"
                    :movement-offset-x="movementOffset.Horizontal"
                    :movement-offset-y="movementOffset.Vertical"
                    :zoom-horizontal-offset="zoomOffset.Horizontal"
                    :zoom-vertical-offset="zoomOffset.Vertical"
                    :transform-f="contextTransformF"
                    :transform-e="contextTransformE"
                    :transform-a="contextTransformA"
                    :hotspot-coords="hotspotCoords"
                    :offsets="{X: canvasImageOffset.Horizontal, Y: canvasImageOffset.Vertical}"
                    @userleft="onHotSpotLeftHandler"
                />
            </template>
            <FullscreenToggle />
        </div>
    </div>
</template>

<script>
import viewersMixin from '@front/mixins/viewersMixin';

export default {
    name: 'Scheme3dViewer',
    mixins: [viewersMixin],
};
</script>
