<template>
    <Slider :slides="mainPageSliderData" />
</template>

<script>
import {mapGetters} from 'vuex';
import Slider from '@front/components/Slider/Slider.vue';

export default {
    name: 'SliderView',
    components: {
        Slider,
    },
    computed: {
        ...mapGetters({
            mainPageSliderData: 'config/mainPageSliderData',
        }),
    },
};
</script>
