import modulesInfo from '@admin/locales/fr/modulesInfo';
import modal from '@admin/locales/fr/modal';
import mainModule from '@admin/locales/fr/mainModule';
import emailTemplatesSlot from '@admin/locales/fr/emailTemplatesSlot';
import toursModule from '@admin/locales/fr/toursModule';
import schemeModule from '@admin/locales/fr/schemeModule';
import localisationModule from '@admin/locales/fr/localisationModule';
import galleryModule from '@admin/locales/fr/galleryModule';

export default {
    actions: 'Actions',
    activated: 'Activer le',
    addPoint: 'Ajouter un point',
    back: 'Retour',
    background: 'Arrière-plan',
    backgroundColor: 'Couleur de l`arrière plan',
    backgroundImage: 'Image de fond',
    block: 'Bloquer | Blocs',
    blockType: 'Type de bloc',
    browse: 'Parcourir',
    button: 'Bouton',
    change: 'Changement',
    chooseFile: 'Choisir le fichier',
    close: 'Fermer',
    code: 'Code',
    color: 'Couleur',
    compassDegree: 'Degré de boussole',
    create: 'Créer',
    deactivated: 'Désactivé',
    delete: 'Supprimer',
    deleteUploaded: 'Supprimer téléchargé',
    disable: 'Désactivé',
    description: 'Description',
    edit: 'Modifier',
    element: 'Élément | Éléments',
    addElement: 'Ajouter un élément',
    enable: 'Activer',
    enabled: 'Activer',
    enableCompass: 'Activer la boussole',
    error: 'Erreur',
    errorText: {
        noImage: 'Aucune image sélectionnée',
        resolutionTooHigh: 'La résolution de la photo est trop élevée',
        saveWithoutImage: 'Impossible d`enregistrer une diapositive sans image',
    },
    field: 'Champ',
    floor: 'Sol | Sols',
    has: 'A',
    image: 'Image | Images',
    imagesCount: 'Les images comptent',
    label: 'Étiqueter',
    lot: 'Lot | Lots',
    logout: 'déconnexion',
    mask: 'Masque | Masques',
    options: 'Choix',
    point: 'Pointe | Points',
    room: 'Chambre | Pièces',
    save: 'Enregistrer',
    saveConfig: 'Enregistrer la config',
    slide: 'Glisser',
    subtitle: 'Sous-titre',
    success: 'Succès',
    successText: {
        configUpdated: 'Configuration mise à jour avec succès',
        documentUpdated: 'Le document a été mis à jour avec succès',
        videoUpdated: 'La vidéo a été téléchargée avec succès',
        videoDeleted: 'La vidéo a été supprimée avec succès',
    },
    text: 'Texte',
    textColor: 'Couleur du texte',
    title: 'Titre',
    tour: 'Visite | Visites',
    type: 'Taper',
    uploadImage: 'Télécharger une image',
    uploadFloor: 'Télécharger l`étage',
    value: 'Valeur',
    visibility: 'Visibilité',
    modules: 'Modules',
    config: 'Configuration',
    privateConfig: 'Configuration privée',
    footnote: 'Note de bas de page',
    modulesInfo,
    modal,
    mainModule,
    emailTemplatesSlot,
    toursModule,
    schemeModule,
    localisationModule,
    galleryModule,
};
