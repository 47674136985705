<template>
    <div class="inner-menu-t2 desktop-menu-template">
        <div class="menu-link-t2__item">
            <div class="menu-link-t2__label" :class="{ 'dropdown-icon': schemeData?.schemes?.length > 1 }">
                <div class="menu-link-t2__label__title">
                    <span>{{ currentScheme.title }}</span>
                </div>
            </div>
            <div class="sub-menu" v-if="schemeData?.schemes?.length > 1">
                <template v-for="({ title, floors }, index) in schemeData?.schemes">
                    <div class="sub-menu-item" :key="title + index">
                        <div
                            class="sub-menu-item__title"
                            :class="{ active: index === currentSchemeView }"
                            @click="onSetCurrentSchemeView(index)"
                        >
                            {{ title }}
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import homeMenuMixin from '@front/mixins/homeMenuMixin';

export default {
    name: 'Scheme3dMenu',
    mixins: [homeMenuMixin],
    data() {
        return {
            currentItem: {}
        };
    },
    mounted() {},
    computed: {
        ...mapGetters({
            activeComponent: 'gallery/activeComponent',
            currentSchemeView: 'scheme/currentSchemeView',
            schemeData: 'scheme/schemeData'
        }),
        currentScheme() {
            let result = { title: 'MAQUETTE 3D' };
            if (this.schemeData?.schemes && typeof this.schemeData.schemes[this.currentSchemeView] !== 'undefined') {
                result = this.schemeData.schemes[this.currentSchemeView];
            }

            return result;
        }
    },
    methods: {
        ...mapMutations({
            setCurrentSchemeView: 'scheme/setCurrentSchemeView',
            setIsToggleViewer: 'scheme/setIsToggleViewer'
        }),
        onSetCurrentSchemeView(data) {
            // Reset to 3D viewer
            this.setIsToggleViewer(false)
            this.setCurrentSchemeView(data);
        }
    }
};
</script>

<style scoped>
.inner-menu-t2 {
    position: relative;
    display: flex;
    gap: 15px;
}

.menu-link-t2__item {
    position: relative;
}

.sub-menu {
    top: 100%;
    left: 0;
    display: none;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.5) !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #fff;
    border-radius: 0 0 4px 4px;
    padding-bottom: 10px;
}

.menu-link-t2__item:hover .sub-menu {
    display: block;
}

.sub-menu-item {
    padding: 8px 20px;
    font-size: 12px;
    letter-spacing: 0.1em;
}

.sub-menu-item__title {
    cursor: pointer;
}

.sub-menu-item__title.active {
    font-weight: bold;
}

.menu-link-t2__label {
    color: #ffffff;
    font-family: 'Open Sans';
    font-size: 18px;
    border-radius: 4px;
    letter-spacing: 0.1em;
    text-decoration: none;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    line-height: 1;
    box-sizing: border-box;
    height: 100%;
    padding: 13px 24px;
}

.menu-link-t2__label.dropdown-icon {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20"><path fill="%23FFFFFF" d="M10.103 12.778L16.81 6.08a.69.69 0 0 1 .99.012a.726.726 0 0 1-.012 1.012l-7.203 7.193a.69.69 0 0 1-.985-.006L2.205 6.72a.727.727 0 0 1 0-1.01a.69.69 0 0 1 .99 0z"/></svg>')
        no-repeat right 15px center;
    background-color: rgba(0, 0, 0, 0.5);
    padding-right: 40px;
}

.menu-link-t2__label i {
    color: #ccc;
    font-weight: 100;
    margin-right: 10px;
}

.menu-link-t2__item:hover .menu-link-t2__label {
    border-radius: 4px 4px 0 0;
}
</style>
