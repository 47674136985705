<template>
    <div class="cl-title">
        <h2
            class="cl-title__title"
            :style="{color: blockData.color}"
        >
            <template v-for="element in blockData.elements">
                <p
                    :key="element.id"
                    v-html="element.text"
                />
            </template>
        </h2>
    </div>
</template>

<script>
import customLandingMixin from '@front/mixins/customLandingMixin';

export default {
    name: 'TitleBlock',
    mixins: [customLandingMixin],
};
</script>

<style lang="scss" scoped>
.cl-title {
    max-width: 1600px;
    width: 100%;
    margin: auto;
    padding: 102px 50px 0 50px;

    ::v-deep {
        b,
        strong {
            font-weight: bold !important;
        }

        i,
        em {
            font-style: italic !important;
        }
    }

    @media (max-width: 992px) {
        padding: 100px 0 0;
    }

    @media (max-width: 768px) {
        padding: 50px 0 0;
    }

    &__title {
        text-align: center;
        font-size: 42px;
        letter-spacing: 2.52px;
        line-height: 48px;
        padding-bottom: 65px;
        max-width: 760px;
        margin: auto;

        @media (max-width: 992px) {
            padding: 0 30px 30px 30px;
        }

        @media (max-width: 768px) {
            font-size: 30px !important;
            letter-spacing: 1.8px;
            line-height: 36px;
            text-decoration: unset;
            padding-bottom: 30px;

            ::v-deep {
                br {
                    display: none;
                }
            }
        }
    }
}
</style>
