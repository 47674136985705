<template>
    <div class="table-row">
        <button
            :class="['collapsible', {active: isActive}]"
            type="button"
            @click="$emit('toggle-activity', $event)"
        >
            <p>{{ getFormattedRoomName(rowName) }}</p>
            <p class="text d-tab">à partir de {{ breakNumberToCategories(minPrice) }} €</p>
        </button>
        <div
            :ref="`content_${rowName}`"
            class="content"
            :style="`max-height: ${isActive ? getContentHeight(`content_${rowName}`) : 0}px`"
        >
            <div
                v-for="(lot, index) in rowValue"
                :key="lot.color"
                :class="['appartment-row', {'bg-white': isEven(index + 1)}]"
            >
                <div class="child one tab">
                    {{ lot.lot }}
                </div>
                <div class="child tab">
                    <p>
                        {{ lot.area }}m
                        <sup>2</sup>
                    </p>
                </div>
                <div class="child three tab">
                    <p>{{ lot.floor }}</p>
                </div>
                <div class="child tab">
                    {{ breakNumberToCategories(lot.price) }}
                    &nbsp;{{ lot.priceData.length ? lot.priceData[0].unit : '€' }}
                </div>
                <button
                    class="child tab"
                    type="button"
                    @click="$emit('open-plan', lot)"
                >
                    Plan
                </button>
                <button
                    class="child"
                    type="button"
                    @click="$emit('show-on-viewer', lot)"
                >
                    <img
                        :src="$getImagePath('svg/3d-plan.svg')"
                        alt=""
                    />
                </button>
                <div class="child tab-none">
                    <p>{{ lot.lot }}</p>
                    <p>
                        {{ lot.area }}m
                        <sup>2</sup>
                    </p>
                </div>
                <div class="child tab-none">{{ breakNumberToCategories(lot.price) }} €</div>
                <div class="child">
                    <button
                        type="button"
                        class="appartment-btn"
                        @click="$emit('select-lot', lot)"
                    >
                        JE SUIS INTÉRESSÉ
                        <span>PAR CE LOT</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {breakNumberToCategories} from '@front/helpers/breakNumberToCategories';

export default {
    name: 'TableRow',
    props: {
        rowName: {
            type: String,
            required: true,
        },
        rowValue: {
            type: Array,
            required: true,
        },
        minPrice: {
            type: Number,
            required: true,
        },
        isActive: {
            type: Boolean,
            default: () => false,
        },
    },
    emits: ['select-lot', 'toggle-activity', 'open-plan', 'show-on-viewer'],
    methods: {
        breakNumberToCategories,
        isEven(number) {
            return number % 2 === 0;
        },
        getContentHeight(refName) {
            try {
                return [...this.$refs[refName].children].reduce((acc, element) => (acc += element.offsetHeight), 0);
            } catch (error) {
                return 0;
            }
        },
        getFormattedRoomName(roomName) {
            return roomName.match(/^\d/) ? `${roomName.replace(/\D/gi, ' PIÈCES')}` : roomName;
        },
    },
};
</script>
