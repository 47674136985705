export const setContactFormProps = (projectExtendData, store) => {
    if (projectExtendData && projectExtendData.contactForm) {
        const {component, action, anchor, errorModalProps, successModalProps} = projectExtendData.contactForm;

        action && store.commit('projectExtend/setFormAction', action);
        component && store.commit('projectExtend/setFormComponent', component());
        anchor && store.commit('projectExtend/setFormAnchor', anchor);
        errorModalProps && store.commit('projectExtend/setErrorModalProps', errorModalProps);
        successModalProps && store.commit('projectExtend/setSuccessModalProps', successModalProps);
    }
};
