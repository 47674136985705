import { mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import RoomsFilterScheme3d from '../components/Scheme3dLotInfo/RoomsFilterScheme3d.vue';

export default {
    components: {
        RoomsFilterScheme3d
    },
    computed: {
        ...mapFields('navigation', { isMenuMustBeHidden: 'isMenuMustBeHidden' }),
        ...mapGetters({
            isDarkTheme: 'theme/isDarkTheme',
            altImgText: 'config/altImgText',
            enableLotsVisibility: 'scheme/enableLotsVisibility',
            enableLotsComparison: 'scheme/enableLotsComparison',
            currentSchemeView: 'scheme/currentSchemeView',
            schemeData: 'scheme/schemeData'
        })
    },
    data: () => ({
        isLotListVisible: false
    }),
    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if (newValue.query?.results === 'show') {
                    this.toggleLotListVisibility();
                }
            }
        }
    },
    methods: {
        ...mapMutations({
            toggleActivityAdditionalWindow: 'navigation/toggleActivityAdditionalWindow',
            changeIsMenuMustBeStatic: 'navigation/changeIsMenuMustBeStatic',
            setIsCloseBurgerButtonHide: 'navigation/setIsCloseBurgerButtonHide',
            setCurrentSchemeView: 'scheme/setCurrentSchemeView'
        }),
        toggleLotListVisibility() {
            this.setIsCloseBurgerButtonHide(true);
            this.changeIsMenuMustBeStatic(true);
            this.toggleActivityAdditionalWindow();
            this.toggleActivityAdditionalWindow(this.$constants.additionalWindows.LOTS_LIST);
        }
    }
};
