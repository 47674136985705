<template>
    <div class="icon-bar-t2 pointer fadein">
        <template v-for="(barRoute, index) in barRoutes">
            <div
                v-if="showMenuItem(barRoute, barRoute.meta.isActive())"
                :key="index"
                class="icon-bar-t2-container"
                :class="{ active: isBarRoutesActive(barRoute) }"
            >
                <router-link
                    :to="{ path: barRoute.path || '/' }"
                    :class="['icon-bar-t2__link', barRoute.name ? barRoute.name.toLowerCase() : '']"
                >
                    <span
                        :class="{
                            'white-icon': isDarkTheme,
                            'active-location': barRoute.name === 'Location' && $route.name === 'Location',
                            active: isBarRoutesActive(barRoute)
                        }"
                        v-html="icons[barRoute.meta.icon]"
                    />
                </router-link>
                <div class="icon-bar-t2-container__name" :class="{ active: isBarRoutesActive(barRoute) }">
                    {{ barRoute.meta.title }}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import routerController from '@front/controllers/RouterController';
import menuItemMixin from '@front/mixins/menuItemMixin';

const ICONS = {
    home: `
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M19.5 10a.5.5 0 0 0-1 0zm-14 0a.5.5 0 0 0-1 0zm15.146 2.354a.5.5 0 0 0 .708-.708zM12 3l.354-.354a.5.5 0 0 0-.708 0zm-9.354 8.646a.5.5 0 0 0 .708.708zM7 21.5h10v-1H7zM19.5 19v-9h-1v9zm-14 0v-9h-1v9zm15.854-7.354l-9-9l-.708.708l9 9zm-9.708-9l-9 9l.708.708l9-9zM17 21.5a2.5 2.5 0 0 0 2.5-2.5h-1a1.5 1.5 0 0 1-1.5 1.5zm-10-1A1.5 1.5 0 0 1 5.5 19h-1A2.5 2.5 0 0 0 7 21.5z"/></svg>
    `,
    localisation: `
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="currentColor" d="M128 66a38 38 0 1 0 38 38a38 38 0 0 0-38-38m0 64a26 26 0 1 1 26-26a26 26 0 0 1-26 26m0-112a86.1 86.1 0 0 0-86 86c0 30.91 14.34 63.74 41.47 94.94a252.32 252.32 0 0 0 41.09 38a6 6 0 0 0 6.88 0a252.32 252.32 0 0 0 41.09-38c27.13-31.2 41.47-64 41.47-94.94a86.1 86.1 0 0 0-86-86m0 206.51C113 212.93 54 163.62 54 104a74 74 0 0 1 148 0c0 59.62-59 108.93-74 120.51"/></svg>
    `,
    residence: `
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path fill="currentColor" d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm10 13h2v-2h-2zm3 0h2V7a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v10h2v-2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zM11 4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v13h5V7a2 2 0 0 1 2-2h1zm1 4.75a.75.75 0 1 1-1.5 0a.75.75 0 0 1 1.5 0m-6.75.75a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5M6 5.75a.75.75 0 1 1-1.5 0a.75.75 0 0 1 1.5 0m5.25 6.75a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5M6 14.75a.75.75 0 1 1-1.5 0a.75.75 0 0 1 1.5 0m-.75-2.25a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5M15.5 8.75a.75.75 0 1 1-1.5 0a.75.75 0 0 1 1.5 0m-.75 3.75a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5"/></svg>
    `,
    '360deg': `
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 21q-1.868 0-3.51-.709t-2.857-1.923q-1.215-1.215-1.924-2.858Q3 13.868 3 12h1q0 3.125 2.091 5.402t5.205 2.563L9.731 18.4l.707-.708l3.127 3.127q-.378.096-.78.139q-.4.042-.785.042m.808-6.308V9.308h2.769q.425 0 .712.287t.288.713v3.384q0 .425-.288.713t-.712.287zm-5.385 0v-.884h2.885v-1.423H8.423v-.77h1.885v-1.423H7.423v-.884H10.5q.294 0 .493.199q.2.199.2.493v4q0 .294-.2.493q-.199.2-.493.2zm6.27-.884h1.769q.115 0 .173-.058q.057-.058.057-.173v-3.154q0-.115-.057-.173q-.058-.058-.173-.058h-1.77zM20 12q0-3.125-2.091-5.402t-5.205-2.563L14.269 5.6l-.707.708l-3.127-3.127q.378-.096.78-.139Q11.614 3 12 3q1.868 0 3.51.709t2.858 1.924q1.215 1.215 1.923 2.857Q21 10.133 21 12z"/></svg>
    `,
    gallery: `
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 36 36"><path fill="currentColor" d="M32 4H4a2 2 0 0 0-2 2v24a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2M4 30V6h28v24Z" class="clr-i-outline clr-i-outline-path-1"/><path fill="currentColor" d="M8.92 14a3 3 0 1 0-3-3a3 3 0 0 0 3 3m0-4.6A1.6 1.6 0 1 1 7.33 11a1.6 1.6 0 0 1 1.59-1.59Z" class="clr-i-outline clr-i-outline-path-2"/><path fill="currentColor" d="m22.78 15.37l-5.4 5.4l-4-4a1 1 0 0 0-1.41 0L5.92 22.9v2.83l6.79-6.79L16 22.18l-3.75 3.75H15l8.45-8.45L30 24v-2.82l-5.81-5.81a1 1 0 0 0-1.41 0" class="clr-i-outline clr-i-outline-path-3"/><path fill="none" d="M0 0h36v36H0z"/></svg>
    `
};

export default {
    name: 'IconBar',
    mixins: [menuItemMixin],
    computed: {
        ...mapGetters({
            isDarkTheme: 'theme/isDarkTheme'
        }),
        icons() {
            return ICONS;
        },
        barRoutes() {
            return routerController.getMenuRoutes().filter((r) => r.meta.isIconBar);
        }
    },
    methods: {
        isBarRoutesActive({ name = '', children = [] }) {
            return name === this.$route.name || children?.some((child) => child?.name === this.$route.name);
        }
    }
};
</script>
