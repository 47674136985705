<template>
    <div class="footer-favorites__btns">
        <div
            class="footer-favorites__btn"
            @click="$emit('compare')"
        >
            {{ isCompare ? 'Retirer' : 'Comparer' }}
        </div>
        <div
            v-if="enableLotsComparison"
            class="footer-favorites__btn off-element"
            @click="$emit('show-info')"
        >
            <p>Optionner ce lot</p>
        </div>
        <div
            class="footer-favorites__btn"
            @click="$emit('remove')"
        >
            effacer
        </div>
    </div>
</template>

<script>
export default {
    name: 'ComparerBtns',
    props: {
        isCompare: {
            type: Boolean,
            required: true,
        },
        enableLotsComparison: {
            type: Boolean,
            required: true,
        },
    },
};
</script>
