export default {
    isDarkTheme: false,
    themeColors: {
        bright: {
            accentColor: '#ffffff',
            elementColor: '#191919',
        },
        dark: {
            accentColor: '#191919',
            elementColor: '#ffffff',
        },
    },
};
