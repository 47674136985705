import axios from 'axios';

const instance = axios.create({
    withCredentials: true,
});

export default {
    get(url, config) {
        return instance.get(url, config);
    },
    post(url, data, config) {
        if (config === undefined) {
            config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
        }

        if (data instanceof FormData) {
            config.headers = {
                'Content-Type': 'multipart/form-data',
            };
        }

        return instance.post(url, data, config);
    },
    put(url, data) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        if (data instanceof FormData) {
            config.headers = {
                'Content-Type': 'multipart/form-data',
            };
        }

        return instance.put(url, data, config);
    },
    patch(url, data) {
        const config = {};
        if (data instanceof FormData) {
            config.headers = {
                'Content-Type': 'multipart/form-data',
            };
        }

        return instance.patch(url, data, config);
    },
    delete(url) {
        return instance.delete(url);
    },
};
