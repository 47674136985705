<template>
    <div :class="className">
        <div class="gallery-slider__wrapper">
            <hooper
                ref="slider"
                class="gallery-slider"
                :settings="hooperSettings"
            >
                <slide
                    v-for="({path}, index) in slides"
                    :key="path + index"
                    :index="index"
                >
                    <div class="gallery-slider__slide">
                        <img
                            :src="path"
                            class="gallery-slider__slide__image"
                            :alt="altImgText"
                        />
                    </div>
                </slide>
            </hooper>

            <button
                class="gallery-slider__nav nav--prev"
                @click="slidePrev"
            >
                <svg
                    width="14"
                    height="24"
                    viewBox="0 0 14 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.07363 11.8092L13.0106 2.87924C13.3268 2.56217 13.504 2.13251 13.5033 1.68477C13.5027 1.23702 13.3242 0.807879 13.0071 0.491741C12.6901 0.175603 12.2604 -0.00163219 11.8127 -0.000975778C11.3649 -0.000319362 10.9358 0.178175 10.6196 0.495242L0.494627 10.6092C0.188711 10.9164 0.0129185 11.3295 0.00379285 11.7629C-0.00533285 12.1963 0.15291 12.6165 0.445627 12.9362L10.6126 23.1292C10.9288 23.4463 11.3579 23.6248 11.8057 23.6255C12.2534 23.6261 12.6831 23.4489 13.0001 23.1327C13.3172 22.8166 13.4957 22.3875 13.4963 21.9397C13.497 21.492 13.3198 21.0623 13.0036 20.7452L4.07363 11.8092Z"
                        fill="white"
                    />
                </svg>
            </button>
            <button
                class="gallery-slider__nav nav--next"
                @click="slideNext"
            >
                <svg
                    width="14"
                    height="25"
                    viewBox="0 0 14 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.4293 12.8099L0.492304 21.7399C0.176166 22.057 -0.00107027 22.4866 -0.000413895 22.9344C0.000242483 23.3821 0.178738 23.8113 0.495804 24.1274C0.81287 24.4435 1.24254 24.6208 1.69028 24.6201C2.13802 24.6195 2.56716 24.441 2.8833 24.1239L13.0083 14.0099C13.3142 13.7028 13.49 13.2896 13.4991 12.8562C13.5083 12.4228 13.35 12.0026 13.0573 11.6829L2.8903 1.4899C2.57416 1.17283 2.14502 0.994337 1.69728 0.993681C1.24954 0.993025 0.81987 1.17026 0.502804 1.4864C0.185737 1.80254 0.00724246 2.23168 0.00658607 2.67942C0.00592969 3.12717 0.183166 3.55683 0.499304 3.8739L9.4293 12.8099Z"
                        fill="white"
                    />
                </svg>
            </button>
            <!-- <FullscreenToggle /> -->
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
// import FullscreenToggle from '@front/components/Navigation/FullscreenToggle';
import {Hooper, Slide} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
    name: 'GallerySlider',
    components: {
        Hooper,
        Slide,
        // FullscreenToggle,
    },
    props: {
        slides: {
            type: Array,
            required: true,
        },
        className: {
            type: String,
            default: () => '',
        },
    },
    computed: {
        ...mapGetters({
            altImgText: 'config/altImgText',
        }),
        slider() {
            return this.$refs.slider;
        },
        hooperSettings() {
            return {
                centerMode: true,
                itemsToShow: 1,
                infiniteScroll: true,
                transition: 500,
            };
        },
    },
    methods: {
        slidePrev() {
            this.slider.slidePrev();
        },
        slideNext() {
            this.slider.slideNext();
        },
    },
};
</script>
