import {wait} from '@front/utils/wait';

const GM_SCRIPT_ID = '__vue-gm-script__';
const BASE_GM_API_PATH = 'https://maps.googleapis.com/maps/api/js';
const BASE_POLYFILL_SRC = 'https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=default';
const GM_ERRORS = {
    DOCUMENT: 'document is not defined',
    GMAPS: 'gmaps is not defined',
    MAP: 'current map is not defined',
};
const MAP_OBJECTS = {};

/**
 * @param {object} props
 * @param {object} props.options
 * @param {string} props.options.key
 * @throws {Error} document is not defined
 * @return {void}
 */
function _appendYmScript({options}) {
    const scriptId = `${GM_SCRIPT_ID}`;
    const isScriptExist = _isGmScriptExist(scriptId);

    if (isScriptExist) {
        return;
    }

    const params = new URLSearchParams(options).toString();
    const src = `${BASE_GM_API_PATH}?${params}`;

    if (!window?.document) {
        throw new Error(GM_ERRORS.DOCUMENT);
    }

    const mapScript = document.createElement('script');
    const polyfillScript = document.createElement('script');
    polyfillScript.setAttribute('src', BASE_POLYFILL_SRC);
    mapScript.setAttribute('id', scriptId);
    mapScript.setAttribute('src', src);
    mapScript.setAttribute('type', 'text/javascript');
    mapScript.setAttribute('defer', 'defer');
    document.head.appendChild(polyfillScript);
    document.head.appendChild(mapScript);
}

/**
 * @param {string} scriptId
 * @return {boolean}
 */
function _isGmScriptExist(scriptId) {
    return Boolean(document.getElementById(scriptId)) ?? false;
}

/**
 * @return {Promise<object | null>}
 */
async function _getGmapsInstance() {
    const attempt = 0;

    return new Promise((resolve) => {
        const interval = setInterval(() => {
            const gMaps = window?.google;

            if (gMaps || attempt > 5) {
                clearInterval(interval);
                resolve(gMaps);
            }
        }, 100);
    });
}

/**
 * @param {object} props
 * @param {string} props.mapId
 * @param {HTMLDivElement} props.mapContainer
 * @param {object} props.mapOptions
 * @return {void}
 */
function _initGoogleMap({mapId, mapContainer, mapOptions}) {
    if (MAP_OBJECTS[mapId]) {
      // return;
    }

    MAP_OBJECTS[mapId] = new window.google.maps.Map(mapContainer, {
        ...mapOptions,
    });
}

/**
 * @param {object} props
 * @param {{lat: number, lng: number}} props.coordinates
 * @param {string} props.markerImagePath
 * @param {string} props.tooltip
 * @param {string} props.tooltipLink
 * @param {string} props.tooltipLinkLabel
 * @throws {Error} gmaps is not defined | current map is not defined
 * @return {void}
 */
export async function setMapMarkers({
    mapId,
    coordinates,
    markerImagePath,
    tooltip,
    tooltipLink,
    tooltipLinkLabel = 'link',
}) {
    if (!MAP_OBJECTS[mapId]) {
        throw new Error(GM_ERRORS.MAP);
    }

    const gmaps = await _getGmapsInstance();

    if (!gmaps) {
        throw new Error(GM_ERRORS.GMAPS);
    }

    const tooltipContent = tooltip ? `<div id="balloon-content">${tooltip}</div>` : '';
    const link = tooltipLink
        ? `
        <div>
            <a href="${tooltipLink}" target="_blank" rel="noopener noreferrer">
                ${tooltipLinkLabel}
            </a>
        </div>
    `
        : '';

    const balloon = new google.maps.InfoWindow({
        content: tooltipContent + link,
    });

    const mapMarker = new google.maps.Marker({
        position: coordinates,
        map: MAP_OBJECTS[mapId],
        icon: markerImagePath,
    });

    mapMarker.addListener('click', () => {
        balloon.open({
            anchor: mapMarker,
            map: MAP_OBJECTS[mapId],
        });
    });

    mapMarker.setMap(MAP_OBJECTS[mapId]);
}

/**
 * @param {object} props
 * @param {string} props.mapId
 * @param {string} props.apiKey
 * @throws {Error} gmaps is not defined
 * @return {void}
 */
export async function googleMapBuilder({mapId, apiKey, mapContainer, mapOptions}) {
    if (!Object.keys(MAP_OBJECTS).length) {
        _appendYmScript({
            options: {key: apiKey},
        });
    }

    if (MAP_OBJECTS[mapId]) {
      // return;
    }

    const gMaps = await _getGmapsInstance();

    if (!gMaps) {
        throw new Error(GM_ERRORS.GMAPS);
    }

    await wait(500);

    _initGoogleMap({mapId, mapContainer, mapOptions});
}
