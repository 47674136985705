import lotConstants from '@common/constants/lot-constants';
import additionalWindows from './menu-additional-windows';
import mainPageTemplates from './mainPageTemplates';
import statuses from './statuses';
import viewers from './viewers';
import extendFormActions from './extendFormActions';
import injectedRouteNames from './injectedRouteNames';

const defaultLotColor = 'grey';
const buildTypes = ['mobile', 'default', 'offline'];
const [, buildType] = buildTypes;
const MOBILE_WIDTH = 540;
const DROP_ZONES = {
    LEFT: 1,
    RIGHT: 2,
};

export {
    additionalWindows,
    buildType,
    mainPageTemplates,
    MOBILE_WIDTH,
    statuses,
    viewers,
    extendFormActions,
    lotConstants,
    DROP_ZONES,
    injectedRouteNames,
    defaultLotColor,
};
