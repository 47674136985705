export default {
    // Lots
    lotsData: null,
    lotInfo: null,
    favorites: [],
    selectedLot: null,
    programId: '',
    lotsInfoApi: '',
    enableLotsComparison: false,
    enableLotsVisibility: false,
    enableAxonometricLot: false,
    enableCreatingLotInfoForm: false,
    showPricePublic: false,
    showPriceConnected: false,
    showLotAvailabilityConnected: false,
    showLotAvailabilityPublic: false,
    show: true,

    // Scheme
    readyToDrag: false,
    schemeData: null,
    scheme3dImagesLoaded: 0,
    scheme3dImages: [],
    scheme3dMasks: [],
    pathsToScheme3dImages: [],
    pathsToSheme3dMasks: [],
    floorImagesLoaded: {},
    floorImages: {},
    floorMasks: {},
    pathsToFloorImages: {},
    pathsToFloorMasks: {},
    isToggleViewer: false,
    selectedFloor: null,
    currentSchemeView: 0,
    enableCompassFloors: false,
    assetsPath: null,
    lotsPath: null,
    currentSchemeAngle: 0,
    chartRotationAngles: null,
    maxCanvasScale: 0,
    loadedViewerMaskCount: 0,
    isFloorLoaded: false,
    activeImage: 1,

    // Filters
    filters: [],
    minMaxArea: {
        min: 0,
        max: 0,
    },
    minMaxPrice: {
        min: 0,
        max: 0,
    },
    isFiltersResetted: false,
};
