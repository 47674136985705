<template>
    <div v-if="isConfigLoad" :class="['zgallery', { 'zgallery--black': isDarkTheme }]">
        <component :is="activeComponent.componentName" :key="activeComponent.name" :slides="currentSlides" />
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import GalleryVideos from '@front/components/Gallery/GalleryVideos.vue';
import GalleryBrochure from '@front/components/Gallery/GalleryBrochure.vue';
import GallerySlider from '@front/components/Slider/GallerySlider';

export default {
    name: 'GalleryPage',
    components: {
        GalleryVideos,
        GalleryBrochure,
        GallerySlider
    },
    computed: {
        ...mapGetters({
            qualityOptions: 'gallery/qualityOptions',
            videos: 'gallery/videos',
            brochure: 'gallery/brochure',
            galleries: 'gallery/galleries',
            isConfigLoad: 'gallery/isConfigLoad',
            activeComponent: 'gallery/activeComponent',
            assetsPath: 'gallery/assetsPath',
            slidesPath: 'gallery/slidesPath',
            currentImageQuality: 'gallery/currentImageQuality',
            isDarkTheme: 'theme/isDarkTheme'
        }),
        currentSlides() {
            return (
                this.galleries
                    ?.find(({ title }) => title === this.activeComponent.name)
                    ?.slides.map(({ gallery, image }) => {
                        return {
                            // eslint-disable-next-line max-len
                            path: `${this.assetsPath}/${this.slidesPath}/${gallery}/${this.currentImageQuality}/${image}`
                        };
                    }) ?? []
            );
        }
    },
    mounted() {
        this.fetchGalleryConfig().then(this.setImageQuality);
    },
    watch: {},
    methods: {
        ...mapMutations({
            setCurrentImageQuality: 'gallery/setCurrentImageQuality'
        }),
        ...mapActions({
            fetchGalleryConfig: 'gallery/fetchGalleryConfig'
        }),
        async setImageQuality() {
            await this.$nextTick();
            const quality = this.qualityOptions?.find(({ minWidth, maxWidth }) => {
                return window.innerWidth >= minWidth && window.innerWidth <= maxWidth;
            })?.title;

            quality && this.setCurrentImageQuality(quality);
        }
    }
};
</script>

<style lang="scss" scoped>
.zgallery {
    background: rgb(255, 255, 255);

    &--black {
        background: rgb(25, 25, 25);
    }
}
</style>
