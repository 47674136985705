import { mapGetters, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            isDarkTheme: 'theme/isDarkTheme',
            initialLotsData: 'scheme/initialLotsData',
            minMaxArea: 'scheme/minMaxArea',
            minMaxPrice: 'scheme/minMaxPrice',
            isToggleViewer: 'scheme/isToggleViewer',
            enableLotsVisibility: 'scheme/enableLotsVisibility',
            accessionButton: 'projectExtend/accessionButton',
            filterByType: 'scheme/filterByType',
            standardFilters: 'scheme/standardFilters',
            rangeFilters: 'scheme/rangeFilters',
            isFiltersResetted: 'scheme/isFiltersResetted',
            isPriceFilterAvailable: 'scheme/isPriceFilterAvailable',
            filters: 'scheme/filters'
        }),
        minMaxRanges() {
            return {
                area: this.minMaxArea,
                price: this.minMaxPrice
            };
        },
        isAllRoomsActive() {
            return this.filterByType(this.$types.ROOMS_TYPE)?.options?.every((filter) => filter.checked) ?? false;
        },
        pointOfInterestFilter() {
            if (!this.standardFilters.length) return null;

            return this.standardFilters?.find(({ type }) => type === this.$types.POINT_OF_INTEREST_TYPE) ?? null;
        },
        floorsFilter() {
            if (!this.standardFilters.length) return null;

            return this.standardFilters?.find(({ type }) => type === this.$types.FLOORS_TYPE) ?? null;
        }
    },
    mounted() {
        this.$eventBus.$on('reload-filters', (skip) => {
            !skip && this.onResetFiltersHandler();
        });
    },
    beforeDestroy() {
        this.$eventBus.$off('reload-filters');
    },
    methods: {
        ...mapMutations({
            updateFilters: 'scheme/updateFilters',
            updateFilter: 'scheme/updateFilter',
            resetActiveFilters: 'scheme/resetActiveFilters',
            setIsFiltersResetted: 'scheme/setIsFiltersResetted',
            updateSelectedLot: 'scheme/updateSelectedLot'
        }),
        onToggleFilter(newFilter) {
            const filters = this.standardFilters.map((filter) => {
                if (filter.type !== newFilter.type) {
                    return filter;
                }

                const checkedOptionIndex = filter.options.findIndex((option) => {
                    return option.option === newFilter.option;
                });

                if (checkedOptionIndex !== -1) {
                    filter.options[checkedOptionIndex] = newFilter;
                }

                return filter;
            });

            this.updateFilters(filters);
        },
        onToggleAllRoomsFiltersHandler(isAllActive) {
            const filters = this.standardFilters.map((filter) => {
                if (filter.type !== this.$types.ROOMS_TYPE) {
                    return filter;
                }

                const options = filter.options.map((option) => {
                    return {
                        ...option,
                        checked: !isAllActive
                    };
                });

                return {
                    ...filter,
                    options
                };
            });

            this.updateFilters(filters);
        },
        getFormatterByType(type) {
            switch (type) {
                case this.$types.PRICE_TYPE:
                    return '\u004B\u00A0\u20AC';
                case this.$types.AREA_TYPE:
                    return '\u33A1';
            }
        },
        onUpdateRangeHandler({ range, filter }) {
            this.updateFilter({ ...filter, range });
        },
        isRangeFilterByTypeAvailable(type) {
            const isAvailable = !!this.minMaxRanges[type].max;

            if (type !== this.$types.PRICE_TYPE) {
                return isAvailable;
            }

            return this.isPriceFilterAvailable && isAvailable;
        },
        async onResetFiltersHandler() {
            this.updateSelectedLot(null);
            this.resetActiveFilters();
            this.setIsFiltersResetted(true);
            await this.$wait();
            this.setIsFiltersResetted(false);
        }
    }
};
