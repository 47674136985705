<template>
    <div class="aside-desktop-scheme-actions">
        <button @click="enterVR">VR</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewerCompass from '@front/components/Viewers/particles/ViewerCompass.vue';
import ViewerChoices from '@front/components/Viewers/particles/ViewerChoices.vue';

export default {
    name: 'Scheme3dPageActions',
    components: {
        ViewerCompass,
        ViewerChoices
    },
    computed: {
        ...mapGetters({
            compassDegree: 'scheme/compassDegree',
            currentSchemeAngle: 'scheme/currentSchemeAngle'
        })
    },
    methods: {
        enterVR() {
            var krpano = document.getElementById('locationPano');
            if (krpano) krpano.call('webvr.enterVR()');
        }
    }
};
</script>

<style lang="scss" scoped>
.aside-desktop-scheme-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    height: 100%;

    &::v-deep {
        .v360-menu-compass-container {
            margin: 0 !important;
        }
    }
}
</style>
