<template>
    <div class="gallery-video">
        <video
            autoplay
            loop
            muted
            :poster="currentVideoPaths.poster"
        >
            <source
                :src="currentVideoPaths.video"
                type="video/mp4"
            />
        </video>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'GalleryVideos',
    computed: {
        ...mapGetters({
            currentVideoPaths: 'gallery/currentVideoPaths',
        }),
    },
    created() {
        this.$wait(100).then(this.loaderHide());
    },
    methods: {
        ...mapActions({
            loaderHide: 'loader/hide',
        }),
    },
};
</script>

<style lang="scss">
.gallery-video {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    video {
        width: 100%;
        height: auto;
        max-height: 100vh;
    }
}
</style>
