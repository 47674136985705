<template>
    <div class="cl-legal">
        <footer>
            <div class="cl-legal__mentions">
                <router-link
                    to="/mentions-legales"
                    class="cl-legal__legales"
                >
                    Mentions légales.
                </router-link>
                &nbsp; Conception et rédaction : &nbsp;
                <span />
                &nbsp;
                <a
                    href="https://www.illusio.fr/"
                    target="_blank"
                >
                    illusio.fr
                </a>
                .
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'LegalInformationBlock',
};
</script>

<style lang="scss">
@import '@vue/styles/vars';

.cl-legal {
    padding: 16px 0;

    &__mentions {
        // display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        color: $black;
        font-size: 16px;
        letter-spacing: 0.16px;
        font-family: Helvetica57;
        text-align: center;
        @media (max-width: 576px) {
            font-size: 14px;
            letter-spacing: 0.14px;
        }
        @media (max-width: 350px) {
            max-width: 250px;
            margin: auto;
        }

        a {
            cursor: pointer;
            color: $black;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        a.legales {
            font-family: Helvetica67;
            color: $primary;
            text-decoration: underline;
            @media (max-width: 576px) {
                padding-bottom: 8px;
            }
        }

        span {
            background-color: #ff0000;
            width: 9px;
            height: 9px;
            display: inline-block;
        }
    }
}
</style>
