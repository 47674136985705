import store from '@front/store';

export default {
    bind(el) {
        [...el.getElementsByTagName('area')].forEach((area) => area.addEventListener('click', _catchRedirectHandler));
    },
    unbind(el) {
        [...el.getElementsByTagName('area')].forEach((area) =>
            area.removeEventListener('click', _catchRedirectHandler),
        );
    },
};

/**
 * @param {MouseEvent} event
 * @return {void}
 * @private
 */
function _catchRedirectHandler(event) {
    const {href} = event.target;
    const {origin, pathname, search} = new URL(href);

    if (!window.location.href.includes(`${origin}${pathname}`)) {
        return;
    }

    event.preventDefault();
    const [, entityId] = search.split('?entityId=');
    const activeEntity =
        _getEntityById(entityId, store.getters['location/mediaItems']) ||
        _getEntityById(entityId, store.getters['location/maps']) ||
        _getEntityById(entityId, store.getters['location/tours']);

    if (!activeEntity) {
        return;
    }

    store.commit('location/setActiveEntity', activeEntity);
}

/**
 * @param {string} id
 * @param {T[]} entities
 * @return {T|null}
 */
function _getEntityById(id, entities) {
    return entities?.find((entity) => entity.id === id) ?? null;
}
