<template>
    <div
        class="search-select"
        :class="{'is-active': isOpen}"
    >
        <input
            ref="search"
            v-model="search"
            readonly
            :placeholder="options[value] ? options[value].title : 'Please select an option'"
            type="text"
            class="search-select-search"
            @focus="open"
            @keydown.enter.prevent="selectHighlighted"
            @keydown.esc="close"
            @keydown.tab="close"
            @keydown.up="highlightPrev"
            @keydown.down="highlightNext"
        />
        <svg
            :class="{open: isOpen}"
            class="search-select-icon"
            width="12px"
            height="8px"
            viewBox="0 0 12 8"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            @click="toggle"
        >
            <g>
                <path
                    d="M5.9403615,4.51983851 L9.61645133,1.12115286 C9.84663298,0.899160775 10.3144958,0.990807274 10.6620138,1.32596106 C11.0095319,1.66111484 11.1045593,2.11233169 10.8743777,2.33432377 L5.91888269,7 L4.61641777,5.74387512 L4.62256756,5.73818941 L1.12562234,2.33432377 C0.895440688,2.11233169 0.990468143,1.66111484 1.33798617,1.32596106 C1.68550421,0.990807274 2.15336702,0.899160775 2.38354867,1.12115286 L5.9403615,4.51983851 Z"
                />
            </g>
        </svg>
        <div
            v-show="isOpen"
            class="search-select-dropdown"
        >
            <ul
                v-show="filteredOptions.length > 0"
                ref="options"
                class="search-select-options"
                @click.prevent="selectClicked"
                @mouseover="highlightOption"
            >
                <li
                    v-for="(option, index) in filteredOptions"
                    :key="option.title"
                    :class="{'is-active': index === highlightedIndex}"
                    class="search-select-option"
                >
                    {{ option.title }}
                </li>
            </ul>
            <div
                v-show="filteredOptions.length === 0"
                class="search-select-empty"
            >
                No results found for "{{ search }}"
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomSelect',
    props: {
        value: {
            type: Number,
            required: true,
        },
        options: {
            required: true,
            type: Array,
        },
        filterFunction: {
            type: Function,
            default(search, options) {
                return options.filter((option) => {
                    return option.title.toLowerCase().startsWith(search.toLowerCase());
                });
            },
        },
    },
    data() {
        return {
            isOpen: false,
            search: '',
            highlightedIndex: 0,
        };
    },
    computed: {
        filteredOptions() {
            return this.filterFunction(this.search, this.options);
        },
    },
    mounted() {
        document.addEventListener('click', this.clickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.clickOutside);
    },
    methods: {
        defaultFilter(search, options) {
            return options.filter((option) => {
                return option.title.toLowerCase().startsWith(search.toLowerCase());
            });
        },
        clickOutside(e) {
            if (e.target === this.$el || this.$el.contains(e.target)) {
                return;
            }

            this.close();
        },
        open() {
            this.isOpen = true;

            this.$nextTick(() => {
                this.$refs.search.focus();
                this.scrollToHighlighted();
            });
        },
        close() {
            this.isOpen = false;
        },
        toggle() {
            this.isOpen ? this.close() : this.open();
        },
        select(selected) {
            this.$emit(
                'input',
                this.options.findIndex((option) => {
                    return option.title === selected;
                }),
            );
            this.search = '';
            this.highlightedIndex = 0;
            this.close();
        },
        selectClicked(e) {
            this.select(e.target.innerText);
        },
        selectHighlighted(e) {
            this.isOpen ? this.select(this.filteredOptions[this.highlightedIndex]) : this.open();
        },
        scrollToHighlighted() {
            this.$refs.options.children[this.highlightedIndex].scrollIntoView({block: 'nearest'});
        },
        highlight(index) {
            this.highlightedIndex = index;

            if (this.highlightedIndex < 0) {
                this.highlightedIndex = this.filteredOptions.length - 1;
            }

            if (this.highlightedIndex > this.filteredOptions.length - 1) {
                this.highlightedIndex = 0;
            }

            this.scrollToHighlighted();
        },

        highlightPrev() {
            if (this.isOpen) this.highlight((this.highlightedIndex -= 1));
        },

        highlightNext() {
            if (this.isOpen) this.highlight((this.highlightedIndex += 1));
        },

        highlightOption(e) {
            // Find the index of the highlighted option
            let node = e.target;
            let index = 0;
            while ((node = node.previousElementSibling)) {
                index++;
            }
            // return index;
            this.highlightedIndex = index;

            // Remove the old active class if one exists
            const old = this.$el.querySelector('.is-active');
            if (old) old.classList.remove('is-active');
            // Add it to the newly hovered item
            e.target.classList.add('is-active');
        },
    },
};
</script>
