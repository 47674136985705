export default {
    activeAdditionalWindow: null,
    galleryMode: null,
    isMenuVisible: false,
    menuOffTimeout: null,
    isMenuMustBeStatic: false,
    isMenuMustBeHidden: false,
    isBurgerWhiteShadow: false,
    isMobileMenuExpanded: false,
    isCloseBurgerButtonHide: false,
};
