<template>
    <div class="filter">
        <h4 class="inner-menu__title pl-0">
            {{ filter.title }}
        </h4>
        <VueSlider
            v-model="rangeValue"
            tooltip="always"
            tooltip-placement="bottom"
            :tooltip-formatter="`{value}${formatter}`"
            class="range-slider"
            :interval="interval"
            :max="minMax[1]"
            :min="minMax[0]"
            silent
        />
    </div>
</template>

<script>
import debounce from 'lodash.debounce';

const DEFAULT_DELAY = 300;

export default {
    name: 'LotsRangeFilter',
    props: {
        filter: {
            type: Object,
            required: true,
        },
        range: {
            type: Object,
            required: true,
        },
        reset: {
            type: Boolean,
            default: () => false,
        },
        formatter: {
            type: String,
            default: () => '\u33A1',
        },
    },
    emits: ['update:range'],
    data() {
        return {
            rangeValue: [],
            interval: 1,
            minMax: [0, 0],
            isFirstRender: true,
        };
    },
    watch: {
        range: {
            immediate: true,
            deep: true,
            handler({min, max}) {
                this.setDefaultRange({min, max, emittable: true});
            },
        },
        rangeValue: {
            deep: true,
            handler(newValue) {
                this.onUpdateRangeHandler(newValue);
            },
        },
        reset(isReset) {
            if (isReset) {
                this.rangeValue = this.minMax;
            }
        },
    },
    methods: {
        onUpdateRangeHandler: debounce(function ([min, max]) {
            // eslint-disable-next-line no-invalid-this
            this.emitUpdate(this.filter.option !== this.$types.PRICE_TYPE ? [min, max] : [min * 1000, max * 1000]);
        }, DEFAULT_DELAY),
        emitUpdate(payload) {
            this.$emit('update:range', payload);
        },
        setDefaultRange({min, max, emittable}) {
            let modifiedRange = [min, max];

            if (this.filter.option === this.$types.PRICE_TYPE) {
                modifiedRange = [Math.ceil(min / 1000), Math.floor(max / 1000)];
                this.interval = (modifiedRange[1] - modifiedRange[0]) / 10;
            }

            this.rangeValue = modifiedRange;
            this.minMax = [...modifiedRange];
            emittable && this.emitUpdate([min, max]);
        },
    },
};
</script>
