import mainPageTemplates, {removedTempaltes} from '@front/constants/mainPageTemplates';

export const getMainPageTemplate = async (projectExtendData, store) => {
    const mainPageTemplate = store.getters['config/mainPageTemplate'] || null;

    if (!mainPageTemplate || mainPageTemplate === mainPageTemplates.SLIDER_VIEW) {
        return import('@front/components/HomeViews/SliderView.vue');
    }

    if (!mainPageTemplate || mainPageTemplate === mainPageTemplates.CUSTOM_LANDING_VIEW) {
        return import('@front/components/HomeViews/CustomLandingView.vue');
    }

    if (!projectExtendData) {
        return import('@front/components/HomeViews/SliderView.vue');
    }

    const viewsList = [mainPageTemplate, ...Object.values(removedTempaltes)];
    const resultImports = await Promise.allSettled(viewsList.map(projectExtendData.mainPageView));
    const importedModule = resultImports.find((res) => res.status === 'fulfilled');

    return importedModule?.value ?? import('@front/components/HomeViews/SliderView.vue');
};
