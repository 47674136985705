import { mapGetters, mapMutations } from 'vuex';
import CLButton from '@front/components/CustomLandingBlocks/components/Button.vue';

export default {
    components: {
        CLButton
    },
    props: {
        blockData: {
            type: Object,
            default: () => null
        }
    },
    computed: {
        ...mapGetters({
            plainAssetsPath: 'config/plainAssetsPath',
            altImgText: 'config/altImgText',
            formAction: 'projectExtend/formAction',
            formAnchor: 'projectExtend/formAnchor'
        }),
        baseImagePath() {
            return `${this.plainAssetsPath}/landing/${this.blockData.id}`;
        },
        imagePath() {
            return `${this.baseImagePath}/original`;
        },
        elements() {
            return this.blockData.elements.reduce((acc, el) => {
                acc[el.type] = el;

                return acc;
            }, {});
        }
    },
    methods: {
        ...mapMutations({
            setIsContactModalActive: 'projectExtend/setIsContactModalActive'
        }),
        elementImagePath({ id, image, quality = 'original' }) {
            return `${this.baseImagePath}/element/${id}/${quality}/${image}`;
        },
        pointImagePath({ elementId, pointId, image, quality = 'original' }) {
            return `${this.baseImagePath}/element/${elementId}/point/${pointId}/${quality}/${image}`;
        },
        onFormButtonHandler() {
            if (this.formAction === this.$constants.extendFormActions.SCROLL) {
                $('html, body').animate({ scrollTop: $(this.formAnchor).offset().top }, 300);
            } else {
                this.setIsContactModalActive(true);
            }
        }
    }
};
