export default {
    setFormComponent(state, component) {
        state.formComponent = component;
    },
    setAccessionButton(state, accessionButton) {
        state.accessionButton = accessionButton;
    },
    setFormAction(state, action) {
        state.formAction = action;
    },
    setFormAnchor(state, anchor) {
        state.formAnchor = anchor;
    },
    setIsContactModalActive(state, bool) {
        state.isContactModalActive = bool;
    },
    setAdditionalData(state, payload) {
        state.additionalData = payload;
    },
    setErrorModalProps(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            if (Object.prototype.hasOwnProperty.call(state.errorModalProps, key)) {
                this.commit('projectExtend/updateContactFormModalProps', {modal: 'error', prop: key, value});
            }
        });
    },
    setSuccessModalProps(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            if (Object.prototype.hasOwnProperty.call(state.successModalProps, key)) {
                this.commit('projectExtend/updateContactFormModalProps', {modal: 'success', prop: key, value});
            }
        });
    },
    updateContactFormModalProps(state, {modal, prop, value}) {
        const modalPropsType = `${modal}ModalProps`;

        state[modalPropsType] = {
            ...state[modalPropsType],
            [prop]: value,
        };
    },
};
