export default {
    slots: {
        logo: 'Gérer le logo du site, le chargeur et l`arrière-plan du menu',
        slider: 'Gérer les sliders',
        clientInfo: 'Gérer les informations client',
        seo: 'Gérer les SEOs',
        analytics: 'Gérer les analyses',
        metaImages: 'Gérer les méta-images',
        microData: 'Gérer les micro-données',
        legalMentionHtml: 'Gérer la mention légale',
        contactFormEmail: 'Gérer les e-mails du formulaire de contact',
        contactPhone: 'Gérer la barre des contacts',
        configParams: 'Gérer les paramètres de configuration',
        homepage: 'Gérer la page d`accueil',
        contactForm: 'Gérer le formulaire de contact',
    },
    mainImages: {
        topLogoWhite: 'Choisissez le logo du programme (blanc)',
        topLogoDark: 'Choisissez le logo du programme (foncé)',
        loader: 'Choisissez l`image de chargement',
        menuBackground: 'Choisir l`image d`arrière-plan du menu (blanc)',
        menuBackgroundDark: 'Choisir l`image d`arrière-plan du menu (sombre)',
    },
    mainSlider: {
        title: 'Curseur principal',
    },
    clientInfo: {
        title: 'Informations du client',
        thead: {
            link: 'Lien',
            text: 'Texte',
            logos: 'Logos',
            actions: 'Actions',
        },
        logoDark: 'Logo client foncé',
        logoWhite: 'Logo client blanc',
    },
    analytics: {
        googleAdsId: 'Google Ads id',
        gtmId: 'GTM id',
        fbqId: 'fbq id',
        fdvCode: 'Code de vérification du domaine Facebook',
        outbrainId: 'Id du pixel outbrain',
        googleStartFillFormId: 'Google Ads "start_fill_form" id',
        googleContactFormId: 'Google Ads "formulaire_contact" id',
        customScriptHead: 'Script personnalisé dans la tête',
    },
    metaImages: {
        ogImage: 'Choisir l`image og',
        twitterImage: 'Choisir l`image Twitter',
        googleImage: 'Choisir une image Google+',
        favicon: 'Choisir l`image de favicon',
    },
    phoneFields: {
        enableContactSidebar: 'Activer',
        whatsappNumber: 'Numéro WhatsApp',
        telephoneNumber: 'Numéro Telephone',
        backgroundPhoneColor: 'Couleur de fond du téléphone',
    },
    configMenuButtons: {
        enableMenu: 'Afficher le menu du site',
        enableSchemeLinkDisplayInMenu: 'Activer le bouton maquette dans le menu',
        enableIllusioButton: 'Activer le bouton Illusio',
        enableContactForm: 'Activer l`affichage du formulaire de contact sur la page principale',
        enableLotAvailabilityPage: 'Activer la page de disponibilité des lots',
        enableChangeColorTheme: 'Activer le changement de thème de couleur',
        darkThemeByDefault: 'Définir le thème sombre par défaut',
        enableCookieAlert: 'Activer l`affichage de l`alerte cookie',
        enableChangeLang: 'Activer le changement de langue',
    },
    configMainFields: {
        client: 'Nom du client',
        programCity: 'Ville du programme',
        altImgText: 'Texte alternatif des images',
        externalRepo: 'Nom du référentiel externe',
        programPostalCode: 'Code postal du programme',
    },
    indexDataFields: {
        siteTitle: 'Titre du site',
        siteDescription: 'Description du site',
    },
    mainPageTemplate: {
        mainPage: 'Modèle de page principale',
        slider: {
            title: 'Affichage du curseur',
        },
        customLanding: {
            title: 'Vue d`atterrissage personnalisée',
            globalSettings: 'Paramètres globaux',
            mainColor: 'Couleur principale',
            secondaryColor: 'Couleur secondaire',
            saveGlobalParams: 'Enregistrer les paramètres globaux',
            pointText: 'Texte ponctuel',
            pointsTextColor: 'Couleur du texte des points',
            // eslint-disable-next-line quotes
            pointsIconColor: "Couleur de l'icône des points",
            pointIcon: 'Icône Point',
            pointActive: 'Le point est actif',
            // eslint-disable-next-line quotes
            pointIconType: "Type d'icône de point",
            types: {
                introduction: 'Introduction',
                progress: 'Progrès',
                maquette: 'Maquette',
                information: 'Information',
                price: 'Prix',
                title: 'Titre',
                apartment: 'Appartement',
                form: 'Formulaire',
                logo: 'Logo',
            },
        },
        tailoredLanding: {
            title: 'Vue d`atterrissage sur mesure(premium)',
        },
    },
    mainModuleButtons: {
        getDb: 'Dump SQLite',
        backupLeads: 'Suivis de secours',
    },
};
