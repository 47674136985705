<template>
    <div
        v-if="!activeAdditionalWindow || activeAdditionalWindow === $constants.additionalWindows.LOTS_LIST"
        :style="{top: activeAdditionalWindow ? '0' : '20px', right: '20px'}"
        class="v360-menu-btns fullscreen-toggle"
        @click="toggleFullScreenMode"
    >
        <img
            v-if="isDarkTheme"
            :src="$getImagePath('icons/fullscreen-icon-wt.svg')"
            :alt="altImgText"
            class="full-screen-btn"
        />
        <img
            v-else
            :src="$getImagePath('icons/fullscreen-icon.svg')"
            :alt="altImgText"
            class="full-screen-btn"
        />
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {toggleFullScreen} from '@front/utils/toggleFullScreen';

export default {
    name: 'FullscreenToggle',
    computed: {
        ...mapGetters({
            activeAdditionalWindow: 'navigation/activeAdditionalWindow',
            isDarkTheme: 'theme/isDarkTheme',
            altImgText: 'config/altImgText',
        }),
    },
    methods: {
        toggleFullScreen,
        toggleFullScreenMode() {
            this.toggleFullScreen('app-wrapper');
        },
    },
};
</script>

<style scoped>
.full-screen-btn:hover {
    transform: scale(1.5);
}
</style>
