const menu = [
    {
        title: 'Perspectives',
        activityFlag: 'isPerspectivesVisible',
        name: 'perspectives',
        componentName: 'GallerySlider'
    },
    {
        title: 'Photos du chantier',
        activityFlag: 'enableProgressGallery',
        name: 'progress',
        componentName: 'GallerySlider'
    },
    {
        title: 'Axonométries',
        activityFlag: 'enableAxonometryGallery',
        name: 'axos',
        componentName: 'GallerySlider'
    },
    {
        title: 'Brochure',
        activityFlag: 'isBrochureVisible',
        name: 'brochure',
        componentName: 'GalleryBrochure'
    },
    {
        title: 'Videos',
        activityFlag: 'isVideosVisible',
        name: 'videos',
        componentName: 'GalleryVideos'
    }
];

export { menu };
