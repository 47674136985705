import {i18n} from '@admin/plugins/i18n';

export const SLIDER_VIEW = 'SliderView';
export const CUSTOM_LANDING_VIEW = 'CustomLandingView';
export const LANDING_VIEW = 'TailoredLandingView';

export const VIEWS_OPTIONS = () => [
    {
        name: i18n.t('mainModule.mainPageTemplate.slider.title'),
        value: SLIDER_VIEW,
    },
    {
        name: i18n.t('mainModule.mainPageTemplate.customLanding.title'),
        value: CUSTOM_LANDING_VIEW,
    },
    {
        name: i18n.t('mainModule.mainPageTemplate.tailoredLanding.title'),
        value: LANDING_VIEW,
    },
];

export const removedTempaltes = {
    CLASSIC_LANDING_VIEW: 'ClassicLandingView',
    BIG_LANDING_VIEW: 'BigLandingView',
    SHORT_LANDING_VIEW: 'ShortLandingView',
};

export default {
    SLIDER_VIEW,
    CUSTOM_LANDING_VIEW,
    LANDING_VIEW,
};
