<template>
    <div class="aside-desktop-scheme-actions">
        <ViewerCompass v-if="$route.meta.menuTemplate === 'ToursMenu'" :rotate-angle="compassDegree" />
    </div>
</template>

<script>
import ViewerCompass from '@front/components/Viewers/particles/ViewerCompass.vue';

export default {
    name: 'ToursPageActions',
    components: {
        ViewerCompass
    },
    data() {
        return {
            krpanoElement: null,
            intervalId: null
        };
    },
    computed: {
        compassDegree() {
            if (!this.krpanoElement) {
                return 0;
            }

            let result = 0;
            let hlookat = this.krpanoElement.get('view.hlookat');
            if (hlookat < 0) {
                hlookat += 360;
            }
            result = hlookat;
            return result;
        }
    },
    mounted() {
        this.checkKrpano();
        this.intervalId = setInterval(this.checkKrpano, 1000);
    },
    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
    methods: {
        checkKrpano() {
            this.krpanoElement = document.getElementById('tourPano');
            if (this.krpanoElement) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.aside-desktop-scheme-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    height: 100%;

    &::v-deep {
        .v360-menu-compass-container {
            margin: 0 !important;
        }
    }
}
</style>
