<template>
    <section class="cl-information">
        <div class="cl-information-container">
            <div class="cl-information-image">
                <img
                    :src="`${imagePath}/${blockData.backgroundImage}`"
                    :alt="altImgText"
                />
            </div>
            <div class="cl-information__content">
                <div class="cl-information__content-items-block">
                    <div
                        v-for="(point, index) in elements.points.points"
                        :key="index"
                        class="cl-information__content-item"
                    >
                        <fa-icon
                            v-if="point.icon"
                            :icon="point.icon"
                            class="cl-information__content-icon"
                            :style="{
                                color: point.color,
                            }"
                        />
                        <img
                            v-else-if="point.image"
                            :src="pointImagePath({elementId: point.element, pointId: point.id, image: point.image})"
                            :alt="altImgText"
                            class="cl-information__content-icon"
                        />
                        <p
                            :style="{
                                color: blockData.color,
                            }"
                            v-html="point.text"
                        />
                    </div>
                </div>
                <div class="cl-information__content-button">
                    <CLButton
                        :label="elements.button.text"
                        :text-color="elements.button.color"
                        :background-color="elements.button.backgroundColor"
                        @click="onFormButtonHandler"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import customLandingMixin from '@front/mixins/customLandingMixin';

export default {
    name: 'InformationBlock',
    mixins: [customLandingMixin],
};
</script>

<style lang="scss" scoped>
.cl-information {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    padding: 51px 50px 132px 50px;

    @media (max-width: 992px) {
        padding: 25px 0 100px;
    }

    @media (max-width: 768px) {
        padding: 25px 0 50px;
    }

    ::v-deep {
        strong,
        b {
            font-weight: bold !important;
        }

        em,
        i {
            font-style: italic !important;
        }
    }

    &-container {
        max-width: var(--max-width);
        width: 100%;
        margin: auto;
        display: flex;

        @media (max-width: 992px) {
            flex-direction: column;
            align-items: center;
        }
    }

    &-image {
        flex-grow: 1;
        max-width: 820px;
        width: 100%;

        @media (max-width: 992px) {
            margin: auto;
        }

        img {
            display: block;
            width: 100%;
            object-fit: cover;
            height: 534px;

            @media (max-width: 768px) {
                height: 400px;
            }
        }
    }

    &__content {
        padding-left: 150px;
        max-width: 620px;
        text-align: center;

        @media (max-width: 1500px) {
            padding-left: 50px;
        }

        @media (max-width: 1200px) {
            padding-left: 25px;
        }

        @media (max-width: 992px) {
            max-width: 540px;
            padding: 40px 30px 0 30px;
        }

        &-items-block {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 36px;

            @media (max-width: 576px) {
                grid-template-columns: 1fr;
            }
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 16px;
            letter-spacing: 0.48px;
            line-height: 21px;
        }

        &-icon {
            max-width: 100px;
            max-height: 100px;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            margin-bottom: 18px;
        }

        &-button {
            margin-top: 36px;
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
}
</style>
