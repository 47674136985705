<template>
    <div class="progress">
        <div
            class="progress-bar progress-bar-striped progress-bar-animated bg-danger"
            role="progressbar"
            :aria-valuenow="currentProgress"
            :aria-valuemin="minValue"
            :aria-valuemax="maxValue"
            :style="{width: currentProgress + '%'}"
        />
    </div>
</template>

<script>
export default {
    name: 'UIProgressBar',
    props: {
        currentProgress: {
            type: Number,
            default: () => 0,
        },
        maxValue: {
            type: Number,
            default: () => 0,
        },
        minValue: {
            type: Number,
            default: () => 0,
        },
    },
};
</script>
