<template>
    <div>
        <UIButton
            v-if="isComparisonAvailable"
            id="my-choices-btn-on-menu"
            :class="['mes-choix-btn', isComparatorVisible ? 'v360-btn-active' : '']"
            @click="toggleComparator"
        >
            <template #image>
                <div class="favorite-icon">
                    <svg clip-rule="evenodd" fill-rule="evenodd" xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                        <path d="M12 21.593c-5.63-5.539-11-10.297-11-14.402 0-3.791 3.068-5.191 5.281-5.191 1.312 0 4.151.501 5.719 4.457 1.59-3.968 4.464-4.447 5.726-4.447 2.54 0 5.274 1.621 5.274 5.181 0 4.069-5.136 8.625-11 14.402m5.726-20.583c-2.203 0-4.446 1.042-5.726 3.238-1.285-2.206-3.522-3.248-5.719-3.248-3.183 0-6.281 2.187-6.281 6.191 0 4.661 5.571 9.429 12 15.809 6.43-6.38 12-11.148 12-15.809 0-4.011-3.095-6.181-6.274-6.181"></path>
                    </svg>
                    {{ favorites.length > 0 ? favorites.length : '' }}
                </div>
            </template>
        </UIButton>
        <ModalComparator
            v-if="isComparatorVisible"
            @close="toggleComparator"
        />
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

import UIButton from '@front/components/UI/UIButton';
import ModalComparator from '@front/components/Comparator/ModalComparator.vue';

export default {
    name: 'ViewerChoices',
    components: {
        UIButton,
        ModalComparator,
    },
    data() {
        return {
            isComparatorVisible: false,
        };
    },
    computed: {
        ...mapGetters({
            enableLotsVisibility: 'scheme/enableLotsVisibility',
            enableLotsComparison: 'scheme/enableLotsComparison',
            favorites: 'scheme/favorites',
        }),
        isComparisonAvailable() {
            if (!this.enableLotsVisibility) {
                return false;
            }

            if (this.$route.meta.menuTemplate !== 'Scheme3dMenu') {
                return false;
            }

            return this.enableLotsComparison;
        },
    },
    methods: {
        toggleComparator() {
            this.isComparatorVisible = !this.isComparatorVisible;
        },
    },
};
</script>

<style lang="scss" scoped>
#my-choices-btn-on-menu {
    width: 45px;
    height: 45px;
    padding: 23px 9px;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.5);
}
#my-choices-btn-on-menu svg {
    fill: #fff !important;
    stroke: #fff !important;
    position: absolute;
    width: auto;
    height: auto;
}
#my-choices-btn-on-menu .favorite-icon {
    font-size: 11px;
}
#my-choices-btn-on-menu:hover svg {
    fill: #fff !important;
    stroke: #fff !important;
}

.favorite-icon {
    transform: translateY(2px);
}
</style>
