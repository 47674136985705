<template>
    <section
        id="cl-form"
        class="form-wrapper"
    >
        <h2
            class="heading"
            v-html="elements.title.text"
        />
        <form
            class="contact-form"
            @submit.prevent="validate"
        >
            <div class="input-columns">
                <div class="col-first">
                    <input
                        v-model.trim="contactModel.name"
                        class="form-input"
                        type="text"
                        placeholder="Nom*"
                        required
                        tabindex="1"
                        @keypress="analyticsEvent"
                    />
                    <input
                        v-model.trim="contactModel.surname"
                        class="form-input"
                        type="text"
                        placeholder="Prénom*"
                        required
                        tabindex="2"
                    />
                    <input
                        v-model="contactModel.mail"
                        class="form-input"
                        type="email"
                        placeholder="Email*"
                        required
                        tabindex="3"
                    />
                    <input
                        v-model="contactModel.postalCode"
                        class="form-input"
                        type="text"
                        placeholder="Code postal*"
                        required
                        tabindex="4"
                    />
                    <div class="select-row">
                        <div id="radio-row">
                            <div>
                                <input
                                    id="habiter"
                                    type="radio"
                                    name="invest-type"
                                    required="required"
                                    value="HABITER"
                                    checked
                                />
                                <label
                                    for="habiter"
                                    class="radio-label"
                                    tabindex="7"
                                >
                                    HABITER
                                </label>
                                <label
                                    class="span"
                                    for="habiter"
                                >
                                    <span class="checked"></span>
                                </label>
                            </div>
                            <div>
                                <input
                                    id="invester"
                                    type="radio"
                                    name="invest-type"
                                    required="required"
                                    value="INVESTIR"
                                />
                                <label
                                    for="invester"
                                    class="radio-label"
                                    tabindex="8"
                                >
                                    INVESTIR
                                </label>
                                <label
                                    class="span"
                                    for="invester"
                                >
                                    <span class="checked"></span>
                                </label>
                            </div>
                            <div class="line"></div>
                        </div>
                    </div>
                </div>
                <div class="col-second">
                    <input
                        v-model="contactModel.phone"
                        class="form-input"
                        type="tel"
                        placeholder="Téléphone"
                        tabindex="5"
                    />
                    <textarea
                        v-model="contactModel.message"
                        name="message"
                        placeholder="Message"
                        tabindex="6"
                    />
                    <div
                        v-click-outside="() => (isSelectOpen = false)"
                        class="form-select"
                    >
                        <div
                            :class="['select-selected', {'select-arrow-active': isSelectOpen}]"
                            @click="isSelectOpen = !isSelectOpen"
                        >
                            {{ contactModel.lotRooms.label }}
                        </div>
                        <ul :class="['select-items', {'select-hide': !isSelectOpen}]">
                            <li
                                v-for="option in piecesOptions"
                                :key="option.label"
                                :class="{'same-as-selected': contactModel.lotRooms.value === option.value}"
                                @click="onSelectHandler(option)"
                            >
                                {{ option.label }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="checkbox">
                <label class="custom-checkbox">
                    En cochant cette case, je confirme avoir lu et accepté les conditions et mentions légales et la
                    politique de confidentialité.
                    <input
                        v-model="agreement"
                        type="checkbox"
                        name="agree_from"
                        required
                        tabindex="9"
                    />
                    <span class="checkmark" />
                </label>
            </div>
            <div
                class="champ-obligations"
                v-html="elements.footnote.text"
            />
            <div class="btn-container">
                <button
                    type="submit"
                    class="link-btn"
                    tabindex="10"
                >
                    ÊTRE RECONTACTÉ(E)
                </button>
            </div>
        </form>
        <VueRecaptcha
            ref="recaptcha"
            size="invisible"
            :sitekey="sitekey"
            @verify="sendForm"
            @expired="onCaptchaExpired"
        />
    </section>
</template>

<script>
import axios from 'axios';
import clone from 'lodash.clonedeep';
import {mapMutations} from 'vuex';
import {VueRecaptcha} from 'vue-recaptcha';

import customLandingMixin from '@front/mixins/customLandingMixin';
import {PIECES, INITIAL_MODEL} from '@front/models/CustomLanding/Form';

export default {
    name: 'FormBlock',
    components: {
        VueRecaptcha,
    },
    mixins: [customLandingMixin],
    data() {
        return {
            contactModel: clone(INITIAL_MODEL),
            agreement: false,
            isSelectOpen: false,
            isFormFilling: false,
            isSendingInProgress: false,
        };
    },
    computed: {
        piecesOptions() {
            return PIECES;
        },
        sitekey() {
            return process.env.RECAPTCHA_SITE_KEY || '';
        },
    },
    methods: {
        ...mapMutations({
            updateContactFormModalProps: 'projectExtend/updateContactFormModalProps',
            setFormAction: 'projectExtend/setFormAction',
            setFormAnchor: 'projectExtend/setFormAnchor',
        }),
        onSelectHandler(option) {
            this.contactModel.lotRooms = option;
            this.isSelectOpen = false;
        },
        async sendForm(recaptchaToken) {
            if (!this.agreement) {
                return;
            }

            if (this.isSendingInProgress) {
                return;
            }

            try {
                this.isSendingInProgress = true;
                const payload = {
                    ...this.contactModel,
                    recaptchaToken,
                };

                payload.lotRooms = this.contactModel.lotRooms.value;
                await axios.post('/api/callback/landing-form', payload);
                window.criteo_q = window.criteo_q || [];
                // prettier-ignore
                const deviceType = /iPad/.test(navigator.userAgent)
                    ? 't'
                    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
                        ? 'm'
                        : 'd';
                // prettier-ignore
                window.criteo_q.push(
                    {event: 'setAccount', account: 100226},
                    {event: 'setEmail', email: `${this.contactModel.mail}`, hash_method: 'sha256'},
                    {event: 'setSiteType', type: deviceType},
                    {event: 'setZipcode', zipcode: `${this.contactModel.postalCode}`},
                    {event: 'Homepage'},
                );
                this.updateContactFormModalProps({modal: 'success', prop: 'isActive', value: true});
                this.contactModel = clone(INITIAL_MODEL);
                this.agreement = false;
                gtag('event', 'submit', {event_category: 'formulaire_contact', event_label: 'formulaire_contact'});
                gtag('event', 'conversion', {send_to: 'AW-10941698271/dRWDCP6sx8oDEN-htOEo'});
                fbq('track', 'Lead');
                typeof window.lintrk === 'function' && window.lintrk('track', {conversion_id: 12000026});
            } catch (error) {
                console.error(error);
                this.updateContactFormModalProps({modal: 'error', prop: 'isActive', value: true});
            } finally {
                this.isSendingInProgress = false;
            }
        },
        analyticsEvent() {
            if (this.isFormFilling) {
                return;
            }

            gtag('event', 'submit', {
                event_category: 'start_fill_form',
                event_label: 'start_fill_form',
            });

            this.isFormFilling = true;
        },
        validate() {
            this.$refs.recaptcha.execute();
        },
        onCaptchaExpired() {
            this.$refs.recaptcha.reset();
        },
    },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
    padding: 105px 15px 50px 15px;
    width: 100%;
    text-align: center;
    background-color: var(--accent-color);

    @media (max-width: 768px) {
        padding: 50px 30px;
    }

    .heading {
        margin: auto;
        display: inline-block;
        position: relative;
        font-family: 'AvenirMedium';
        font-size: 36px;
        letter-spacing: 2.16px;
        line-height: 43px;
        text-align: center;
        color: var(--secondary-color);
        padding-bottom: 87px;

        @media (max-width: 992px) {
            margin: unset;
            padding-bottom: 50px;
        }

        @media (max-width: 768px) {
            font-size: 30px;
            letter-spacing: 1.2px;
            line-height: 32px;
            text-decoration: unset;
            padding-bottom: 30px;
        }

        @media (max-width: 390px) {
            max-width: 250px;
            width: 100%;
            margin: auto;
        }
    }

    .contact-form {
        max-width: 674px;
        width: 100%;
        margin: auto;

        input,
        textarea {
            resize: none;
            border: 1px solid var(--accent-color);
            background-color: var(--secondary-color);
            color: rgba(33, 28, 73, 0.75);
            padding: 15px;
            margin-bottom: 15px;
            height: 38px;
            min-width: 329px;
            max-width: 329px;
            width: 100%;
            font-size: 15px;
            letter-spacing: 0.9px;
            font-family: 'AvenirBook';

            &::placeholder {
                background-color: var(--secondary-color);
            }

            &:focus {
                outline: none;
            }
        }

        textarea {
            height: 144px;
        }

        .input-columns {
            display: flex;
            justify-content: space-between;

            .col-first {
                text-align: left;
            }

            .form-input {
                padding-bottom: 15px;
            }

            @media (max-width: 768px) {
                flex-direction: column;

                .form-input,
                textarea {
                    width: 100%;
                    max-width: unset;
                    min-width: unset;
                }
            }

            .custom-select {
                text-align: left;
                margin-top: 13px;
                max-width: 290px;
                width: 100%;
                position: relative;
                font-family: 'AvenirBook';
                font-size: 18px;
                letter-spacing: 1.8px;

                @media (max-width: 768px) {
                    max-width: unset;
                    width: 100%;
                    margin-top: 0;
                    margin-bottom: 15px;
                }

                &:hover {
                    @media (min-width: 900px) {
                        .select-selected {
                            color: var(--secondary-color);
                            transition: all 0.3s ease-out;
                        }

                        .select-items {
                            visibility: visible;
                            transition: all 0.3s ease-out;
                        }
                    }
                }
            }

            .select-selected {
                background-color: transparent;
                height: 40px;
                font-family: 'AvenirBlack';
                font-size: 18px;
                letter-spacing: 1.8px;
            }

            .select-selected:after {
                position: absolute;
                content: '';
                top: 15px;
                right: 10px;
                width: 0;
                height: 0;
                border: 10px solid var(--secondary-color);
                border-color: var(--secondary-color) transparent transparent transparent;
            }

            .select-selected.select-arrow-active:after {
                border-color: transparent transparent var(--secondary-color) transparent;
                top: 7px;
            }

            .select-selected {
                color: var(--secondary-color);
                padding: 8px 16px;
                border: 2px solid var(--secondary-color);
                border-color: transparent transparent var(--secondary-color) transparent;
                cursor: pointer;
                user-select: none;
            }

            .select-items div {
                color: var(--secondary-color);
                cursor: pointer;
                user-select: none;
                padding-bottom: 9px;
                font-size: 18px;
                letter-spacing: 1.8px;
            }

            .select-items {
                padding: 12px;
                position: absolute;
                background-color: var(--secondary-color);
                top: calc(100% - 4px);
                left: 0;
                right: 0;
                z-index: 99;
                overflow: hidden;
                transition: all 0.3s ease-out;
            }

            .select-hide {
                visibility: hidden;
                height: 0;
            }

            .select-items div:hover,
            .same-as-selected {
                font-family: 'AvenirBook';
            }
        }

        label.custom-checkbox {
            text-align: left;
            max-width: 630px;
            display: block;
            position: relative;
            padding-left: 25px;
            cursor: pointer;
            color: var(--secondary-color);
            font-family: 'AvenirBook';
            font-size: 15px;
            letter-spacing: 0.15px;
            user-select: none;

            #agree_from-error {
                display: block;
                position: absolute;
                left: 6px;
                top: 25px;
            }
        }

        label.custom-checkbox input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            top: 4px;
            left: 0;
            height: 16px;
            width: 16px;
            background-color: transparent;
            border: 1px solid var(--secondary-color);
        }

        label.custom-checkbox:hover input ~ .checkmark {
            background-color: transparent;
        }

        label.custom-checkbox input:checked ~ .checkmark {
            background-color: transparent;
            border: 1px solid var(--secondary-color);
        }

        .checkmark:after {
            content: '';
            position: absolute;
            display: none;
        }

        label.custom-checkbox input:checked ~ .checkmark:after {
            display: block;
        }

        label.custom-checkbox .checkmark:after {
            left: 3px;
            top: 3px;
            width: 8px;
            height: 8px;
            background-color: var(--secondary-color);
        }

        .select-row {
            display: flex;
            justify-content: flex-start;
            padding: 12px 0 25px 0;

            @media (max-width: 768px) {
                flex-wrap: wrap;
            }

            #radio-row {
                display: flex;
                justify-content: space-around;
                min-width: 330px;
                position: relative;
                margin-top: 7px;

                @media (max-width: 450px) {
                    min-width: 100%;
                }

                .line {
                    position: absolute;
                    bottom: 13px;
                    width: 100%;
                    background-color: var(--secondary-color);
                    height: 2px;
                }

                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

            #radio-row input[type='radio'] {
                display: none;
            }

            #radio-row input[type='radio'] ~ label {
                text-align: center;
                min-width: 75px;
                position: relative;
                cursor: pointer;
                margin-bottom: 4px;
                font-size: 16px;
                letter-spacing: 1.6px;
                line-height: 18px;
                opacity: 0.5;
                color: var(--secondary-color);
                font-family: 'AvenirBook';

                &:hover {
                    opacity: 1;

                    & + label.span {
                        opacity: 1;
                        border: 2px solid var(--secondary-color);
                    }
                }
            }

            #radio-row input[type='radio']:checked ~ label {
                font-size: 18px;
                letter-spacing: 1.8px;
                font-family: 'AvenirBlack';
                line-height: 18px;
                color: var(--secondary-color);
                opacity: 1;

                & + label.span {
                    border: 2px solid var(--secondary-color);
                }
            }

            #radio-row input[type='radio'] ~ label + label.span {
                margin-top: 3px;
                max-width: 20px;
                min-width: unset;
                display: block;
                content: '';
                width: 20px;
                height: 20px;
                border: 1px solid var(--secondary-color);
                border-radius: 50%;
                z-index: 1;
                position: relative;

                &:hover {
                    opacity: 1;
                }
            }

            #radio-row input[type='radio']:checked ~ label + label.span {
                border: 2px solid var(--secondary-color);
            }

            #radio-row input[type='radio']:checked + label + label.span .checked {
                display: none;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                content: '';
                width: 10px;
                height: 10px;
                background-color: var(--secondary-color);
                z-index: 2;
            }
        }

        .checkbox {
            padding-top: 40px;
            padding-bottom: 10px;
        }

        .champ-obligations {
            text-align: left;
            font-size: 15px;
            line-height: 16px;
            padding-bottom: 40px;
            font-family: 'AvenirBook';
            color: var(--secondary-color);

            a {
                font-family: 'AvenirBook';
                text-decoration: underline;
            }
        }

        .form-select {
            text-align: left;
            margin-top: 13px;
            max-width: 290px;
            width: 100%;
            position: relative;
            font-family: 'AvenirBook';
            font-size: 18px;
            letter-spacing: 1.8px;
        }

        @media (max-width: 768px) {
            .form-select {
                max-width: unset;
                width: 100%;
                margin-top: 0;
                margin-bottom: 15px;
            }
        }

        .select-selected {
            background-color: transparent;
            height: 40px;
            font-family: RalewayBold;
            font-size: 18px;
            letter-spacing: 1.8px;
        }

        .select-selected:after {
            position: absolute;
            content: '';
            top: 15px;
            right: 10px;
            width: 0;
            height: 0;
            border: 10px solid var(--secondary-color);
            border-color: var(--secondary-color) transparent transparent transparent;
        }

        .select-selected.select-arrow-active:after {
            border-color: transparent transparent var(--secondary-color) transparent;
            top: 7px;
        }

        .select-selected {
            color: var(--secondary-color);
            padding: 8px 16px;
            border: 2px solid var(--secondary-color);
            border-color: transparent transparent var(--secondary-color) transparent;
            cursor: pointer;
            user-select: none;
        }

        .select-items li {
            color: var(--accent-color);
            cursor: pointer;
            user-select: none;
            padding-bottom: 9px;
            font-size: 15px;
            letter-spacing: 1.8px;
            font-family: 'AvenirBook';
        }

        .select-items {
            padding: 12px;
            position: absolute;
            background-color: var(--secondary-color);
            top: 100%;
            left: 0;
            right: 0;
            z-index: 99;
        }

        .select-hide {
            display: none;
        }

        .select-items li:hover,
        .same-as-selected {
            font-weight: bold;
            font-family: 'AvenirBlack';
        }
    }

    .link-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 50px;
        background-color: transparent;
        color: var(--secondary-color);
        cursor: pointer;
        border: 4px solid var(--secondary-color);
        font-family: 'AvenirBlack';
        font-size: 20px;
        letter-spacing: 1.2px;
        text-align: center;
        transition: all 0.3s ease-out;
        border-radius: 10px;

        @media (max-width: 992px) {
            padding: 10px 15px;
        }

        @media (max-width: 576px) {
            padding: 10px 5px;
            font-size: 18px;
            letter-spacing: 0.9px;
        }

        @media (max-width: 350px) {
            padding: 10px 5px;
            font-size: 16px;
            letter-spacing: 0.96px;
        }
    }

    .form-info-row {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 24px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            padding-top: 20px;
        }

        .heading-info {
            font-size: 20px;
            letter-spacing: 2px;
            color: var(--secondary-color);
            font-family: 'AvenirBlack';

            @media (max-width: 768px) {
                padding-bottom: 10px;
            }
        }

        .line {
            width: 1px;
            min-height: 39px;
            height: 100%;
            background-color: var(--secondary-color);
            margin: 0 26px 0 26px;

            @media (max-width: 768px) {
                display: none;
            }
        }

        .text-info {
            text-align: left;
            color: var(--secondary-color) !important;
            max-width: 235px;
            letter-spacing: 0.15px;
            font-size: 15px;
            line-height: 21px;
            font-family: 'AvenirBook';
        }
    }
}
</style>
