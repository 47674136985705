import Vue from 'vue';
import Vuex from 'vuex';
import loader from './loader-module';
import scheme from './scheme-module';
import tours from './tours-module';
import location from './location-module';
import theme from './theme-module';
import navigation from './navigation-module';
import config from './config-module';
import captcha from './captcha-module';
import externalApi from './external-api-module';
import projectExtend from './project-extend-module';
import gallery from './gallery-module';
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        loader,
        scheme,
        tours,
        location,
        theme,
        navigation,
        config,
        captcha,
        externalApi,
        projectExtend,
        gallery,
    },
});
