<template>
    <!-- <Scheme3dMenuDesktop v-if="!isShowMobileView" />
    <Scheme3dMenuMobile v-else /> -->
    <HomeMenu />
</template>

<script>
// import Scheme3dMenuDesktop from './Scheme3dMenuDesktop.vue';
// import Scheme3dMenuMobile from './Scheme3dMenuMobile.vue';
import HomeMenu from '../HomeMenu/HomeMenu.vue';
import { isInIframe } from '@front/utils/isInIframe';

const DEFAULT_IFRAME_WIDTH = 700;

export default {
    name: 'Scheme3dMenu',
    components: {
        // Scheme3dMenuDesktop,
        // Scheme3dMenuMobile: Scheme3dMenuDesktop,
        HomeMenu
    },
    computed: {
        isShowMobileView() {
            return isInIframe(window) ? this.$isMobile() || window.innerWidth < DEFAULT_IFRAME_WIDTH : this.$isMobile();
        }
    }
};
</script>
