export default {
    formComponent: null,
    formAction: '',
    formAnchor: '',
    isContactModalActive: false,
    accessionButton: '',
    additionalData: null,
    errorModalProps: {
        isActive: false,
        title: null,
        description: null,
        color: null,
    },
    successModalProps: {
        isActive: false,
        title: null,
        description: null,
        color: null,
    },
};
