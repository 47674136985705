<template>
    <div
        ref="dropdownRef"
        v-click-outside="() => isDropdownOpen = false"
        class="ui-dropdown"
        :class="isDropdownOpen ? 'active': ''"
        :style="{
            minWidth: dropdownWidth ? `${dropdownWidth}px` : 'max-content',
        }"
        @click="isDropdownOpen = !isDropdownOpen"
    >
        <div
            v-if="!!this.$slots.icon"
            class="ui-dropdown__icon"
        >
            <slot name="icon" />
        </div>
        <span class="ui-dropdown__value">{{ currentValue?.label ?? currentValue?.value ?? value }}</span>
        <Icon
            v-if="items.length"
            icon="uiw:down"
            class="ui-dropdown__chevron"
        />
        <div class="ui-dropdown__items">
            <template v-if="items.length">
                <div
                    v-for="({value: v, label}) in items"
                    :key="v"
                    class="ui-dropdown__items-item"
                    @click.prevent.stop="onInput(v)"
                >
                    {{ label || v }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {Icon} from '@iconify/vue2';

export default {
    name: 'UIDropdown',
    components: {
        Icon,
    },
    props: {
        value: {
            type: [String, Number, Boolean],
            default: () => '',
        },
        options: {
            type: [Array],
            required: true,
        },
    },
    emits: ['input'],
    data() {
        return {
            isDropdownOpen: false,
            dropdownRef: null,
            dropdownWidth: 0,
        };
    },
    computed: {
        currentValue() {
            return this.options.find(({value}) => value === this.value);
        },
        items() {
            return this.options.filter(({value}) => value !== this.value);
        },
    },
    mounted() {
        this.updateWidth();
    },
    methods: {
        async updateWidth() {
            await this.$nextTick();

            if (!this.$refs.dropdownRef) return;

            const itemsWidth = this.$refs.dropdownRef.querySelector('.ui-dropdown__items').offsetWidth;

            if (itemsWidth > this.dropdownWidth) this.dropdownWidth = itemsWidth;
        },
        onInput(v) {
            this.$emit('input', v);
            this.isDropdownOpen = false;
            this.updateWidth();
        },
    },
};
</script>


<style lang="scss" scoped>
.ui-dropdown {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    height: 100%;
    padding: 10px 15px;
    transition: background .5s;
    border-radius: 4px;
    cursor: pointer;

    &__icon {
        display: flex;
        align-items: center;
        min-width: 20px;
        height: 20px;
        padding-top: 2px;

        > * {
            width: 100%;
            height: auto;
        }
    }

    &__chevron {
        min-width: 20px;
        color: #FFFFFF;
        transition: transform .3s;
    }

    &__value {
        color: #FFFFFF;
        font-family: 'Open Sans';
        font-size: 14px;
        text-decoration: underline;
        white-space: nowrap;
        margin-right: auto;
    }

    &__items {
        position: absolute;
        z-index: -1;
        top: 100%;
        right: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-radius: 0 0 4px 4px;
        background-color: rgba(0, 0, 0, .5);
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s;
        padding: 10px 0;

        &-item {
            padding: 0 15px;
            color: #FFFFFF;
            font-family: 'Open Sans';
            font-size: 14px;
            text-align: left;
            white-space: nowrap;
        }
    }

    &.active {
        background-color: rgba(0, 0, 0, .5);
        border-radius: 4px 4px 0 0;

        .ui-dropdown {
            &__chevron {
                transform: rotate(180deg);
            }

            &__items {
                z-index: 1;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
</style>
