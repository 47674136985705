export default {
    program: null,
    lot: null,
    contact: {
        nom: null,
        prenom: null,
        cityId: null,
        cityLabel: null,
        telephone: '',
        mail: null
    }
};
