export default {
    tours(state) {
        return state.tours;
    },
    tourshotspots(state) {
        return state.tours;
    },
    selectedTourIndex(state) {
        return state.selectedTourIndex;
    },
    selectedRoomIndex(state) {
        return state.selectedRoomIndex;
    },
    assetsPath(state) {
        return state.assetsPath;
    },
    toursPath(state, getters) {
        return `${getters.assetsPath}/${state.toursPath}`;
    },
    roomsPath(state, getters) {
        return `${getters.toursPath}/${getters.tours[getters.selectedTourIndex].id}/${state.roomsPath}`;
    },
    hotspotsPath(state, getters) {
        return `${getters.roomsPath}/${getters.tours[getters.selectedTourIndex].rooms[getters.selectedRoomIndex].id}/${
            state.hotspotsPath
        }`;
    },
    uploadsPath(state) {
        return state.uploadsPath;
    },
    mediaItemsPath(state, getters) {
        return `${getters.assetsPath}/${getters.uploadsPath}/${state.mediaItemsPath}`;
    },
    mediaItems(state) {
        return state.mediaItems;
    },
    googleMapsPath(state, getters) {
        return `${getters.assetsPath}/${getters.uploadsPath}/${state.googleMapsPath}`;
    },
    maps(state) {
        return state.maps;
    },
    activeEntity(state) {
        return state.activeEntity;
    },
    isConfigLoaded(state) {
        return state.isConfigLoaded;
    },
    qualityOptions(state) {
        return state.qualityOptions;
    },
    currentImageQuality(state) {
        return state.currentImageQuality;
    },
};
