import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@admin/locales/en';
import fr from '@admin/locales/fr';

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'fr',
    fallbackLocale: 'fr',
    messages: {en, fr},
});
