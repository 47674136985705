export default {
    slots: {
        documents: 'Brochure',
        video: 'Vidéo',
        sliderImages: 'Photo',
    },
    documents: {
        choseBrochure: 'Choisir la brochure',
    },
    video: {
        choseVideo: 'Choisir la vidéo',
        choseVideoPoster: 'Choisir l`affiche de la vidéo',
    },
    images: {
        tabs: {
            perspectives: {
                slotName: 'Perspectives',
                tableTitle: 'Perspectives',
            },
            progress: {
                slotName: 'Chantier',
                tableTitle: 'Chantier',
            },
            axos: {
                slotName: 'axos',
                tableTitle: 'Axos',
            },
        },
        tableFields: {
            title: 'Titre',
            slide: 'Diapositive',
            actions: 'Actions',
        },
    },
    fieldsVisibilityCheckboxes: {
        isPerspectivesVisible: 'Activer la visibilité des perspectives',
        enableProgressGallery: 'Activer la visibilité des photos de progression',
        enableAxonometryGallery: 'Activer la visibilité des axos',
        isBrochureVisible: 'Activer la visibilité de la brochure',
        isVideosVisible: 'Activer la visibilité de la vidéo',
    },
};
