import { mapGetters, mapMutations } from 'vuex';
import clone from 'lodash.clonedeep';
import HomeMenuRoutes from '@front/components/T02/Templates/HomeMenu/HomeMenuRoutes.vue';
import menuOffTimeoutMixin from '@front/mixins/menuOffTimeoutMixin';
import routerController from '@front/controllers/RouterController';

export default {
    name: 'HomeMenu',
    components: {
        HomeMenuRoutes
    },
    mixins: [menuOffTimeoutMixin],
    computed: {
        ...mapGetters({
            isDarkTheme: 'theme/isDarkTheme',
            enableLotAvailabilityPage: 'config/enableLotAvailabilityPage',
            topLogoWhitePath: 'config/topLogoWhitePath',
            topLogoDarkPath: 'config/topLogoDarkPath',
            enableContactForm: 'config/enableContactForm',
            altImgText: 'config/altImgText',
            isContactModalActive: 'projectExtend/isContactModalActive',
            formAction: 'projectExtend/formAction',
            formAnchor: 'projectExtend/formAnchor',
            bottomLogos: 'config/bottomLogos',
            bottomLogoPath: 'config/bottomLogoPath',
            tours: 'tours/tours',
            selectedTourIndex: 'tours/selectedTourIndex',
            selectedRoomIndex: 'tours/selectedRoomIndex',
            isShowMobileMenu: 'config/isShowMobileMenu'
        }),
        realisationTopLogo() {
            return this.isDarkTheme ? this.topLogoDarkPath : this.topLogoWhitePath;
        },
        menuRoutes() {
            const clonedRoutes = clone(routerController.getMenuRoutes());
            clonedRoutes.splice(clonedRoutes.length - 1, 0, {
                slot: this.$constants.injectedRouteNames.AVAILABILITY_ROUTE
            });
            clonedRoutes.push({ slot: this.$constants.injectedRouteNames.CONTACT_ROUTE });

            return clonedRoutes;
        }
    },
    methods: {
        ...mapMutations({
            setIsContactModalActive: 'projectExtend/setIsContactModalActive',
            setSelectedRoomIndex: 'tours/setSelectedRoomIndex',
            setIsShowMobileMenu: 'config/setIsShowMobileMenu'
        }),
        onContactFormHandler(event) {
            if (this.formAction === this.$constants.extendFormActions.SCROLL) {
                this.$route.path !== '/' && this.$router.replace('/');
                this.hideMenuHandler();

                return;
            }

            typeof event !== 'undefined' && event.preventDefault();
            this.setIsContactModalActive(!this.isContactModalActive);
        }
    }
};
