var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cl-progress",style:({
        backgroundColor: _vm.blockData.backgroundColor,
    })},[_c('div',{staticClass:"cl-progress-container"},[_c('div',{staticClass:"cl-progress__name",style:({
                color: _vm.elements.title.color,
            }),domProps:{"innerHTML":_vm._s(_vm.elements.title.text)}}),_vm._v(" "),_c('div',{staticClass:"cl-progress__points"},[_vm._l((_vm.elements.points.points),function(point,index){return _c('div',{key:index,class:[
                    'cl-progress__points-child',
                    {
                        active: point.active,
                    },
                ]},[_c('p',{staticClass:"cl-progress__points-child__text",style:({
                        color: _vm.blockData.color,
                    }),domProps:{"innerHTML":_vm._s(point.text)}}),_vm._v(" "),(point.image)?_c('img',{staticClass:"cl-progress__points-child-icon",attrs:{"src":_vm.pointImagePath({elementId: point.element, pointId: point.id, image: point.image}),"alt":_vm.altImgText}}):(point.icon)?_c('div',{staticClass:"cl-progress__points-child-icon",style:({
                        backgroundColor: _vm.blockData.backgroundColor,
                    })},[_c('fa-icon',{attrs:{"icon":point.icon}})],1):_c('div',{staticClass:"cl-progress__points-child-placeholder",style:({
                        backgroundColor: _vm.blockData.backgroundColor,
                        borderColor: _vm.blockData.color,
                    })})])}),_vm._v(" "),_c('div',{staticClass:"line",style:({
                    backgroundColor: _vm.blockData.color,
                })})],2)])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }