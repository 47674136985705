export default {
    externalLotsData(state) {
        return state.externalLotsData;
    },
    apiToken(state) {
        return state.apiToken;
    },
    cities(state) {
        return state.cities;
    },
    contacts(state) {
        return state.contacts;
    },
    isEmployee(state) {
        return !!state.apiToken;
    },
};
