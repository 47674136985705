<template>
    <div class="cl-logo">
        <img
            :src="`${imagePath}/${blockData.backgroundImage}`"
            :alt="altImgText"
        />
    </div>
</template>

<script>
import customLandingMixin from '@front/mixins/customLandingMixin';

export default {
    name: 'LogoBlock',
    mixins: [customLandingMixin],
};
</script>

<style lang="scss">
.cl-logo {
    text-align: center;
    padding: 25px 30px 25px 30px;

    img {
        max-width: 150px;
    }

    @media (max-width: 576px) {
        padding: 40px 30px;

        img {
            max-width: 100px;
        }
    }
}
</style>
