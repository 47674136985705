import {getField} from 'vuex-map-fields';

export default {
    getField,
    activeAdditionalWindow(state) {
        return state.activeAdditionalWindow;
    },
    galleryMode(state) {
        return state.galleryMode;
    },
    isBurgerWhiteShadow(state) {
        return state.isBurgerWhiteShadow;
    },
    isMobileMenuExpanded(state) {
        return state.isMobileMenuExpanded;
    },
    isCloseBurgerButtonHide(state) {
        return state.isCloseBurgerButtonHide;
    },
    isMenuVisible(state) {
        return state.isMenuVisible;
    },
};
