<template>
    <div class="scheme-lot-list__table--element">
        <div v-if="lot.rooms !== '0'">
            <div
                class="scheme-lot-list__table--element__button info add"
                @click="addToFavorites(lot)"
            >
                <fa-icon
                    v-if="isLotChoosed(lot)"
                    class="scheme-lot-list__table--icon"
                    icon="minus"
                />
                <fa-icon
                    v-else
                    icon="plus"
                    class="scheme-lot-list__table--icon"
                />
            </div>
            <p class="scheme-lot-list__table--element__particle">
                {{ lot.lot }}
            </p>
            <p
                :style="{color: lot.filterColor ? lot.filterColor.split('|')[0] : `#${lot.color}`}"
                class="scheme-lot-list__table--element__particle"
            >
                {{ lot.rooms }}
            </p>
            <p class="scheme-lot-list__table--element__particle">{{ lot.area }} м²</p>
            <p
                v-if="additionalLotInfo"
                class="scheme-lot-list__table--element__particle"
            >
                {{ priceFormat(additionalLotInfo.contractPrice || additionalLotInfo.price) }}
            </p>
        </div>
        <div v-else>
            <p class="scheme-lot-list__table--element__particle">
                {{ lot.lot }}
            </p>
        </div>
        <div
            class="scheme-lot-list__table--element__button info"
            @click="showOnScheme(lot)"
        >
            <fa-icon
                icon="map-marker"
                class="scheme-lot-list__table--icon"
            />
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {priceFormat} from '@front/utils/priceFormat';

export default {
    name: 'LotListElement',
    props: {
        lot: {
            required: true,
            type: Object,
        },
    },
    computed: {
        ...mapGetters({
            favorites: 'scheme/favorites',
            externalLotsData: 'externalApi/externalLotsData',
        }),
        additionalLotInfo() {
            return this.externalLotsData.find((x) => x.lot === this.lot.lot);
        },
    },
    methods: {
        priceFormat,
        ...mapActions({
            updateFavorites: 'scheme/updateFavorites',
        }),
        ...mapMutations({
            toggleActivityAdditionalWindow: 'navigation/toggleActivityAdditionalWindow',
            changeIsMenuMustBeStatic: 'navigation/changeIsMenuMustBeStatic',
            setIsMenuVisible: 'navigation/setIsMenuVisible',
            updateSelectedLot: 'scheme/updateSelectedLot',
            setIsCloseBurgerButtonHide: 'navigation/setIsCloseBurgerButtonHide',
        }),
        addToFavorites(lot) {
            this.updateFavorites(lot);
        },
        isLotChoosed(lot) {
            return !!this.favorites.find((favoriteLot) => favoriteLot?.lot === lot?.lot);
        },
        showOnScheme(lot) {
            this.changeIsMenuMustBeStatic(false);
            this.setIsCloseBurgerButtonHide(false);
            this.toggleActivityAdditionalWindow();
            this.setIsMenuVisible(false);
            this.updateSelectedLot(lot);
        },
    },
};
</script>

<style scoped></style>
