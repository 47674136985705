<template>
    <div class="aside-desktop-navigations">
        <HomeMenuRoutes
            v-if="$route.path === '/'"
            :menu-routes="menuRoutes"
            :is-dark-theme="isDarkTheme"
            :enable-lot-availability-page="enableLotAvailabilityPage"
            :enable-contact-form="enableContactForm"
            :form-anchor="formAnchor"
            class="aside-desktop-navigations__home"
            @click="onContactFormHandler"
        />
        <PageLabel v-else />
    </div>
</template>

<script>
import homeMenuMixin from '@front/mixins/homeMenuMixin';
import PageLabel from '@front/components/T02/AsideMenu/components/Navigations/PageLabel.vue';

export default {
    name: 'AsideDesktopNavigations',
    mixins: [homeMenuMixin],
    components: { PageLabel }
};
</script>

<style lang="scss" scoped>
.aside-desktop-navigations {
    display: flex;
    height: 100%;

    &__home {
        &::v-deep {
            > * {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 16px 20px;
                border-radius: 4px 4px 0 0;
                line-height: 0.8;
                transition: background 0.3s;

                &:not(:has(.menu-sub-hover)) {
                    border-radius: 4px;
                }

                .menu-link-t2-item {
                    background-color: transparent;
                }

                .menu-sub-hover {
                    top: 100%;
                    flex-direction: column;
                    border-radius: 0 0 4px 4px;
                    transition: all 0.3s;
                }

                .menu-link-t2__label {
                    display: inline-block;
                    transition: transform 0.3s;
                    white-space: nowrap;
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.5);

                    .menu-sub-hover {
                        display: flex;
                        background: rgba(0, 0, 0, 0.5);
                    }

                    .menu-link-t2__label {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
}
</style>
