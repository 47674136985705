export default {
    inserted(el, {value}) {
        if (!value) {
            return;
        }

        el.addEventListener('click', _scrollTo.bind(this, value));
    },
    unbind(el, {value}) {
        if (!value) {
            return;
        }

        el.removeEventListener('click', _scrollTo.bind(this, value));
    },
};

function _scrollTo(anchor, event) {
    event.preventDefault();

    // needed for scrolling in safari
    $('html, body').animate({scrollTop: $(anchor).offset().top}, 300);
}
