<template>
    <!-- <div
        v-if="!isCloseBurgerButtonHide"
        ref="menu"
        :class="['aside-menu__burger']"
        @mouseenter="enterHandler"
        @click="$eventBus.$emit('close-menu', true)"
    >
        <div
            v-if="!isMenuVisible"
            :class="[
                'aside-menu__inner',
                {
                    'aside-menu__inner--dark': isDarkTheme || isWhiteShadow,
                },
            ]"
        >
            <img
                v-if="!isDarkTheme"
                :src="$getImagePath('icons/burger-wt.svg')"
                :alt="altImgText"
            />
            <img
                v-else
                :src="$getImagePath('icons/burger-bt.svg')"
                :alt="altImgText"
            />
        </div>
        <div v-else>
            <img
                v-if="!isDarkTheme"
                :src="$getImagePath('icons/close-burger-bt.svg')"
                :alt="altImgText"
            />
            <img
                v-else
                :src="$getImagePath('icons/close-burger.svg')"
                :alt="altImgText"
            />
        </div>
        <slot />
    </div> -->
</template>

<script>
import { mapGetters } from 'vuex';
import menuOffTimeoutMixin from '@front/mixins/menuOffTimeoutMixin';

export default {
    name: 'AsideMenuBurger',
    mixins: [menuOffTimeoutMixin],
    computed: {
        ...mapGetters({
            isDarkTheme: 'theme/isDarkTheme',
            isWhiteShadow: 'navigation/isBurgerWhiteShadow',
            isCloseBurgerButtonHide: 'navigation/isCloseBurgerButtonHide',
            altImgText: 'config/altImgText',
        }),
    },
};
</script>
