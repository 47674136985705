<template>
    <div
        @drop="$emit('drop', $event)"
        @dragover.prevent
        @dragenter.prevent
    >
        <div
            v-if="isCompare"
            class="modal__box"
        >
            <div
                v-if="!$isMobile()"
                style="height: 370px"
            >
                <object
                    v-if="!lot.image && lotInfo.document"
                    width="100%"
                    height="100%"
                    :data="
                        lotInfo.document && !lotInfo.document.startsWith('default')
                            ? getMediaPath(lotMediaPath, lotInfo.color, lotInfo.document)
                            : defaultPdf
                    "
                    type="application/pdf"
                />
                <img
                    v-else
                    :src="lotImagePaths"
                    :alt="altImgText"
                    class="modal__box__img"
                />
            </div>
            <img
                v-if="$isMobile()"
                :src="lotImagePaths"
                :alt="altImgText"
                class="modal__box__img"
            />
            <div class="modal__box-content">
                <div
                    class="modal__box-title"
                    :style="{color: hotspotColor}"
                >
                    <div class="lot-title">
                        <span class="lot-title__id">{{ lot.lot }}</span>
                    </div>
                    <p
                        v-if="isPriceFilterAvailable"
                        class="lot-price"
                    >
                        <template v-if="lotInfo.priceData">
                            <template v-for="(priceData, index) in lotInfo.priceData">
                                <span
                                    v-if="isPrice(priceData.value)"
                                    :key="priceData.tvaCode + index"
                                    class="lot-title__price"
                                >
                                    <span>{{ priceData.labelShort }}{{ priceData.tvaLabel }}:</span>
                                    <span>{{ priceFormat(priceData.value, priceData.unit) }}</span>
                                </span>
                            </template>
                        </template>
                        <template v-if="!lotInfo.priceData.length && isPrice(lotInfo.price)">
                            <span class="lot-title__price">{{ priceFormat(getPrice(lotInfo)) }} *</span>
                            <span class="table-descriptions">
                                (*) Prix annexe
                                {{ lotInfo && lotInfo.taxIncluded ? '' : 'non ' }}
                                inclus
                            </span>
                        </template>
                    </p>
                </div>
                <div class="table-descriptions">
                    <template v-for="description in descriptions">
                        <div
                            v-if="isDescriptionValueNotEmpty(lot[description.key])"
                            :key="description.key"
                            class="table-descriptions__item"
                        >
                            <div class="table-descriptions__title">{{ description.label }}:</div>
                            <div class="table-descriptions__key">
                                {{ formatDescriptionValue(lot[description.key]) }}
                                {{ description.postfix }}
                            </div>
                        </div>
                    </template>
                    <div
                        v-if="enableLotsComparison"
                        class="modal-optionner modal-optionner_table off-element"
                        @click="$emit('option-lot', lot)"
                    >
                        <p>Optionner ce lot</p>
                    </div>
                    <div
                        class="modal-optionner modal-remove"
                        @click="$emit('remove-lot', lot)"
                    >
                        <p>Effacer</p>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="not-picked-title"
        >
            Choisir le lot à comparer
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {priceFormat} from '@front/utils/priceFormat';
import priceLotsMixin from '@front/mixins/priceLotsMixin';
import {getMediaPath} from '@front/utils/getMediaPath';

export default {
    name: 'ComparatorField',
    mixins: [priceLotsMixin],
    props: {
        lot: {
            type: Object,
            default: () => null,
        },
        lotImagePaths: {
            type: [String, Array],
            default: () => '',
        },
        hotspotColor: {
            type: String,
            default: () => '',
        },
        lotInfo: {
            type: Object,
            default: () => null,
        },
        descriptions: {
            type: Array,
            default: () => [],
        },
        isCompare: {
            type: Boolean,
            default: () => false,
        },
    },
    data: () => ({
        defaultPdf: './data/project/scheme_module/uploads/default/lots/plan.pdf',
    }),
    computed: {
        ...mapGetters({
            enableLotsComparison: 'scheme/enableLotsComparison',
            isPriceFilterAvailable: 'scheme/isPriceFilterAvailable',
            altImgText: 'config/altImgText',
            lotMediaPath: 'scheme/lotMediaPath',
        }),
    },
    methods: {
        getMediaPath,
        priceFormat,
        formatDescriptionValue(descriptionValue) {
            const numberValue = parseFloat(descriptionValue);
            const isValueDecimal = !!String(numberValue).match(/[.]/);

            return isValueDecimal ? numberValue.toFixed(2) : descriptionValue;
        },
        isDescriptionValueNotEmpty(descriptionValue) {
            return Boolean(descriptionValue) && parseInt(descriptionValue) !== 0;
        },
    },
};
</script>
