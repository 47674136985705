<template>
    <div class="legales-wrapper wrapper" v-html="legalMentionHtml" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import menuOffTimeoutMixin from '../../app/mixins/menuOffTimeoutMixin';

export default {
    name: 'MentionLegales',
    mixins: [menuOffTimeoutMixin],
    metaInfo: {
        meta: [{ name: 'robots', content: 'noindex' }]
    },
    computed: {
        ...mapGetters({
            legalMentionHtml: 'config/legalMentionHtml'
        })
    },
    mounted() {
        window.scrollTo(0, 0);
        this.hideMenuHandler();
        this.loaderHide();
    },
    methods: {
        ...mapActions({
            loaderHide: 'loader/hide'
        })
    }
};
</script>

<style lang="scss">
$black: #000000b3;
$white: #fff;
$primary: #381e6b;
$secondary: #c8d660;
$chart: #f9f9f9;
.legales-wrapper {
    height: 100vh;
    max-width: 850px;
    margin: 0 auto;
    padding: 40px 16px;
    .heading {
        color: $primary;
        font-size: 24px;
        line-height: 1.4;
        font-family: 'HelveticaNeue', 'LatoRegular', sans-serif;
        font-weight: bolder;
        margin-bottom: 16px;
        @media (max-width: 576px) {
            font-size: 18px;
        }
        &--underline {
            text-decoration: underline;
        }
        &--italic {
            font-style: italic;
        }
    }
    p,
    ul,
    li {
        color: $black;
        font-family: 'HelveticaNeue', 'LatoRegular', sans-serif;
        font-size: 18px;
        line-height: 23.5px;
        padding-bottom: 20px;
        @media (max-width: 576px) {
            font-size: 16px;
        }
    }
    .text {
        &--red {
            color: #000000b3;
        }
    }
}
</style>
