import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// Load locale messages
function loadLocaleMessages() {
    try {
        const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
        const messages = locales.keys().reduce((messages, key) => {
            const locale = key.match(/([A-Za-z0-9-_]+)/i)[1];
            messages[locale] = locales(key);
            return messages;
        }, {});

        return messages;
    } catch (e) {
        console.log(e);
        return {};
    }
}

function loadLocaleMessagesOfThemes() {
    try {
        const locales = require.context('./project-extend', true, /locales\/[A-Za-z0-9-_,\s]+\.json$/i);
        const messages = locales.keys().reduce((messages, key) => {
            const matched = key.match(/locales\/([A-Za-z0-9-_]+)\.json$/);
            if (matched && matched[1]) {
                const locale = matched[1];
                const content = locales(key);
                messages[locale] = messages[locale] ? { ...messages[locale], ...content } : content;
            }
            return messages;
        }, {});

        return messages;
    } catch (e) {
        console.log(e);
        return {};
    }
}

function loadAndMergeLocaleMessages() {
    const messages = loadLocaleMessages();
    const messagesOfThemes = loadLocaleMessagesOfThemes();
    const mergedMessages = { ...messages };

    for (const locale in messagesOfThemes) {
        if (mergedMessages[locale]) {
            mergedMessages[locale] = { ...mergedMessages[locale], ...messagesOfThemes[locale] };
        } else {
            mergedMessages[locale] = messagesOfThemes[locale];
        }
    }

    console.log(mergedMessages);
    return mergedMessages;
}

// Create VueI18n instance with options
const urlParams = new URLSearchParams(window.location.search);
const curLang = urlParams.get('lang');
if (curLang) {
    localStorage.setItem('lang', curLang);
}

const userLang = navigator.language || navigator.userLanguage;
const defaultLang = userLang.split('-')[0] || 'fr';

window.missingKeyLang = [];
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || defaultLang,
    fallbackLocale: 'fr',
    messages: loadAndMergeLocaleMessages(),
    missing: (locale, key) => {
        if (!window.missingKeyLang.includes(key)) {
            window.missingKeyLang.push(key);
        }
    }
});

export default i18n;
