<template>
    <div
        class="cl-progress"
        :style="{
            backgroundColor: blockData.backgroundColor,
        }"
    >
        <div class="cl-progress-container">
            <div
                class="cl-progress__name"
                :style="{
                    color: elements.title.color,
                }"
                v-html="elements.title.text"
            />
            <div class="cl-progress__points">
                <div
                    v-for="(point, index) in elements.points.points"
                    :key="index"
                    :class="[
                        'cl-progress__points-child',
                        {
                            active: point.active,
                        },
                    ]"
                >
                    <p
                        class="cl-progress__points-child__text"
                        :style="{
                            color: blockData.color,
                        }"
                        v-html="point.text"
                    />
                    <img
                        v-if="point.image"
                        :src="pointImagePath({elementId: point.element, pointId: point.id, image: point.image})"
                        :alt="altImgText"
                        class="cl-progress__points-child-icon"
                    />
                    <div
                        v-else-if="point.icon"
                        class="cl-progress__points-child-icon"
                        :style="{
                            backgroundColor: blockData.backgroundColor,
                        }"
                    >
                        <fa-icon :icon="point.icon" />
                    </div>
                    <div
                        v-else
                        class="cl-progress__points-child-placeholder"
                        :style="{
                            backgroundColor: blockData.backgroundColor,
                            borderColor: blockData.color,
                        }"
                    />
                </div>
                <div
                    class="line"
                    :style="{
                        backgroundColor: blockData.color,
                    }"
                />
            </div>
        </div>
    </div>
</template>

<script>
import customLandingMixin from '@front/mixins/customLandingMixin';

export default {
    name: 'ProgressBlock',
    mixins: [customLandingMixin],
};
</script>

<style lang="scss" scoped>
.cl-progress {
    max-width: 1600px;
    width: 100%;
    margin: auto;
    padding: 90px 50px 90px 50px;
    position: relative;
    z-index: 1;
    background-color: var(--accent-color);

    ::v-deep {
        b,
        strong {
            font-weight: bold !important;
        }

        i,
        em {
            font-style: italic !important;
        }
    }

    @media (max-width: 992px) {
        display: flex;
    }

    @media (max-width: 768px) {
        padding: 50px 30px;
    }

    &-container {
        margin: auto;
        max-width: 1219px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        @media (max-width: 992px) {
            flex-direction: column;
            padding: 0;
            display: inline-flex;
            width: unset;
        }
    }

    &__name {
        font-size: 28px;
        letter-spacing: 0;
        line-height: 33px;
        text-align: right;
        padding-right: 50px;

        @media (max-width: 992px) {
            padding: 0 0 15px 0;
            text-align: center;
        }
    }

    &__points {
        display: flex;
        justify-content: space-between;
        max-width: 920px;
        width: 100%;
        padding: 0 18px;
        position: relative;
        color: var(--secondary-color);

        @media (max-width: 992px) {
            flex-direction: column;
            padding: 0;
            display: inline-flex;
            width: unset;
            padding: 15px 0;
        }

        &-child {
            display: flex;
            flex-direction: column;
            align-items: center;
            opacity: 0.5;

            @media (max-width: 992px) {
                flex-direction: row-reverse;
                justify-content: center;
                padding-bottom: 30px;

                &:last-child {
                    padding-bottom: 0;
                }
            }

            &__text {
                text-align: center;
                font-size: 16px;
                letter-spacing: 0.64px;
                line-height: 20px;
                height: 71px;
                display: flex;
                align-items: center;
                padding-bottom: 25px;
                max-width: 240px;
                overflow: hidden;

                @media (max-width: 992px) {
                    min-width: 198px;
                    padding-left: 20px;
                    justify-content: center;
                    padding-bottom: 0;
                    min-height: 46px;
                    width: 100%;
                }

                @media (max-width: 320px) {
                    padding-left: 10px;
                }
            }

            &.active {
                opacity: 1;
            }

            &-icon {
                width: 32px;
                height: 32px;
                object-fit: cover;
                position: relative;

                svg {
                    width: 32px;
                    height: 32px;
                }
            }

            &-placeholder {
                border-radius: 50%;
                width: 32px;
                height: 32px;
                border: 3px solid var(--secondary-color);
                position: relative;
            }
        }

        .line {
            position: absolute;
            left: 0;
            bottom: 14.5px;
            z-index: -1;
            width: 100%;
            height: 3px;
            background-color: var(--secondary-color);

            @media (max-width: 992px) {
                width: 3px;
                height: 100%;
                top: 0;
                left: 14.5px;
            }
        }
    }
}
</style>
