export default {
    slots: {
        documents: 'Gallery documents',
        video: 'Gallery video',
        sliderImages: 'Gallery slider images',
    },
    documents: {
        choseBrochure: 'Choose brochure',
    },
    video: {
        choseVideo: 'Choose video',
        choseVideoPoster: 'Choose video poster',
    },
    images: {
        tabs: {
            perspectives: {
                slotName: 'perspectives',
                tableTitle: 'Perspectives slides',
            },
            progress: {
                slotName: 'progress',
                tableTitle: 'Progress slides',
            },
            axos: {
                slotName: 'axos',
                tableTitle: 'Axos slides',
            },
        },
        tableFields: {
            title: 'Title',
            slide: 'Slide',
            actions: 'Actions',
        },
    },
    fieldsVisibilityCheckboxes: {
        isPerspectivesVisible: 'Enable perspectives visibility',
        enableProgressGallery: 'Enable progress visibility',
        enableAxonometryGallery: 'Enable axos visibility',
        isBrochureVisible: 'Enable brochure visibility',
        isVideosVisible: 'Enable video visibility',
    },
};
