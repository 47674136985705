export default {
    async getLocations({commit, getters}) {
        const config = await (await fetch('/data/project/localisation_module/config.json')).json();
        commit('setIsConfigLoaded', true);
        commit('setTours', config.tours || []);
        commit('setAssetsPath', config.assetsPath || '');
        commit('setHotspotsPath', config.hotspotsPath || '');
        commit('setRoomsPath', config.roomsPath || '');
        commit('setToursPath', config.toursPath || '');
        commit('setUploadsPath', config.uploadsPath || '');
        commit('setMediaItemsPath', config.mediaItemsPath || '');
        commit('setMediaItems', config.mediaItems || []);
        commit('setMaps', config.maps || []);
        commit('setGoogleMapsPath', config.googleMapsPath || '');
        commit('setQualityOptions', config.qualityOptions || '');

        if (getters.tours && getters.tours.length) {
            commit('setActiveEntity', getters.tours[0]?.rooms[0]);
            return
        }

        if (getters.mediaItems && getters.mediaItems.length) {
            commit('setActiveEntity', getters.mediaItems[0]);
            return;
        }

        if (getters.maps && getters.maps.length) {
            commit('setActiveEntity', getters.maps[0]);
            return;
        }
    },
};
