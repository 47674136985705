<template>
    <component v-if="hasActions" :is="currentPageAction" />
</template>

<script>
const COMPONENTS = ['HomePageActions', 'Scheme3dPageActions', 'ToursPageActions']; //, 'LocationPageActions'];

export default {
    name: 'AsideDesktopPagesActions',
    components: {
        HomePageActions: () =>
            import('@front/components/T02/AsideMenu/components/PageActions/Actions/HomePageActions.vue'),
        Scheme3dPageActions: () =>
            import('@front/components/T02/AsideMenu/components/PageActions/Actions/Scheme3dPageActions.vue'),
        LocationPageActions: () =>
            import('@front/components/T02/AsideMenu/components/PageActions/Actions/LocationPageActions.vue'),
        ToursPageActions: () =>
            import('@front/components/T02/AsideMenu/components/PageActions/Actions/ToursPageActions.vue')
    },
    computed: {
        hasActions() {
            console.log(this.$route.name);
            return COMPONENTS.some((cName) => cName.includes(this.$route.name));
        },
        currentPageAction() {
            return this.hasActions ? `${this.$route.name}PageActions` : null;
        }
    }
};
</script>

<style lang="scss" scoped></style>
