<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="30.05"
        viewBox="0 0 34.331 25.798"
    >
        <g transform="translate(-100.9 -797.576)">
            <path
                id="Path_563"
                data-name="Path 563"
                d="M31.287,0H2.845A2.848,2.848,0,0,0,0,2.844v19.91A2.847,2.847,0,0,0,2.845,25.6H31.287a2.847,2.847,0,0,0,2.844-2.844V2.844A2.847,2.847,0,0,0,31.287,0M2.845,1.422H31.287a1.366,1.366,0,0,1,.3.06C29.12,3.736,20.955,11.205,18.1,13.78a1.428,1.428,0,0,1-2.064,0C13.176,11.205,5.01,3.735,2.549,1.482a1.374,1.374,0,0,1,.3-.06M1.423,22.754V2.844A1.386,1.386,0,0,1,1.5,2.451c1.885,1.725,7.582,6.937,11.276,10.3C9.1,15.91,3.4,21.314,1.5,23.124a1.367,1.367,0,0,1-.074-.37m29.864,1.422H2.845a1.333,1.333,0,0,1-.321-.065c1.963-1.871,7.7-7.305,11.316-10.4.472.428.9.818,1.242,1.124a2.841,2.841,0,0,0,3.968,0c.34-.305.77-.7,1.243-1.124,3.617,3.095,9.352,8.527,11.315,10.4a1.326,1.326,0,0,1-.32.065m1.422-1.422a1.375,1.375,0,0,1-.075.37c-1.9-1.812-7.6-7.214-11.281-10.377,3.694-3.358,9.391-8.57,11.276-10.3a1.355,1.355,0,0,1,.08.393Z"
                transform="translate(101 797.676)"
                fill="#161615"
            />
            <path
                id="Path_563_-_Outline"
                data-name="Path 563 - Outline"
                d="M2.845-.1H31.287a2.947,2.947,0,0,1,2.944,2.944v19.91A2.947,2.947,0,0,1,31.287,25.7H2.845A2.948,2.948,0,0,1-.1,22.754V2.844A2.948,2.948,0,0,1,2.845-.1ZM31.287,25.5a2.747,2.747,0,0,0,2.744-2.744V2.844A2.747,2.747,0,0,0,31.287.1H2.845A2.748,2.748,0,0,0,.1,2.844v19.91A2.748,2.748,0,0,0,2.845,25.5ZM2.845,1.322H31.287a.832.832,0,0,1,.231.04c.028.008.057.016.086.022l.189.041-.143.13c-3.136,2.87-10.727,9.813-13.486,12.3a1.527,1.527,0,0,1-2.2,0C13.23,11.389,5.624,4.432,2.481,1.556l-.143-.13.189-.041c.029-.006.057-.014.085-.022A.841.841,0,0,1,2.845,1.322Zm28.534.211a.434.434,0,0,0-.092-.011H2.845a.438.438,0,0,0-.092.011C5.974,4.481,13.4,11.275,16.1,13.7a1.329,1.329,0,0,0,1.93,0C20.75,11.256,28.165,4.474,31.379,1.533ZM1.451,2.269l.494.452c2.222,2.033,7.426,6.794,10.9,9.952l.084.076-.086.074C9.253,15.907,3.761,21.105,1.566,23.2l-.121.115L1.4,23.151,1.385,23.1a1.207,1.207,0,0,1-.062-.342V2.844a1.325,1.325,0,0,1,.072-.384ZM12.627,12.745C9.153,9.586,4.014,4.885,1.81,2.868l-.256-.234a.838.838,0,0,0-.031.21v19.91a.752.752,0,0,0,.026.183C3.8,20.792,9.1,15.785,12.627,12.745ZM32.679,2.27l.055.183a1.324,1.324,0,0,1,.075.391v19.91a1.194,1.194,0,0,1-.062.339l-.017.058-.045.161-.121-.115c-1.7-1.624-7.551-7.173-11.277-10.374l-.086-.074.084-.076c3.746-3.4,9.577-8.741,11.276-10.3Zm-.1,20.667a.744.744,0,0,0,.027-.183V2.844a.831.831,0,0,0-.031-.21C30.756,4.3,25.169,9.413,21.5,12.745,25.153,15.887,30.753,21.194,32.583,22.937ZM13.842,13.578l.065.059c.471.428.9.818,1.242,1.124a2.741,2.741,0,0,0,3.834,0c.275-.247.609-.55.976-.883l.332-.3.067.057c3.7,3.164,9.6,8.763,11.319,10.4l.132.126-.177.044-.076.021a.967.967,0,0,1-.268.047H2.845a.964.964,0,0,1-.267-.047L2.5,24.208l-.178-.043.132-.126c2.216-2.112,7.754-7.353,11.32-10.4ZM31.409,24.06c-1.857-1.769-7.5-7.121-11.115-10.216l-.2.182c-.368.333-.7.636-.977.883a2.941,2.941,0,0,1-4.1,0c-.324-.292-.731-.66-1.177-1.065-3.58,3.068-9.25,8.439-11.116,10.215a.547.547,0,0,0,.123.016H31.287A.545.545,0,0,0,31.409,24.06Z"
                transform="translate(101 797.676)"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'SvgMessage',
};
</script>
