export default {
    isConfigLoad: false,
    assetsPath: null,
    brochure: null,
    enableAxonometryGallery: false,
    enableProgressGallery: false,
    galleries: null,
    isBrochureVisible: false,
    isPerspectivesVisible: false,
    isVideosVisible: false,
    qualityOptions: null,
    slidesPath: null,
    uploadsPath: null,
    videos: null,
    videosPath: null,
    currentImageQuality: 'original',
    currentVideoIndex: 0,
    activeComponent: {
        name: null,
        componentName: null,
    },
};
