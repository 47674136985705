<template>
    <div class="filter">
        <h4 class="inner-menu__title pl-0">
            {{ filter.title }}
        </h4>
        <div class="rooms__filter rooms__filter_typologie">
            <LotsFilterOption
                v-for="options in filter.options"
                :key="options.option"
                :options="options"
                :has-color-mapping="filter.hasColorMapping"
                @toggle="$emit('toggle', $event)"
            />
            <slot />
        </div>
    </div>
</template>

<script>
import LotsFilterOption from '@front/components/Filters/LotsFilter/LotsFilterOption.vue';

export default {
    name: 'LotsFilter',
    components: {
        LotsFilterOption,
    },
    props: {
        filter: {
            type: Object,
            required: true,
        },
    },
    emits: ['toggle'],
};
</script>
