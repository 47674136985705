<template>
    <div class="inner-menu-t2 inner-menu-t2_kauf">
        <ul class="tour-sublist" :class="{ 'hide-on-mobile': !isShowMobileMenu }">
            <template v-for="({ title, activityFlag, name, componentName }, index) in menu">
                <li v-if="$store.getters[`gallery/${activityFlag}`]" :key="title + index" class="tour-sublist__item">
                    <button
                        :class="[
                            'tour-sublist__item__router',
                            {
                                'tour-sublist__item__router--active': name === activeComponent.name
                            }
                        ]"
                        @click="onSetActiveComponent({ name, componentName })"
                    >
                        <span v-show="name === activeComponent.name" class="bullet" />
                        <span class="aside-menu-localisation-element-padding gallery-menu__title abc">
                            {{ title }}
                        </span>
                    </button>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { menu } from '@front/models/gallery-menu';
import homeMenuMixin from '@front/mixins/homeMenuMixin';

export default {
    name: 'GalleryMenu',
    mixins: [homeMenuMixin],
    computed: {
        ...mapGetters({
            activeComponent: 'gallery/activeComponent'
        }),
        menu() {
            return menu;
        }
    },
    methods: {
        ...mapMutations({
            setActiveComponent: 'gallery/setActiveComponent'
        }),
        onSetActiveComponent(data) {
            this.setIsShowMobileMenu(false);
            this.setActiveComponent(data);
        }
    }
};
</script>

<style lang="scss" scoped>
.gallery-menu__title {
    font-size: 16px;
    font-weight: bolder;
    letter-spacing: 1px;
    font-weight: bolder;
    letter-spacing: 1px;
}

.tour-sublist__item__router {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    gap: 0px;
    padding: 0px;

    &--active {
        margin-left: -9px;
    }

    .bullet {
        display: inline-block;
        border-radius: 50%;
        background-color: var(--elementColor);
        width: 5px;
        height: 5px;
    }

    &:hover {
        .gallery-menu__title {
            text-decoration: underline;
            text-decoration-thickness: 2px;
        }
    }
}

.aside-menu-localisation-element-padding {
    margin-left: 0 !important;
}
</style>
