/**
 * @param {string} path
 * @param {boolean} isPublicPath
 * @return {string}
 */
export function getImagePath(path = '', isPublicPath = false) {
    try {
        return require(`../../../images/${path}`);
    } catch (error) {
        console.error(error);

        return '';
    }
}
