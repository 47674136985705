<template>
    <div ref="sideMenu" :class="['aside-menu-desktop-t2']">
        <div class="aside-menu-desktop-t2-wrapper">
            <router-link
                class="aside-menu-desktop-t2__logo"
                :class="{ 'is-home-template': $route.meta.menuTemplate === 'HomeMenu' }"
                to="/"
            >
                <img
                    v-if="realisationTopLogo"
                    class="aside-menu-desktop-t2__logo-img"
                    :src="realisationTopLogo"
                    alt="Logo"
                />
                <span class="aside-menu-desktop-t2__logo-text">
                    {{ programCity }}
                </span>
            </router-link>

            <div class="aside-menu-desktop-t2__actions">
                <div class="aside-menu-desktop-t2__actions-navigation">
                    <Navigations />
                    <template v-if="$route.name === 'Scheme3d'">
                        <Scheme3dMenuDropdown />
                        <ToggleAllHotspots>Afficher tous les lots</ToggleAllHotspots>
                        <AsideDesktopFilters />
                    </template>

                    <template v-if="$route.name === 'Location'">
                        <LocationMenuDropdown />
                    </template>

                    <template v-if="$route.name === 'Tours'">
                        <ToursMenuDropdown />
                    </template>

                    <template v-if="$route.name === 'Gallery'">
                        <GalleryMenuDropdown />
                    </template>
                </div>
                <div class="aside-menu-desktop-t2__actions-common">
                    <template v-if="$route.name === 'Scheme3d'">
                        <UIDropdown
                            v-if="isFloorPlanAvaliable && isToggleViewer"
                            :value="selectedFloorOption.value"
                            :options="floorSelectOptions"
                            @input="onSelectFloorHandler"
                        />
                        <UIDropdown
                            :value="!isToggleViewer ? 'Vue maquette 3D' : 'Vue plan 2D'"
                            :options="viewsOptions"
                            @input="(v) => setIsToggleViewer(v === 'Vue maquette 3D' ? false : true)"
                        >
                            <template #icon>
                                <img :src="$getImagePath(`svg/new-menu-icons/eye.svg`)" />
                            </template>
                        </UIDropdown>
                    </template>
                    <Contacts />
                </div>
                <div class="aside-menu-desktop-t2__actions-pages">
                    <AsideDesktopPagesActions />
                </div>
            </div>

            <!-- Modals components -->
            <LotList v-if="activeAdditionalWindow === $constants.additionalWindows.LOTS_LIST" />

            <transition name="fade" mode="out-in">
                <ModalComparator v-if="isComparatorVisible && !isOptionsOpen" @close="toggleComparator" />
            </transition>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { Icon } from '@iconify/vue2';
import menuOffTimeoutMixin from '@front/mixins/menuOffTimeoutMixin';
import homeMenuMixin from '@front/mixins/homeMenuMixin';
import viewersMixin from '@front/mixins/viewersMixin';
import scheme3dMenuFiltersMixin from '@front/mixins/scheme3dMenuFiltersMixin';
import floorSelectMixin from '@front/mixins/floorSelectMixin';
import scheme3dMenuMixin from '@front/mixins/scheme3dMenuMixin';
import UIButton from '@front/components/UI/UIButton';
import ModalComparator from '@front/components/Comparator/ModalComparator';
import IconBar from '@front/components/T02/AsideMenu/IconBar';
import LotsFilter from '@front/components/Filters/LotsFilter/LotsFilter.vue';
import LotsRangeFilter from '@front/components/Filters/LotsFilter/LotsRangeFilter.vue';
import LotList from '@front/components/Scheme3dLotInfo/LotList/LotList';
import UISelect from '@front/components/UI/UISelect';

import Navigations from '@front/components/T02/AsideMenu/components/Navigations/Navigations.vue';
import Contacts from '@front/components/T02/AsideMenu/components/CommonActions/Contacts.vue';
import AsideDesktopPagesActions from '@front/components/T02/AsideMenu/components/PageActions/PagesActions.vue';
import AsideDesktopFilters from '@front/components/T02/AsideMenu/components/Navigations/Filters.vue';
import UIDropdown from '@front/components/UI/UIDropdown.vue';
import ToggleAllHotspots from '@front/components/Viewers/particles/ToggleAllHotspots.vue';
import LocationMenuDropdown from '../Templates/LocationMenu/LocationMenuDropdown.vue';
import ToursMenuDropdown from '../Templates/ToursMenuDropdown.vue';
import GalleryMenuDropdown from '../Templates/GalleryMenuDropdown.vue';
import Scheme3dMenuDropdown from '../Templates/Scheme3dMenuDropdown.vue';

export default {
    name: 'AsideMenu',
    components: {
        IconBar,
        Icon,
        UIButton,
        ModalComparator,
        LotsFilter,
        LotsRangeFilter,
        LotList,
        UISelect,

        Navigations,
        Contacts,
        AsideDesktopPagesActions,
        UIDropdown,
        AsideDesktopFilters,
        ToggleAllHotspots,

        LocationMenuDropdown,
        ToursMenuDropdown,
        GalleryMenuDropdown,
        Scheme3dMenuDropdown
    },
    mixins: [
        menuOffTimeoutMixin,
        homeMenuMixin,
        viewersMixin,
        scheme3dMenuFiltersMixin,
        scheme3dMenuMixin,
        floorSelectMixin
    ],
    data() {
        return {
            isComparatorVisible: false,
            isMasquer: false
        };
    },
    watch: {
        $route() {
            this.setIsShowMobileMenu(false);
        }
    },
    computed: {
        ...mapFields('navigation', {
            isMenuMustBeStatic: 'isMenuMustBeStatic',
            activeAdditionalWindow: 'activeAdditionalWindow'
        }),
        ...mapGetters({
            isMobileMenuExpanded: 'navigation/isMobileMenuExpanded',
            altImgText: 'config/altImgText',
            enableChangeColorTheme: 'config/enableChangeColorTheme',
            programCity: 'config/programCity',
            backgroundWhiteMenuImagePath: 'config/backgroundWhiteMenuImagePath',
            backgroundDarkMenuImagePath: 'config/backgroundDarkMenuImagePath',

            // Contacts
            enableContactSidebar: 'config/enableContactSidebar',

            telephoneNumber: 'config/telephoneNumber',
            whatsappNumber: 'config/whatsappNumber',
            contactSidebarBackground: 'config/contactSidebarBackground',
            enableContactForm: 'config/enableContactForm',
            isDarkTheme: 'theme/isDarkTheme',
            formAction: 'projectExtend/formAction',
            formAnchor: 'projectExtend/formAnchor',

            enableLotsVisibility: 'scheme/enableLotsVisibility',
            enableLotsComparison: 'scheme/enableLotsComparison',
            favorites: 'scheme/favorites',
            isFloorPlanAvaliable: 'scheme/isFloorPlanAvaliable',
            enableScheme3dDisplay: 'scheme/enableScheme3dDisplay',
            isToggleViewer: 'scheme/isToggleViewer'
        }),
        viewsOptions() {
            if (this.enableScheme3dDisplay && !this.isFloorPlanAvaliable) return [{ value: 'Vue maquette 3D' }];
            if (!this.enableScheme3dDisplay && this.isFloorPlanAvaliable) return [{ value: 'Vue plan 2D' }];

            return this.enableScheme3dDisplay && this.isFloorPlanAvaliable
                ? [{ value: 'Vue maquette 3D' }, { value: 'Vue plan 2D' }]
                : [];
        },
        menuTemplate() {
            return this.$route.meta.menuTemplate;
        },
        isComparisonAvailable() {
            if (!this.enableLotsVisibility) {
                return false;
            }

            // this.menuTemplate !== 'ToursMenu' &&
            if (this.menuTemplate !== 'Scheme3dMenu') {
                return false;
                // return (
                //     this.$constants.mainPageTemplates.SLIDER_VIEW === this.mainPageTemplate
                // && this.enableLotsComparison
                // );
            }

            return this.enableLotsComparison;
        },
        isShadowBlockRenderer() {
            return this.$isMobile() && this.$route.path.includes('scheme3d');
        },
        backgroundImagePath() {
            return this.isDarkTheme ? this.backgroundDarkMenuImagePath : this.backgroundWhiteMenuImagePath;
        }
    },
    methods: {
        ...mapMutations({
            setIsCloseBurgerButtonHide: 'navigation/setIsCloseBurgerButtonHide',
            toggleActivityAdditionalWindow: 'navigation/toggleActivityAdditionalWindow',
            setFormAnchor: 'projectExtend/setFormAnchor',
            setFormAction: 'projectExtend/setFormAction',
            setIsToggleViewer: 'scheme/setIsToggleViewer'
        }),
        ...mapActions({
            setThemeColors: 'theme/setThemeColors'
        }),
        toggleComparator() {
            this.isComparatorVisible = !this.isComparatorVisible;
        },
        leave() {
            if (this.isMenuMustBeStatic) return;

            this.leaveHandler();
        },
        onClickMasquer() {
            this.isMasquer = !this.isMasquer;
        },
        onClickModel() {
            this.$modal.show('modal-plan');
        },
        onClickMobileMenu() {
            if (this.isOptionsOpen) {
                this.isOptionsOpen = false;
                this.setIsShowMobileMenu(false);
            } else {
                this.setIsShowMobileMenu(!this.isShowMobileMenu);
            }
        },
        onClickHandler(type) {
            const { name, counter } = GA_EVENTS[type];

            gtag('event', 'submit', {
                event_category: name,
                event_label: name
            });
            gtag('event', 'conversion', {
                send_to: counter
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.aside-menu-desktop-t2 {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));

    &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        margin: 0 auto;
        max-width: 1700px;
        padding: 48px 24px;
    }

    &__logo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 200px;
        max-height: 80px;

        &-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: left;
        }

        &-text {
            display: none;
            font-size: 12px;
        }
    }

    &__actions {
        flex: 1;
        display: flex;
        align-items: stretch;
        justify-content: flex-end;
        gap: 16px;
        height: 45px;

        &-common {
            display: flex;
            gap: 16px;
            height: 100%;
        }

        &-navigation {
            display: flex;
            gap: 16px;
            height: 100%;
        }

        &-pages:not(:has(*)) {
            display: none;
        }
    }
}
</style>
