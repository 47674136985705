export default {
  setTours(state, payload) {
    state.tours = payload.filter((tour) => tour.enabled);
  },
  setTourshotspots(state, payload) {
    state.tours = payload.filter((tour) => tour.enabled);
  },
  setSelectedRoomIndex(state, index) {
    state.selectedRoomIndex = index;
  },
  setSelectedTourIndex(state, index) {
    state.selectedTourIndex = index;
    state.selectedRoomIndex = 0;
  },
  setAssetsPath(state, path) {
    state.assetsPath = path;
  },
  setHotspotsPath(state, path) {
    state.hotspotsPath = path;
  },
  setRoomsPath(state, path) {
    state.roomsPath = path;
  },
  setToursPath(state, path) {
    state.toursPath = path;
  },
  setUploadsPath(state, path) {
    state.uploadsPath = path;
  },
  setMediaItemsPath(state, path) {
    state.mediaItemsPath = path;
  },
  setMediaItems(state, payload) {
    state.mediaItems = payload.filter((mediaItem) => mediaItem.enabled);
  },
  setGoogleMapsPath(state, path) {
    state.googleMapsPath = path;
  },
  setMaps(state, payload) {
    state.maps = payload.filter((map) => map.enabled);
  },
  updateTourRoomHotspots: (state, { tourIndex, roomIndex, hotspotIndex }) => {
    // Turn off all
    state.tours[tourIndex].rooms[roomIndex].hotspots.forEach(ele => (ele.visible = false));
    // Turn on
    state.tours[tourIndex].rooms[roomIndex].hotspots[hotspotIndex].visible = true;

    // Update state
    state.selectedRoomIndex = roomIndex
    state.selectedTourIndex = tourIndex
    state.activeEntity = state.tours[tourIndex].rooms[roomIndex];
    state.tours = [...state.tours]
  },
  hideAllRoomHotspots(state, { tourIndex, roomIndex }) {
    state.tours[tourIndex].rooms.forEach((room) => room.hotspots.forEach((hotspot) => (hotspot.visible = false)));
  },
  showAllRoomHotspots(state, { tourIndex, roomIndex }) {
    state.tours[tourIndex].rooms.forEach((room) => room.hotspots.forEach((hotspot) => (hotspot.visible = true)));
  },
  setActiveEntity(state, entity) {
    state.activeEntity = entity;
  },
  setIsConfigLoaded(state, bool) {
    state.isConfigLoaded = bool;
  },
  setQualityOptions(state, payload) {
    state.qualityOptions = payload;
  },
  setCurrentImageQuality(state, payload) {
    state.currentImageQuality = payload;
  },
  enterVR(state) {
    var krpano = document.getElementById('locationPano');
    krpano?.call("webvr.enterVR()");
  }
};
