<template>
    <div
        :class="['cookiealert', {show}]"
        role="alert"
    >
        <span class="mobile">
            En utilisant ce site, vous acceptez l'utilisation des cookies à des fins d'analyse. Pour en savoir plus
            <a
                href="/mentions-legales"
                target="_blank"
            >
                cliquez ici.
            </a>
        </span>
        <button
            type="button"
            class="acceptcookies"
            @click="$emit('accept')"
        >
            J'accepte
        </button>
        <button
            type="button"
            class="refusecookies"
            @click="$emit('refuse')"
        >
            Je refuse
        </button>
    </div>
</template>

<script>
export default {
    name: 'CookieAlert',
    props: {
        show: {
            type: Boolean,
            default: () => false,
        },
    },
    emits: ['accept', 'refuse'],
};
</script>

<style lang="scss" scoped>
.cookiealert {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 !important;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    border-radius: 0;
    transform: translateY(100%);
    transition: all 500ms ease-out;
    color: #ecf0f1;
    background: #212327;
    padding: 10px 25px;
    text-align: center;
    font-size: 14px;
    font-family: Arial;

    & > .mobile {
        display: inline-block;
        @media screen and (max-width: 768px) {
            display: block;
        }
    }
}

.cookiealert.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0%);
    transition-delay: 1000ms;
}

.cookiealert a {
    text-decoration: underline;
    color: #fff;
}

.cookiealert .acceptcookies {
    background-color: #e3a534;
    border: 1px solid #e3a534;
}

.cookiealert .refusecookies {
    background-color: #ecf0f1;
    color: #212327;
}

.cookiealert .acceptcookies,
.cookiealert .refusecookies {
    margin: 8px 0 0 8px;
    padding: 6px 15px;
    border-radius: 10px;
    box-shadow: none;
    border: none;
    font-size: 14px;
    font-family: arial;
}
</style>
