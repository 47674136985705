import { mapGetters, mapMutations } from 'vuex';

export default {
    data() {
        return {
            currentFloors: []
        };
    },
    computed: {
        ...mapGetters({
            enableFloorPlanDisplay: 'scheme/enableFloorPlanDisplay',
            enableScheme3dDisplay: 'scheme/enableScheme3dDisplay',
            currentSchemeView: 'scheme/currentSchemeView',
            currentSchemeViewDelayed: 'scheme/currentSchemeViewDelayed',
            schemeData: 'scheme/schemeData',
            selectedFloor: 'scheme/selectedFloor'
        }),
        floorSelectOptions() {
            return this.currentFloors.map(({ title }) => {
                return {
                    label: title,
                    value: title
                };
            });
        },
        selectedFloorOption() {
            return this.floorSelectOptions.find((option) => option.value === this.selectedFloor);
        }
    },
    watch: {
        schemeData(newValue) {
            const isBreak = this.enableScheme3dDisplay ? !this.enableFloorPlanDisplay : false;
            if (!newValue || isBreak) {
                return;
            }

            this.currentFloors = this.schemeData.schemes[this.currentSchemeView].floors;
            this.onSelectFloorHandler({ value: this.currentFloors[0].title });
        }
    },
    methods: {
        ...mapMutations({
            setSelectedFloor: 'scheme/setSelectedFloor',
            setFloorLoaded: 'scheme/setFloorLoaded',
            setCurrentSchemeViewDelayed: 'scheme/setCurrentSchemeViewDelayed'
        }),
        onSelectFloorHandler(selected) {
            if (this.selectedFloor !== (selected.value || selected)) {
                this.setFloorLoaded(false);
                this.setSelectedFloor(selected.value || selected);
            }
        }
    }
};
