<template>
    <div
        v-if="isContactDataExist"
        :class="['v360-contact-icons', {'v360-contact-icons--mobile': $isMobile()}]"
    >
        <template v-if="enableContactForm && (isScrollToFormAction || isOpenFormAction)">
            <a
                v-if="isScrollToFormAction"
                v-scroll-to="formAnchor"
                :href="formAnchor"
                class="v360-contact-icons-animate v360-contact-icons__icon v360-contact-icons__icon--mail"
            >
                <SvgMessage />
            </a>
            <button
                v-if="isOpenFormAction"
                type="button"
                class="v360-contact-icons-animate v360-contact-icons__icon v360-contact-icons__icon--mail"
                @click="setIsContactModalActive(true)"
            >
                <SvgMessage />
            </button>
        </template>
        <a
            v-if="telephoneNumber"
            :href="`tel:+${sanitizeNumber(telephoneNumber)}`"
            class="v360-contact-icons__icon v360-contact-icons__icon--phone"
            :style="`background-color: ${contactSidebarBackground}`"
        >
            <SvgPhone
                class="v360-contact-icons-animate"
                :fill="isDarkTheme ? 'white' : 'black'"
            />
            <span
                class="v360-contact-icons__icon-inner"
                :style="{color: isDarkTheme ? 'white' : 'black'}"
            >
                {{ telephoneNumber }}
            </span>
        </a>
        <a
            v-if="whatsappNumber"
            :href="`https://wa.me/${sanitizeNumber(whatsappNumber)}`"
            target="_blank"
            rel="noopener noreferrer"
            class="v360-contact-icons__icon v360-contact-icons__icon--whatsapp"
            :style="`background-color: ${contactSidebarBackground}`"
        >
            <SvgWhatsapp
                class="v360-contact-icons-animate"
                :fill="isDarkTheme ? 'white' : 'black'"
            />
            <span
                class="v360-contact-icons__icon-inner"
                :style="{color: isDarkTheme ? 'white' : 'black'}"
            >
                {{ whatsappNumber }}
            </span>
        </a>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import SvgMessage from '@front/components/Svg/Message.vue';
import SvgPhone from '@front/components/Svg/Phone.vue';
import SvgWhatsapp from '@front/components/Svg/Whatsapp.vue';

const GA_EVENTS = {
    phone: {
        name: 'click_tel',
        counter: 'AW-11077019800/vlL_COaauokYEJjR96Ep',
    },
    whatsapp: {
        name: 'click_whatsapp',
        counter: 'AW-11077019800/8Qr-COmauokYEJjR96Ep',
    },
};

export default {
    name: 'CommunicationPanel',
    components: {
        SvgMessage,
        SvgPhone,
        SvgWhatsapp,
    },
    computed: {
        ...mapGetters({
            telephoneNumber: 'config/telephoneNumber',
            whatsappNumber: 'config/whatsappNumber',
            contactSidebarBackground: 'config/contactSidebarBackground',
            enableContactForm: 'config/enableContactForm',
            isDarkTheme: 'theme/isDarkTheme',
            formAction: 'projectExtend/formAction',
            formAnchor: 'projectExtend/formAnchor',
        }),
        isContactDataExist() {
            return this.enableContactForm || this.telephoneNumber || this.whatsappNumber;
        },
        isScrollToFormAction() {
            return this.formAction === this.$constants.extendFormActions.SCROLL;
        },
        isOpenFormAction() {
            return this.formAction === this.$constants.extendFormActions.OPEN;
        },
    },
    methods: {
        ...mapMutations({
            setIsContactModalActive: 'projectExtend/setIsContactModalActive',
        }),
        sanitizeNumber(phoneNumber) {
            return phoneNumber.replace(/[^0-9]/g, '');
        },
        onClickHandler(type) {
            const {name, counter} = GA_EVENTS[type];

            gtag('event', 'submit', {
                event_category: name,
                event_label: name,
            });
            gtag('event', 'conversion', {
                send_to: counter,
            });
        },
    },
};
</script>

<style lang="scss">
@import '@vue/styles/vars';
@import '@vue/styles/components/communication-panel';
</style>
