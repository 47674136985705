<template>
    <div
        class="modal-info-backdrop"
        @mouseenter="enterHandler"
        @click.self="$emit('close')"
    >
        <div class="modal-info">
            <section class="modal-info-body">
                <slot name="body">
                    <div class="lot-info__wrapper">
                        <div class="lot-info--container">
                            <!-- <div class="back-btn" @click="$emit('close')"></div> -->
                            <div class="lot-info__header">
                                <div class="header__left">
                                    <img
                                        v-if="!isDarkTheme"
                                        :src="$getImagePath('icons/men-icon-wt.svg')"
                                        :alt="altImgText"
                                    />
                                    <img
                                        v-if="isDarkTheme"
                                        :src="$getImagePath('icons/men-icon-bt.svg')"
                                        :alt="altImgText"
                                    />
                                    <p class="modal-header__title">OPTIONNER</p>
                                </div>
                                <div class="header__right">
                                    <button
                                        type="button"
                                        class="btn-close"
                                        @click="$emit('close')"
                                    >
                                        <img
                                            v-if="!isDarkTheme"
                                            :src="$getImagePath('icons/icon-close-black.svg')"
                                            :alt="altImgText"
                                        />
                                        <img
                                            v-else
                                            :src="$getImagePath('icons/icon-close.svg')"
                                            :alt="altImgText"
                                        />
                                    </button>
                                </div>
                            </div>
                            <div class="lot-info__content">
                                <p
                                    class="lot-info__number lot-info__number--optioner"
                                    :style="{color: hotspotColor(lotInfo)}"
                                >
                                    <span class="lot-info__number--optionner">{{ lotInfo.lot }}</span>
                                </p>
                                <div class="table-info-descriptions">
                                    <div
                                        v-for="description in descriptions"
                                        :key="description.key"
                                        class="table-info-descriptions__item"
                                    >
                                        <div class="table-descriptions__title">
                                            {{ description.label }}
                                        </div>
                                        <div class="table-descriptions__key">
                                            {{ lotInfo[description.key] }}
                                            <span class="table-descriptions__postfix">{{ description.postfix }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="lot-info__img">
                                <img
                                    :src="imagePath"
                                    :alt="altImgText"
                                />
                            </div>
                        </div>
                    </div>
                    <form
                        v-if="!isOffline"
                        ref="form"
                        class="lot-info__form"
                    >
                        <div class="lot-info__btns-wrapper">
                            <div
                                class="lot-info__btn lot-info__btn--right"
                                :class="{active: model.type === 'être contacté'}"
                                @click="model.type = 'être contacté'"
                            >
                                être contacté
                            </div>
                            <div
                                class="lot-info__btn lot-info__btn--right"
                                :class="{active: model.type === 'rendez-vous'}"
                                @click="model.type = 'rendez-vous'"
                            >
                                RENDEZ-VOUS
                            </div>
                            <!-- <div
                                class="lot-info__btn lot-info__btn--right"
                                @click="activeButton = 'chattez'"
                                :class="{ active: activeButton === 'chattez' }"
                            >
                                CHATTEZ
                            </div> -->
                        </div>
                        <div class="lot-info__text">
                            VOUS SOUHAITEZ VENIR NOUS RENCONTRER
                            <span class="regular">SUR NOTRE BUREAU DE VENTE</span>
                        </div>
                        <div style="display: block">
                            <div class="feedback-form__input">
                                <input
                                    v-model.trim="model.name"
                                    type="text"
                                    placeholder="NOM"
                                    class="full-width"
                                />
                                <p class="feedback-form__error-label">
                                    {{ errors.name }}
                                </p>
                            </div>
                            <div class="feedback-form__input">
                                <input
                                    v-model.trim="model.surname"
                                    type="text"
                                    placeholder="PRÉNOM"
                                    class="full-width"
                                />
                                <p class="feedback-form__error-label">
                                    {{ errors.surname }}
                                </p>
                            </div>
                            <div class="feedback-form__input">
                                <vue-tel-input
                                    v-model="model.phone"
                                    :input-options="{placeholder: 'SAISIR UN TÉLÉPHONE'}"
                                />
                                <p class="feedback-form__error-label">
                                    {{ errors.phone }}
                                </p>
                            </div>
                            <div class="feedback-form__input">
                                <input
                                    v-model.trim="model.address"
                                    type="text"
                                    placeholder="EMAIL"
                                    class="full-width"
                                />
                                <p class="feedback-form__error-label">
                                    {{ errors.address }}
                                </p>
                            </div>
                            <div class="feedback-form__input flex">
                                <p class="date-label">Date</p>
                                <button
                                    type="button"
                                    class="feedback-form__date-button"
                                    @click="isDatePickerVisible = !isDatePickerVisible"
                                >
                                    <fa-icon icon="calendar" />
                                </button>
                                <p class="date-format">
                                    {{ formattedDate }}
                                </p>
                                <div
                                    v-if="isDatePickerVisible"
                                    class="date-picker"
                                    @mouseleave="isDatePickerVisible = false"
                                >
                                    <date-picker
                                        v-model="date"
                                        locale="fr"
                                        @dayfocusout="isDatePickerVisible = false"
                                    />
                                </div>
                            </div>
                            <p class="lot-info__text-info">
                                Vous allez recevoir un mail récapitulatif de votre demande, souhaitez vouz y joindre les
                                éléments suivants :
                            </p>
                            <!-- <label
                                class="feedback-form__radio no-margin"
                                for="détaillée"
                            >
                                <span class="feedback-form__custom-radio">
                                    <span
                                        class="feedback-form__custom-rad"
                                        :class="{ active: model.includeDetails }"
                                    >
                                    </span>
                                </span>
                                <input
                                    v-model="model.includeDetails"
                                    name="type"
                                    value="details"
                                    type="checkbox"
                                    id="détaillée"
                                    class="feedback-form__radio-input"
                                />
                                <div class="feedback-form__radio-button">
                                    <fa-icon icon="map"/>
                                </div>
                                <p class="label-form" >Fiche détaillée</p>
                            </label> -->
                            <label
                                class="feedback-form__radio no-margin"
                                for="plan"
                            >
                                <span class="feedback-form__custom-radio">
                                    <span
                                        class="feedback-form__custom-rad"
                                        :class="{active: model.includePlan}"
                                    />
                                </span>
                                <input
                                    id="plan"
                                    v-model="model.includePlan"
                                    style="-webkit-appearance: radio"
                                    name="type"
                                    value="plan"
                                    type="checkbox"
                                    class="feedback-form__radio-input"
                                />
                                <div class="feedback-form__radio-button">
                                    <fa-icon icon="info" />
                                </div>
                                <p class="label-form flex">Plan de l'appartement</p>
                            </label>
                        </div>
                        <div class="lot-info__buttons">
                            <div
                                class="lot-info__btn lot-info__btn--form"
                                @click="send"
                            >
                                <div class="lot-info__btn--wrapper lot-info__btn_prende">
                                    <div class="back-btn back-btn--right rotate" />
                                    {{ model.type === 'rendez-vous' ? `PRENDRE ${model.type}` : model.type }}
                                </div>
                            </div>
                        </div>
                    </form>
                    <p
                        v-else
                        class="offline-form"
                    >
                        Envoi désactivé
                    </p>
                </slot>
            </section>
            <p
                v-if="isSendSuccess"
                class="form-submitted"
            >
                Votre demande a bien été prise&nbsp; en compte. Un conseiller reviendra vers vous dans les plus brefs
                délais.
            </p>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import clone from 'lodash.clonedeep';
import roomsDescriptions from '@front/constants/rooms-descriptions';
import {buildType} from '@front/constants/index';
import menuOffTimeoutMixin from '@front/mixins/menuOffTimeoutMixin';
import notificationFormModel from '@front/models/notificationFormModel';

export default {
    name: 'ModalSchemeLotInfo',
    mixins: [menuOffTimeoutMixin],
    props: {
        lot: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            model: clone(notificationFormModel),
            date: new Date(),
            isDatePickerVisible: false,
            errors: {
                name: '',
                surname: '',
                date: '',
                address: '',
                phone: '',
            },
            isSendSuccess: false,
        };
    },
    computed: {
        ...mapGetters({
            lotInfo: 'scheme/lotInfo',
            isDarkTheme: 'theme/isDarkTheme',
            altImgText: 'config/altImgText',
        }),
        descriptions() {
            return roomsDescriptions;
        },
        imagePath() {
            return this.lotInfo.image
                ? `data/project/lots/${this.lotInfo.color.substring(1)}/${this.lotInfo.image}`
                : 'images/lot_default.png';
        },
        formattedDate() {
            return `${this.date.getDate()}/${this.date.getMonth() + 1}/${this.date.getFullYear()}`;
        },
        isOffline() {
            return buildType === 'offline';
        },
    },
    methods: {
        ...mapMutations({
            toggleActivityAdditionalWindow: 'navigation/toggleActivityAdditionalWindow',
        }),
        ...mapActions({
            createCallbackRequest: 'scheme/sendCallbackRequest',
        }),
        validateForm() {
            let flag = true;
            this.errors = {
                name: '',
                surname: '',
                date: '',
                address: '',
                phone: '',
            };

            const telRegex = /^([\(\)\+0-9\s\-\#]+)$/gi;
            const emailRegex = /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/gi;

            if (this.model.name === '') this.errors.name = 'Nom requis';
            if (this.model.surname === '') this.errors.surname = 'Prénom requis';
            if (!emailRegex.test(this.model.address)) this.errors.address = 'Email requis';
            if (!telRegex.test(this.model.phone)) this.errors.phone = 'Téléphone requis';

            Object.entries(this.errors).forEach((p) => {
                if (p[1] !== '') flag = false;
            });

            return flag;
        },
        send() {
            if (!this.validateForm()) return;
            const payload = Object.assign({}, this.model);
            payload.lot = this.lot.color.slice(1);
            payload.date = this.date;

            this.createCallbackRequest(payload)
                .then(() => {
                    this.model = clone(notificationFormModel);
                    this.isSendSuccess = true;
                })
                .catch(() => (this.isSendSuccess = false));
        },
        hotspotColor(lot) {
            if (!lot) {
                return this.$constants.defaultLotColor;
            }

            return lot.filterColor ? lot.filterColor.split('|')[0] : `#${lot.color}`;
        },
    },
};
</script>

<style scoped>
.date-picker {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
}

.offline-form {
    font-weight: 600;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-submitted {
    position: absolute;
    bottom: 7%;
    right: 50%;
    transform: translateX(50%);
    text-transform: uppercase;
    font-weight: 500;
}
</style>
