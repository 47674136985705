export default {
    isTourPageVisible(state) {
        return state.isTourPageVisible;
    },
    isSchemePageVisible(state) {
        return state.isSchemePageVisible;
    },
    isLocationPageVisible(state) {
        return state.isLocationPageVisible;
    },
    isGalleryPageVisible(state) {
        return state.isGalleryPageVisible;
    },
    enableChangeColorTheme(state) {
        return state.enableChangeColorTheme;
    },
    enableChangeLang(state) {
        return state.enableChangeLang;
    },
    enableIllusioButton(state) {
        return state.enableIllusioButton;
    },
    videoPoster(state) {
        return state.videoPoster;
    },
    clientName(state) {
        return state.clientName;
    },
    googleAdsId(state) {
        return state.googleAdsId;
    },
    programName(state) {
        return state.programName;
    },
    altImgText(state) {
        return state.altImgText;
    },
    mainPageTemplate(state) {
        return state.mainPageTemplate;
    },
    plainAssetsPath(state) {
        return state.assetsPath;
    },
    assetsPath(state) {
        return `${state.assetsPath}/${state.currentImageQuality}`;
    },
    topLogoWhite(state) {
        return state.topLogoWhite;
    },
    topLogoWhitePath(state, getters) {
        return state.topLogoWhite ? `${getters.assetsPath}/${state.topLogoWhite}` : null;
    },
    topLogoDark(state) {
        return state.topLogoDark;
    },
    topLogoDarkPath(state, getters) {
        return state.topLogoDark ? `${getters.assetsPath}/${state.topLogoDark}` : null;
    },
    enableContactForm(state) {
        return state.enableContactForm;
    },
    programCity(state) {
        return state.programCity;
    },
    externalRepo(state) {
        return state.externalRepo;
    },
    loaderImage(state) {
        return state.loaderImage;
    },
    loaderImagePath(state, getters) {
        return `${state.loaderImage ? state.assetsPath : getters.assetsPath}/${
            state.loaderImage || state.defaultImages.loader
        }`;
    },
    defaultImages(state) {
        return state.defaultImages;
    },
    backgroundWhiteMenuImagePath(state, getters) {
        return state.backgroundWhiteMenuImageName
            ? `${getters.assetsPath}/${state.backgroundWhiteMenuImageName}`
            : null;
    },
    backgroundDarkMenuImagePath(state, getters) {
        return state.backgroundDarkMenuImageName ? `${getters.assetsPath}/${state.backgroundDarkMenuImageName}` : null;
    },
    enableLotAvailabilityPage(state, getters) {
        return state.enableLotAvailabilityPage && getters.isSchemePageVisible;
    },
    enableCookieAlert(state) {
        return state.enableCookieAlert;
    },
    legalMentionHtml(state) {
        return state.legalMentionHtml;
    },
    enableMenu(state) {
        return state.enableMenu;
    },
    enableSchemeLinkDisplayInMenu(state) {
        return state.enableSchemeLinkDisplayInMenu;
    },
    whatsappNumber(state) {
        return state.whatsappNumber;
    },
    telephoneNumber(state) {
        return state.telephoneNumber;
    },
    contactSidebarBackground(state) {
        return state.contactSidebarBackground;
    },
    enableContactSidebar(state) {
        return state.enableContactSidebar;
    },
    emailBackgroundColor(state) {
        return state.emailBackgroundColor;
    },
    qualityOptions(state) {
        return state.qualityOptions;
    },
    bottomLogos(state) {
        return state.bottomLogos;
    },
    bottomLogoPath: (_, getters) => (logo) => {
        return `${getters.assetsPath}/${logo}`;
    },
    currentImageQuality(state) {
        return state.currentImageQuality;
    },
    galleries(state) {
        return state.galleries;
    },
    currentGallery(state) {
        return state.currentGallery;
    },
    mainPageSliderData(state) {
        return state.galleries?.[state.currentGallery]?.slides ?? [];
    },
    analyticsEvents(state) {
        return state.analyticsEvents;
    },
    customLanding(state) {
        return state.customLanding;
    },
    isShowMobileMenu(state) {
        return state.isShowMobileMenu;
    },
};
