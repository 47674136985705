<template>
    <div class="inner-menu_wrapper">
        <div class="menu-link--wrapper">
            <button class="search-btn">
                <img
                    v-if="!isDarkTheme"
                    class="search-icon"
                    :src="$getImagePath('icons/icon-search-grey.svg')"
                    :alt="altImgText"
                />
                <img
                    v-else
                    class="search-icon"
                    :src="$getImagePath('icons/icon-search-white.svg')"
                    :alt="altImgText"
                />
            </button>
        </div>
        <button
            class="reset-btn"
            @click="onResetFiltersHandler"
        >
            réinitialiser la recherche
        </button>
        <div class="clearfix fw">
            <div class="filters mt-48 pr-8 pl-8">
                <LotsFilter
                    v-for="filter in standardFilters"
                    :key="filter.type"
                    :filter="filter"
                    @toggle="onToggleFilter({...$event, type: filter.type})"
                >
                    <button
                        v-if="filter.type === $types.ROOMS_TYPE"
                        :class="['typology-btnAll', {active: isAllRoomsActive}]"
                        @click="onToggleAllRoomsFiltersHandler(isAllRoomsActive)"
                    >
                        {{ isAllRoomsActive ? 'Cacher' : 'Tous' }}
                    </button>
                </LotsFilter>
                <div class="d-flex flex-column gap-24">
                    <template v-for="filter in rangeFilters">
                        <template v-for="(option, index) in filter.options">
                            <LotsRangeFilter
                                v-if="isRangeFilterByTypeAvailable(option.option)"
                                :key="option.option + index"
                                :filter="option"
                                :range="minMaxRanges[option.option]"
                                :reset="isFiltersResetted"
                                :formatter="getFormatterByType(option.option)"
                                @update:range="onUpdateRangeHandler({range: $event, filter: option})"
                            />
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import scheme3dMenuFiltersMixin from '@front/mixins/scheme3dMenuFiltersMixin';
import LotsFilter from '@front/components/Filters/LotsFilter/LotsFilter.vue';
import LotsRangeFilter from '@front/components/Filters/LotsFilter/LotsRangeFilter.vue';

export default {
    name: 'RoomsFilterScheme3d',
    components: {
        LotsFilter,
        LotsRangeFilter,
    },
    mixins: [scheme3dMenuFiltersMixin],
    computed: {
        ...mapGetters({
            altImgText: 'config/altImgText',
        }),
    },
};
</script>

<style lang="scss">
.typology-btnAll {
    width: 88px;
    height: 30px;
    background: #747474 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #0000004d;
    color: #fff;
    border-radius: 20px;
    margin-bottom: 0;
    padding: 6px 9px;
    border: 0;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    opacity: 0.5;
    cursor: pointer;
    &.active {
        opacity: 1;
    }
}
.scheme-button {
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: #f8ba2e 0% 0% no-repeat padding-box;
    box-shadow: 10px 10px 10px #00000066;
    top: 0;
    right: 0px;
    &__indicator {
        position: absolute;
        top: 0;
        left: -20px;
        width: 20px;
        height: 20px;
    }
    p {
        transform: matrix(0, 1, -1, 0, 0, 0);
        white-space: nowrap;
        text-align: left;
        font: normal normal bold 10px/12px Lato;
        letter-spacing: 1px;
        color: #ffffff;
        text-transform: uppercase;
        opacity: 1;
        span {
            &:nth-child(1) {
                width: 4px;
                height: 15px;
                position: absolute;
                left: -17px;
                top: 50%;
                transform: translateY(-50%);
                background: #fff;
            }
            &:nth-child(2) {
                width: 15px;
                height: 4px;
                position: absolute;
                left: -22px;
                top: 50%;
                transform: translateY(-50%);
                background: #fff;
            }
        }
    }
}
.rooms__filter-name:first-child {
    margin-top: 50px;
}
</style>
