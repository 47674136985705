export default {
    isConfigLoad(state) {
        return state.isConfigLoad;
    },
    assetsPath(state) {
        return `${state.assetsPath}/${state.uploadsPath}`;
    },
    brochure(state) {
        return state.brochure;
    },
    galleries(state) {
        return state.galleries;
    },
    enableAxonometryGallery(state) {
        return state.enableAxonometryGallery;
    },
    enableProgressGallery(state) {
        return state.enableProgressGallery;
    },
    isBrochureVisible(state) {
        return state.isBrochureVisible;
    },
    isPerspectivesVisible(state) {
        return state.isPerspectivesVisible;
    },
    isVideosVisible(state) {
        return state.isVideosVisible;
    },
    qualityOptions(state) {
        return state.qualityOptions;
    },
    slidesPath(state) {
        return state.slidesPath;
    },
    uploadsPath(state) {
        return state.uploadsPath;
    },
    videos(state) {
        return state.videos;
    },
    videosPath(state) {
        return state.videosPath;
    },
    currentImageQuality(state) {
        return state.currentImageQuality;
    },
    currentVideoIndex(state) {
        return state.currentVideoIndex;
    },
    currentVideo(state) {
        return state.videos[state.currentVideoIndex];
    },
    currentVideoPaths(state, getters) {
        const {file, poster, id} = getters.currentVideo;
        const basePath = `${getters.assetsPath}/${state.videosPath}/${id}`;

        return {
            video: `${basePath}/${file}`,
            poster: poster ? `${basePath}/${poster}` : null,
        };
    },
    brochurePath(state, getters) {
        return `${getters.assetsPath}/${state.brochure}`;
    },
    activeComponent(state) {
        return state.activeComponent;
    },
};
