<template>
    <div class="aside-desktop-scheme-actions">
        <ViewerChoices />
        <ViewerCompass
            v-if="$route.meta.menuTemplate === 'Scheme3dMenu'"
            :rotate-angle="currentSchemeAngle + compassDegree"
        />
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ViewerCompass from '@front/components/Viewers/particles/ViewerCompass.vue';
import ViewerChoices from '@front/components/Viewers/particles/ViewerChoices.vue';

export default {
    name: 'Scheme3dPageActions',
    components: {
        ViewerCompass,
        ViewerChoices,
    },
    computed: {
        ...mapGetters({
            compassDegree: 'scheme/compassDegree',
            currentSchemeAngle: 'scheme/currentSchemeAngle',
        }),
    },
};
</script>

<style lang="scss" scoped>
.aside-desktop-scheme-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    height: 100%;

    &::v-deep {
        .v360-menu-compass-container {
            margin: 0 !important;
        }
    }
}
</style>
