<template>
    <section
        class="table-section"
        :style="{
            backgroundColor: blockData.backgroundColor,
        }"
    >
        <h3
            class="primary-heading"
            :style="{
                color: elements.title.color,
            }"
            v-html="elements.title.text"
        />
        <div class="appartments-plan">
            <div class="appartment-row-head">
                <div class="child one">
                    <p>
                        Numéro
                        <br />
                        de&nbsp;lot
                    </p>
                </div>
                <div class="child two">
                    <p class="two">
                        Surface
                        <br />
                        habitable
                    </p>
                </div>
                <div class="child three">
                    <p class="three">Étage</p>
                </div>
                <div class="child four">
                    <p class="four">Prix</p>
                </div>
                <div class="child five">
                    <p class="five">Plans</p>
                </div>
                <div class="child six">
                    <img
                        :src="$getImagePath('svg/3d-plan.svg')"
                        alt=""
                    />
                </div>
                <div class="child seven">
                    <a
                        href=""
                        class="appartment-btn"
                    >
                        JE SUIS INTÉRESSÉ
                        <span>PAR CE LOT</span>
                    </a>
                </div>
            </div>
            <template v-for="[key, {minPrice, value}] in tbodyData">
                <TableRow
                    :key="key"
                    :row-name="key"
                    :row-value="value"
                    :min-price="minPrice"
                    :is-active="key === activeRow"
                    @select-lot="onSelectLotHandler"
                    @toggle-activity="onToggleActivityHandler(key, $event)"
                    @open-plan="onPlanOpenHandler"
                    @show-on-viewer="onShowOnViewerHandler"
                />
            </template>
        </div>
    </section>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';
import customLandingMixin from '@front/mixins/customLandingMixin';
import TableRow from '@front/components/CustomLandingBlocks/components/TableRow.vue';

export default {
    name: 'PriceBlock',
    components: {
        TableRow,
    },
    mixins: [customLandingMixin],
    emits: ['select-lot'],
    data() {
        return {
            activeRow: '',
        };
    },
    computed: {
        ...mapGetters({
            initialLotsData: 'scheme/initialLotsData',
            lotsData: 'scheme/lotsData',
        }),
        tbodyData() {
            const groupedLots = this.initialLotsData.reduce((acc, lot) => {
                if (this.isLotAvailable(lot)) {
                    if (!acc[lot.rooms]) {
                        acc[lot.rooms] = {
                            value: [],
                            minPrice: lot.price,
                        };
                    }

                    if (acc[lot.rooms].minPrice > lot.price) {
                        acc[lot.rooms].minPrice = lot.price;
                    }

                    acc[lot.rooms].value.push(lot);
                }

                return acc;
            }, {});

            return Object.entries(
                Object.keys(groupedLots)
                    .sort()
                    .reduce((acc, key) => {
                        acc[key] = groupedLots[key];

                        return acc;
                    }, {}),
            );
        },
    },
    async mounted() {
        !this.initialLotsData.length && (await this.getSchemeData());
        [[this.activeRow]] = this.tbodyData;
    },
    methods: {
        ...mapMutations({
            setLotInfo: 'scheme/setLotInfo',
            toggleActivityAdditionalWindow: 'navigation/toggleActivityAdditionalWindow',
            setIsMenuVisible: 'navigation/setIsMenuVisible',
            updateSelectedLot: 'scheme/updateSelectedLot',
            setIsContactModalActive: 'projectExtend/setIsContactModalActive',
        }),
        ...mapActions({
            getSchemeData: 'scheme/getSchemeData',
        }),
        async onToggleActivityHandler(key, event) {
            this.activeRow = this.activeRow === key ? '' : key;
            await this.$wait(300);
            event?.target?.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        },
        isTaxIncluded() {
            return Boolean(this.lotsData?.filter((x) => x.taxIncluded).length);
        },
        isLotAvailable(lot) {
            const isStatusAvailable = [this.$constants.statuses.OPTION, this.$constants.statuses.DISPO].includes(
                lot.status,
            );

            return isStatusAvailable && lot.published && lot.rooms;
        },
        onPlanOpenHandler(lot) {
            this.setLotInfo(lot);
            this.toggleActivityAdditionalWindow(this.$constants.additionalWindows.LOT_INFO);
        },
        onShowOnViewerHandler(lot) {
            this.setIsMenuVisible(false);
            this.$router.push({name: 'Scheme3d', params: {lotToShow: lot}});
        },
        onSelectLotHandler(lot) {
            this.updateSelectedLot(lot);
            this.setIsContactModalActive(true);
        },
    },
};
</script>

<style lang="scss">
.table-section {
    padding: 101px 50px 158px 50px;
    background-color: var(--accent-color);
    @media (max-width: 768px) {
        padding: 82px 50px;
    }
    @media (max-width: 576px) {
        padding: 82px 30px;
    }
    @media (max-width: 350px) {
        padding: 82px 0px;
    }
    b,
    strong {
        font-weight: bold !important;
    }

    i,
    em {
        font-style: italic !important;
    }
    .primary-heading {
        color: white;
        padding-bottom: 103px;
        @media (max-width: 768px) {
            font-size: 24px;
            letter-spacing: 0.96px;
            line-height: 25px;
            padding-bottom: 60px;
        }
        @media (max-width: 576px) {
            max-width: 100%;
        }
    }
    .appartments-plan {
        max-width: 1080px;
        margin: auto;
        .appartment-row-head {
            color: var(--accent-color);
            min-height: 75px;
            display: flex;
            background-color: white;
            @media (max-width: 992px) {
                display: none;
            }
            .child {
                flex-grow: 1;
                padding: 12px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 1px solid white;
                text-align: center;
                font-size: 18px;
                letter-spacing: 0.72px;
                line-height: 17.5px;
                &.tab-none {
                    display: none;
                    @media (max-width: 768px) {
                        display: flex;
                        flex-direction: column;
                    }
                }
                @media (max-width: 768px) {
                    border: unset;
                    &.tab {
                        display: none;
                    }
                }
                @media (max-width: 576px) {
                    font-size: 14px;
                    letter-spacing: 0.56px;
                    line-height: 12px;
                    img {
                        max-width: 30px;
                    }
                }
                &:last-child {
                    border-right: unset;
                }
                &.one {
                    justify-content: unset;
                    text-align: left;
                    padding-left: 30px;
                    max-width: 139.72px;
                    width: 100%;
                    @media (max-width: 1100px) {
                        max-width: 112px;
                    }
                    p {
                        max-width: 32px;
                    }
                }
                &.two {
                    max-width: 147.77px;
                    width: 100%;
                }
                & .two {
                    max-width: 69.64px;
                    width: 100%;
                }
                &.three {
                    max-width: 109.28px;
                    width: 100%;
                }
                & .three {
                    max-width: 31.16px;
                    width: 100%;
                }
                &.four {
                    max-width: 162.41px;
                    width: 100%;
                }
                & .four {
                    max-width: 84.48px;
                    width: 100%;
                }
                &.five {
                    max-width: 113.41px;
                }
                & .five {
                    max-width: 35.28px;
                    width: 100%;
                }
                &.six {
                    max-width: 113.95px;
                    width: 100%;
                }
                &.seven {
                    min-width: 266px;
                    max-width: 293.25px;
                    width: 100%;
                }
                sup {
                    vertical-align: super;
                    font-size: 11px;
                }
                img {
                    visibility: hidden;
                }
            }
            .appartment-btn {
                visibility: hidden;
                max-width: 230px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid white;
                border-radius: 5px;
                padding: 10px;
                font-size: 16px;
                letter-spacing: 0.64px;
                line-height: 14px;
                color: white;
                min-height: 50px;
                span {
                    display: inline-block;
                    padding-left: 4px;
                }
                @media (max-width: 768px) {
                    max-width: 120px;
                    min-height: 30px;
                    font-size: 13px;
                    letter-spacing: 0.26px;
                    line-height: 11px;
                    span {
                        display: none;
                    }
                }
                &:hover {
                    background-color: white;
                    color: var(--accent-color);
                }
            }
        }
        .collapsible {
            font-family: 'ConduitItcRegular';
            position: relative;
            background-color: var(--accent-color);
            color: white;
            cursor: pointer;
            padding: 18px;
            width: 100%;
            border: none;
            text-align: left;
            outline: none;
            font-size: 24px;
            letter-spacing: 0.96px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 65px 11px 31px;
            min-height: 45px;
            border-bottom: 1px solid white;
            outline: unset;
            @media (max-width: 576px) {
                padding: 12px 10px;
            }
            @media (max-width: 350px) {
                // font-size: 20px;
                // letter-spacing: 0.8px;
            }
            .text {
                font-size: 14px;
                letter-spacing: 0.56px;
                line-height: 12px;
                padding-right: 62px;
                @media (max-width: 385px) {
                    padding-right: 30px;
                }
            }
        }
        .active {
            border-bottom: 0px solid transparent;
        }

        .active,
        .collapsible:hover {
            background-color: var(--accent-color);
        }

        .collapsible:after {
            content: '';
            color: white;
            min-height: 12px;
            min-width: 20px;
            background-image: url('../../../../images/svg/arrow-up.svg');
            background-repeat: no-repeat;
            transform: rotate(180deg);
            @media (max-width: 576px) {
                position: absolute;
                right: 10px;
            }
        }

        .active:after {
            content: '';
            transform: rotate(0deg);
        }
        .content {
            overflow: hidden;
            transition: max-height 0.3s ease-out;
            .bg-white {
                background-color: rgba(255, 255, 255, 0.1) !important;
            }
            .appartment-row {
                color: white;
                min-height: 75px;
                display: flex;
                border-top: 1px solid white;
                @media (max-width: 768px) {
                    min-height: 68px;
                }
                .child {
                    flex-grow: 1;
                    padding: 12px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    border-right: 1px solid white;
                    text-align: center;
                    font-size: 20px;
                    letter-spacing: 0.8px;
                    line-height: 18px;
                    background: transparent;
                    color: white;
                    &.tab-none {
                        display: none;
                        @media (max-width: 768px) {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                    @media (max-width: 768px) {
                        border: unset;
                        &.tab {
                            display: none;
                        }
                    }
                    @media (max-width: 576px) {
                        font-size: 14px;
                        letter-spacing: 0.56px;
                        line-height: 12px;
                        img {
                            max-width: 30px;
                        }
                    }
                    &:last-child {
                        border-right: unset;
                    }
                    &.one {
                        justify-content: unset;
                        text-align: left;
                        padding-left: 30px;
                    }
                    &.three {
                        min-width: 32.05px;
                    }
                    sup {
                        vertical-align: super;
                        font-size: 11px;
                    }
                }
                .appartment-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    max-width: 230px;
                    min-height: 50px;
                    padding: 10px;
                    font-family: 'ConduitItcRegular';
                    font-size: 16px;
                    letter-spacing: 0.64px;
                    line-height: 1;
                    color: white;
                    background-color: transparent;
                    border: 1px solid white;
                    border-radius: 5px;
                    span {
                        display: inline-block;
                        padding-left: 4px;
                        font-family: 'ConduitItcRegular';
                    }
                    @media (max-width: 768px) {
                        max-width: 120px;
                        min-height: 30px;
                        font-size: 13px;
                        letter-spacing: 0.26px;
                        line-height: 11px;
                        span {
                            display: none;
                        }
                    }
                    &:hover {
                        background-color: white;
                        color: var(--accent-color);
                    }
                }
            }
        }
    }
}
</style>
