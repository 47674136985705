import mainPageTemplates from '@front/constants/mainPageTemplates';

export default {
    isTourPageVisible: false,
    isSchemePageVisible: false,
    isLocationPageVisible: false,
    isGalleryPageVisible: false,
    enableChangeColorTheme: false,
    enableLotAvailabilityPage: false,
    enableIllusioButton: false,
    enableCookieAlert: false,
    enableChangeLang: true,
    clientName: '',
    programName: '',
    altImgText: '',
    mainPageTemplate: mainPageTemplates.SLIDER_VIEW,
    assetsPath: '',
    topLogoWhite: '',
    topLogoDark: '',
    enableContactForm: false,
    enableMenu: true,
    enableSchemeLinkDisplayInMenu: true,
    programCity: '',
    externalRepo: '',
    loaderImage: '',
    googleAdsId: null,
    defaultImages: {
        logo: '',
        loader: '',
    },
    backgroundWhiteMenuImageName: '',
    backgroundDarkMenuImageName: '',
    legalMentionHtml: '',
    whatsappNumber: '',
    telephoneNumber: '',
    enableContactSidebar: false,
    contactSidebarBackground: '',
    emailBackgroundColor: '',
    currentImageQuality: 'original',
    qualityOptions: null,
    bottomLogos: null,
    galleries: null,
    currentGallery: 0,
    analyticsEvents: null,
    customLanding: null,
    isShowMobileMenu: false,
};
