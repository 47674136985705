<template>
    <div class="cl-apartment">
        <div class="cl-apartment-image">
            <img
                :src="`${imagePath}/${blockData.backgroundImage}`"
                :alt="altImgText"
            />
        </div>
        <div class="cl-apartment-content">
            <div class="cl-apartment-content-container">
                <div class="cl-apartment-content-wrapper">
                    <h2
                        class="cl-apartment-content__title"
                        :style="{color: elements.title.color, backgroundColor: elements.title.backgroundColor}"
                        v-html="elements.title.text"
                    />
                    <div
                        class="cl-apartment-content__description"
                        :style="{
                            color: elements.description.color,
                            backgroundColor: elements.description.backgroundColor,
                        }"
                        v-html="elements.description.text"
                    />
                    <CLButton
                        :label="elements.button.text"
                        :text-color="elements.button.color"
                        :background-color="elements.button.backgroundColor"
                        class="cl-apartment-content__button"
                        @click="onFormButtonHandler"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import customLandingMixin from '@front/mixins/customLandingMixin';

export default {
    name: 'ApartmentBlock',
    mixins: [customLandingMixin],
};
</script>

<style lang="scss" scoped>
.cl-apartment {
    display: flex;

    @media (max-width: 1200px) {
        flex-direction: column;
    }

    ::v-deep {
        b,
        strong {
            font-weight: bold !important;
        }

        i,
        em {
            font-style: italic !important;
        }
    }

    &-image {
        flex-grow: 1;
        width: 100%;

        ::v-deep img {
            display: block;
            width: 100%;
            object-fit: cover;
            height: 1080px;

            @media (max-width: 768px) {
                height: 700px;
            }
        }
    }

    &-content {
        flex-grow: 1;
        width: 100%;
        background-color: transparent;
        padding: 60px;
        transition: background-color 0.3s ease-out;

        &:hover {
            background-color: var(--accent-color);
        }

        @media (max-width: 768px) {
            padding: 50px 30px;
            padding: 0;
        }

        &-container {
            height: 100%;
            width: 100%;
            background-color: var(--secondary-color);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 100px 50px;

            @media (max-width: 768px) {
                padding: 50px 30px;
                text-align: center;
            }
        }

        &-wrapper {
            max-width: 490px;
        }

        &__title {
            font-size: 42px;
            letter-spacing: 2.52px;
            line-height: 48px;
            padding-bottom: 65px;

            @media (max-width: 768px) {
                font-size: 30px !important;
                letter-spacing: 1.8px;
                line-height: 36px;
                text-decoration: unset;
                padding-bottom: 30px;
            }
        }

        &__description {
            ::v-deep p,
            ::v-deep span,
            & {
                font-size: 20px;
                letter-spacing: 0.6px;
                line-height: 29px;
                padding-bottom: 30px;
            }

            @media (max-width: 768px) {
                ::v-deep p,
                ::v-deep span,
                & {
                    letter-spacing: 0.54px;
                    line-height: 25px;
                    font-size: 18px !important;
                    padding-bottom: 20px;
                }
            }
        }

        &__button {
            margin-top: 20px;

            @media (max-width: 768px) {
                margin: 10px auto 0 auto;
            }
        }
    }
}
</style>
