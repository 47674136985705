<template>
    <div class="inner-menu-t2 inner-menu-t2_tours desktop-menu-template">
        <template v-for="(tour, tourIndex) in tourshotspots">
            <template v-for="(room, roomIndex) in tour.rooms">
                <div v-if="room.hotspots.length">
                    <div
                        class="menu-link-t2__label"
                        @click="
                            onUpdateTourRoomHotspots({ tourIndex: tourIndex, roomIndex: roomIndex, hotspotIndex: 0 })
                        "
                    >
                        <div class="menu-link-t2__label__title" :class="{ active: activeEntity.id === room.id }">
                            <span class="aside-menu-localisation-element-padding">{{ room.title }}</span>
                        </div>
                    </div>
                </div>
            </template>
        </template>
        <template v-if="mediaItems && mediaItems.length">
            <div v-for="mediaItem in mediaItems" :key="mediaItem.id" class="menu-link-t2__label">
                <div
                    class="menu-link-t2__label__title"
                    @click="onSetActiveEntity(mediaItem)"
                    :class="{ active: activeEntity.id === mediaItem.id }"
                >
                    <span class="aside-menu-localisation-element-padding" v-html="mediaItem.title" />
                </div>
            </div>
        </template>
        <template v-if="maps && maps.length">
            <div v-for="mapItem in maps" :key="mapItem.id" class="menu-link-t2__label">
                <div
                    class="menu-link-t2__label__title"
                    @click="onSetActiveEntity(mapItem)"
                    :class="{ active: activeEntity.id === mapItem.id }"
                >
                    <template v-if="activeEntity.id === mapItem.id">
                        <img
                            v-if="isDarkTheme"
                            class=""
                            :src="$getImagePath('icons/arrow-black-theme.svg')"
                            :alt="altImgText"
                        />
                        <img v-else class="" :src="$getImagePath('icons/arrow-white-theme.svg')" :alt="altImgText" />
                    </template>
                    <span class="aside-menu-localisation-element-padding">{{ mapItem.title }}</span>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import locationMixin from '@front/mixins/locationMixin';
import homeMenuMixin from '@front/mixins/homeMenuMixin';

export default {
    mixins: [locationMixin, homeMenuMixin],
    methods: {
        onUpdateTourRoomHotspots(data) {
            this.setIsShowMobileMenu(false);
            this.updateTourRoomHotspots(data);
        },
        onSetActiveEntity(data) {
            this.setIsShowMobileMenu(false);
            this.setActiveEntity(data);
        }
    }
};
</script>
