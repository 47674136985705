import { mapGetters, mapActions, mapMutations } from 'vuex';
import debounce from 'lodash.debounce';
import menuOffTimeoutMixin from '@front/mixins/menuOffTimeoutMixin';
import Hotspot360 from '@front/components/Hotspots/Hotspot360';
import FullscreenToggle from '@front/components/Navigation/FullscreenToggle';
import ViewerCompass from '@front/components/Viewers/particles/ViewerCompass';
import { MOBILE_WIDTH } from '@front/constants';
import { getNumberRange } from '@front/utils/getNumberRange';

const DEFAULT_CANVAS_SCROLL_SPEED = 3;
const DEFAULT_SCROLL_DELAY = 300;

export default {
    components: {
        Hotspot360,
        FullscreenToggle,
        ViewerCompass
    },
    mixins: [menuOffTimeoutMixin],
    props: {
        pathsToImages: {
            type: Array,
            default: () => []
        },
        loadedImagesCount: {
            type: Number,
            default: () => 0
        },
        images: {
            type: Array,
            default: () => []
        },
        masks: {
            type: Array,
            default: () => []
        },
        totalImagesCount: {
            type: Number,
            require: true
        },
        disableZoom: {
            type: Boolean,
            default: () => false
        },
        spinReverse: {
            type: Boolean,
            default: () => false
        },
        imagesCount: {
            type: Number,
            require: true
        },
        currentViewer: {
            type: String,
            default: () => ''
        }
    },
    data() {
        return {
            // final offset parameters, used in canvas drawImage
            horizontalOffset: 0,
            // final offset parameters, used in canvas drawImage
            verticalOffset: 0,
            // parameter to adapt image size to screen size, by creating offset
            imageToScreenOffset: 0,
            // max value between vertical & horizontal screen to image ratio
            maxRatioCanvasToImage: 0,
            readyToShowSVG: false,
            // to center default size image
            canvasImageOffset: {
                Horizontal: 0,
                Vertical: 0
            },
            // lot that is currently hovered by user
            hoveredLot: null,
            // parameter to show correct hotspot (carries lot id)
            currHoverHotspot: '',
            // constant value - default lot opacity
            defaultLotOpacity: 0.8,
            // constant value - scale iterations minimum
            minScale: 1,
            // constant value - scale iterations maximum
            currentScale: 1.0,
            currentImage: null,
            // imageContainer reference's canvas
            canvas: null,
            // 2D context to reference's canvas
            ctx: null,
            // center coordinate X (image width * maxRatioCanvasToImage)
            centerX: 0,
            // center coordinate Y (image width * maxRatioCanvasToImage)
            centerY: 0,
            currentCanvasImage: null,
            isFullScreen: false,
            movementStart: {
                X: 0,
                Y: 0
            },
            // coordinate where image released
            movementLast: {
                X: 0,
                Y: 0
            },
            // offset used for moving zoomed image
            movementOffset: {
                Horizontal: 0,
                Vertical: 0
            },
            // offset to create zoom effect
            zoomOffset: {
                Horizontal: 0,
                Vertical: 0
            },
            zoomMouseCenter: null,
            isMoving: false,
            // constant value - how fast moving is
            speedFactor: 13,
            // active image index
            // contains hovered lot opacity to restore it when lot mouseleave
            hoveredLotOpacity: 0,
            imageSize: {
                width: 0,
                height: 0
            },
            isFirstImageLoaded: false,
            defaultSVGViewbox: {
                width: 0,
                height: 0
            },
            contextTransformE: 0,
            contextTransformF: 0,
            contextTransformA: 1,
            hotspotCoords: {
                x: 0,
                y: 0
            },
            direction: 1,
            // option to keep the mask visible until a specific event
            keepOpacityAlive: false,
            initImage: 1,
            timer: null
        };
    },
    computed: {
        ...mapGetters({
            initialLotsData: 'scheme/initialLotsData',
            lotsData: 'scheme/lotsData',
            favorites: 'scheme/favorites',
            readyToDrag: 'scheme/readyToDrag',
            selectedLot: 'scheme/selectedLot',
            compassDegree: 'scheme/compassDegree',
            isToggleViewer: 'scheme/isToggleViewer',
            currentSchemeAngle: 'scheme/currentSchemeAngle',
            enableLotsVisibility: 'scheme/enableLotsVisibility',
            chartRotationAngles: 'scheme/chartRotationAngles',
            maxCanvasScale: 'scheme/maxCanvasScale',
            lotMediaPath: 'scheme/lotMediaPath',
            enableSchemeCompassAngleLimit: 'scheme/enableSchemeCompassAngleLimit',
            beginningSchemeImage: 'scheme/beginningSchemeImage',
            endingSchemeImage: 'scheme/endingSchemeImage',
            isLoaderActive: 'loader/isLoaderActive',
            isDefaultLoaderActive: 'loader/isDefaultLoaderActive',
            activeImage: 'scheme/activeImage'
        }),
        initialAngle() {
            return 360 / this.totalImagesCount;
        }
    },
    watch: {
        isFullScreen(value) {
            if (!value) {
                this.$refs.viewerContainer.classList.remove('v360-main');
                this.$refs.viewerContainer.classList.remove('v360-fullscreen');
            } else {
                this.$refs.viewerContainer.classList.add('v360-main');
                this.$refs.viewerContainer.classList.add('v360-fullscreen');
            }
            this.setImage();
        },
        lotsData: {
            deep: true,
            handler() {
                this.showActiveFilterZones();
            }
        },
        currentCanvasImage: {
            deep: true,
            handler(newVal, oldVal) {
                if (!newVal || !oldVal) {
                    return;
                }

                if (newVal.src === oldVal.src) {
                    return;
                }

                const { degree } = this.getCanvasRotationParams(newVal, this.initialAngle);
                this.updateCurrentSchemeAngle(this.getCurrentCompassAngle(degree, this.currentSchemeAngle));

                this.updateLotLinkIcon();
            }
        },
        selectedLot: {
            deep: true,
            immediate: true,
            handler(newVal) {
                newVal ? this.moveToLot(newVal) : this.resetSelectedLot();
            }
        },
        imagesCount() {
            this.update();
            if (this.loadedImagesCount === this.imagesCount) {
                this.initData();
            }
        },
        images: {
            deep: true,
            handler(newValue) {
                if (newValue && newValue.length) {
                    this.checkFirstImage();
                    this.setFloorLoaded(true);
                }
            }
        }
    },
    mounted() {
        if (window && window.innerWidth <= MOBILE_WIDTH) {
            document.body.style.overflow = 'hidden';
        }

        window.addEventListener('resize', this.onResizeHandler);
        this.initImage = this.enableSchemeCompassAngleLimit ? this.beginningSchemeImage - 1 : 0;
        const initialImageNumber = window.sessionStorage.getItem('image-number');
        if (this.$route.query['init-with-last-image'] && initialImageNumber) {
            this.initImage = +initialImageNumber - 1;
            this.$router.replace(this.$route.path);
            window.sessionStorage.removeItem('image-number');
        }
        this.checkFirstImage(this.initImage);
        this.$emit('upload:start', this.currentViewer === this.$constants.viewers.SCHEME_3D_VIEWER);
    },
    beforeDestroy() {
        document.body.style.overflow = 'visible';
        window.removeEventListener('resize', this.onResizeHandler);
        const svgObjects = document.querySelectorAll('[id^=svg-]');
        for (let index = 0; index < svgObjects.length; index++) {
            const svg = svgObjects[index]?.contentDocument?.documentElement;
            if (!svg) {
                continue;
            }

            svg.removeEventListener('mouseup', this.hideMenuHandler);
        }
    },
    methods: {
        ...mapMutations({
            updateSelectedLot: 'scheme/updateSelectedLot',
            updateCurrentSchemeAngle: 'scheme/updateCurrentSchemeAngle',
            setChartRotationAngles: 'scheme/setChartRotationAngles',
            setFloorLoaded: 'scheme/setFloorLoaded',
            setActiveImage: 'scheme/setActiveImage',
            setIsToggleViewer: 'scheme/setIsToggleViewer'
        }),
        ...mapActions({
            loaderHide: 'loader/hide'
        }),
        getCanvasRotationAngles() {
            return this.images.reduce((acc, image) => {
                const { imageIndex, degree } = this.getCanvasRotationParams(image, this.initialAngle);
                acc[imageIndex] = degree + this.compassDegree;

                return acc;
            }, {});
        },
        moveToLot(lot) {
            this.timer = setInterval(
                () => {
                    this.onShowSelectedLot(lot);
                },
                this.isToggleViewer ? 5000 : 0
            );
        },
        getCanvasRotationParams(image, initialAngle) {
            const imageIndex = image.src.match(/\d{1,3}/g).at(-1);
            const degree = imageIndex * initialAngle;

            return {
                imageIndex,
                degree
            };
        },
        async onShowSelectedLot(lot) {
            const svgObjects = [...document.querySelectorAll('[id^=svg-]')];

            if (svgObjects.length) {
                clearInterval(this.timer);
            }

            const svgList = svgObjects.reduce((acc, object) => {
                const svg = object?.contentDocument?.documentElement;
                svg && acc.push(svg);

                return acc;
            }, []);
            const svgPaths = [];

            for (let index = 0; index < svgList.length; index++) {
                const svg = svgList[index];
                const result = svg?.getElementById(lot.color);

                if (result) {
                    svgPaths.push({
                        index,
                        size: result.getBoundingClientRect().width + result.getBoundingClientRect().height
                    });
                    result.setAttribute('fill-opacity', this.defaultLotOpacity);
                    continue;
                }
            }

            const [{ index }] = svgPaths.filter((path) => path.size < 1).sort((a, b) => b.size - a.size);
            await this.$wait(DEFAULT_SCROLL_DELAY);
            this.scrollToImageByIndex(index);

            this.keepOpacityAlive = true;
        },
        async scrollToImageByIndex(index) {
            const isLeftShift = this.totalImagesCount - index <= Math.round(this.totalImagesCount / 2);
            const rangeProp =
                isLeftShift && this.activeImage === 1
                    ? { from: this.totalImagesCount, to: index, step: -1 }
                    : { from: this.activeImage, to: index, step: this.activeImage > index ? -1 : 1 };

            const imageIndexes = [...getNumberRange(rangeProp)];

            for (const imageIndex of imageIndexes) {
                await this.setActiveImageIndex(imageIndex);
            }
        },
        setActiveImageIndex(index) {
            return new Promise((resolve) => {
                setTimeout(() => {
                    this.setActiveImage(index);
                    this.update();
                    resolve();
                }, DEFAULT_CANVAS_SCROLL_SPEED);
            });
        },
        onResizeHandler() {
            if (window && window.innerWidth <= MOBILE_WIDTH) {
                document.body.style.overflow = 'hidden';

                return;
            }

            document.body.style.overflow = 'visible';
        },
        getCurrentCompassAngle(degree, currentDegree) {
            // ATTENTION: don't touch this function! This is a strongly mathematic magic!
            return currentDegree + ((((degree - currentDegree) % 360) + 180 * 3) % 360) - 180;
        },
        onHotSpotLeftHandler() {
            this.currHoverHotspot = null;
            this.hoveredLot.setAttribute('fill-opacity', this.hoveredLotOpacity);
        },
        onLotHoverHandler(event) {
            if (this.hoveredLot && this.hoveredLot !== event.target) {
                this.hoveredLot.setAttribute('fill-opacity', this.hoveredLotOpacity);
            }

            this.hotspotCoords = { x: event.x, y: event.y };
            this.hoveredLot = event.target;
            this.currHoverHotspot = this.hoveredLot.id;
            this.hoveredLotOpacity = this.hoveredLot.getAttribute('fill-opacity');
            this.hoveredLot.setAttribute('fill-opacity', this.defaultLotOpacity);

            if (this.selectedLot?.color === this.hoveredLot?.getAttribute('id')) {
                this.keepOpacityAlive = false;
                this.updateSelectedLot(null);
            }
        },
        resetSelectedLot() {
            this.keepOpacityAlive = false;
            this.updateSelectedLot(null);
        },
        onLotLeaveHandler(event) {
            this.currHoverHotspot = event.target.id;
            if (event.target.dataset.filtred) {
                return;
            }

            if (event.relatedTarget && this.hoveredLot === event.target) {
                this.hoveredLot.setAttribute('fill-opacity', this.hoveredLotOpacity);
                this.currHoverHotspot = null;
            }
        },
        toggleSVGPointerStyle() {
            if (this.currentScale > 1) {
                const svgDoc = document.getElementById(`svg-${this.activeImage - 1}`);
                svgDoc.contentDocument.documentElement.style.cursor = 'move';
            } else {
                for (let i = 0; i < this.imagesCount; i++) {
                    const svgDoc = document.getElementById(`svg-${i}`);
                    if (
                        !svgDoc ||
                        svgDoc.contentDocument?.documentElement == null ||
                        svgDoc.contentDocument.documentElement.getElementsByTagName('body').length > 0
                    ) {
                        continue;
                    }
                    svgDoc.contentDocument.documentElement.style.cursor = 'grab';
                }
            }
            this.redraw();
        },
        onSVGLoad(id) {
            const docObj = document.getElementById(`svg-${id}`);
            const svg = docObj.contentDocument.documentElement;
            svg.addEventListener('mouseup', this.hideMenuHandler);
            let defsElement = svg.querySelector('defs');

            if (!defsElement) {
                svg.insertAdjacentHTML('afterbegin', '<defs></defs>');
                defsElement = svg.querySelector('defs');
            }

            if (this.enableLotsVisibility) {
                this.lotsData.forEach((lot) => {
                    const lotMask = svg.getElementById(lot.color);
                    const isLotHide = !lot.published;
                    const isLotAvailable = [this.$constants.statuses.OPTION, this.$constants.statuses.DISPO].includes(
                        lot.status
                    );
                    if (lotMask) {
                        lot.lotLinkIcon && this.createLotLinkIcon({ lot, mask: lotMask });
                        isLotHide && lotMask.setAttribute('style', 'display:none;');
                        !lot.specialLot && lotMask.setAttribute('fill-opacity', 0);
                        const colors = lot.palette?.split('|').slice(0, 1) ?? [`#${lot.color}`];
                        this.setLotMaskColor({
                            lot,
                            colors: isLotAvailable ? colors : ['grey'],
                            targetElement: lotMask,
                            container: defsElement
                        });
                        lotMask.style.cursor = 'pointer';
                        if (this.$isMobile()) {
                            lotMask.addEventListener('click', this.onLotHoverHandler);
                        } else {
                            lotMask.addEventListener('mouseenter', this.onLotHoverHandler);
                            lotMask.addEventListener('mouseleave', this.onLotLeaveHandler);
                        }
                    }
                });
            } else {
                this.lotsData.forEach((lot) => {
                    svg.getElementById(lot.color)?.setAttribute('fill-opacity', 0);
                });
            }

            if (this.totalImagesCount > 1) {
                svg.addEventListener('touchend', this.touchEnd);
                svg.addEventListener('touchstart', this.touchStart);
                svg.addEventListener('touchmove', this.touchMove);

                svg.addEventListener('mouseup', this.stopMoving);
                svg.addEventListener('mousedown', this.startMoving);
                svg.addEventListener('mousemove', this.doMoving);
            }

            svg.addEventListener('wheel', this.wheelHandler);

            if (!this.readyToShowSVG) {
                this.readyToShowSVG = true;
            }

            if (id === this.totalImagesCount - 1) {
                this.defaultSVGViewbox = {
                    width: svg?.viewBox?.baseVal?.width ?? 0,
                    height: svg?.viewBox?.baseVal?.height ?? 0
                };
                this.toggleSVGPointerStyle();
                this.redraw();
                const isScheme3d = this.currentViewer === this.$constants.viewers.SCHEME_3D_VIEWER;
                this.$emit('upload:success', isScheme3d);
                this.setChartRotationAngles(this.getCanvasRotationAngles());
            }
        },
        showActiveFilterZones() {
            if (!this.enableLotsVisibility || this.keepOpacityAlive) {
                return;
            }

            /** @todo find out why you need it */
            const svgDoc = document.getElementById(`svg-${this.pathsToImages.length - 1}`);
            if (!svgDoc) {
                return;
            }

            for (let i = 0; i < this.pathsToImages.length; i++) {
                const svgDocElement = document.getElementById(`svg-${i}`)?.contentDocument?.documentElement;
                if (!svgDocElement) {
                    break;
                }

                const defsElement = svgDocElement.querySelector('defs');

                for (let j = 0; j < this.lotsData.length; j++) {
                    const lot = this.lotsData[j];
                    const element =
                        typeof svgDocElement?.getElementById === 'function'
                            ? svgDocElement?.getElementById(lot.color)
                            : null;

                    if (!element) {
                        continue;
                    }

                    const filterColors = lot.filterColor?.split('|').slice(0, 1) ?? [];
                    const paletteColors = lot.palette?.split('|').slice(0, 1) ?? [`#${lot.color}`];

                    !lot.specialLot &&
                        element.setAttribute('fill-opacity', filterColors.length ? this.defaultLotOpacity : 0);
                    element.setAttribute('data-filtred', !!filterColors.length);
                    this.setLotMaskColor({
                        lot,
                        colors: filterColors.length ? filterColors : paletteColors,
                        targetElement: element,
                        container: defsElement
                    });
                }
            }
        },
        createLotLinkIcon({ lot, mask }) {
            const gElement = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            const imageElement = document.createElementNS('http://www.w3.org/2000/svg', 'image');
            const imagePath = `${window.location.origin}${this.lotMediaPath.slice(1)}/${lot.color}/${lot.lotLinkIcon}`;
            const { width, height, x, y } = mask.getBBox();

            imageElement.setAttributeNS(null, 'width', lot.lotIconWidth || width);
            imageElement.setAttributeNS(null, 'height', lot.lotIconHeight || height);
            imageElement.setAttributeNS(null, 'href', imagePath);
            imageElement.setAttributeNS(null, 'x', x);
            imageElement.setAttributeNS(null, 'y', y);
            imageElement.setAttributeNS(null, 'data-id', lot.color);
            mask.style.opacity = '0';

            gElement.appendChild(imageElement);
            mask.parentNode.insertBefore(gElement, mask);
            gElement.appendChild(mask);
        },
        updateLotLinkIcon() {
            const svgDoc = document.getElementById(`svg-${this.activeImage - 1}`);
            const svg = svgDoc?.contentDocument?.documentElement;

            for (const lot of this.lotsData) {
                if (!svg) {
                    break;
                }

                const lotMask = svg.getElementById(lot.color);

                if (!lotMask || !lot.lotLinkIcon) {
                    continue;
                }

                const imageElement = svg.querySelector(`[data-id="${lot.color}"]`);

                if (!imageElement) {
                    continue;
                }

                const { width, height, x, y } = lotMask.getBBox();
                imageElement.setAttributeNS(null, 'width', lot.lotIconWidth || width);
                imageElement.setAttributeNS(null, 'height', lot.lotIconHeight || height);
                imageElement.setAttributeNS(null, 'x', x);
                imageElement.setAttributeNS(null, 'y', y);
            }
        },
        setLotMaskColor({ lot, colors, targetElement, container }) {
            if (!colors?.length) {
                targetElement.setAttribute('fill', `#${lot.color}`);

                return;
            }

            const stops = colors.map((color, index) => {
                const offsetPercent = Math.floor((100 / colors.length) * index);

                return `<stop offset="${offsetPercent}%" stop-color="${color}" />`;
            });

            const existedGradient = container.querySelector(`#gradient-${lot.color}`);
            existedGradient && existedGradient.remove();

            const linearGradient = `
                <linearGradient id="gradient-${lot.color}">
                    ${stops.join(' ')}
                </linearGradient>
            `;

            container.insertAdjacentHTML('afterbegin', linearGradient);
            targetElement.setAttribute('fill', `url(#gradient-${lot.color})`);
        },
        checkFirstImage(imageNumber = 0) {
            if (this.loadedImagesCount > 0 && (this.isToggleViewer || !this.isFirstImageLoaded)) {
                this.showActiveFilterZones();
                this.canvas = this.$refs.imageContainer;
                this.currentImage = this.pathsToImages[imageNumber];
                this.setActiveImage(imageNumber + 1);
                this.ctx = this.canvas.getContext('2d', { alpha: false });

                this.currentLeftPosition = 0;
                this.currentTopPosition = 0;
                const viewportElement = this.$refs.viewport.getBoundingClientRect();
                this.currentCanvasImage = this.images[imageNumber];
                this.canvas.width = viewportElement.width;
                this.canvas.height = viewportElement.height;
                this.trackTransforms(this.ctx);
                this.redraw();
                this.isFirstImageLoaded = true;
            }

            if (this.loadedImagesCount === this.imagesCount) {
                this.initData();
            }
        },
        initData() {
            if (!this.currentCanvasImage?.width || !this.currentCanvasImage?.height) {
                return;
            }

            window.addEventListener('resize', debounce(this.resizeWindow, 50));
            this.imageSize = {
                width: this.currentCanvasImage.width,
                height: this.currentCanvasImage.height
            };

            const transform = this.ctx.getTransform();

            this.contextTransformE = transform.e;
            this.contextTransformF = transform.f;
            this.contextTransformA = transform.a;
            this.resizeWindow();
            this.showActiveFilterZones();
        },
        resizeWindow() {
            this.setActiveImage(this.initImage || (this.enableSchemeCompassAngleLimit ? this.beginningSchemeImage : 1));
            this.resetZoom();
            this.setImage();
        },
        wheelHandler(evt) {
            if (!this.disableZoom) this.zoom(evt.deltaY < 0 ? 2 : -2, evt.x, evt.y);
        },
        setImage(cached = false) {
            try {
                const viewportElement = this.$refs.viewport.getBoundingClientRect();

                if (!cached) {
                    this.currentCanvasImage = new Image();
                    this.currentCanvasImage.crossOrigin = 'anonymous';
                    this.currentCanvasImage.src = this.currentImage
                        ? this.currentImage
                        : this.pathsToImages[this.initImage - 1];
                    this.currentCanvasImage.onload = () => {
                        this.canvas.width = viewportElement.width;
                        this.canvas.height = viewportElement.height;
                        this.trackTransforms(this.ctx);
                        this.redraw();
                    };
                    this.currentCanvasImage.onerror = () => {
                        console.error('cannot load this image');
                    };
                } else {
                    this.currentCanvasImage = this.images[this.initImage - 1];
                    this.canvas.width = !this.isFullScreen
                        ? viewportElement.width + 200
                        : this.currentCanvasImage.width;
                    this.canvas.height = !this.isFullScreen
                        ? viewportElement.height + 200
                        : this.currentCanvasImage.height;
                    this.trackTransforms(this.ctx);
                    this.redraw();
                }
            } catch (e) {
                this.trackTransforms(this.ctx);
            }
        },
        redraw() {
            try {
                const p1 = this.ctx.transformedPoint(0, 0);
                const p2 = this.ctx.transformedPoint(this.canvas.width, this.canvas.height);
                const hRatio = this.canvas.width / this.currentCanvasImage.width;
                const vRatio = this.canvas.height / this.currentCanvasImage.height;
                this.maxRatioCanvasToImage = Math.max(hRatio, vRatio);

                this.canvasImageOffset.Horizontal =
                    this.currentCanvasImage.width - this.canvas.width > 0
                        ? (this.canvas.width - this.currentCanvasImage.width * this.maxRatioCanvasToImage) / 2
                        : 0;
                this.canvasImageOffset.Vertical =
                    this.currentCanvasImage.height - this.canvas.height > 0
                        ? (this.canvas.height - this.currentCanvasImage.height * this.maxRatioCanvasToImage) / 2
                        : 0;

                this.horizontalOffset =
                    this.canvasImageOffset.Horizontal +
                    this.movementOffset.Horizontal / 2 +
                    this.zoomOffset.Horizontal / 2;
                this.verticalOffset =
                    this.canvasImageOffset.Vertical + this.movementOffset.Vertical / 2 + this.zoomOffset.Vertical / 2;

                this.ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
                // this.centerX = (this.canvasImageOffset.Horizontal + this.canvas.width) / 2;
                // this.centerY = (this.canvasImageOffset.Vertical + this.canvas.height) / 2;

                this.centerX = (this.currentCanvasImage.width * this.maxRatioCanvasToImage) / 2;
                this.centerY = (this.currentCanvasImage.height * this.maxRatioCanvasToImage) / 2;

                this.ctx.fillRect(0, 0, this.currentCanvasImage.width, this.currentCanvasImage.height);
                this.ctx.drawImage(
                    this.currentCanvasImage,
                    0,
                    0,
                    this.currentCanvasImage.width,
                    this.currentCanvasImage.height,
                    this.horizontalOffset,
                    this.verticalOffset,
                    this.currentCanvasImage.width * this.maxRatioCanvasToImage,
                    this.currentCanvasImage.height * this.maxRatioCanvasToImage
                );

                const scaledMaskWidth =
                    this.defaultSVGViewbox.width / this.maxRatioCanvasToImage / this.contextTransformA;
                const scaledMaskHeight =
                    this.defaultSVGViewbox.height / this.maxRatioCanvasToImage / this.contextTransformA;

                const svgDoc = document.getElementById(`svg-${this.activeImage - 1}`);
                if (svgDoc) {
                    // const h = (Math.abs(this.horizontalOffset)
                    //     - (this.contextTransformE / this.contextTransformA)) / this.maxRatioCanvasToImage;
                    const h =
                        this.horizontalOffset < 0
                            ? (Math.abs(this.horizontalOffset) - this.contextTransformE / this.contextTransformA) /
                              this.maxRatioCanvasToImage
                            : this.horizontalOffset * -1 -
                              this.contextTransformE / this.contextTransformA / this.maxRatioCanvasToImage;
                    const v =
                        this.verticalOffset < 0
                            ? (Math.abs(this.verticalOffset) - this.contextTransformF / this.contextTransformA) /
                              this.maxRatioCanvasToImage
                            : this.verticalOffset * -1 -
                              this.contextTransformF / this.contextTransformA / this.maxRatioCanvasToImage;

                    // const h = Math.abs(this.horizontalOffset)
                    //     - (this.contextTransformE / this.contextTransformA) / this.maxRatioCanvasToImage;
                    // const v = Math.abs(this.verticalOffset)
                    //     - (this.contextTransformF / this.contextTransformA) / this.maxRatioCanvasToImage;

                    if (!isNaN(h) && !isNaN(v)) {
                        svgDoc.contentDocument.documentElement.setAttribute(
                            'viewBox',
                            `
                                ${h}
                                ${v}
                                ${scaledMaskWidth}
                                ${scaledMaskHeight}
                            `
                        );
                    }
                }
            } catch (e) {
                this.trackTransforms(this.ctx);
            }
        },
        onMove(pageX) {
            if (this.isLoaderActive || this.isDefaultLoaderActive) {
                return;
            }

            if (pageX - this.movementStart.X >= this.speedFactor) {
                this.direction = 1;
                const itemsSkippedRight = Math.floor((pageX - this.movementStart.X) / this.speedFactor) || 1;
                this.movementStart.X = pageX;
                if (this.spinReverse) {
                    this.moveActiveIndexUp(itemsSkippedRight);
                } else {
                    this.moveActiveIndexDown(itemsSkippedRight);
                }
            } else if (this.movementStart.X - pageX >= this.speedFactor) {
                this.direction = -1;
                const itemsSkippedLeft = Math.floor((this.movementStart.X - pageX) / this.speedFactor) || 1;
                this.movementStart.X = pageX;
                if (this.spinReverse) {
                    this.moveActiveIndexDown(itemsSkippedLeft);
                } else {
                    this.moveActiveIndexUp(itemsSkippedLeft);
                }
            }
            this.redraw();
            this.setActiveImage(this.activeImage);
        },
        isRotateAllow(isUp, itemsSkipped) {
            if (!this.enableSchemeCompassAngleLimit) {
                return true;
            }

            if (isUp && this.activeImage + itemsSkipped > this.endingSchemeImage) {
                return false;
            }

            const activeImage = this.activeImage - itemsSkipped;
            const isSmallerThanMin = activeImage < this.beginningSchemeImage;
            const isBiggerThanMin = activeImage > this.beginningSchemeImage;
            const isBiggerThanMax = activeImage > this.endingSchemeImage;

            if (!isUp && (isSmallerThanMin || (isBiggerThanMin && isBiggerThanMax))) {
                return false;
            }

            return true;
        },
        startMoving(evt) {
            this.isMoving = true;
            this.movementStart.X = evt.pageX;
            this.movementStart.Y = evt.pageY;
            this.$refs.viewport.style.cursor = 'grabbing';
        },
        doMoving(evt) {
            if (this.isMoving && this.readyToDrag && this.currentScale === 1) {
                this.onMove(evt.clientX);
            } else if (this.isMoving && this.currentScale > 1) {
                const scalePow = Math.pow(1.01, this.currentScale);
                // Получаем значение того, насколько можно двигать изображение, чтобы не было белых полос
                // (Размер * Масштаб - Размер + оффсет) / 2 - потому что крутить можно в обе стороны
                const dragAbilityX = (screen.width * scalePow - screen.width + this.zoomOffset.Horizontal) / 2;
                const dragAbilityY = (screen.height * scalePow - screen.height + this.zoomOffset.Vertical) / 2;

                if (dragAbilityX > Math.abs(this.movementLast.X + this.movementStart.X - evt.pageX)) {
                    this.movementOffset.Horizontal = this.movementLast.X + this.movementStart.X - evt.pageX;
                }
                if (dragAbilityY > Math.abs(this.movementLast.Y + this.movementStart.Y - evt.pageY)) {
                    this.movementOffset.Vertical = this.movementStart.Y - evt.pageY;
                }

                this.redraw();
            }
        },
        stopMoving() {
            this.isMoving = false;
            this.movementLast.X = this.movementOffset.Horizontal;
            this.movementLast.Y = this.movementOffset.Vertical;
            this.movementStart.X = 0;
            this.movementStart.Y = 0;
            this.$refs.viewport.style.cursor = 'grab';
            this.showActiveFilterZones();
        },
        moveActiveIndexUp(itemsSkipped) {
            if (!this.isRotateAllow(true, itemsSkipped)) {
                return;
            }
            this.setActiveImage(
                (this.activeImage + itemsSkipped) % this.pathsToImages.length || this.pathsToImages.length
            );
            this.update();
        },
        moveActiveIndexDown(itemsSkipped) {
            if (!this.isRotateAllow(false, itemsSkipped)) {
                return;
            }
            if (this.activeImage - itemsSkipped < 1) {
                this.setActiveImage(this.pathsToImages.length + (this.activeImage - itemsSkipped));
            } else {
                this.setActiveImage(this.activeImage - itemsSkipped);
            }
            this.update();
        },
        update() {
            this.currentCanvasImage = this.findCurrentImage(this.images, this.activeImage);

            if (!this.currentCanvasImage) {
                return;
            }

            this.redraw();
        },
        findCurrentImage(images, imageIndex) {
            return images.find((image) => parseInt(image.dataset.id) === imageIndex);
        },
        touchStart(evt) {
            this.movementStart.X = evt.touches[0].clientX;
        },
        touchMove(evt) {
            if (this.readyToDrag) this.onMove(evt.touches[0].clientX);
        },
        touchEnd() {
            this.movementStart.X = 0;
        },
        calculateZoomOffset(mouseX, mouseY) {
            // the more mouse distance from screen center - the more offset will be
            const mouseToScreenRatioX = ((mouseX - screen.width / 2) / (screen.width / 2)).toFixed(2);
            const mouseToScreenRatioY = ((mouseY - screen.height / 2) / (screen.height / 2)).toFixed(2);

            const scaledScreenWidth = screen.width * Math.pow(1.01, this.currentScale);
            const scaledScreenHeight = screen.height * Math.pow(1.01, this.currentScale);

            const screenDistanceOffsetX = ((scaledScreenWidth - screen.width) / 2) * mouseToScreenRatioX;
            const screenDistanceOffsetY = ((scaledScreenHeight - screen.height) / 2) * mouseToScreenRatioY;

            this.zoomOffset.Horizontal = screenDistanceOffsetX + (this.zoomOffset.Horizontal - screenDistanceOffsetX);
            this.zoomOffset.Vertical = screenDistanceOffsetY + (this.zoomOffset.Vertical - screenDistanceOffsetY);
        },
        zoom(clicks, mouseX, mouseY) {
            const factor = Math.pow(1.01, clicks);
            if (factor > 1) {
                this.currentScale += Math.round(factor);
                if (this.currentScale - 1 === 1) this.toggleSVGPointerStyle();
            } else {
                if (this.currentScale - factor > 1) {
                    this.currentScale -= Math.round(factor);
                    if (this.currentScale === 1) this.toggleSVGPointerStyle();
                } else {
                    this.currentScale = 1;
                }
            }

            if (this.currentScale > this.maxCanvasScale) {
                this.currentScale = this.maxCanvasScale;

                return;
            } else if (this.currentScale < this.minScale) {
                this.currentScale = this.minScale;

                return;
            }

            this.calculateZoomOffset(mouseX, mouseY);

            if (this.currentScale > 1) {
                const pt = this.ctx.transformedPoint(mouseX, mouseY);
                // const pt = this.ctx.transformedPoint(this.centerX, this.centerY);

                this.ctx.translate(pt.x, pt.y);
                this.ctx.scale(factor, factor);
                this.ctx.translate(-pt.x, -pt.y);

                this.movementOffset.Horizontal = 0;
                this.movementOffset.Vertical = 0;
                this.movementLast.X = 0;
                this.movementLast.Y = 0;

                const transform = this.ctx.getTransform();

                this.contextTransformE = transform.e;
                this.contextTransformF = transform.f;
                this.contextTransformA = transform.a;

                this.redraw();
            } else {
                this.resetZoom();
            }
        },
        resetZoom() {
            this.currentScale = 1;
            this.ctx.setTransform(1, 0, 0, 1, 0, 0);
            const transform = this.ctx.getTransform();
            this.contextTransformE = transform.e;
            this.contextTransformF = transform.f;
            this.contextTransformA = transform.a;
            this.redraw();
            this.zoomOffset.Horizontal = 0;
            this.zoomOffset.Vertical = 0;
        },
        trackTransforms(context) {
            const ctx = context;
            if (ctx === null) {
                return;
            }

            return new Promise((resolve) => {
                const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                let xform = svg.createSVGMatrix();
                ctx.getTransform = () => xform;

                const savedTransforms = [];
                const { save } = ctx;
                ctx.save = () => {
                    savedTransforms.push(xform.translate(0, 0));

                    return save.call(ctx);
                };

                const { restore } = ctx;
                ctx.restore = () => {
                    xform = savedTransforms.pop();

                    return restore.call(ctx);
                };

                const { scale } = ctx;
                ctx.scale = (sx, sy) => {
                    xform = xform.scaleNonUniform(sx, sy);

                    return scale.call(ctx, sx, sy);
                };

                const { rotate } = ctx;
                ctx.rotate = (radians) => {
                    xform = xform.rotate((radians * 180) / Math.PI);

                    return rotate.call(ctx, radians);
                };

                const { translate } = ctx;
                ctx.translate = (dx, dy) => {
                    xform = xform.translate(dx, dy);

                    return translate.call(ctx, dx, dy);
                };

                const { transform } = ctx;
                ctx.transform = (a, b, c, d, e, f) => {
                    const m2 = svg.createSVGMatrix();
                    m2.a = a;
                    m2.b = b;
                    m2.c = c;
                    m2.d = d;
                    m2.e = e;
                    m2.f = f;
                    xform = xform.multiply(m2);

                    return transform.call(ctx, a, b, c, d, e, f);
                };

                const { setTransform } = ctx;
                ctx.setTransform = (a, b, c, d, e, f) => {
                    xform.a = a;
                    xform.b = b;
                    xform.c = c;
                    xform.d = d;
                    xform.e = e;
                    xform.f = f;

                    return setTransform.call(ctx, a, b, c, d, e, f);
                };

                const pt = svg.createSVGPoint();
                ctx.transformedPoint = (x, y) => {
                    pt.x = x;
                    pt.y = y;

                    return pt.matrixTransform(xform.inverse());
                };
                resolve(ctx);
            });
        }
    }
};
