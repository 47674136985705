<template>
    <div
        v-if="isCompassVisible"
        id="v360-menu-btns light"
        class="compass-block"
    >
        <div class="v360-navigate-btns">
            <div class="v360-menu-compass-container">
                <div
                    class="v360-menu-compass"
                    :style="{
                        transform: isFloorLoaded && `rotate(${rotateAngle}deg)`,
                    }"
                >
                    <svg width="12" height="33" viewBox="0 0 12 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_1_134" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="33">
                            <path d="M0.416504 0.119685L0.416504 32.6086L11.6155 32.6086L11.6155 0.119685L0.416504 0.119685Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_1_134)">
                        <path d="M0.416616 16.3647C0.414475 16.5464 0.44287 16.7273 0.500616 16.8997C2.04461 21.4307 3.61661 25.9556 5.18961 30.4826C5.43561 31.1916 5.68961 31.8996 5.93361 32.6086C6.06102 32.538 6.15761 32.4225 6.20461 32.2846C7.98727 27.146 9.7636 22.006 11.5336 16.8647C11.5876 16.7035 11.615 16.5346 11.6146 16.3647L0.416616 16.3647Z" fill="white"/>
                        <path d="M11.6165 16.3647C11.6186 16.1829 11.5902 16.002 11.5325 15.8297C9.98747 11.2987 8.41147 6.77367 6.84247 2.24668C6.59648 1.53768 6.34248 0.829674 6.09848 0.120675C5.97107 0.191307 5.87448 0.306795 5.82748 0.44468C4.04481 5.58334 2.26848 10.7233 0.498484 15.8647C0.444461 16.0258 0.417102 16.1947 0.417484 16.3647L11.6165 16.3647Z" fill="#FF0000"/>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'ViewerCompass',
    props: {
        rotateAngle: {
            type: Number,
            default: () => 0,
        },
    },
    computed: {
        ...mapGetters({
            isToggleViewer: 'scheme/isToggleViewer',
            enableFloorsCompas: 'scheme/enableFloorsCompas',
            enableSchemeCompas: 'scheme/enableSchemeCompas',
            isFloorLoaded: 'scheme/isFloorLoaded',
        }),
        isCompassVisible() {
            return this.isToggleViewer ? this.enableFloorsCompas : this.enableSchemeCompas;
        },
    },
};
</script>
