import HttpClient from '@vue/js/service/HttpClient';

export default {
    async getExternalLotsData({rootGetters, commit}) {
        const lotsInfoApi = rootGetters['scheme/lotsInfoApi'];
        const programId = rootGetters['scheme/programId'];
        const apiToken = rootGetters['externalApi/apiToken'];
        const url = `${lotsInfoApi}/${programId}`;

        const config = {
            headers: {
                'Authorization-Symfony': `Basic ${apiToken}`,
            },
        };

        try {
            const {data} = await HttpClient.get(url, config);

            if (!Array.isArray(data)) {
                console.warn('External lots not found!');

                return [];
            }

            commit('setExternalLotsData', data);

            !data.length && console.warn('External lots are empty!');

            return data;
        } catch (error) {
            this._vm.$notify({
                title: 'Error',
                text: error?.response?.data?.error ?? 'Error',
                type: 'error',
            });
        }
    },
    async createSoftBooking({rootGetters}, payload) {
        const lotsInfoApi = rootGetters['scheme/lotsInfoApi'];
        const apiToken = rootGetters['externalApi/apiToken'];
        const config = {
            headers: {
                'Authorization-Symfony': `Basic ${apiToken}`,
            },
        };

        try {
            return await HttpClient.post(lotsInfoApi, payload, config);
        } catch (error) {
            this._vm.$notify({
                title: 'Error',
                text: error?.response?.data?.error ?? 'Error',
                type: 'error',
            });
        }
    },
    async clearLotStatus({rootGetters}, payload) {
        const lotsInfoApi = rootGetters['scheme/lotsInfoApi'];
        const apiToken = rootGetters['externalApi/apiToken'];
        const url = `${lotsInfoApi}/clear-booking`;

        const config = {
            headers: {
                'Authorization-Symfony': `Basic ${apiToken}`,
            },
        };
        try {
            return await HttpClient.post(url, payload, config);
        } catch (error) {
            this._vm.$notify({
                title: 'Error',
                text: error?.response?.data?.error ?? 'Error',
                type: 'error',
            });
        }
    },
    async getCitiesList({rootGetters, commit}, query) {
        const lotsInfoApi = rootGetters['scheme/lotsInfoApi'];
        const apiToken = rootGetters['externalApi/apiToken'];
        const url = `${lotsInfoApi}/cities/${query}`;
        const config = {
            headers: {
                'Authorization-Symfony': `Basic ${apiToken}`,
            },
        };

        try {
            const {data} = await HttpClient.get(url, config);
            commit('setCities', data);

            return data;
        } catch (error) {
            this._vm.$notify({
                title: 'Error',
                text: error?.response?.data?.error ?? 'Aucune ville trouvée !',
                type: 'error',
            });
        }
    },
    async getContactsList({rootGetters, commit}, query) {
        const lotsInfoApi = rootGetters['scheme/lotsInfoApi'];
        const apiToken = rootGetters['externalApi/apiToken'];
        const url = `${lotsInfoApi}/contacts/${query}`;
        const config = {
            headers: {
                'Authorization-Symfony': `Basic ${apiToken}`,
            },
        };

        try {
            const {data} = await HttpClient.get(url, config);
            if (data?.length) {
                commit('setContacts', data);

                return data;
            }

            const defaultData = {
                id: null,
                firstname: null,
                lastname: null,
                email: query,
                phone: null,
            };

            commit('setContacts', [defaultData]);

            return defaultData;
        } catch (error) {
            this._vm.$notify({
                title: 'Error',
                text: error?.response?.data?.error ?? 'Contacts introuvables !',
                type: 'error',
            });
        }
    },
};
