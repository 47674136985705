<template>
    <div
        class="slider__wrapper"
        @click="clickHandler"
    >
        <hooper
            ref="slider"
            class="illusio-slider illusio-slider--noarrow"
            :settings="hooperSettings"
            style="height: 100vh; width: 100%"
        >
            <slide
                v-for="(slide, index) in sliderImages"
                :key="index"
            >
                <div class="illusio-slider__slide">
                    <img
                        :src="slide.path"
                        class="illusio-slider__slide__image"
                        :alt="altImgText"
                        :style="{
                            height: slider.containerWidth > slider.containerHeight ? 'auto' : '100vh',
                            width: slider.containerWidth > slider.containerHeight ? '100%' : 'auto',
                            transform: `translate${slide.direction}(-${
                                index === currentSlide ? transformOffset : 0
                            }px)`,
                        }"
                    />
                    <div class="illusio-slider__slide__content">
                        <router-link
                            :to="{name: $constants.SСHEME_PAGE}"
                            class="illusio-slider__slide__label"
                        >
                            {{ slide.label }}
                        </router-link>
                    </div>
                </div>
            </slide>
            <hooper-pagination slot="hooper-addons" />
        </hooper>
        <!-- <FullscreenToggle /> -->
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
// import FullscreenToggle from '@front/components/Navigation/FullscreenToggle';
import {Hooper, Slide, Pagination as HooperPagination} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
    name: 'SliderComponent',
    components: {
        Hooper,
        Slide,
        // FullscreenToggle,
        HooperPagination,
    },
    props: {
        slides: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            requireImages: [],
            sliderImages: [],
            transformOffset: 0,
            interval: null,
            currentSlide: 0,
            galleryMode: 'slides',
        };
    },
    computed: {
        ...mapGetters({
            altImgText: 'config/altImgText',
            assetsPath: 'config/plainAssetsPath',
            currentImageQuality: 'config/currentImageQuality',
        }),
        slider() {
            return this.$refs.slider;
        },
        hooperSettings() {
            return {
                itemsToShow: 1,
                infiniteScroll: true,
                mouseDrag: false,
                touchDrag: false,
                wheelControl: false,
                keysControl: false,
            };
        },
    },
    created() {
        this.getImages();
    },
    mounted() {
        this.$nextTick().then(this.initImages);
        window.addEventListener('resize', this.resizeHandler);
    },
    methods: {
        ...mapActions({
            loaderHide: 'loader/hide',
        }),
        getImages() {
            for (let index = 0; index < this.slides.length; index++) {
                const {title, image, gallery} = this.slides[index];
                this.requireImages.push({
                    path: `${this.assetsPath}/galleries/${gallery}/${this.currentImageQuality}/${image}`,
                    label: title,
                });
            }
        },
        async initImages() {
            if (!this.requireImages.length) {
                this.loaderHide();

                return;
            }

            try {
                const asyncLoadImages = this.requireImages.map((item, index) => this.setImageData(item, index));
                Promise.all(asyncLoadImages).then(this.transformSlide).then(this.loaderHide);

                return 'loaded';
            } catch (error) {
                console.error(error);
            }
        },
        setImageData(slide, index) {
            const vm = this;

            return new Promise((resolve, reject) => {
                const image = new Image();
                image.src = slide.path;

                const sliderWidth = this.slider.containerWidth;
                const sliderHeight = this.slider.containerHeight;

                image.onload = () => {
                    const screenRatio = sliderWidth / sliderHeight;
                    const imageRatio = image.width / image.height;

                    const ratioX = sliderWidth / image.width;
                    const ratioY = sliderHeight / image.height;
                    const ratio = screenRatio < imageRatio ? ratioY : ratioX;

                    const slideData = {
                        key: index,
                        label: slide.label,
                        image,
                        path: image.src,
                        naturalWidth: image.width,
                        naturalHeight: image.height,
                        width: Math.floor(image.width * ratio),
                        height: Math.floor(image.height * ratio),
                        direction: screenRatio < imageRatio ? 'X' : 'Y',
                        offset: 0,
                        intervalOffset: 0,
                    };

                    slideData.offset =
                        screenRatio < imageRatio
                            ? parseInt(Math.abs(sliderWidth - slideData.width))
                            : parseInt(Math.abs(sliderHeight - slideData.height));
                    slideData.intervalOffset = slideData.offset / 100;

                    vm.sliderImages.push(slideData);
                    vm.sliderImages = vm.sliderImages
                        .reduce((acc, item) => {
                            if (!acc.find((value) => value.path === item.path)) {
                                acc.push(item);
                            }

                            return acc;
                        }, [])
                        .sort((a, b) => (a.key > b.key ? 1 : -1));

                    resolve(image);
                };

                image.onerror = (error) => reject(error);
            });
        },
        slideChange() {
            this.destroyInterval();
            if (this.currentSlide < this.sliderImages.length) {
                this.currentSlide++;
            } else {
                this.currentSlide = 0;
            }
            this.slider.slideTo(this.currentSlide);
            // this.slider.slideNext();
            this.transformOffset = 0;
            this.transformSlide();
        },
        transformSlide() {
            this.interval = setInterval(() => {
                this.transformOffset += this.$isMobile()
                    ? 0.5
                    : this.sliderImages[this.currentSlide]?.intervalOffset || 0;
                const imageWidth = this.$isMobile()
                    ? this.sliderImages[this.currentSlide]?.offset / 2
                    : this.sliderImages[this.currentSlide]?.offset;
                if (this.transformOffset >= imageWidth) {
                    this.slideChange();
                }
            }, 50);
        },
        destroyInterval() {
            return new Promise((resolve) => {
                clearInterval(this.interval);
                this.transformOffset = 0;
                resolve('destroyed');
            });
        },
        async resizeHandler() {
            this.sliderImages = [];
            await this.destroyInterval();
            await this.initImages();
            this.slider.update();
        },
        clickHandler({target}) {
            if (!target.classList.contains('hooper-indicator')) return;
            clearInterval(this.interval);
            this.currentSlide = +target.textContent.slice(-1);
            this.transformOffset = 0;
            this.transformSlide();
        },
    },
};
</script>

<style>
.hooper-indicator:hover,
.hooper-indicator.is-active {
    background-color: black;
}
.hooper-indicator {
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: none;
    padding: 0;
    background-color: #fff;
    cursor: pointer;
}
.hooper-pagination {
    bottom: 3%;
}
@media (max-width: 700px) {
    .hooper-list {
        height: 100vh;
    }
    .hooper-pagination {
        bottom: 15%;
    }
}
</style>
