export default {
    manageSlide: {
        title: 'Définir le titre de la diapositive',
        dimensionInfo: 'Définir l`image de la diapositive(résolution maximale {maxDimension} pixels)',
    },
    clientInfo: {
        ref: 'Client réf',
        bottomLogoText: 'Texte du logo en bas',
        logos: {
            imageWhite: 'Choisissez le logo (blanc)',
            imageDark: 'Choisissez le logo (foncé)',
        },
    },
    editTour: {
        enableCompass: 'Activer la boussole',
    },
    editScheme: {
        enableCompass: 'Activer la boussole',
        compassDegree: 'Degré de boussole',
        enableCompassAngleLimit: 'Activer les limitations des angles de rotation',
        beginningImage: 'Image de début',
        endingImage: 'Image de fin',
        mustBeBetween: 'Doit être entre',
        and: 'et',
    },
    uploadViewsTitle: 'Schéma de téléchargement',
    filterCreation: {
        title: 'Créer un filtre',
        filterType: 'Type de filtre',
        colorMapping: 'Activer le mappage des couleurs',
        removeFilter: 'Supprimer le filtre',
        saveFilter: 'Enregistrer le filtre',
    },
    filterOptions: {
        chooseOption: 'Choisissez l`option',
        chooseTitle: 'Choisissez le titre de l`option',
        chooseColor: 'Choisissez la couleur des options',
    },
    editRoom: {
        initialAngle: 'Angle initial de la boussole',
        initialCamera: 'Appareil photo initial {coord}',
        floor: 'Sol',
        imageType: 'Type d`image',
        sphere: 'sphère',
        imagesOrder: 'Commande d`images {order} ne la changez pas si vous n`êtes pas sûr',
        imagesRotation: 'Rotation des images {rotation} ne le changez pas si vous n`êtes pas sûr',
        stereoSphere: 'sphère stéréo',
    },
    mediaItem: {
        mapArea: 'Zone cartographique',
        entityIdTitle: 'Entity id pour la balise de zone',
        textInfo: {
            switchThePlan: 'Si vous souhaitez basculer le plan sur le',
            loction: 'emplacement',
            pageByClicking: 'page en cliquant sur la zone de l`image, vous devez insérer',
            entityId: 'entityId',
            hrefAttributeExample: 'dans l`attribut href de la zone, par exemple: ',
        },
        listAvailableIds: 'Liste des id disponibles',
        entryTitle: 'Titre de l`entité',
    },
    mediaItemTable: {
        createMediaItem: 'Créer un élément multimédia',
    },
    mapsTable: {
        createMap: 'Créer une carte',
        apiKey: 'Clé API Google',
        address: 'Adresse cliquable (URL)',
        latitude: 'Latitude du marqueur',
        longitude: 'Longitude du marqueur',
        centerLatitude: 'Latitude du centre de la carte',
        centerLongitude: 'Longitude du centre de la carte',
        tooltipLinkLabel: 'Libellé du lien de l`info- bulle',
        zoom: 'Zoom de la carte par défaut',
        tooltipContent: 'Contenu de l`info- bulle',
        snazzyStyle: 'Style élégant',
        uploadMarkerImage: 'Télécharger l`image du marqueur',
    },
    editProceccingConfig: {
        distance: 'distance',
        ignoreColorThreshold: 'ignorer le seuil de couleur (arrière-plan)',
        detectColorThreshold: 'détecter le seuil de couleur (lot)',
        generateRotation: 'générer une rotation',
        detectedColors: 'couleurs détectées',
        ignoredColors: 'couleurs ignorées',
        add: 'ajouter',
    },
    editLot: {
        hotspotSettings: 'Paramètres du point d`accès',
        untitledLot: 'Lot sans titre',
        specialLots: 'Lots spéciaux',
        iconSize: 'Icône {size}',
        hoverIcon: 'icône de survol',
        anchor: 'Anchor',
        template: 'Modèle',
        typeOfSale: 'Type de vente',
        planPdf: 'Plan pdf (bureau)',
        planImage: 'Planifier l`image(mobile)',
    },
    multiEdit: {
        showStatus: 'Afficher l`état',
        allowComparison: 'Autoriser la comparaison',
        showInfo: 'Afficher les info',
        building: 'Bâtiment',
    },
    externalArea: 'Espace extérieur',
    addBlock: {
        title: 'Ajouter un nouveau bloc',
    },
};
