<template>
    <div v-if="show">
        <Scheme3d />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Scheme3d from './Scheme3d.vue';

export default {
    name: 'Scheme3DPageWrap',
    components: {
        Scheme3d
    },
    computed: {
        ...mapGetters({
            show: 'scheme/show',
        })
    },
    watch: {
        show(newVal) {
        }
    },
    methods: {
        ...mapMutations({
            refresh: 'scheme/refresh',
        })
    }
};
</script>
