export default {
  setTours(state, payload) {
    state.tours = payload.filter((tour) => tour.enabled);
  },
  setSelectedRoomIndex(state, index) {
    state.selectedRoomIndex = index;
  },
  setSelectedTourIndex(state, index) {
    state.selectedTourIndex = index;
    state.selectedRoomIndex = 0;
  },
  setAssetsPath(state, path) {
    state.assetsPath = path;
  },
  setHotspotsPath(state, path) {
    state.hotspotsPath = path;
  },
  setRoomsPath(state, path) {
    state.roomsPath = path;
  },
  setToursPath(state, path) {
    state.toursPath = path;
  },
  setUploadsPath(state, path) {
    state.uploadsPath = path;
  },
  setTourIndexFile(state, file) {
    state.tourIndexFile = file;
  },
  setIsToursLoading(state, bool) {
    state.isToursLoading = bool;
  },
};
