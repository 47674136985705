<template>
    <div
        class="aside-desktop-filter-block"
        :class="{ hovered: isFiltersVisisble }"
        @mouseenter="isFiltersVisisble = true"
        @mouseleave="isFiltersVisisble = false"
    >
        <div class="aside-desktop-filter-option-header">
            <span class="aside-desktop-filter-option">
                <Icon icon="uiw:search" />
                Recherche
            </span>
            <span class="aside-desktop-filter-option aside-desktop-filter-option--green" @click="onResetFiltersHandler">
                / RÉINITIALISER
            </span>
        </div>
        <transition name="fade">
            <div v-show="isFiltersVisisble" class="aside-desktop-filter-inner-right-block">
                <div class="filters">
                    <LotsFilter
                        v-for="filter in standardFilters"
                        :key="filter.type"
                        :filter="filter"
                        @toggle="onToggleFilter({ ...$event, type: filter.type })"
                    >
                        <button
                            v-if="filter.type === $types.ROOMS_TYPE"
                            :class="['typology-btnAll', { active: isAllRoomsActive }]"
                            @click="onToggleAllRoomsFiltersHandler(isAllRoomsActive)"
                        >
                            {{ isAllRoomsActive ? 'Cacher' : 'Tous' }}
                        </button>
                    </LotsFilter>
                    <div class="d-flex flex-column gap-24">
                        <template v-for="filter in rangeFilters">
                            <template v-for="(option, index) in filter.options">
                                <LotsRangeFilter
                                    v-if="isRangeFilterByTypeAvailable(option.option)"
                                    :key="option.option + index"
                                    :filter="option"
                                    :range="minMaxRanges[option.option]"
                                    :reset="isFiltersResetted"
                                    :formatter="getFormatterByType(option.option)"
                                    @update:range="onUpdateRangeHandler({ range: $event, filter: option })"
                                />
                            </template>
                        </template>
                    </div>
                </div>
                <a href="" class="aside-desktop-filter-voir-tous" @click.prevent="toggleLotListVisibility">
                    <span class="aside-desktop-filter-voir-tous__icon">
                        <Icon icon="clarity:view-list-line" />
                    </span>
                    Voir tous les résultats sous forme de tableau
                </a>
            </div>
        </transition>
    </div>
</template>

<script>
import { Icon } from '@iconify/vue2';
import scheme3dMenuFiltersMixin from '@front/mixins/scheme3dMenuFiltersMixin';
import LotsRangeFilter from '@front/components/Filters/LotsFilter/LotsRangeFilter.vue';
import LotsFilter from '@front/components/Filters/LotsFilter/LotsFilter.vue';

export default {
    name: 'AsideDesktopFilters',
    mixins: [scheme3dMenuFiltersMixin],
    components: {
        LotsRangeFilter,
        LotsFilter,
        Icon
    },
    data() {
        return {
            isFiltersVisisble: false
        };
    }
};
</script>

<style lang="scss" scoped>
.aside-desktop-filter {
    &-block {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #ffffff;
        border-radius: 5px;

        &::before {
            position: absolute;
            z-index: -1;
            inset: 0 -10px;
            display: block;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 4px 4px 0 0;
            content: '';
            opacity: 0;
            transition: opacity 0.5s;
        }

        &.hovered {
            &::before,
            .aside-desktop-filter-option--green {
                opacity: 1;
            }
        }
    }

    &-inner-right-block {
        position: absolute;
        top: 100%;
        border-radius: 5px;
        margin-top: 14px;
        padding: 18px 18px 14px;
        background-color: rgba(0, 0, 0, 0.5);
        width: max-content;
        max-width: 40vw;

        &::before {
            position: absolute;
            z-index: -1;
            inset: -14px -10px -10px;
            display: block;
            background-color: rgba(0, 0, 0, 0.5);
            content: '';
            border-radius: 0 4px 4px;
        }

        .filters {
            .filter {
                display: flex;
                gap: 10px;
                justify-content: flex-start;
                align-items: center;

                ::v-deep {
                    .range-slider {
                        width: 80% !important;
                    }

                    .inner-menu__title {
                        display: flex;
                        align-items: center;
                        height: 30px;
                        margin-bottom: 20px;
                        white-space: nowrap;
                        font-family: 'Lato', 'Roboto', sans-serif;
                        font-size: 15px;

                        &::after {
                            content: ':';
                        }
                    }

                    .rooms__filter {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 5px 10px;
                        height: auto;
                        max-width: unset;
                        text-align: left;
                        overflow: visible !important;

                        .typology-item {
                            flex: 0 1;
                            min-width: unset !important;
                            width: auto;
                            height: auto;
                            margin: 0;
                            padding: 0;
                            background: transparent;
                            text-align: left;
                            transform: translateY(2px);

                            &__checkbox {
                                text-align: left;

                                &__label {
                                    color: var(--item-color);
                                    text-transform: lowercase;
                                    font-size: 12px;
                                    font-family: 'Lato', 'Roboto', sans-serif;
                                    display: block;
                                    text-align: left;
                                    white-space: nowrap;
                                    opacity: 0.7;
                                    margin: 0 0 2px 0;
                                    padding: 0;

                                    &::first-letter {
                                        text-transform: capitalize;
                                    }
                                }
                            }

                            &.active {
                                .typology-item__checkbox__label {
                                    font-weight: bold;
                                    text-align: left;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-option {
        text-decoration: underline;

        &--green {
            color: #00ff4e;
            margin-left: 8px;
            font-size: 14px;
            opacity: 0;
            transition: opacity 0.5s;
        }

        svg {
            margin-right: 8px;
        }
    }

    &-voir-tous {
        display: flex;
        align-items: flex-end;
        font-size: 14px;
        text-decoration: underline;
        color: #ffffff;
        margin-top: 12px;

        &__icon {
            display: block;
            font-size: 24px;
            overflow: hidden;
            transform: translateY(4px);

            svg {
                transform: translateX(-4px);
            }
        }
    }

    &-floors,
    &-floors.open {
        background-color: transparent;
        border-radius: 0;
        margin-top: 8px;

        ::v-deep {
            .ui-select-container {
                padding: 4px 0 4px 16px;
                background-color: transparent;
                border-radius: 4px 4px 0 0;
            }

            .ui-select-options {
                padding: 0 0 0 18px;
                background-color: transparent;
                border-radius: 0 0 4px 4px;
            }

            .ui-select-container {
                .ui-select__icon {
                    left: -4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 16px;
                    top: 0;
                    bottom: 0;
                    height: auto;
                    gap: 4px;
                    flex-direction: column;

                    &::before,
                    &::after {
                        position: static;
                        transform-origin: center;
                    }
                }
            }

            .ui-select-options {
                top: 100%;

                &__item {
                    padding: 8px 0;
                }
            }
        }
    }

    &-floors.open {
        ::v-deep {
            .ui-select-container {
                background-color: rgba(0, 0, 0, 0.3);

                .ui-select__icon {
                    &::before {
                        transform: rotate(270deg) translate(-6px, 4px);
                    }
                }
            }

            .ui-select-options {
                background-color: rgba(0, 0, 0, 0.5);
            }
        }
    }
}
</style>
