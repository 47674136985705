import {CUSTOM_LANDING_VIEW} from '@front/constants/mainPageTemplates';

export const getProjectExtendData = (store) => {
    let projectExtendData = null;
    const externalRepo = store.getters['config/externalRepo'];
    const mainPageTemplate = store.getters['config/mainPageTemplate'];

    if (externalRepo && mainPageTemplate !== CUSTOM_LANDING_VIEW) {
        projectExtendData = require(`@front/project-extend/${externalRepo}`);
    }

    return projectExtendData;
};
