import clone from 'lodash.clonedeep';
import HttpClient from '@vue/js/service/HttpClient';
import ApiConstants from '@common/constants/api-constants';
import {ADDITIONS_TYPE, AREA_TYPE, PRICE_TYPE} from '@common/constants/lot-types';

export default {
    async getSchemeData({commit, getters}) {
        const json = await (await fetch('data/project/scheme_module/config.json')).json();
        commit('setSchemeData', json || []);
        commit('setLotsData', json.lots || []);
        commit('setFilters', json.filters || []);
        commit('setEnableLotsVisibility', json.enableLotsVisibility || false);
        commit('setEnableAxonometricLot', json.enableAxonometricLot || false);
        commit('setEnableLotsComparison', json.enableLotsComparison || false);
        commit('setEnableCreatingLotInfoForm', json.enableCreatingLotInfoForm || false);
        commit('setAssetsPath', json.assetsPath || '');
        commit('setLotsPath', json.lotsPath || '');
        commit('setProgramId', json.programId || '');
        commit('setLotsInfoApi', json.lotsInfoApi || '');
        commit('setMaxCanvasScale', json.maxCanvasScale || 20);
        commit('setEnableCompassFloors', json.enableCompass || false);
        commit('setShowPricePublic', json.showPricePublic || false);
        commit('setShowPriceConnected', json.showPriceConnected || false);
        commit('setShowLotAvailabilityConnected', json.showLotAvailabilityConnected || false);
        commit('setShowLotAvailabilityPublic', json.showLotAvailabilityPublic || false);

        commit('setLotsData', _modifyLots(json.lots, getters.filters));

        return json;
    },
    updateFavorites({commit, state}, favorite) {
        if (favorite == null) {
            commit('setFavorites', []);

            return;
        }

        const currentFavorites = state.favorites;

        if (!!currentFavorites.find((favoriteLot) => favoriteLot?.lot === favorite?.lot)) {
            currentFavorites.splice(
                currentFavorites.findIndex((p) => p === favorite),
                1,
            );
        } else {
            currentFavorites.push(favorite);
        }

        commit('setFavorites', currentFavorites);
    },
    sendCallbackRequest({}, payload) {
        return HttpClient.post(ApiConstants.CALLBACK_URL, payload)
            .then((response) => {
                this._vm.$notify({
                    title:
                        'Votre demande a bien été prise en compte.\n' +
                        'Un conseiller reviendra vers vous dans les plus brefs délais.',
                    type: 'success',
                });

                return response;
            })
            .catch((error) => {
                this._vm.$notify({
                    title: 'Error',
                    text: error?.response?.data?.error ?? 'Error',
                    type: 'error',
                });

                return error;
            });
    },
};

function _modifyLots(lots, filters) {
    return lots.map((lot) => {
        const clonedLot = clone(lot);

        filters.forEach(({options}) => {
            options.forEach(({option, type, color, range}) => {
                let modifiedCondition = option === clonedLot[type];

                if (type === ADDITIONS_TYPE) {
                    modifiedCondition = !!clonedLot[option];
                }

                if ([AREA_TYPE, PRICE_TYPE].includes(type)) {
                    modifiedCondition =
                        clonedLot[option] >= range[0] && clonedLot[option] <= range[1] && clonedLot[option] !== 0;
                }

                if (modifiedCondition) {
                    clonedLot.palette = `${clonedLot.palette ? `${clonedLot.palette}|` : ''}${color}`;
                }
            });
        });

        return {...clonedLot};
    });
}
