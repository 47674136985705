import {
    ORIENTATIONS_TYPE,
    FLOORS_TYPE,
    STATUSES_TYPE,
    APARTMENTS_TYPE,
    ROOMS_TYPE,
    SALE_TYPE,
    POINT_OF_INTEREST_TYPE,
    DISPLAY_TYPE,
    ADDITIONS_TYPE,
    PRICE_TYPE,
    AREA_TYPE,
    LOT_TYPE,
    LOT_AS_LINK_TYPE,
    LOT_AS_TEMPLATE_TYPE,
    TRANCHE,
} from '@common/constants/lot-types';

export default {
    [ORIENTATIONS_TYPE]: ['N', 'S', 'E', 'O', 'N/E', 'N/O', 'S/E', 'S/O', 'N/S', 'E/O'],
    [FLOORS_TYPE]: [
        '',
        'R-2',
        'R-1',
        'Sous-sol',
        'RDJ',
        'RDC',
        'Entresol',
        'R+1',
        'R+2',
        'R+3',
        'R+4',
        'R+5',
        'R+6',
        'R+7',
        'R+8',
        'R+9',
        'R+10',
        'R+11',
        'R+12',
        'Vue Globale',
        'R+14',
        'R+15',
        'R+16',
        'R+17',
        'R+18',
        'Combles',
        'NC',
    ],
    [STATUSES_TYPE]: [
        'DISPONIBLE',
        'OPTION',
        'INDISPONIBLE',
        'ALLOTEMENT',
        'PRE_RESERVATION',
        'SIGNATURE',
        'LIVRAISON',
        'DESISTEMENT',
    ],
    [APARTMENTS_TYPE]: [
        'Appartement',
        'Maison',
        'Commerce',
        'Bureau',
        'Duplex',
        'Parking/Box',
        'Annexe',
        'Bâtiment',
        'Boutique',
        'Château',
        'hôtel particulier',
        'Immeuble',
        'Local',
        'Autre',
    ],
    [ROOMS_TYPE]: ['STUDIO', '1P', '2P', '3P', '4P', '5P', '6P', '7P', '8P'],
    [SALE_TYPE]: ['accession', 'investissement'],
    [POINT_OF_INTEREST_TYPE]: [
        'Biodiversité',
        'Circulation douce',
        'Espace de convivialité',
        'Espaces communs',
        'Données économiques',
        'Œuvres',
        'Parcours de santé',
        'Performances énergétiques',
        'Qualité de vie',
        'Restaurant',
        'Commission',
        'Salle Pleniaire',
        'Coworking',
    ],
    [ADDITIONS_TYPE]: ['hasBalcony', 'hasCaves', 'hasLoggia', 'hasTerrace', 'hasYard', 'hasGarden'],
    [PRICE_TYPE]: ['price'],
    [AREA_TYPE]: ['area'],
    [TRANCHE]: ['1 - 1', '2 - 2', '3 - 3', '4 - 4'],

    // Non-filtering values
    [DISPLAY_TYPE]: [LOT_TYPE, LOT_AS_LINK_TYPE, LOT_AS_TEMPLATE_TYPE],

    // !If you need to add new filters to display on the front, then add them to the array below
    availableFilterTypes: [
        ORIENTATIONS_TYPE,
        FLOORS_TYPE,
        STATUSES_TYPE,
        APARTMENTS_TYPE,
        ROOMS_TYPE,
        SALE_TYPE,
        POINT_OF_INTEREST_TYPE,
        ADDITIONS_TYPE,
        AREA_TYPE,
        PRICE_TYPE,
        TRANCHE,
    ],
};
