import {updateField} from 'vuex-map-fields';

export default {
    updateField,
    toggleActivityAdditionalWindow(state, windowName = null) {
        if (!state.activeAdditionalWindow || state.activeAdditionalWindow !== windowName) {
            state.activeAdditionalWindow = windowName;
        } else {
            state.activeAdditionalWindow = null;
        }
    },
    setGalleryMode(state, mode = '') {
        state.galleryMode = mode;
        state.isBurgerWhiteShadow = !!mode;
    },
    setIsMenuVisible(state, payload) {
        state.isMenuVisible = payload;
    },
    changeIsMenuMustBeStatic(state, payload) {
        state.isMenuMustBeStatic = payload;
    },
    changeIsMenuMustBeHidden(state, payload) {
        state.isMenuMustBeHidden = payload;
    },
    setIsMobileMenuExpanded(state, payload) {
        state.isMobileMenuExpanded = payload;
    },
    setIsCloseBurgerButtonHide(state, payload) {
        state.isCloseBurgerButtonHide = payload;
    },
};
