import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            enableSchemeLinkDisplayInMenu: 'config/enableSchemeLinkDisplayInMenu'
        })
    },
    methods: {
        showMenuItem(menuItem, isActive) {
            if (!isActive) {
                return;
            }

            if (menuItem.name === this.$constants.SСHEME_PAGE) {
                return this.enableSchemeLinkDisplayInMenu;
            }

            return true;
        }
    }
};
